export const WIDGETS: Array<IWidgetData> = [
  {
    slug: 'recurring',
    icon: 'checklist',
    canCreateOverlay: true,
    overlaySize: 'large',
    renderAs: 'list',
    listModifier: 'controls',
    color: 'alert'
  },
  {
    slug: 'qm_completion',
    icon: 'manual',
    canCreateOverlay: false,
    renderAs: 'comparison',
    comparisonLabel: I18n.t('global.labels.complete')
  },
  {
    slug: 'past_due',
    icon: 'calendar',
    canCreateOverlay: true,
    overlaySize: 'large',
    renderAs: 'list',
    linkTarget: '_blank',
    color: 'alert'
  },
  {
    slug: 'approaching',
    icon: 'calendar',
    canCreateOverlay: true,
    overlaySize: 'large',
    renderAs: 'list',
    linkTarget: '_blank',
    color: 'warning'
  },
  {
    slug: 'completed',
    icon: 'success-outline',
    canCreateOverlay: true,
    overlaySize: 'large',
    linkTarget: '_blank',
    renderAs: 'list'
  },
  {
    slug: 'shortcuts',
    icon: 'navigation',
    canCreateOverlay: true,
    renderAs: 'list',
    overlaySize: 'medium',
    listModifier: 'centered',
    overlayIcon: 'cog'
  },
  {
    slug: 'news',
    icon: 'news',
    canCreateOverlay: true,
    overlaySize: 'small',
    listModifier: 'centered',
    linkTarget: '_blank',
    renderAs: 'list',
    showYear: true
  },
  {
    slug: 'qms_reports',
    icon: 'reports',
    canCreateOverlay: true,
    overlaySize: 'medium',
    renderAs: 'list'
  },
  {
    slug: 'training',
    icon: 'evaluations',
    canCreateOverlay: true,
    overlaySize: 'medium',
    renderAs: 'list'
  },
  {
    slug: 'assessors',
    icon: 'users',
    canCreateOverlay: true,
    overlaySize: 'small',
    renderAs: 'list'
  },
  {
    slug: 'invoices',
    icon: 'checklist',
    canCreateOverlay: true,
    overlaySize: 'medium',
    renderAs: 'list',
    listModifier: 'controls',
    color: 'alert'
  }
];
