import * as React from 'react';
import classNames from 'classnames';

import { Icon } from '.';

interface LoadingProps {
  strokeColor?: string;
  size?: string;
}

/**
 * Stateless component to display a loading graphic.
 */
export const Loading = ({ strokeColor = '#a1a1a1', size }: LoadingProps) => {
  return (
    <div
      className={classNames('loading-wrapper', {
        [`loading-wrapper--${size}`]: size !== undefined
      })}>
      <div
        className={classNames('loading', {
          [`loading--${size}`]: size !== undefined
        })}
        style={strokeColor ? { stroke: strokeColor } : undefined}>
        <Icon icon="loading" />
      </div>
    </div>
  );
};
