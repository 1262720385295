import * as React from 'react';

export interface BadgeWrapperProps {
  color: string;
  count: number;
  render(): JSX.Element;
}

export const BadgeWrapper = ({ color, count, render }: BadgeWrapperProps) => (
  <div className="badge-wrapper">
    <div className="badge-wrapper__contents">{render()}</div>
    {count > 0 && (
      <span
        className={`badge ${color} badge--length-${
          count.toString().length
        } badge-wrapper__badge`}>
        {count}
      </span>
    )}
  </div>
);
