import * as React from 'react';
import classNames from 'classnames';

interface RadioGroupFieldProps extends ICustomField {
  choices: Array<SelectOption>;
  wrapperClassName?: string;
}

export const RadioGroupField = (props: RadioGroupFieldProps) => {
  const { name, label, required, wrapperClassName, choices } = props;

  return (
    <div className={wrapperClassName}>
      {label && (
        <label className={classNames({ 'is-required': required })}>
          {label}
        </label>
      )}
      <ul className="menu simple">
        {choices.map(choice => (
          <li key={choice.value}>
            <div className="radio">
              <input
                type="radio"
                name={name}
                value={choice.value}
                required={required}
                id={`${name}_${choice.value})`}
              />
              <label htmlFor={`${name}_${choice.value})`}>{choice.label}</label>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
