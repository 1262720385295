import { isUndefined, uniqueId } from 'lodash-es';
import * as React from 'react';

import { componentClasses, executeDelete } from '../../shared';
import { Icon } from '../common';
import { ConfirmDeleteAction } from '../common/confirm-actions';
import { asAction, asLink } from '../common/hoc';
import {
  ListItemAction as Action,
  ListItemActionProps,
  ListItemDate as Date,
  ListItemTitle as Title,
  ListItemTitleProps
} from './ListItemStructural';
import classNames from "classnames";

export interface ListItemProps extends IAction {
  itemStyle: string | Array<string>;
  itemModifier?: string;
  index?: number;
  isWide?: boolean;
  isNumbered?: boolean;
  isHidden?: boolean;
  color?: string;
  linkTarget?: string;
  showYear?: boolean;
  updateListData?: (items: Array<IAction>) => void;
  children?: React.ReactNode;
}

/**
 * Stateless component to render an individual item in a list.
 */
export const ListItem = (props: ListItemProps) => {
  const {
    title,
    subtitle,
    label,
    date,
    url,
    viewed,
    type,
    actions,
    actionWrapper,
    deleteRoute,
    itemStyle,
    itemModifier,
    index,
    icon,
    isWide,
    isNumbered,
    isHidden,
    color,
    showYear,
    linkTarget,
    className,
    updateListData,
    children
  } = props;

  const titleProps: ListItemTitleProps = {
    title,
    subtitle,
    label,
    itemModifier,
    type
  };

  const actionProps: ListItemActionProps = {
    title,
    actions,
    url,
    deleteRoute,
    updateListData
  };

  const itemStyles = Array.isArray(itemStyle) ? itemStyle : [itemStyle];

  const componentModifiers = Object.assign(
    {
      wide: isWide,
      enclosure: children !== undefined
    },
    itemStyles.reduce((final: { [key: string]: boolean }, style) => {
      final[style] = true;

      return final;
    }, {}),
    itemModifier
      ? {
          [itemModifier]: true
        }
      : {},
    url
      ? {
          link: true
        }
      : {}
  );

  // first prepare the core content of the list item as a standalone stateless component...
  const TitleAndActionCore = () => {
    return (
      <>
        <Title {...titleProps} />
        <Action {...actionProps} />
      </>
    );
  };

  // ...then wrap it as a link if contextually appropriate
  let TitleAndAction: any;
  if (actionWrapper) {
    TitleAndAction = asAction(actionWrapper, 'list-item__link-wrap')(
      TitleAndActionCore
    );
  } else if (url && !actionWrapper) {
    TitleAndAction = asLink(url, 'list-item__link-wrap', linkTarget)(
      TitleAndActionCore
    );
  } else {
    TitleAndAction = TitleAndActionCore;
  }

  // set the date color for notifications
  let dateColor;
  if (!isUndefined(viewed)) {
    dateColor = !viewed ? 'alert' : undefined;
  } else {
    dateColor = color;
  }

  return (
    <div
      key={uniqueId('ListItem_')}
      className={classNames(componentClasses('list-item', componentModifiers), className)}>
      {isNumbered && (
        <div
          className={`list-item__badge badge ${`badge--length-${
            index.toString().length
          }`}`}>
          {index + 1}
        </div>
      )}
      {icon && (
        <div className="list-item__icon">
          {actionWrapper && (
            <a role="button" onClick={actionWrapper}>
              <Icon icon={icon} size="medium" />
            </a>
          )}
          {!actionWrapper && (
            <Icon icon={icon} size="medium" />
          )}
        </div>
      )}
      {date && <Date date={date} color={dateColor} showYear={showYear} />}
      <TitleAndAction />
      {deleteRoute && (
        <ConfirmDeleteAction
          id={`ConfirmDelete_${title}`}
          title={title}
          itemType={I18n.t('global.labels.shortcut.one').toLowerCase()}
          buttonProps={{
            label: <Icon icon="close" size="medium" />,
            classNames: 'small clear alert can-transition-transform-fast'
          }}
          action={async () => {
            const updatedItems = await executeDelete(deleteRoute);

            updateListData(updatedItems);
          }}
        />
      )}
      {children}
    </div>
  );
};
