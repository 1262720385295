import * as React from 'react';
import { createPortal } from 'react-dom';

import { componentClasses, safeOverlayPosition, STATES } from '../../shared';

interface OverlayProps {
  isVisible: boolean;
  topPosition: number;
  size?: string;
  render: Function;
}

export class Overlay extends React.Component<OverlayProps> {
  public props: OverlayProps;
  private overlayElement: HTMLElement;
  private backdropElement: HTMLElement;

  public render() {
    const { isVisible, topPosition, size, render } = this.props;
    const componentModifiers = size ? { [size]: true } : undefined;

    Object.assign({}, size ? { [size]: true } : {});

    return createPortal(
      <div
        className={`overlay ${isVisible ? STATES.isVisible : ''}`}
        ref={overlay => (this.overlayElement = overlay)}>
        <div
          className="overlay__backdrop"
          ref={backdrop => (this.backdropElement = backdrop)}
        />
        <div
          className={componentClasses('overlay__content', componentModifiers)}>
          {render()}
        </div>
      </div>,
      document.body
    );
  }

  private setPosition(): React.CSSProperties {
    return safeOverlayPosition(this.props.topPosition);
  }
}
