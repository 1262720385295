import * as React from 'react';
import { Button, Colors, Row, Column, Sizes } from 'react-foundation';
import { last, find, pick } from 'lodash-es';

import { ListItem } from '../list-item/ListItem';
import { Overlay, OverlayContent } from '../overlay';
import { Loading, Icon } from '../common';
import { executeGet, executeDelete } from '../../shared/utils';

interface NotificationsProps {
  notificationsRoute: string;
  dismissRoute: string;
}

interface NotificationsState {
  items: Array<IAction>;
  deletedIds: { [key: number]: boolean };
  overlayIsVisible: boolean;
  isLoading: boolean;
  hasError: boolean;
  showClearAllWarning: boolean;
}

export class Notifications extends React.Component<
  NotificationsProps,
  NotificationsState
> {
  public props: NotificationsProps;
  public state: NotificationsState;
  private scrollTopper: HTMLElement;
  private overlayWidth: number = 0;

  constructor(props: NotificationsProps) {
    super(props);

    this.state = {
      items: [],
      deletedIds: {},
      isLoading: true,
      hasError: false,
      overlayIsVisible: false,
      showClearAllWarning: false
    };

    this.handleMarkViewed = this.handleMarkViewed.bind(this);
    this.handleDismissAll = this.handleDismissAll.bind(this);
    this.handleRefreshList = this.handleRefreshList.bind(this);
    this.handleToggleClearAllWarning =
      this.handleToggleClearAllWarning.bind(this);
    this.renderNotificationAction = this.renderNotificationAction.bind(this);
  }

  private get badgeCount() {
    return this.state.items.filter(item => !item.viewed).length;
  }

  public async componentDidMount() {
    const $scrollTopper = $(this.scrollTopper);
    const $overlay = $scrollTopper.parents('.overlay-content');

    if ($overlay.length) {
      this.overlayWidth = $overlay.width();
    }

    this.handleRefreshList();
  }

  public handleMarkViewed(id: number, viewedUrl: string, externalUrl: string) {
    if (externalUrl) window.open(externalUrl);

    executeGet(viewedUrl).then(() => {
      this.setState((prevState: NotificationsState) => ({
        items: prevState.items.map(item =>
          item.id === id ? { ...item, viewed: true } : item
        )
      }));
    });
  }

  public handleDismissOne(id: number, dismissUrl: string) {
    this.setState(
      {
        deletedIds: {
          ...this.state.deletedIds,
          [id]: true
        }
      },
      () => {
        executeDelete(dismissUrl).then(() => {
          let { deletedIds } = this.state;
          delete deletedIds[id];

          this.setState({
            items: this.state.items.filter(item => item.id !== id),
            deletedIds
          });
        });
      }
    );
  }

  public handleDismissAll() {
    const { dismissRoute } = this.props;
    const { items } = this.state;

    if (items.length) {
      const itemIds = items.map(item => item.id);
      const url = `${dismissRoute}?notification_ids=${itemIds.join(',')}`;

      this.setState(
        {
          isLoading: true
        },
        () => {
          executeGet(url)
            .then(() => {
              this.setState({
                items: [],
                showClearAllWarning: false,
                isLoading: false
              });
            })
            .catch(() => {
              this.setState({
                hasError: true
              });
            });
        }
      );
    }
  }

  public handleRefreshList() {
    this.getNotifications().then(items => {
      this.setState(() => {
        return {
          items: items,
          isLoading: false
        };
      });
    });
  }

  public handleToggleClearAllWarning() {
    this.setState({
      showClearAllWarning: !this.state.showClearAllWarning
    });
  }

  public renderNotifications() {
    const { items, deletedIds, isLoading, hasError, showClearAllWarning } =
      this.state;

    if (showClearAllWarning) {
      return (
        <>
          <p className="h3 text-alert text-center push--bottom">
            <Icon icon="alert" size="medium" classNames="icon--alert" />
            <span className="uppercase push--left">
              {I18n.t('global.labels.warning')}!
            </span>
          </p>
          <p className="h5 text-center">
            {I18n.t('notifications.labels.clear_all_warning_1')}
          </p>
          <p className="h5 text-center push--bottom">
            {I18n.t('notifications.labels.clear_all_warning_2')}
          </p>
          <Row isCollapsed>
            <Column className="push--right-half">
              <Button
                color={Colors.ALERT}
                isHollow
                isExpanded
                className="emphasis"
                isDisabled={isLoading}
                onClick={this.handleDismissAll}>
                {I18n.t('notifications.buttons.confirm_mark_all')}
              </Button>
            </Column>
            <Column isShrunk>
              <Button
                color={Colors.SECONDARY}
                className="emphasis"
                isDisabled={isLoading}
                onClick={this.handleToggleClearAllWarning}>
                {I18n.t('global.buttons.cancel')}
              </Button>
            </Column>
          </Row>
        </>
      );
    }

    return (
      <>
        {!isLoading && items.length > 0 && (
          <div
            className="scroll-topper__top"
            style={{ width: this.overlayWidth }}>
            <Button
              className="emphasis"
              isHollow
              isExpanded
              color={Colors.ALERT}
              onClick={this.handleToggleClearAllWarning}>
              {I18n.t('notifications.buttons.mark_all')}
            </Button>
          </div>
        )}
        <div className="scroll-topper__bottom">
          {hasError && <p>{I18n.t('notifications.labels.error')}</p>}
          {isLoading && <Loading />}
          {!isLoading && !items.length && (
            <p className="text-center h4">
              {I18n.t('notifications.labels.none_found')}
            </p>
          )}
          {!isLoading && items.length > 0 && (
            <ul className="list list--stacked list--stacked-actions">
              {items.map((item, index) => (
                <div className="list__item" key={`Notification_${index}`}>
                  <ListItem
                    id={item.id}
                    title={item.title}
                    subtitle={item.subtitle}
                    type={item.type}
                    date={item.date}
                    viewed={item.viewed}
                    itemStyle={['stacked', 'stacked-actions'].concat(
                      deletedIds[item.id] ? ['disabled'] : []
                    )}
                    actions={item.buttons.map(button =>
                      this.renderNotificationAction(button, item.id, item.url)
                    )}
                  />
                </div>
              ))}
            </ul>
          )}
        </div>
      </>
    );
  }

  public render() {
    const { overlayIsVisible, items, isLoading } = this.state;
    const badgeCount = this.badgeCount;

    return (
      <>
        <a
          role="button"
          onClick={() => {
            this.setState({
              overlayIsVisible: true
            });
          }}>
          <div className="badge-wrapper">
            <div className="badge-wrapper__contents">
              <Icon icon="notification" size="medium" />
            </div>
            {!isLoading && badgeCount > 0 && (
              <div
                className={`badge-wrapper__badge badge alert badge--length-${
                  badgeCount.toString().length
                }`}>
                {badgeCount}
              </div>
            )}
          </div>
        </a>
        <Overlay
          isVisible={overlayIsVisible}
          size="small"
          topPosition={0}
          render={() => (
            <OverlayContent
              title={I18n.t('global.labels.notification.other')}
              icon="notification"
              closeOverlay={() => {
                this.setState({
                  overlayIsVisible: false
                });
              }}>
              <div className="scroll-topper" ref={e => (this.scrollTopper = e)}>
                {this.renderNotifications()}
              </div>
            </OverlayContent>
          )}
        />
      </>
    );
  }

  private renderNotificationAction(
    button: IButton,
    id: number,
    externalUrl?: string
  ) {
    switch (button.label) {
      case I18n.t('global.buttons.view'):
        return (
          <Button
            key={`Notification_View_${id}`}
            size={Sizes.SMALL}
            color={Colors.SECONDARY}
            className="push--bottom-half"
            onClick={() => this.handleMarkViewed(id, button.url, externalUrl)}>
            <span>{I18n.t('global.buttons.go')}</span>
            <Icon icon="forward" />
          </Button>
        );
      case I18n.t('global.buttons.dismiss'):
        return (
          <Button
            key={`Notification_View_${id}`}
            size={Sizes.TINY}
            color={Colors.ALERT}
            className="push--bottom-half"
            isHollow
            onClick={() => this.handleDismissOne(id, button.url)}>
            <Icon icon="close" />
          </Button>
        );
      default:
        return null;
    }
  }

  private getNotifications() {
    const { notificationsRoute } = this.props;

    return executeGet(notificationsRoute);
  }
}
