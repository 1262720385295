import * as React from 'react';
import { Row, Column } from 'react-foundation';

interface NoneFoundProps {
  message: string;
}

export const NoneFound = ({ message }: NoneFoundProps) => (
  <Row>
    <Column>
      <p>{message}</p>
    </Column>
  </Row>
);
