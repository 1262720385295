import * as React from 'react';
import { Link, Sizes, Colors, Row, Column } from 'react-foundation';

import { fieldName } from '../../../../shared/utils';
import { ControlBar, Icon } from '../../../common';
import { TextField } from '../../../fields';
import { List } from '../../../list/List';
import {
  LabDetailsFormProps,
  LabDetailsFormState,
  Subscription
} from '../LabDetailsForm';
import { UpdateItemHandler, RemoveItemHandler } from './types';

interface LabDetailsFormPlmFieldsProps
  extends Pick<
      LabDetailsFormProps,
      | 'canAddPrimaryLabManager'
      | 'currentItem'
      | 'currentUserId'
      | 'plmRoleId'
      | 'primaryLab'
      | 'showPrimaryLabManager'
    >,
    Pick<
      LabDetailsFormState,
      | 'newPrimaryLabManagers'
      | 'primaryLabManagerEmail'
      | 'primaryLabManagerFirstName'
      | 'primaryLabManagerLastName'
      | 'primaryLabManagers'
      | 'showPrimaryLabManagerFields'
    > {
  baseParam: string;
  addPlm(): void;
  removeItem: RemoveItemHandler;
  updateItem: UpdateItemHandler;
}

const LabDetailsFormPlmFields = (props: LabDetailsFormPlmFieldsProps) => {
  const {
    baseParam,
    canAddPrimaryLabManager,
    currentItem,
    currentUserId,
    newPrimaryLabManagers,
    plmRoleId,
    primaryLab,
    primaryLabManagerEmail,
    primaryLabManagerFirstName,
    primaryLabManagerLastName,
    primaryLabManagers,
    removeItem,
    addPlm,
    showPrimaryLabManager,
    showPrimaryLabManagerFields,
    updateItem
  } = props;

  const f = fieldName(baseParam);
  let list: IListActions;

  if (primaryLabManagers.length) {
    list = {
      slug: 'primaryLabManagers',
      items: primaryLabManagers,
      title: 'Current Primary Lab Managers'
    };
  }

  return (
    <>
      <ControlBar isAutoHeight={canAddPrimaryLabManager} isFlush>
        <p className="h4 flush--bottom">
          {I18n.t('manage_lab.labels.primary_lab_manager.other')}
        </p>
        {canAddPrimaryLabManager && (
          <Link
            size={Sizes.SMALL}
            color={Colors.SECONDARY}
            isHollow
            className="clear emphasis"
            onClick={addPlm}>
            <Icon icon="add-small" size="very-small" />
            <span>{I18n.t('primary_lab.buttons.add_plm')}</span>
          </Link>
        )}
      </ControlBar>
      {canAddPrimaryLabManager && newPrimaryLabManagers.length > 0 && (
        <ul className="list list--panel">
          {newPrimaryLabManagers.map((plmFields, index) => {
            const { id, firstName, lastName, email } = plmFields;

            return (
              <li className="list__item">
                <div className="list-item list-item--panel">
                  <Row key={`plmFields_${index.toString()}`}>
                    <Column small={12} medium={6}>
                      <TextField
                        name={f(`users_attributes.${index}.first_name`)}
                        value={firstName}
                        label={I18n.t('users.labels.first_name')}
                        showLabel
                        required
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          updateItem(
                            'newPrimaryLabManagers',
                            id,
                            'firstName',
                            event.target.value
                          );
                        }}
                      />
                    </Column>
                    <Column small={12} medium={6}>
                      <TextField
                        name={f(`users_attributes.${index}.last_name`)}
                        value={lastName}
                        label={I18n.t('users.labels.last_name')}
                        showLabel
                        required
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          updateItem(
                            'newPrimaryLabManagers',
                            id,
                            'lastName',
                            event.target.value
                          );
                        }}
                      />
                    </Column>
                    <Column small={8}>
                      <TextField
                        name={f(`users_attributes.${index}.email`)}
                        value={email}
                        label={I18n.t('global.labels.email_address')}
                        required
                        showLabel
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          updateItem(
                            'newPrimaryLabManagers',
                            id,
                            'email',
                            event.target.value
                          );
                        }}
                      />
                      <input
                        type="hidden"
                        name={f(`users_attributes.${index}.created_by_id`)}
                        value={currentUserId}
                      />
                      <input
                        type="hidden"
                        name={f(
                          `users_attributes.${index}.bypass_lab_checklist`
                        )}
                        value="true"
                      />
                      <input
                        type="hidden"
                        name={f(`users_attributes.${index}.role_id`)}
                        value={plmRoleId}
                      />
                    </Column>
                    <Column small={4}>
                      <Link
                        role="button"
                        color={Colors.ALERT}
                        size={Sizes.SMALL}
                        isHollow
                        isExpanded
                        className="emphasis"
                        style={{ marginTop: 24 }}
                        onClick={() => {
                          removeItem('newPrimaryLabManagers', id);
                        }}>
                        {I18n.t('global.buttons.remove')}
                      </Link>
                    </Column>
                  </Row>
                </div>
              </li>
            );
          })}
        </ul>
      )}
      {list && (
        <Row>
          <Column>
            <List list={list} listStyle="stacked" isWide />
          </Column>
        </Row>
      )}
    </>
  );
};

export default LabDetailsFormPlmFields;
