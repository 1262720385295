import * as React from "react";
import { Badge, Colors } from "react-foundation";

import { STATES, componentClasses } from "../../shared";

export interface TabProps {
  slug: string;
  title: string;
  isActive: boolean;
  hasBadge?: boolean;
  badgeCount?: number;
  setSection?(section: string): void;
}

export const Tab = ({
  slug,
  title,
  isActive,
  hasBadge,
  badgeCount,
  setSection
}: TabProps) => {
  badgeCount = badgeCount || 0;

  return (
    <li className={`tabs-title ${isActive ? STATES.isActive : ""}`}>
      <a
        role="button"
        aria-selected={isActive}
        onClick={() => setSection(slug)}
      >
        {title}
        {hasBadge && (
          <Badge
            color={Colors.SECONDARY}
            className={`badge--length-${badgeCount.toString().length}`}
          >
            {badgeCount}
          </Badge>
        )}
      </a>
    </li>
  );
};

export interface TabsProps {
  tabs: Array<TabProps>;
  setSection(section: string): void;
  tabStyle?: "plain" | "bordered";
}

export const Tabs = ({ tabs, tabStyle = "plain", setSection }: TabsProps) => {
  return (
    <ul className={componentClasses("tabs", { [tabStyle]: true })}>
      {tabs.map(tab => (
        <Tab key={tab.title} setSection={setSection} {...tab} />
      ))}
    </ul>
  );
};
