import * as React from 'react';
import { Row, Column } from 'react-foundation';
import { isEqual, toUpper } from 'lodash-es';
import classnames from 'classnames';

import { createMarkup, executeGet, formatDate } from '../../../shared';
import { CheckboxField, DatePickerField, SelectField } from '../../fields';
import { ConfirmAction } from '../ConfirmAction';

interface ProcedureOption extends SelectOption {
  procedureType: string;
}

interface PutIntoServiceFormProps {
  searchRoute: string;
  updateParent(newState: any): void;
}

interface PutIntoServiceFormState {
  inServiceDate: Date;
  performCalibration: boolean;
  equipmentProcedureId: number;
  procedureOptions: Array<SelectOption>;
}

class PutIntoService extends React.Component<
  PutIntoServiceFormProps,
  PutIntoServiceFormState
> {
  props: PutIntoServiceFormProps;
  state: PutIntoServiceFormState = this.initialState();
  form: HTMLFormElement;

  public componentDidMount() {
    this.setEquipmentProcedures();
  }

  public componentDidUpdate(
    prevProps: PutIntoServiceFormProps,
    prevState: PutIntoServiceFormState
  ) {
    const { searchRoute, updateParent } = this.props;
    const {
      performCalibration,
      inServiceDate,
      equipmentProcedureId
    } = this.state;

    if (searchRoute !== prevProps.searchRoute) {
      this.setEquipmentProcedures();
    }

    if (performCalibration !== prevState.performCalibration) {
      if (performCalibration) {
        this.setEquipmentProcedures();
      } else {
        this.setState({
          procedureOptions: []
        });
      }
    }

    if (updateParent && !isEqual(this.state, prevState)) {
      updateParent({
        inServiceDate,
        equipmentProcedureId
      });
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: PutIntoServiceFormProps) {
    this.setState(this.initialState());
  }

  public render() {
    const {
      inServiceDate,
      performCalibration,
      equipmentProcedureId,
      procedureOptions
    } = this.state;

    return (
      <>
        <Row>
          <Column small={12}>
            <p
              dangerouslySetInnerHTML={createMarkup(
                I18n.t('modals.confirm_put_into_service.message_html', {
                  name
                })
              )}
            />
          </Column>
          <Column small={12} medium={5}>
            <DatePickerField
              name="inServiceDate"
              id="inServiceDate"
              label={I18n.t(
                'modals.confirm_put_into_service.labels.in_service_date'
              )}
              value={inServiceDate}
              alignCalendar="left"
              handleDateChange={(date: Date) => {
                this.setState({
                  inServiceDate: date
                });
              }}
            />
          </Column>
        </Row>
        <CheckboxField
          name="performCalibration"
          label="Would you like to perform a calibration?"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({
              performCalibration: event.target.checked
            });
          }}
        />
        {performCalibration && procedureOptions && (
          <SelectField
            options={procedureOptions}
            label="Select an Equipment Procedure"
            value={equipmentProcedureId}
            simpleValue
            showLabel
            onChange={(newOption: SelectOption) => {
              this.setState({
                equipmentProcedureId: Number(newOption.value)
              });
            }}
          />
        )}
      </>
    );
  }

  private async setEquipmentProcedures() {
    const { searchRoute } = this.props;
    const procedures: Array<ProcedureOption> = await executeGet(searchRoute);

    this.setState({
      procedureOptions: procedures.map(p => {
        return {
          value: p.value,
          label: p.label
        };
      })
    });
  }

  private initialState(): PutIntoServiceFormState {
    return {
      inServiceDate: new Date(),
      performCalibration: false,
      equipmentProcedureId: null,
      procedureOptions: []
    };
  }
}

interface ConfirmPutIntoServiceActionProps {
  id: string;
  name: string;
  url: string;
  proceduresRoute: string;
  isExpanded?: boolean;
  disabled?: boolean;
  updateParent(
    item: IEquipmentRecord,
    prevSection: string,
    nextSection: string
  ): void;
}

interface ConfirmPutIntoServiceActionState {
  inServiceDate: Date;
  equipmentProcedureId: number;
  isSaving: boolean;
}

type ConfirmPutIntoServiceResponse = {
  status: any;
  equipmentRecord?: IEquipmentRecord;
  procedureRecordPath?: string;
};

export class ConfirmPutIntoServiceAction extends React.Component<
  ConfirmPutIntoServiceActionProps,
  ConfirmPutIntoServiceActionState
> {
  props: ConfirmPutIntoServiceActionProps;
  state: ConfirmPutIntoServiceActionState;

  constructor(props: ConfirmPutIntoServiceActionProps) {
    super(props);

    this.state = this.initialState();

    this.updateState = this.updateState.bind(this);
  }

  static defaultProps = {
    isExpanded: true
  };

  componentDidUpdate(
    prevProps: ConfirmPutIntoServiceActionProps,
    prevState: ConfirmPutIntoServiceActionState
  ) {
    if (this.state.isSaving && !prevState.isSaving) {
      this.processUpdate();
    }
  }

  updateState(newState: any) {
    this.setState(newState);
  }

  handleProcessForm = () => {
    // flag a save to fire after update
    this.setState({
      isSaving: true
    });
  };

  render() {
    const { id, proceduresRoute, disabled, isExpanded } = this.props;
    const { isSaving } = this.state;
    const buttonIsDisabled = isSaving === true || disabled === true;

    return (
      <ConfirmAction
        id={`ConfirmAction_ConfirmPutIntoService_${id}`}
        title={I18n.t('modals.confirm_put_into_service.title')}
        message={
          <PutIntoService
            searchRoute={proceduresRoute}
            updateParent={this.updateState}
          />
        }
        buttonProps={{
          label: I18n.t('inventory.buttons.put_into_service'),
          classNames: classnames('hollow', 'alert', {
            expanded: isExpanded
          }),
          disabled: buttonIsDisabled
        }}
        action={this.handleProcessForm}
      />
    );
  }

  private processUpdate() {
    const { url, updateParent } = this.props;
    const { inServiceDate, equipmentProcedureId } = this.state;

    let requestUrl = `${url}?`;

    if (inServiceDate) {
      requestUrl += `calibration=true&inServiceDate=${formatDate(
        inServiceDate,
        'MM/DD/YYYY'
      )}`;
    }

    if (equipmentProcedureId) {
      requestUrl += `&equipment_procedure_id=${equipmentProcedureId.toString()}`;
    }

    executeGet(requestUrl)
      .then((response: ConfirmPutIntoServiceResponse) => {
        if (response.procedureRecordPath) {
          // redirect to the form, and don't bother updating the table
          window.location.href = response.procedureRecordPath;
        } else if (response.equipmentRecord) {
          updateParent(response.equipmentRecord, 'outOfService', 'inService');
        }

        this.setState({
          isSaving: false
        });
      })
      .catch(error => {})
  }

  private initialState(): ConfirmPutIntoServiceActionState {
    return {
      inServiceDate: new Date(),
      equipmentProcedureId: null,
      isSaving: false
    };
  }
}
