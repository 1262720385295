import * as React from 'react';
import { ConfirmAction, ConfirmActionButtonProps } from '../ConfirmAction';
import { createMarkup } from '../../../shared';

interface ConfirmDeleteActionProps {
  id: string;
  title?: string;
  itemType?: string;
  message?: string | React.ReactNode;
  buttonProps?: ConfirmActionButtonProps;
  action(): void;
}

export const ConfirmDeleteAction = ({
  id,
  title,
  itemType,
  action,
  message,
  buttonProps
}: ConfirmDeleteActionProps) => (
  <ConfirmAction
    id={`ConfirmAction_Delete_${id}`}
    title={I18n.t('modals.confirm_delete.title')}
    message={
      message || (
        <p
          dangerouslySetInnerHTML={createMarkup(
            I18n.t('modals.confirm_delete.message_html', {
              title: title,
              item: itemType
            })
          )}
        />
      )
    }
    buttonProps={
      buttonProps || {
        label: I18n.t('global.buttons.delete'),
        classNames: 'small hollow alert emphasis'
      }
    }
    action={action}
  />
);
