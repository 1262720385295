import * as React from 'react';
import { Callout } from 'react-foundation';

interface ErrorBoundaryProps {
  children: any;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: any;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true, error: error });
  }

  render() {
    if (this.state.hasError) {
      return <Callout>{this.state.error}</Callout>;
    }

    return this.props.children;
  }
}
