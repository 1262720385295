import * as React from 'react';

import { componentClasses } from '../../shared';
import { Icon } from '../common';

interface PageTitleProps {
  title: string;
  modifiers?: Array<string>;
  showWelcomeMessage?: boolean;
  currentUser?: CurrentUser;
  children?: React.ReactNode;
  newInvoice?: boolean;
  scrollToInvoicesWidget?: () => void;
}

const PageTitle = ({
                     title,
                     modifiers,
                     currentUser,
                     showWelcomeMessage,
                     children,
                     newInvoice,
                     scrollToInvoicesWidget
                   }: PageTitleProps) => {
  const coreClass = 'page-title';

  return (
    <div className={componentClasses(coreClass, modifierClasses(modifiers))}>
      <h1 className={`${coreClass}__title`}>
        <span>
          {title}
          {showWelcomeMessage && <small>{welcomeMessage(currentUser)}</small>}
        </span>
      </h1>
      {newInvoice && <span className="new-invoice-alert"><a title="Scroll to invoices" role="button" onClick={ scrollToInvoicesWidget }>New invoice<Icon icon="arrow-right" size="small" /></a></span>}
      {children && <div className={`${coreClass}__controls`}>{children}</div>}
    </div>
  );

  function welcomeMessage(user: CurrentUser): string {
    const { details } = user;

    return I18n.t('global.labels.welcome', {
      name: userName(details),
      role: details.role
    });
  }

  function userName(details: User.Details) {
    return `${details.firstName} ${details.lastName.slice(0, 1)}.`;
  }

  function modifierClasses(modifiers: Array<string>) {
    if (modifiers) {
      return modifiers.reduce((final: { [key: string]: boolean }, m) => {
        final[m] = true;
        return final;
      }, {});
    }
  }
};

export { PageTitle };
