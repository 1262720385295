import * as React from 'react';

import { List } from '../list/List';

interface MultiListProps {
  lists: Array<IList<any>>;
  listStyle: string;
  isWide: boolean;
  color?: string;
  actionCallback?: (data: any, slug?: string) => void;
}

/**
 * Stateless component for rendering several lists with titles.
 */
export const MultiList = ({
  lists,
  listStyle,
  isWide,
  color,
  actionCallback
}: MultiListProps) => {
  return (
    <div className="multi-list">
      {lists.map((list, index) => {
        const { title, subtitle, slug, items } = list;

        if (items && items.length > 0) {
          return (
            <div key={`MultiList_${slug}_${index}`}>
              <span className="multi-list__title">{title}</span>
              {subtitle && (
                <span className="multi-list__subtitle">{subtitle}</span>
              )}
              <div className="multi-list__body">
                <List
                  list={list}
                  listStyle={listStyle}
                  isWide={isWide}
                  color={color}
                  actionCallback={actionCallback}
                />
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};
