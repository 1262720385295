import { fw } from 'framewerk';
import { format, isAfter, isBefore, isValid, parse } from 'date-fns';
import {
  camelCase,
  isEmpty,
  isNumber,
  isObject,
  isUndefined,
  map,
  snakeCase,
  transform
} from 'lodash-es';

import controllers from '../controllers';
import { disable } from '../modules';
import { ckEditor, select2 } from '../plugins';

/**
 * Retrieves the current CSRF authentication token.
 */
export function getAuthToken(): string | undefined {
  const csrf = document.querySelector(
    'meta[name="csrf-token"]'
  ) as HTMLMetaElement;

  if (csrf) return csrf.content;
}

/**
 * Prepares a data object for a Rails-friendly POST.
 *
 * @param {string} [method='post'] The post method.
 */
export function preparePostData(method: string = 'post'): IPostData {
  method = method || 'post';

  return Object.assign(
    {},
    {
      _method: method,
      authenticity_token: getAuthToken() || ''
    }
  );
}

/**
 * Process a `<form>` tag asynchronously and execute one of two callbacks based
 * on response.
 *
 * @param saveRoute The URL to request to.
 * @param form The form tag to process.
 * @param formSuccess The success callback.
 * @param formFailure The failure callback.
 * @param extraOptions Additional options.
 * @param extraOptions.method Specify a request method.
 * @param extraOptions.responseType Specify the expected response type. Accepts 'json' or 'text'.
 * @param extraOptions.submitter Name and value of the originally submitting element.
 * @param extraOptions.formDataOverrides Specific data overrides for preprocessing `FormData`
 */
export async function processForm(
  saveRoute: string,
  form: HTMLFormElement,
  formSuccess: (data: any) => void,
  formFailure: (data: any) => void,
  extraOptions: {
    method?: string;
    accept?: string;
    responseType?: 'json' | 'text';
    submitter?: { name: string; value: string };
    formDataOverrides?: Record<string, string | Array<string>>;
  } = {}
) {
  const {
    method,
    accept = 'application/json',
    responseType,
    submitter,
    formDataOverrides
  } = extraOptions;

  const headers = new Headers();
  headers.append('Accept', accept);

  let body = new FormData(form);
  const _method = body.get('_method');

  if (
    method === 'put' ||
    method === 'PUT' ||
    (_method && _method === 'patch')
  ) {
    body.append('_method', 'put');
  }

  if (submitter) {
    const { name, value } = submitter;
    body.append(name, value);
  }

  if (!isEmpty(formDataOverrides)) {
    for (const field in formDataOverrides) {
      body.append(field, formDataOverrides[field].toString());
    }
  }

  form
    .querySelectorAll('[data-ckeditor]')
    .forEach((field: HTMLTextAreaElement) => {
      const instance = CKEDITOR.instances[field.id];

      if (instance) {
        body.set(field.name, instance.getData());
      }
    });

  const save = await executeFetch(
    saveRoute,
    {
      method: extraOptions.method || 'post',
      body: body,
      headers: headers
    },
    false,
    responseType ? responseType === 'json' : true
  );

  if ([400, 422, 500].some(status => status === save.status)) {
    formFailure(save);
  } else {
    formSuccess(save);
  }
}

/**
 * Prepares and executes a `fetch` call, and returns the response as JSON.
 *
 * @param {string} url The URL to access.
 * @param {RequestInit} options Initialization options.
 * @param {boolean} isJsonPost Is this a JSON post?
 * @param {boolean} requestJson Are we requesting JSON or plain text?
 * @param {boolean|string} requestFile Accepts 'pdf', 'csv' or `false`
 */
export async function executeFetch(
  url: string,
  options?: RequestInit,
  isJsonPost: boolean = false,
  requestJson: boolean = true,
  requestFile: boolean | string = false
) {
  try {
    const response = await request();

    if (requestJson) {
      if (response.status === 422) {
        return {
          errors: await response.json(),
          status: response.status
        };
      }
      return await response.json();
    } else if (requestFile) {
      await downloadFile(response);
    } else {
      return await response.text();
    }
  } catch (error) {
    console.debug(error);
    return [];
  }

  async function downloadFile(response: Response) {
    const fileName = getFileNameFromContentDispostionHeader(
      response.headers.get('Content-Disposition')
    ).replace('\\"', '');

    let blob = await response.blob();
    let type: string;

    switch (requestFile) {
      case 'pdf':
        type = 'application/pdf';
        break;
      case 'csv':
        type = 'text/csv';
        break;
    }

    blob = new Blob([blob], { type });

    const url = window.URL.createObjectURL(blob);

    let link = document.createElement('a');
    link.href = url;
    link.download = fileName.endsWith(`.${requestFile}`)
      ? fileName
      : `${fileName}.${requestFile}`;
    link.click();

    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 250);
  }

  async function request() {
    const defaultInit: RequestInit = {
      credentials: 'same-origin'
    };

    const headers = new Headers();

    // need to manually append this header so Rails can recognize XHR requests
    headers.append('X-Requested-With', 'XMLHttpRequest');

    if (requestJson) {
      headers.append('Accept', 'application/json');
    }

    if (isJsonPost) {
      headers.append('Content-Type', 'application/json');
      headers.append('X-CSRF-Token', getAuthToken());
    } else {
      if (options.body) {
        (options.body as FormData).append('utf8', '✓');
        (options.body as FormData).append('authenticity_token', getAuthToken());
      }
    }

    if (requestFile) {
      headers.append('Content-Disposition', 'attachment');
    }

    const init: RequestInit = Object.assign(
      {},
      defaultInit,
      { headers },
      options
    );

    return fetch(url, init);
  }

  function getFileNameFromContentDispostionHeader(header: string) {
    const standardPattern = /filename=(["']?)(.+)\1/i;
    const wrongPattern = /filename=([^"'][^;"'\n]+)/i;

    if (standardPattern.test(header)) {
      return header.match(standardPattern)[2];
    }

    if (wrongPattern.test(header)) {
      return header.match(wrongPattern)[1];
    }
  }
}

/**
 * Requests a chunk of HTML from a remote source.
 *
 * @uses executeFetch
 *
 * @param url Url to request the template from.
 * @param selector Optional selector for an element to extract.
 */
export async function requestTemplate(
  url: string,
  selector: string = '.wrapper'
) {
  const template = await executeFetch(
    url,
    {
      method: 'get'
    },
    false,
    false
  );
  const div = document.createElement('div');

  div.innerHTML = template;

  return div.querySelector(selector).outerHTML;
}

/**
 * Executes a GET request. Wraps `executeFetch` for convenience and
 * applies necessary settings.
 *
 * @param {string} url The URL to GET.
 */
export async function executeGet(url: string) {
  return await executeFetch(
    url,
    Object.assign({
      method: 'get'
    }),
    true
  );
}

/**
 * Executes a POST request. Wraps `executeFetch` for convenience and
 * applies necessary settings. Optionally accepts data to post.
 *
 * @param {string} url The URL to POST to.
 * @param {*} [body] Optional form data to attach. Expects a JSON string.
 */
export async function executePost(url: string, body?: any) {
  return await executeFetch(
    url,
    Object.assign(
      {
        method: 'post'
      },
      body ? { body } : {}
    ),
    true
  );
}

/**
 * Executes a PUT request. Wraps `executeFetch` for convenience and
 * applies necessary settings. Optionally accepts data to post.
 *
 * @param {string} url The URL to PUT to.
 * @param {*} [body] Optional form data to attach. Expects a JSON string.
 */
export async function executePut(url: string, body?: string) {
  return await executeFetch(
    url,
    Object.assign(
      {
        method: 'put'
      },
      body ? { body } : {}
    ),
    true
  );
}

/**
 * Executes a DELETE request. Wraps `executeFetch` for convenience and applies
 * necessary settings.
 *
 * @uses executeFetch
 *
 * @param url URL of the item to delete.
 */
export async function executeDelete(url: string) {
  return await executeFetch(
    url,
    {
      method: 'delete',
      body: JSON.stringify({
        _method: 'delete'
      })
    },
    true
  );
}

/**
 * Prepares a space-separated list of classes inheriting from a base object
 * class. The second param should be an object where the key name is the
 * modifier, and the value is a boolean to determine whether or not to
 * attach it. Modifier are attached in BEM style using `--`.
 *
 * @example
 * styleClasses('foo', { 'bar': true, 'baz': false, 'bam': true });
 * // 'foo foo--bar foo--bam'
 *
 * @param base The base class name.
 * @param modifiers A list of potential modifiers to attach.
 */
export function componentClasses(base: string, modifiers?: Modifiers) {
  const attached = modifiers
    ? Object.keys(modifiers).reduce(
        (attachList: Array<string>, current: string) =>
          attachList.concat(modifiers[current] === true ? [current] : []),
        []
      )
    : [];

  return [base]
    .concat(attached.map(modifier => `${base}--${modifier}`))
    .join(' ');
}

/**
 * Attempts to create a basic deep clone of an object or array by running it
 * through `JSON`. Returns `undefined` on failure.
 */
export function simpleClone<T>(item: T): T {
  try {
    return JSON.parse(JSON.stringify(item));
  } catch (error) {
    return undefined;
  }
}

/**
 * Evaluates a string and breaks it into component parts,
 * identifying individual words and ignoring divider characters.
 *
 * @example
 * decodeString('the[sample][string]'); // returns ['the', 'sample', 'string']
 */
export function decodeString(name: string): Array<string> {
  const regex = /(\w+)/g;
  let params: string[] = [];
  let match;

  // loop through all RegEx matches and push results to an array
  while ((match = regex.exec(name))) {
    params.push(match[1]);
  }

  return params;
}

/**
 * Parse a JSON-style string set within an HTML attribute of the following format:
 *
 * `attr="'{'key1': 'value1', 'key2': ['value2-1', 'value2-2']}'"`
 *
 * The single quotes are necessary for proper HTML validation, but need to be
 * transformed to double quotes for proper JSON validation.
 *
 * @param {string} str A JSON-styled string.
 *
 * @return {Object} Returns a standard JS object.
 */
export function parseOptions<T>(str: string): T {
  return JSON.parse(str.substring(1, str.length - 1).replace(/'/g, '"'));
}

/**
 * Creates a selector string to target a specific data attribute.
 * Accepts an attribute value in _camelCase_ or _snake-case_ and transforms appropriately. If the value starts with `^`,
 * the selector will be tailored to look for all elements with the given
 * data attribute and a value which starts with the provided selector string.
 *
 * @example
 * // returns '[data-attribute-name]`
 * dataSelector('attributeName');
 *
 * @example
 * // returns '[data-attribute-name]`
 * dataSelector('attribute-name');
 *
 * @example
 * // returns '[data-attribute-name="testValue"]'
 * dataSelector('attributeName', 'testValue');
 *
 * @example
 * // returns '[data-attribute-name^="testValue"]`
 * dataSelector('attributeName', '^testValue');
 *
 * @export
 * @param {string} attr The name of the data attribute.
 * @param {string} [value] A value to assign to the attribute.
 *
 * @return {string} - The formatted selector string.
 */
export function dataSelector(attr: string, value?: string): string {
  let operator: string = '=';

  if (attr.indexOf('-') === -1) {
    attr = camelToKebab(attr);
  }

  if (value && value.startsWith('^')) {
    operator = `${value.slice(0, 1)}${operator}`;
    value = value.slice(1);
  }

  return `[data-${attr}${operator}"${value}"]`;
}

/**
 * Finds the first parent of a given element by tag name.
 */
export function findParentNodeByTag<K extends keyof HTMLElementTagNameMap>(
  startingElement: HTMLElement,
  tagName: K
): HTMLElementTagNameMap[K] | null {
  let element = startingElement.parentElement;
  while (element && element.tagName !== tagName.toUpperCase()) {
    element = element.parentElement;
  }

  return element as HTMLElementTagNameMap[K] | null;
}

/**
 * Finds the first parent of a given element with a given class name.
 */
export function findParentNodeByClass(
  startingElement: HTMLElement,
  className: string
): HTMLElement {
  let element = startingElement.parentElement;

  while (element && !element.classList.contains(className)) {
    element = element.parentElement;
  }

  return element;
}

/**
 * Finds the first parent of a given element by data attribute.
 */
export function findParentNodeByDataAttribute(
  startingElement: HTMLElement,
  attrName: string
): HTMLElement {
  let element = startingElement.parentElement;

  while (element && !element.hasAttribute(attrName)) {
    element = element.parentElement;
  }

  return element;
}

/**
 * Converts a string from `camelCase` to `kebab-case`.
 *
 * @example
 * // returns 'sample-attribute-name'
 * camelToSnake('sampleAttributeName);
 *
 * @see https://jamesroberts.name/blog/2010/02/22/string-functions-for-javascript-trim-to-camel-case-to-dashed-and-to-underscore/
 *
 * @param {string} str The string to transform.
 *
 * @return Returns the transformed string.
 */
export function camelToKebab(str: string) {
  return str.replace(/([A-Z])/g, $1 => `-${$1.toLowerCase()}`);
}

/**
 * Converts a string from `camelCase` to `snake_case`.
 *
 * @example
 * // returns 'sample_attribute_name'
 * camelToSnake('sampleAttributeName);
 *
 * @param {string} str The string to transform.
 *
 * @return Returns the transformed string.
 */
export function camelToSnake(str: string) {
  return str.replace(/([A-Z])/g, $1 => `_${$1.toLowerCase()}`);
}

/**
 * Converts a string from `kebab-case` to `camelCase`.
 *
 * @example
 * // returns 'sampleAttributeName'
 * kebabToCamel('sample-attribute-name);
 *
 * @param {string} str The string to transform.
 *
 * @returns Returns the transformed string.
 */
export function kebabToCamel(str: string) {
  return str.replace(/(\-\w)/g, function (m) {
    return m[1].toUpperCase();
  });
}

/**
 * Converts a string from `snake_case` to `camelCase`.
 *
 *  * @example
 * // returns 'sampleAttributeName'
 * kebabToCamel('sample_attribute_name);
 *
 * @param {string} str The string to transform.
 *
 * @returns {string} Returns the transform string.
 */
export function snakeToCamel(str: string) {
  return str.replace(/(\_\w)/g, m => m[1].toUpperCase());
}

/**
 * Store a JSON representation of a data array in a specified input field.
 * Reduces the given data to an array of individual values.
 *
 * @param {(string|string[])} data The data to store.
 * @param {string} field A selector string for the desired input.
 */
export function saveDataToField(
  data: string | Array<string>,
  field: HTMLInputElement
) {
  const dataToSave = Array.isArray(data) ? JSON.stringify(data) : data;

  field.value = dataToSave;
}

/**
 * Determine if a given number is between two numbers, up to but not including
 * the end number.
 *
 * @param {number} n The number to check.
 * @param {number} [start=0] The start of the range.
 * @param {number} end The end of the range.
 */
export function inRange(n: number, start: number = 0, end: number) {
  return n >= start && n < end;
}

/**
 * Create the necessary prop to render HTML within a React component.
 *
 * @param {string} content HTML content to be rendered.
 */
export function createMarkup(content: string) {
  const div = document.createElement('div');
  div.insertAdjacentHTML('afterbegin', content);

  removeStyles(div);

  return {
    __html: div.innerHTML
  };

  function removeStyles(element: HTMLElement) {
    element.removeAttribute('style');

    if (element.childNodes.length > 0) {
      for (let child in element.childNodes) {
        if (element.childNodes[child].nodeType === 1) {
          removeStyles(element.childNodes[child] as HTMLElement);
        }
      }
    }
  }
}

/**
 * Creates a field name. Must first be curried into a new function expression with
 * a base name. The new expression can then be called with either an array of strings,
 * or a period-separated string.
 *
 * @example
 * const f = fieldName('foo');
 *
 * const single = f('bar.baz');
 * // 'foo[bar][baz]'
 * const multiple = f('bam.baz', true);
 * // 'foo[bam][baz][]`
 *
 * @param baseParam The base parameter name.
 */
export function fieldName(baseParam: string) {
  return (params: string | Array<string>, isMultiple: boolean = false) => {
    let fieldNameArray: Array<string> = [];

    if (!Array.isArray(params)) {
      params = params.split('.');
    }

    params = params as Array<string>;

    fieldNameArray.push(baseParam);
    fieldNameArray = fieldNameArray.concat(params.map(param => `[${param}]`));

    if (isMultiple) {
      fieldNameArray.push('[]');
    }

    return fieldNameArray.join('');
  };
}

/**
 * Creates a field name. Accepts either an array of strings, or a period-separated
 * string. The first value is the primary field name; all additional strings are
 * appended with brackets as nested values.
 *
 * @param params The field name components.
 * @param isMultiple If true, append an additional empty pair of brackets.
 */
export function fName(
  params: string | Array<string>,
  isMultiple: boolean = false
) {
  let fieldNameArray: Array<string> = [];

  if (!Array.isArray(params)) {
    params = params.split('.');
  }

  params = params as Array<string>;

  fieldNameArray.push(params.shift());
  fieldNameArray = fieldNameArray.concat(params.map(param => `[${param}]`));

  if (isMultiple) {
    fieldNameArray.push('[]');
  }

  return fieldNameArray.join('');
}

/**
 * Given a property key and an object, returns that property value if the object is
 * not undefined and contains that property. Otherwise returns `undefined`.
 *
 * @param prop The property key.
 * @param obj An Object, or `undefined`
 */
export function propIfAvailable(
  prop: string,
  obj?: { [key: string]: any }
): any {
  if (obj && obj.hasOwnProperty(prop)) {
    return obj[prop];
  } else {
    return undefined;
  }
}

/**
 * Performs a deep comparison of two objects for equality.
 *
 * @param {{ [key: string]: any }} obj1 The first object.
 * @param {{ [key: string]: any }} obj2 The second object.
 *
 * @return {boolean}
 */
export function areEqualObjects(
  obj1: { [key: string]: any },
  obj2: { [key: string]: any }
): boolean {
  //Loop through properties in object 1
  for (let p in obj1) {
    //Check property exists on both objects
    if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;

    switch (typeof obj1[p]) {
      // Deep compare objects
      case 'object':
        if (!areEqualObjects(obj1[p], obj2[p])) return false;

        break;
      // Compare function code
      case 'function':
        if (
          typeof obj2[p] === 'undefined' ||
          (p !== 'compare' && obj1[p].toString() !== obj2[p].toString())
        ) {
          return false;
        }
        break;
      // Compare values
      default:
        if (obj1[p] !== obj2[p]) return false;
    }
  }

  // Check object 2 for any extra properties
  for (let p in obj2) {
    if (typeof obj1[p] === 'undefined') return false;
  }

  return true;
}

/**
 * Generates a dataset lookup name for an element within a Controller.
 *
 * @param baseName The base name of the controller.
 * @param propName The internal prop name.
 */
export function dataPropName(baseName: string, propName: string) {
  return `${kebabToCamel(`${baseName}-${propName}`)}`;
}

/**
 * Get the `em` value of an element in pixels.
 *
 * @see https://github.com/tysonmatanich/getEmPixels
 *
 * @param {element} An element to test.
 */
export function getEmPixels(element: HTMLElement = document.documentElement) {
  const important = '!important;';
  const style = `position:absolute${important}visibility:hidden${important}width:1em${important}font-size:1em${important}padding:0${important}`;

  // Create and style a test element
  const testElement = document.createElement('i');

  testElement.style.cssText = style;
  element.appendChild(testElement);

  // Get the client width of the test element
  const value = testElement.clientWidth;

  // Remove the test element
  element.removeChild(testElement);

  // Return the em value in pixels
  return value;
}

/**
 * Capitalize only the **first** letter of a string, and leave
 * all other letters untouched.
 *
 * @export
 * @param {string} str The string to process.
 */
export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Converts an HTML element to a targeted selector string,
 * combining key attributes in an attempt to create as unique
 * a selector as possible.
 *
 * @example
 * element = '<div id="example_div" class="class-name-one class-name-two"></div>';
 * // returns 'div#example_div.class-name-one.class-name-two
 *
 * @param {HTMLElement} element The element to transform.
 *
 * @returns {string} Returns a valid selector string.
 */
export function elementToSelectorString(element: HTMLElement): string {
  let parts: string[] = [];

  /*
    `element.tagName` comes straight from the DOM and is in
     all caps by default.
   */
  parts.push(element.tagName.toLowerCase());

  /*
    Push the `id` first since that makes for the fastest selection.
   */
  if (element.id) {
    parts.push(`#${element.id}`);
  }

  if (element.classList) {
    parts.push(
      /*
        `classList` is not an array but a DOMTokenList, which does not
        have access to Array functions. First convert it to a pure array and
        append a period to each string…
       */
      Array.from(element.classList, className => `.${className}`)
        /*
       …and then join the resulting Array with no spaces.
      */
        .join('')
    );
  }

  /*
   Join the final `parts` array with no spaces to prepare
   the final selector string.
   */
  return parts.join('');
}

/**
 * Generates a localized title using a core action and a model name. The action string
 * should relate to a key ending with `_object`, e.g., an action of 'new' will search for
 * `global.<value of `type`>.new_object`. The model name should be present within `global.labels` and
 * should have keys to handle pluralization.
 *
 * @export
 * @param {string} action A string representing an action.
 * @param {string} model A string representing a model or other key object.
 * @param {string} [type='labels'] The global key to search for a translation.
 * @param {number} [count=1] An optional count to dictate pluralization.
 */
export function actionModelTitle(
  action: string,
  model: string,
  count: number = 1,
  type: string = 'titles'
) {
  return I18n.t(`global.${type}.${action}_object`, {
    title: I18n.t(`global.labels.${model}`, { count })
  });
}

/**
 * Wrap a template string with a tag. Optionally accepts a set of key-value
 * pairs which will translate to attributes on the wrapper. Attribute names
 * should be given in camelCase.
 *
 * @export
 * @param {string} template The template to wrap.
 * @param {string} tag The tag to wrap with.
 * @param {{ [key: string]: string }} [attrs] Optional attributes to attach to the tag.
 *
 * @returns {string}
 */
export function wrapTemplate(
  template: string,
  tag: string,
  attrs?: { [key: string]: string }
): string {
  return `
    <${tag} ${
    attrs &&
    map(attrs, (value, key) => `${camelToKebab(key)}="${value}"`).join(' ')
  }>
      ${template}
    </${tag}>
  `;
}

/**
 * Converts a date string into
 * @param date
 * @param [dateFormat='YYYY-MM-DD']
 */
export function formatDateString(date: string, dateFormat = 'YYYY-MM-DD') {
  const parsedDate = parse(date);

  return isValid(parsedDate) ? format(parsedDate, dateFormat) : 'Invalid Date';
}

/**
 * Prepares an overlay wrapper.
 *
 * @export
 * @param {string} [size='medium'] The size of the overlay.
 * @param {string} [content=''] The content of the overlay drawer.
 *
 * @return {string}
 */
export function createOverlay(size: string = 'medium', content: string = '') {
  return `
    <div class="overlay">
      <div class="overlay__backdrop"></div>
      <div class="overlay__content overlay__content--${size}">
        ${content}
      </div>
    </div>
  `;
}

/**
 * Prepares content for an overlay.
 *
 * @export
 * @param {string} icon The icon to display.
 * @param {string} title The title of the overlay.
 * @param {string} content The main content.
 *
 * @return {string}
 */
export function createOverlayContent(
  icon: string,
  title: string,
  content: string
) {
  const parser = new DOMParser();
  const parsedContent = parser.parseFromString(content, 'text/html');
  const contentWrapper = parsedContent.body.firstChild as HTMLElement;

  // since we append this controller call to the wrapper,
  // it's not necessary to have it on the content itself
  if (contentWrapper.dataset.controller === 'r18-ui') {
    contentWrapper.removeAttribute('data-controller');
  }

  return `
    <div class="card overlay-content" data-controller="r18-ui">
      <div class="card-divider overlay-content__title">
        <div class="overlay-content-title">
          <div class="overlay-content-title__icon">
            <svg class="icon icon--medium">
              <use xlink:href="#${icon}-icon" />
            </svg>
          </div>
          <span class="overlay-content-title__text">
            ${title}
          </span>
          <a role="button" class="overlay-content-title__button" data-r18-ui-overlay-close>
            <svg class="icon icon--medium">
              <use xlink:href="#shrink-icon" />
            </svg>
          </a>
        </div>
      </div>
      <div class="card-section overlay-content__window">
        ${contentWrapper.outerHTML}
      </div>
    </div>
  `;
}

/**
 * Convert a string of HTML into a DOM element.
 *
 * @export
 * @param {string} html The HTML to process.
 *
 * @return {Element}
 */
export function createElementFromHTML(html: string): HTMLElement {
  const div = document.createElement('div');
  div.innerHTML = html.trim();

  return div.firstElementChild as HTMLElement;
}

/**
 * Read the current location query string to determine if
 * a given key is present. Can optionally match the value
 * of that key against a provided value.
 *
 * @param {string} key The key to search for.
 * @param {string} [value] A value to compare the key's value against.
 *
 * @return {boolean}
 */
export function hasQueryParam(key: string, value?: string) {
  // break the query string into a group of key-value arrays
  const parts = window.location.search
    .split('?')
    .slice(1)
    .map(p => p.split('='));

  const part = parts.find(p => p[0] === key);

  if (part) {
    return value ? part[1] === value : true;
  } else {
    return false;
  }
}

/**
 * Attempts to validate a URL.
 *
 * @param {string} url The string to validate.
 *
 * @return {boolean}
 */
export function isValidUrl(url: string) {
  // RegEx pattern by Diego Perini
  // https://gist.github.com/dperini/729294
  const regEx = new RegExp(
    '^' +
      // protocol identifier
      '(?:(?:https?|ftp)://)' +
      // user:pass authentication
      '(?:\\S+(?::\\S*)?@)?' +
      '(?:' +
      // IP address exclusion
      // private & local networks
      '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
      '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
      '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
      // IP address dotted notation octets
      // excludes loopback network 0.0.0.0
      // excludes reserved space >= 224.0.0.0
      // excludes network & broacast addresses
      // (first & last IP address of each class)
      '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
      '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
      '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
      '|' +
      // host name
      '(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)' +
      // domain name
      '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*' +
      // TLD identifier
      '(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))' +
      // TLD may end with dot
      '\\.?' +
      ')' +
      // port number
      '(?::\\d{2,5})?' +
      // resource path
      '(?:[/?#]\\S*)?' +
      '$',
    'i'
  );

  return url.match(regEx);
}

/**
 * Formats a date string, attempting to parse it. Passes a format string
 * to date-fns.
 *
 * @param date
 * @param format
 */
export function formatDate(date: string | Date, rules: string = '') {
  const parsedDate = parse(date);

  return isValid(parsedDate) ? format(parsedDate, rules) : 'Invalid Date';
}

// @deprecated
export function prepareTabs<T>(
  sections: Array<ISubSection<T>>,
  currentSection: string,
  count?: number
) {
  return sections.map(section => {
    return {
      slug: section.slug,
      title: section.sectionTitle,
      isActive: section.slug === currentSection,
      hasBadge: !isUndefined(count),
      badgeCount: count
    };
  });
}

/**
 * A natural sorting algorithm which accounts for both letters and numbers.
 *
 * @see https://datatables.net/plug-ins/sorting/natural
 *
 * @param a First value
 * @param b Second value
 */
export function naturalSort(a: any, b: any) {
  const re = /(^-?[0-9]+(\.?[0-9]*)[df]?e?[0-9]?%?$|^0x[0-9a-f]+$|[0-9]+)/gi,
    sre = /(^[ ]*|[ ]*$)/g,
    dre =
      /(^([\w ]+,?[\w ]+)?[\w ]+,?[\w ]+\d+:\d+(:\d+)?[\w ]?|^\d{1,4}[\/\-]\d{1,4}[\/\-]\d{1,4}|^\w+, \w+ \d+, \d{4})/,
    hre = /^0x[0-9a-f]+$/i,
    ore = /^0/,
    // convert all to strings and trim()
    x = a.toString().replace(sre, '') || '',
    y = b.toString().replace(sre, '') || '';
  // chunk/tokenize
  const xN = x
      .replace(re, '\0$1\0')
      .replace(/\0$/, '')
      .replace(/^\0/, '')
      .split('\0'),
    yN = y
      .replace(re, '\0$1\0')
      .replace(/\0$/, '')
      .replace(/^\0/, '')
      .split('\0'),
    // numeric, hex or date detection
    xD =
      parseInt(x.match(hre), 10) ||
      (xN.length !== 1 && x.match(dre) && Date.parse(x)),
    yD =
      parseInt(y.match(hre), 10) ||
      (xD && y.match(dre) && Date.parse(y)) ||
      null;

  // first try and sort Hex codes or Dates
  if (yD) {
    if (xD < yD) {
      return -1;
    } else if (xD > yD) {
      return 1;
    }
  }

  // natural sorting through split numeric strings and default strings
  for (
    let cLoc = 0, numS = Math.max(xN.length, yN.length);
    cLoc < numS;
    cLoc++
  ) {
    // find floats not starting with '0', string or 0 if not defined (Clint Priest)
    let oFxNcL =
      (!(xN[cLoc] || '').match(ore) && parseFloat(xN[cLoc])) || xN[cLoc] || 0;
    let oFyNcL =
      (!(yN[cLoc] || '').match(ore) && parseFloat(yN[cLoc])) || yN[cLoc] || 0;
    // handle numeric vs string comparison - number < string - (Kyle Adams)
    if (isNaN(oFxNcL) !== isNaN(oFyNcL)) {
      return isNaN(oFxNcL) ? 1 : -1;
    } else if (typeof oFxNcL !== typeof oFyNcL) {
      // rely on string comparison if different types - i.e. '02' < 2 != '02' < '2'
      oFxNcL += '';
      oFyNcL += '';
    }
    if (oFxNcL < oFyNcL) {
      return -1;
    }
    if (oFxNcL > oFyNcL) {
      return 1;
    }
  }
  return 0;
}

export function dateSort(a: Date, b: Date) {
  if (isBefore(a, b)) {
    return -1;
  }

  if (isAfter(a, b)) {
    return 1;
  }

  return 0;
}

export function humanizeBoolean(value: boolean) {
  return I18n.t(`global.labels.${value ? 'yes' : 'no'}_label`);
}

export function submitNinjaForm(postRoute: string, userId: string) {
  const form = document.createElement('form');
  const csrfField = document.createElement('input');
  const userIdField = document.createElement('input');

  form.action = postRoute;
  form.method = 'post';

  csrfField.name = 'authenticity_token';
  csrfField.value = getAuthToken();

  userIdField.type = 'hidden';
  userIdField.name = 'ninja_user_id';
  userIdField.value = userId;

  form.appendChild(csrfField);
  form.appendChild(userIdField);

  // we have to append the form to the document or it will not submit properly
  document.body.appendChild(form);
  form.submit();
}

export function getParameterByName(name: string, href: string) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regexS = '[\\?&]' + name + '=([^&#]*)';
  var regex = new RegExp(regexS);
  var results = regex.exec(href);
  if (results == null) {
    return '';
  } else {
    return decodeURIComponent(results[1].replace(/\+/g, ' '));
  }
}

/**
 * Determine the width of the vertical scrollbar in the current user's
 * browser, and returns it.
 *
 * @export
 * @returns {number}
 */
export function getScrollbarWidth() {
  let outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.width = '100px';

  document.body.appendChild(outer);

  let widthNoScroll = outer.offsetWidth;
  // force scrollbars
  outer.style.overflow = 'scroll';

  // add innerdiv
  let inner = document.createElement('div');
  inner.style.width = '100%';
  outer.appendChild(inner);

  let widthWithScroll = inner.offsetWidth;

  // remove divs
  outer.parentNode.removeChild(outer);

  return widthNoScroll - widthWithScroll;
}

export function canScroll(element: HTMLElement) {
  if (!element) return false;

  return element.scrollHeight > element.clientHeight;
}

/**
 * Converts a number to USD with locale formatting.
 *
 * @example
 * toUSD(196.3660); // returns "$196.37"
 */
export function toUSD(num: number) {
  if (!isNumber(num)) return num;

  return num.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
}

/**
 * Displays a value, or 'N/A' when no value is available.
 *
 * @export
 * @returns {string}
 */
export function valueOrNot(value: any) {
  return value || I18n.t('global.labels.n_a');
}

/**
 * Initialize various view libraries, either on a specific container
 * or on the entire document.
 */
export function initLibraries(container = document.documentElement) {
  ReactOnRails.reactOnRailsPageLoaded();
  $(container).foundation();
  disable(container).init();
  fw(controllers).initialize(container);
}

/**
 * Initialize all plugins on the current page, or within a given element.
 *
 * @param {HTMLElement} [container=document.body] The container to operate on.
 */
export function initPlugins(container: HTMLElement = document.body) {
  [ckEditor, select2].forEach(plugin => plugin.call(undefined, container));
}

/**
 * Recursively camelizes all keys in a provided object,
 *
 * @param object The object to process.
 */
export function camelizeObject(object) {
  return transform(object, (acc, value, key) => {
    const camelKey = camelCase(key.toString());
    acc[camelKey] = isObject(value) ? camelizeObject(value) : value;
  });
}

/**
 * Recursively snakecases all keys in a provided object,
 *
 * @param object The object to process.
 */
export function snakeObject(object) {
  return transform(object, (acc, value, key) => {
    const snakeKey = snakeCase(key.toString());
    acc[snakeKey] = isObject(value) ? snakeObject(value) : value;
  });
}

export function controllerSelector(baseName: string) {
  return (
    propName: string,
    bareAttributeName = false,
    tagName = ''
  ): string => {
    const attributeName = `data-${baseName}-${propName}`;
    return bareAttributeName ? attributeName : `${tagName}[${attributeName}]`;
  };
}

export function formatErrors(errors: Record<string, string[]>) {
  return Object.keys(errors)
    .map(key => {
      const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
      const messages = errors[key].join(', ');
      return `${capitalizedKey}: ${messages}`;
    })
    .join('\n');
}
