import * as React from 'react';

import { componentClasses, createMarkup } from '../../shared';

interface DataPointProps {
  title: string;
  content: string | JSX.Element;
  subContent?: string;
  isHtmlContent?: boolean;
  modifiers?: Modifiers;
}

/**
 * Stateless component to render two or three lines of content in a stacked
 * context, with the ability to apply modifiers.
 */
export const DataPoint = ({
  title,
  content,
  subContent,
  isHtmlContent,
  modifiers
}: DataPointProps) => {
  return (
    <div
      className={componentClasses(
        'two-tier',
        Object.assign(
          {
            datapoint: true,
            html: isHtmlContent
          },
          modifiers
        )
      )}>
      <span>{title}</span>
      {isHtmlContent && (
        <span dangerouslySetInnerHTML={createMarkup(content as string)} />
      )}
      {!isHtmlContent && <span>{content}</span>}
      {subContent && <span>{subContent}</span>}
    </div>
  );
};
