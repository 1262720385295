import * as React from 'react';
import { Row, Column } from 'react-foundation';
import classnames from 'classnames';

import { createMarkup, fieldName, processForm } from '../../../shared';
import {
  CKEditorField,
  CheckboxField,
  DatePickerField,
  FileUploadField
} from '../../fields';
import { ConfirmAction } from '../ConfirmAction';

interface TakOutOfServiceFormProps {
  availableFileFormats: string;
}

interface TakeOutOfServiceFormState {
  reasonIsChecked: boolean;
  reasonValue: string;
  outOfServiceDate: Date;
  comments: string;
}

class TakeOutOfServiceForm extends React.Component<TakOutOfServiceFormProps,
  TakeOutOfServiceFormState> {
  props: TakOutOfServiceFormProps;
  state: TakeOutOfServiceFormState = this.initialState();
  form: HTMLFormElement;
  fName: (
    params: string | Array<string>,
    isMultiple?: boolean
  ) => string = fieldName('equipment_record');

  public UNSAFE_componentWillReceiveProps(nextProps: TakOutOfServiceFormProps) {
    this.setState(this.initialState());
  }

  public render() {
    const { availableFileFormats } = this.props;
    const { reasonValue, reasonIsChecked, outOfServiceDate, comments } = this.state;

    const f = this.fName;

    return (
      <>
        <Row>
          <Column small={12}>
            <p
              dangerouslySetInnerHTML={createMarkup(
                I18n.t('modals.confirm_take_out_of_service.message_html', {
                  name
                })
              )}
            />
            <CheckboxField
              name={f('reason')}
              label={I18n.t(
                'modals.confirm_take_out_of_service.labels.add_comments'
              )}
              value={reasonValue}
              checked={reasonIsChecked}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                this.setState({
                  reasonIsChecked: event.target.checked,
                  reasonValue: event.target.checked ? 'yes' : 'no'
                });
              }}
            />
          </Column>
          {reasonIsChecked && (
            <Column small={12}>
              <CKEditorField
                name={f('comments')}
                label={I18n.t('global.labels.comment.one')}
                initialValue={comments}
                onChange={(value: string) => {
                  this.setState({
                    comments: value
                  });
                }}
              />
              {/* this hidden field overrides the one right above it, ensuring the field value
                  is correctly captured prior to POST. This is necessary because we access the 
                  form element directly to create a `FormData` object, but the CKEditorField
                  does not populate its linked textarea field on change. */}
              <input type="hidden" name={f('comments')} value={comments} />
              <FileUploadField
                name={f('supporting_document')}
                id="equipment_record_supporting_document"
                label={I18n.t(
                  'modals.confirm_take_out_of_service.labels.supporting_document'
                )}
                showLabel
                availableFileFormats={availableFileFormats}
              />
            </Column>
          )}
        </Row>
        <Row>
          <Column small={12} medium={6}>
            <DatePickerField
              name={f('service_date')}
              id="equipment_record_service_date"
              label={I18n.t(
                'modals.confirm_take_out_of_service.labels.out_of_service_date'
              )}
              value={outOfServiceDate}
              alignCalendar="left"
              handleDateChange={(date: Date) => {
                this.setState({
                  outOfServiceDate: date
                });
              }}
            />
          </Column>
        </Row>
      </>
    );
  }

  private initialState(): TakeOutOfServiceFormState {
    return {
      reasonIsChecked: false,
      reasonValue: 'no',
      outOfServiceDate: new Date(),
      comments: ''
    };
  }
}

type TakeOutOfServiceResponse = {
  status: any;
  equipmentRecord?: IEquipmentRecord;
};

interface ConfirmTakeOutOfServiceActionProps {
  id: string;
  name: string;
  url: string;
  availableFileFormats: string;
  isExpanded?: boolean;
  disabled?: boolean;

  updateParent(
    item: IEquipmentRecord,
    prevSection: string,
    nextSection: string
  ): void;
}

interface ConfirmTakeOutOfServiceActionState {
  isSaving: boolean;
}

export class ConfirmTakeOutOfServiceAction extends React.Component<ConfirmTakeOutOfServiceActionProps,
  ConfirmTakeOutOfServiceActionState> {
  props: ConfirmTakeOutOfServiceActionProps;
  state: ConfirmTakeOutOfServiceActionState;
  form: HTMLFormElement;

  constructor(props: ConfirmTakeOutOfServiceActionProps) {
    super(props);

    this.state = {
      isSaving: false
    };
  }

  static defaultProps = {
    isExpanded: true
  };

  handleProcessForm = () => {
    const form = this.form;

    this.setState(
      {
        isSaving: true
      },
      () => {
        this.processUpdate(form);
      }
    );
  };

  public render() {
    const { id, isExpanded, availableFileFormats } = this.props;
    const { isSaving } = this.state;

    return (
      <ConfirmAction
        id={`ConfirmAction_TakeOutOfService_${id}`}
        title={I18n.t('modals.confirm_take_out_of_service.title')}
        message={
          <form ref={form => (this.form = form)}>
            <TakeOutOfServiceForm availableFileFormats={availableFileFormats} />
          </form>
        }
        buttonProps={{
          label: I18n.t('inventory.buttons.take_out_of_service'),
          classNames: classnames('hollow', 'alert', {
            expanded: isExpanded
          }),
          disabled: isSaving
        }}
        action={this.handleProcessForm}
      />
    );
  }

  private processUpdate(form: HTMLFormElement) {
    const { url, updateParent } = this.props;

    processForm(
      url,
      form,
      (response: TakeOutOfServiceResponse) => {
        if (response.equipmentRecord) {
          this.setState(
            {
              isSaving: false
            },
            () => {
              updateParent(
                response.equipmentRecord,
                'inService',
                'outOfService'
              );
            }
          );
        }
      },
      () => {
      },
      {
        method: 'put',
        responseType: 'json'
      }
    );
  }
}
