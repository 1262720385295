import { Controller } from 'framewerk';
import {
  controllerSelector,
  initLibraries,
  initPlugins,
  requestTemplate
} from '../shared';
import { isEmpty } from 'lodash-es';

/**
 * The initialization function for creating the `r18-assign-tests` Controller.
 */
export function assignTests(): Controller {
  let userId: string;
  let assignedTestMethodIds: string[] = [];
  let testMethodCategoryId = '';
  let testMethodId = '';
  let urls: Record<'create' | 'edit', string>;
  let $selectMethod: JQuery<HTMLSelectElement>;

  /**
   * The name of the controller.
   */
  const name: string = 'r18-assign-tests';

  /**
   * Factory method for creating selectors.
   */
  const selector = controllerSelector(name);

  /**
   * Selector strings for the `r18-assign-tests` Controller.
   */
  let targets = Controller.getTargets({
    controller: `[data-controller="${name}"]`,
    createPath: selector('create-path'),
    editPath: selector('edit-path'),
    formWrapper: selector('form-wrapper'),
    selectUser: selector('select-user'),
    selectMethod: selector('select-method'),
    selectCategory: selector('select-category'),
    addMethod: selector('add-method'),
    table: selector('table'),
    userId: selector('user-id'),
    submit: selector('submit')
  }) as {
    controller: HTMLElement[];
    createPath: HTMLInputElement[];
    editPath: HTMLInputElement[];
    formWrapper: HTMLElement[];
    selectUser: HTMLSelectElement[];
    selectMethod: HTMLSelectElement[];
    selectCategory: HTMLSelectElement[];
    addMethod: HTMLButtonElement[];
    table: HTMLTableElement[];
    userId: HTMLInputElement[];
    submit: HTMLInputElement[];
  };

  const events = {
    setInitialValues: async () => {
      $selectMethod = $(targets.selectMethod[0]);
      userId = targets.userId[0]?.value ?? '';

      if (userId) {
        setUserUrls(userId);
      }

      if (!userId?.length && targets.submit[0]) {
        targets.submit[0].disabled = true;
      }

      setAssignedTestIds();
      updateAvailableTestMethods();
      setFormState();
    },
    addListeners: async () => {
      const $controller = $(targets.controller[0]);
      $controller
        .on('change', selector('select-user'), event => {
          userId = event.target.value;
          setUserUrls(userId);
          getAssignedTestsForUser();
        })
        .on('change', selector('select-category'), event => {
          testMethodCategoryId = event.target.value;
          setFormState();
        })
        .on('change', selector('select-method'), event => {
          testMethodId = event.target.value;
          setFormState();
        })
        .on('change', `${selector('table')} input[type="checkbox"]`, () => {
          updateAllTestMethods();
        });

      window.addEventListener('userTestMethods:update', () => {
        setTableInputState(false);
      });
    }
  };

  return new Controller({ name, targets, events });

  /////////////////

  async function updateAllTestMethods() {
    const form = targets.controller[0].querySelector('form');
    form?.requestSubmit();
    setTableInputState(true);
  }

  function setFormState() {
    const isValid = [testMethodCategoryId, testMethodId].every(
      value => !isEmpty(value)
    );
    const submit = targets.controller[0].querySelector<HTMLButtonElement>(
      selector('add-method')
    );
    if (submit) submit.disabled = !isValid;
  }

  function setAssignedTestIds() {
    if (!targets.table[0]) return;

    assignedTestMethodIds = Array.from(
      targets.table[0].querySelectorAll<HTMLTableRowElement>('tbody > tr')
    ).map(row => row.dataset.testMethodId);
  }

  async function getAssignedTestsForUser() {
    if (!userId?.length) return;

    const formWrapper = targets.formWrapper[0];

    formWrapper.textContent = I18n.t('global.labels.loading.with_ellipses');
    formWrapper.innerHTML = await requestTemplate(
      urls.edit.replace(':user_id', userId),
      '[data-r18-assign-tests-form-wrapper]'
    );

    // refresh references
    targets = {
      ...targets,
      formWrapper: [formWrapper],
      table: [formWrapper.querySelector('table')],
      addMethod: [formWrapper.querySelector(selector('add-method'))],
      selectMethod: [formWrapper.querySelector(selector('select-method'))],
      selectCategory: [formWrapper.querySelector(selector('select-category'))]
    };

    resetFormState();
    setFormState();

    initLibraries(formWrapper);
    initPlugins(formWrapper);
  }

  function resetFormState() {
    testMethodCategoryId = '';
    testMethodId = '';
    targets.selectCategory[0].value = '';
    targets.selectMethod[0].innerHTML = '';
  }

  function updateAvailableTestMethods() {
    $selectMethod.find('option').each((index, element) => {
      element.disabled = assignedTestMethodIds.includes(
        element.value.toString()
      );
    });

    $selectMethod.trigger('change');
  }

  function setTableInputState(isDisabled: boolean) {
    const form = targets.controller[0].querySelector('form');
    form
      .querySelectorAll('input, button, a')
      .forEach(
        (input: HTMLInputElement & HTMLButtonElement & HTMLAnchorElement) => {
          input.disabled = isDisabled;
        }
      );
  }

  function setUserUrls(userId: string) {
    if (targets.createPath[0] && targets.editPath[0]) {
      urls = {
        create: new URL(
          targets.createPath[0].value.replace(':user_id', userId),
          window.location.origin
        ).toString(),
        edit: new URL(
          targets.editPath[0].value.replace(':user_id', userId),
          window.location.origin
        ).toString()
      };
    }
  }
}
