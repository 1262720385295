import * as React from 'react';
import { Button, Colors, Sizes } from 'react-foundation';
import matchSorter from 'match-sorter';
import { words, throttle, debounce } from 'lodash-es';

import { simpleClone, submitNinjaForm } from '../../shared';
import { MultiList } from '../multi-list/MultiList';
import {
  ButtonForm,
  ButtonFormProps,
  ButtonFormState
} from '../common/ButtonForm';
import { Overlay, OverlayContent } from '../overlay';
import { ConfirmNinjaLogin } from '../common/confirm-actions';
import { Icon } from '../common/Icon';

const { rankings } = matchSorter;

interface NinjaLogin extends IAction {
  isHidden?: boolean;
}

interface NinjaList extends IList<NinjaLogin> {}

interface UsersNinjaLoginProps {
  allUsers: Array<NinjaList>;
  postRoute: string;
}

interface UsersNinjaLoginState {
  filteredUsers: Array<NinjaList>;
}

export class UsersNinjaLogin extends React.Component<
  UsersNinjaLoginProps,
  UsersNinjaLoginState
> {
  public props: UsersNinjaLoginProps;
  public state: UsersNinjaLoginState;

  constructor(props: UsersNinjaLoginProps) {
    super(props);

    this.state = {
      filteredUsers: []
    };
  }

  public handleSubmit(item: NinjaLogin) {
    const { allUsers, postRoute } = this.props;

    /*
      For this to work correctly, we have to create a form,
      populate it, and then submit it. It's **possible** an AJAX post
      might work, but I'm not sure how to handle the response.
    */
    submitNinjaForm(postRoute, item.id);
  }

  /**
   * Filters the original list data and adjusts data to reveal matches.
   *
   * @param filterValue
   */
  public handleFilterData(filterValue: string) {
    if (filterValue.length < 3) {
      this.setState({
        filteredUsers: []
      });
    } else {
      const { allUsers, postRoute } = this.props;

      const initialData = simpleClone(allUsers);
      const matchedData: Array<NinjaList> = matchSorter(
        initialData,
        filterValue,
        {
          keys: [
            {
              key: 'title',
              maxRanking: rankings.CONTAINS
            },
            (item: NinjaList) => {
              return item.items.map((i: NinjaLogin) => i.title);
            },
            (item: NinjaList) => {
              return item.items.map((i: NinjaLogin) => i.subtitle);
            }
          ]
        }
      );

      matchedData.forEach(list => {
        const hasTitleMatch =
          matchSorter(words(list.title), filterValue, {
            threshold: rankings.STARTS_WITH
          }).length > 0;

        // if the title matches, leave the existing items; if not, filter them again
        if (!hasTitleMatch) {
          list.items = matchSorter(list.items, filterValue, {
            keys: ['title', 'subtitle'],
            threshold: rankings.CONTAINS
          });
        }

        list.items.forEach(item => {
          item.actions = [
            <ConfirmNinjaLogin
              name={item.title}
              buttonLabel={<Icon icon="arrow-right" size="medium" />}
              action={() => {
                this.handleSubmit(item);
              }}
            />
          ];
        });
      });

      this.setState({
        filteredUsers: matchedData
      });
    }
  }

  public render() {
    const { allUsers } = this.props;
    const { filteredUsers } = this.state;

    return (
      <>
        {!allUsers.length && (
          <>
            <Icon icon="ninja" size="xlarge" />
            <p className="text-center h4">You cannot log in as anyone else.</p>
          </>
        )}
        {allUsers.length > 0 && (
          <div className="scroll-topper">
            <div className="scroll-topper__top">
              <div className="input-group input-group--search">
                <input
                  type="text"
                  className="input-group-field"
                  placeholder="Start typing the name of a user or lab"
                  onChange={event => {
                    this.debouncedChangeFilterEvent(event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="scroll-topper__bottom">
              <MultiList
                lists={filteredUsers}
                listStyle="stacked"
                isWide={false}
              />
            </div>
          </div>
        )}
      </>
    );
  }

  private debouncedChangeFilterEvent = debounce(value => {
    this.handleFilterData(value);
  }, 1500);

  private _prepareLogins(users: Array<NinjaLogin>) {
    return users.map(u => Object.assign(u, { isVisible: false }));
  }
}
