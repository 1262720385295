import { fw, Controller } from 'framewerk';
import { isEqual } from 'lodash-es';

import controllers from '../controllers';
import { exportOrgChart } from '../modules';
import { orgChart as orgChartPlugin } from '../plugins';
import {
  STATES,
  actionModelTitle,
  elementToSelectorString,
  executeFetch,
  executeGet,
  executePost,
  requestTemplate,
  createOverlayContent
} from '../shared';

const { Tooltip } = Foundation;

interface OrgChartResponse {
  all: OrgChartDataSource;
  active: OrgChartDataSource;
}

/**
 * The initialization function for creating the `r18-positionDescriptions` Controller.
 */
export function orgChart(): Controller {
  const parser = new DOMParser();

  const ORG_CHART_STATES = {
    view: 'view',
    edit: 'edit',
    export: 'export'
  };

  let currentState = 'view';
  let orgChart: OrgChart.API;
  let orgChartData: OrgChartResponse;
  let originalChart: any;
  let reveal;

  /**
   * The name of the controller.
   */
  const name: string = 'r18-org-chart';

  /**
   * Selector strings for the `r18-org-chart` Controller.
   */
  const targets = Controller.getTargets({
    loading: `[data-${name}-loading]`,
    chart: `div[data-${name}]`,
    labName: `input[data-${name}-labname]`,
    path: `input[data-${name}-path]`,
    savePath: `input[data-${name}-save-path]`,
    overlay: `div[data-${name}-overlay]`,
    lastEdited: `span[data-${name}-last-edited]`,
    wasUpdated: `input[data-${name}-was-updated]`,
    userForm: `div[data-${name}-user-form]`,
    action: `a[data-${name}-action]`,
    addNew: `a[data-${name}-add-new]`,
    zoom: `button[data-${name}-zoom]`,
    export: `button[data-${name}-export]`
  });

  /**
   * Events created for the `r18-org-chart` Controller.
   */
  const events = {
    windowLoad: async () => {
      if (!targets.chart[0].querySelector('.orgchart')) {
        const url = (targets.path[0] as HTMLInputElement).value;

        orgChartData = await executeGet(url);

        initializeOrgChart();
        targets.zoom.forEach(target => {
          target.removeAttribute('disabled');
        });

        const dataLoadedEvent = new CustomEvent('orgChart.dataLoaded');
        if (targets.export.length > 0) {
          targets.export[0].removeAttribute('disabled');
        }
        window.dispatchEvent(dataLoadedEvent);
      }
    },
    orgChartEvents: () => {
      window.addEventListener(
        'orgChart.reloadContent',
        (event: CustomEvent) => {
          orgChartData = JSON.parse(event.detail);

          setOrgChartState('edit');
        }
      );
    },
    clickEvents: () => {
      targets.zoom.forEach(zoom => {
        zoom.addEventListener('click', event => {
          const eventTarget = event.currentTarget as HTMLElement;
          const action = eventTarget.dataset.r18OrgChartZoom;
          const chart = orgChart.$chart[0];
          const transform = chart.style.transform;

          if (transform) {
            const currentScale = parseFloat(
              transform.match(/[+-]?\d+(\.\d+)?/)[0]
            );
            let newScale;

            switch (action) {
              case 'in':
                newScale = currentScale + 0.1;
                break;
              case 'out':
                newScale = currentScale - 0.1;
                break;
            }
            chart.style.transform = `scale(${newScale.toString()})`;
          } else {
            chart.style.transform = `scale(1)`;
          }
        });
      });

      targets.action.forEach(action => {
        action.addEventListener('click', event => {
          const eventTarget = event.target as HTMLElement;
          currentState = eventTarget.dataset.r18OrgChartAction;

          if (eventTarget.innerText === I18n.t('global.buttons.save')) {
            eventTarget.parentElement.classList.add(STATES.hasSpinner);
            saveChart(eventTarget);
          } else {
            setTabsState(currentState);
            setOrgChartState(currentState);
          }
        });
      });

      targets.chart[0].addEventListener('click', event => {
        const eventTarget = event.target as HTMLElement;

        if (eventTarget) {
          if (eventTarget.hasAttribute(`data-${name}-toggle-node`)) {
            event.stopImmediatePropagation();
            eventTarget.classList.add(STATES.isActive);
            toggleNode(eventTarget.dataset.r18OrgChartTogglePath, () => {
              eventTarget.classList.remove(STATES.isActive);
            });
          }
        }
      });

      if (targets.overlay.length) {
        targets.overlay[0].addEventListener('click', event => {
          const target = event.target as HTMLElement;

          if (target) {
            if (target.hasAttribute(`data-${name}-overlay-close`)) {
              event.stopImmediatePropagation();

              (targets.overlay[0] as HTMLElement).classList.remove(
                STATES.isVisible
              );
            }
          }
        });
      }
    }
  };

  return new Controller({ name, targets, events });

  /////////////////

  /**
   * Initialize a new instance of `OrgChart`.
   */
  function initializeOrgChart(
    options: Partial<OrgChart.Options> = {},
    state: string = 'view'
  ) {
    const loader = targets.loading[0];
    const data = JSON.stringify(
      state === ORG_CHART_STATES.edit ? orgChartData.all : orgChartData.active
    );

    orgChart = orgChartPlugin(
      elementToSelectorString(targets.chart[0]),
      data,
      options
    );

    originalChart = orgChart.getHierarchy();

    if (loader && loader.parentNode) {
      loader.parentNode.removeChild(loader);
    }

    window.dispatchEvent(new CustomEvent('orgchart.loaded'));
  }

  /**
   * Sets the state of the tab controller by appropriately hiding and disabling buttons.
   *
   * @param state The action to set as active. Accepts `view`, `edit` or `export`.
   */
  function setTabsState(state: string) {
    targets.action.forEach(action => {
      action.classList.toggle(
        STATES.isDisabled,
        state === ORG_CHART_STATES.export
      );

      if (state !== ORG_CHART_STATES.export) {
        const actionName = action.dataset.r18OrgChartAction;
        const isActive = state === actionName;

        action.setAttribute('aria-selected', isActive.toString());
        action.parentElement.classList.toggle(STATES.isActive, isActive);

        if (actionName === ORG_CHART_STATES.view) {
          action.innerText = I18n.t(
            `global.buttons.${
              state === ORG_CHART_STATES.edit ? 'save' : 'view'
            }`
          );
        }
      }
    });

    targets.zoom.forEach(zoom => {
      zoom.classList.toggle(
        STATES.isDisabled,
        state === ORG_CHART_STATES.export
      );
    });

    targets.addNew[0].classList.toggle(
      STATES.isHidden,
      [ORG_CHART_STATES.view, ORG_CHART_STATES.export].some(
        key => state === key
      )
    );

    if (targets.export.length > 0) {
      targets.export[0].classList.toggle(
        STATES.isHidden,
        state === ORG_CHART_STATES.edit
      );
    }
  }

  async function saveChart(saveButton: HTMLElement) {
    const url = (targets.savePath[0] as HTMLInputElement).value;
    const updatedChart = orgChart.getHierarchy();
    const response: OrgChartResponse = await executePost(
      url,
      JSON.stringify(updatedChart)
    );

    if (!isEqual(originalChart, updatedChart)) {
      (targets.wasUpdated[0] as HTMLInputElement).value = 'true';
    }

    orgChartData = response;
    saveButton.parentElement.classList.remove(STATES.hasSpinner);

    if ((targets.wasUpdated[0] as HTMLInputElement).value = 'true') {
      targets.lastEdited[0].innerHTML = I18n.t('global.labels.today');
    }

    setTabsState(ORG_CHART_STATES.view);
    setOrgChartState(ORG_CHART_STATES.view);
  }

  async function createOrEditNode(url: string, context: string) {
    const formTemplate: string = await requestTemplate(url, 'form');
    const container = targets.userForm[0];
    const $parent = $(targets.userForm[0].parentElement);

    const form = prepareForm(context, formTemplate);

    container.innerHTML = createOverlayContent(
      'users',
      I18n.t('global.titles.edit_object', {
        title: I18n.t('global.labels.user.one')
      }),
      form.innerHTML
    );

    (targets.overlay[0] as HTMLElement).classList.add(STATES.isVisible);

    const $tooltips = $(container).find('[data-tooltip]');

    if ($tooltips.length) {
      const tooltip = new Tooltip($tooltips);
    }

    fw(controllers).initialize(container);
  }

  async function toggleNode(url: string, callback: () => void) {
    const response = await executeGet(url);

    orgChartData = response;
    (targets.wasUpdated[0] as HTMLInputElement).value = 'true';
    setOrgChartState('edit');
    callback();
  }

  /**
   * Prepare the user form by appending controls to it.
   *
   * @param {string} context
   * @param {*} formTemplate
   * @returns
   */
  function prepareForm(context: string, formTemplate: string) {
    const buttonLabel =
      context === 'edit'
        ? I18n.t('global.buttons.save_changes')
        : 'Save New User';

    const formControls = `
      <div class="row">
        <div class="column">
          <button type="button" class="button secondary hollow has-spinner" data-r18-org-chart-overlay-close>
            ${I18n.t('global.buttons.cancel')}
          </button>
        </div>
        <div class="column text-right">
          <button type="button" class="button secondary has-spinner" data-r18-org-chart-overlay-close data-r18-ui-submit-form="userForm">
            ${buttonLabel}
          </button>
        </div>
      </div>
    `;

    const form = document.createElement('div');

    form.appendChild(
      parser.parseFromString(formTemplate, 'text/html').querySelector('form')
    );

    form.insertAdjacentElement(
      'beforeend',
      parser.parseFromString(formControls, 'text/html').querySelector('.row')
    );

    return form;
  }

  /**
   * Set the state of the org chart based on a selected tab option.
   *
   * @param state The action used to set the state. Accepts `view` or `edit`.
   */
  function setOrgChartState(state: string) {
    const chartContainer = targets.chart[0];
    let newOptions: Partial<OrgChart.Options> = {};

    /*
      clear out the existing container. note that we could use `.innerHTML = ''` here,
      but this `while` loop is dramatically faster. see: https://jsperf.com/innerhtml-vs-removechild
    */
    while (chartContainer.firstChild) {
      chartContainer.removeChild(chartContainer.firstChild);
    }

    switch (state) {
      case 'edit':
        newOptions = {
          draggable: true,
          dropCriteria: ($draggedNode: JQuery, $dropZone: JQuery) => {
            // don't allow user to drag inactive items, or to drop onto inactive items
            return (
              !$draggedNode.hasClass('inactive') &&
              !$dropZone.hasClass('inactive')
            );
          },
          createNode: ($node: JQuery, data: OrgChartDataSource) => {
            /*
            `relationship` is a string composed of three "0/1" identifiers.
            - First character stands for whether current node has parent node.
            - Second character stands for whether current node has siblings nodes.
            - Third character stands for whether current node has children node.
          */

            if (data.relationship !== '001') {
              // don't add the edit controls to the top node
              $node.find('.content')[0].insertAdjacentHTML(
                'afterend',
                `
                <div class="action">
                  <button
                    data-r18-org-chart-toggle-node="${data.slug}"
                    data-r18-org-chart-toggle-path="${data.togglePath}"
                    class="button small secondary clear emphasis">
                    ${I18n.t(
                  `global.buttons.${data.isVisible ? 'hide' : 'show'}`
                )}
                  </button>
                  ${
                  data.canBeEdited
                    ? `
                    <a href="${data.editPath}"
                      data-r18-ui-create-overlay="large"
                      data-r18-ui-create-overlay-title="${actionModelTitle(
                      'edit',
                      'user'
                    )}"
                      data-r18-ui-create-overlay-icon="users"
                      data-r18-org-chart-edit-node="${data.slug}"
                      class="button small secondary emphasis has-spinner">
                      ${I18n.t('global.buttons.edit')}
                    </a>
                  `
                    : ''
                }
                </div>
              `
              );
            }

            if (!data.isVisible) {
              $node.addClass('inactive');
            }
          }
        };
        break;
    }

    initializeOrgChart(newOptions, state);

    window.requestAnimationFrame(() => {
      chartContainer.classList.toggle(STATES.isActive, state === 'edit');
    });
  }
}
