import * as React from 'react';
import classnames from 'classnames';
import { get, isFunction, isUndefined, snakeCase } from 'lodash-es';

import { TextField } from './TextField';
import { CheckboxField } from './CheckboxField';
import { STATES } from '../../shared/states';

interface SignatureFieldProps extends ICustomField {
  fieldName?: string;
  signature?: IFile;
  isNested?: boolean;
  value?: string;
  isStandalone?: boolean;
  useProfileSignature?: boolean;
  onChange?(value: string): void;
}

interface SignatureFieldState {
  signatureFieldValue: string;
  useSignature: boolean;
}

export class SignatureField extends React.Component<
  SignatureFieldProps,
  SignatureFieldState
> {
  signatureInputRef: HTMLInputElement;

  constructor(props: SignatureFieldProps) {
    super(props);

    this.state = {
      signatureFieldValue: '',
      useSignature: get(this.props, 'useSignature', false)
    };
  }

  static defaultProps = {
    fieldName: 'use_profile_signature',
    isStandalone: false,
    isNested: false
  };

  private get signatureFieldValue() {
    if (this.props.isStandalone) {
      return this.state.signatureFieldValue;
    }

    return this.props.value || this.props.initialValue;
  }

  private get useSignature() {
    if (!isUndefined(this.props.useProfileSignature))
      return this.props.useProfileSignature;

    return this.state.useSignature;
  }

  public componentDidUpdate(
    prevProps: SignatureFieldProps,
    prevState: SignatureFieldState
  ) {
    if (
      this.state.useSignature !== prevState.useSignature &&
      this.signatureInputRef
    ) {
      this.signatureInputRef.dispatchEvent(new Event('change'));

      if (isFunction(this.props.onChange)) {
        this.props.onChange(
          this.state.useSignature ? '' : this.state.signatureFieldValue
        );
      }
    }
  }

  public handleSignatureFieldChange: React.ChangeEventHandler<
    HTMLInputElement
  > = event => {
    const { isStandalone, onChange } = this.props;
    const value = event.currentTarget.value;

    if (isStandalone) {
      this.setState({
        signatureFieldValue: value
      });
    } else if (isFunction(onChange)) {
      onChange(value);
    }
  };

  public handleUseSignatureChange: React.MouseEventHandler<
    HTMLInputElement
  > = event => {
    this.setState({
      useSignature: !this.useSignature
    }, () => {
      if (isFunction(this.props.onChange)) {
        this.props.onChange();
      }
    });
  };

  render() {
    const {
      label,
      name,
      signature,
      fieldName,
      initialValue,
      required,
      isNested
    } = this.props;

    const useSignature = this.useSignature;

    return (
      <div style={isNested ? { padding: '0 2px' } : undefined}>
        <label
          className={classnames({
            'not-required': useSignature,
            'is-required': !useSignature && required
          })}>
          <span>{label}</span>
          <input
            ref={ref => (this.signatureInputRef = ref)}
            name={name}
            value={this.signatureFieldValue}
            type="text"
            className={classnames({
              [STATES.isHidden]: useSignature
            })}
            required={required && !useSignature}
            disabled={useSignature}
            onChange={this.handleSignatureFieldChange}
          />
        </label>
        {signature && (
          <>
            <img
              src={signature.downloadUrl}
              className={classnames('thumbnail', {
                [STATES.isHidden]: !useSignature
              })}
            />
            <input type="hidden" name={fieldName} value="0" />
            <div className="checkbox">
              <input
                type="checkbox"
                name={fieldName}
                id={snakeCase(fieldName)}
                value="1"
                checked={useSignature}
                onClick={this.handleUseSignatureChange}
              />
              <label htmlFor={snakeCase(fieldName)}>
                {I18n.t('users.labels.use_signature_image')}
              </label>
              <p className="help-text push--top-half">
                {I18n.t('users.labels.use_signature_image_help')}
              </p>
            </div>
          </>
        )}
      </div>
    );
  }
}
