import * as React from 'react';
import classnames from 'classnames';
import { isEmpty, isNumber, isNull, isUndefined } from 'lodash-es';

interface TextFieldProps extends ICustomField {
  showLabel?: boolean;
  value?: string | number;
  defaultValue?: string | number;
  isNumeric?: boolean;
  isTextarea?: boolean;
  minValue?: number;
  maxValue?: number;
  step?: number;
  className?: string;
  error?: string;
  placeholder?: string;
  wrapperClassName?: string;

  onChange?(event: any): void;

  onKeyUp?(event: any): void;
}

/**
 * Stateless component to render a standard text input.
 */
export const TextField = (props: TextFieldProps) => {
  const {
    name,
    label,
    disabled,
    isNumeric,
    required,
    isTextarea,
    minValue,
    maxValue,
    step,
    showLabel,
    placeholder,
    error,
    wrapperClassName,
    onChange,
    onKeyUp
  } = props;

  const className = [
    props.className || '',
    error ? 'is-invalid-input' : ''
  ].join(' ');

  const inputProps = Object.assign(
    {},
    {
      name: name,
      placeholder: showLabel ? undefined : label,
      value: prepareValue(props.value),
      defaultValue: prepareValue(props.defaultValue),
      required: required,
      disabled: disabled,
      className: className,
      onChange: onChange,
      onKeyUp: onKeyUp
    },
    isNumeric
      ? {
          min: minValue,
          max: maxValue,
          step
        }
      : {},
    placeholder ? { placeholder } : {}
  );

  return (
    <label
      className={classnames(wrapperClassName, {
        'is-required':
          required &&
          (isNumeric ? isUndefined(inputProps.value) : !inputProps.value),
        nowrap: required && !inputProps.value
      })}
    >
      <span className={showLabel ? '' : 'show-for-sr'}>{label}</span>
      {isTextarea && <textarea rows={6} {...inputProps} />}
      {!isTextarea && (
        <input type={isNumeric ? 'number' : 'text'} {...inputProps} />
      )}
      {error && <span className="text-alert">{error}</span>}
    </label>
  );

  function prepareValue(value?: any): any {
    if (isUndefined(value)) return undefined;

    if (isNumeric) {
      if (value === '' || isNull(value)) {
        return '';
      } else {
        return Number(value);
      }
    } else {
      return value;
    }
  }
};
