import * as React from 'react';

import { createMarkup } from '../../../shared';
import { ConfirmAction } from '../ConfirmAction';

interface ConfirmCorrectiveActionProps {
  incompleteItems: Array<string>;
  isDisabled: boolean;
  buttonLabel: string;
  action(): void;
}

export const ConfirmCorrectiveAction = ({
  incompleteItems,
  buttonLabel,
  isDisabled,
  action
}: ConfirmCorrectiveActionProps) => (
  <ConfirmAction
    id="ConfirmAction_CorrectiveAction"
    title={I18n.t('modals.confirm_corrective_action.title')}
    message={
      <span
        dangerouslySetInnerHTML={createMarkup(prepareMessage(incompleteItems))}
      />
    }
    buttonProps={{
      label: buttonLabel,
      classNames: 'secondary expanded push--bottom',
      disabled: isDisabled
    }}
    action={action}
  />
);

function prepareMessage(items: Array<string>) {
  return `
    <p>${I18n.t('modals.confirm_corrective_action.message_intro')}</p>
    <ul>${items
      .map(
        item =>
          `<li>${I18n.t(`qms_reports.labels.corrective_action.${item}`)}</li>`
      )
      .join('')}</ul>
    <p>${I18n.t('modals.confirm_corrective_action.message_outro')}</p>
  `;
}
