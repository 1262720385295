import { Controller } from 'framewerk';

import {
  controllerSelector,
  initLibraries,
  requestTemplate,
  STATES
} from '../shared';

const { Tabs } = Foundation;

interface QualityManualTabDataAttributes {
  qualityStandardAbbreviation: string;
}

interface QualityManualPanelDataAttributes {
  r18QualityManualContent: string;
  r18QualityManualStandard: string;
}

interface QualityManualTab extends HTMLElement {
  dataset: DOMStringMap & QualityManualTabDataAttributes;
}

interface QualityManualPanel extends HTMLElement {
  dataset: DOMStringMap & QualityManualPanelDataAttributes;
}

/**
 * The initialization function for creating the `r18-quality-manual` Controller.
 */
export function qualityManual(): Controller {
  let tabs: typeof Tabs;

  /**
   * The name of the controller.
   */
  const name: string = 'r18-quality-manual';

  /**
   * Factory method for creating selectors.
   */
  const selector = controllerSelector(name);

  /**
   * Selector strings for the `r18-quality-manual` Controller.
   */
  const targets = Controller.getTargets({
    controller: `[data-controller="${name}"]`,
    content: selector('content'),
    exportButton: selector('export-button'),
    addSectionButton: selector('add-section-button'),
    tocButton: selector('toc-button'),
    goToTab: selector('go-to-tab'),
    tabs: selector('tabs'),
    tab: selector('tab')
  });

  /**
   * Events created for the `r18-quality-manual` Controller.
   */
  const events = {
    windowLoad: async () => {
      // initialize tabs for Quality Standards
      tabs = new Tabs($(targets.tabs[0]));
      $(targets.tabs[0]).on('change.zf.tabs', handlePanelLoad);

      // check for entry params and shift the page to the appropriate tab and section
      const params = new URLSearchParams(window.location.search);

      targets.controller[0].addEventListener(
        'qm.panel-loaded',
        () => {
          jumpToSection(params.get('report_entry_template_id'));
        },
        {
          once: true
        }
      );

      if (
        params.has('quality_standard_id') &&
        params.has('report_entry_template_id')
      ) {
        if (params.get('quality_standard_id') !== '1') {
          const tab = targets.tab.find(
            tab =>
              tab.dataset.r18QualityManualTab ===
              params.get('quality_standard_id')
          );

          tab?.click();
        } else {
          await handlePanelLoad();
        }
      } else {
        await handlePanelLoad();
      }
    },
    controllerClick: () => {
      targets.controller[0].addEventListener('click', event => {
        const target = event.target as HTMLButtonElement;
        if (target.dataset.r18QualityManualGoToTab) {
          goToTab(target.dataset.r18QualityManualGoToTab);
        }
      });
    }
  };

  return new Controller({ name, targets, events });

  /////////////////

  function goToTab(abbreviation: string) {
    // @ts-ignore
    // string id is valid value for this method
    $(targets.tabs[0]).foundation('selectTab', `standard_${abbreviation}`);
  }

  async function handlePanelLoad() {
    const panel = getActivePanel();
    if (!panel) return;

    const exportButton = targets.exportButton[0] as HTMLAnchorElement;
    const standard = JSON.parse(
      panel.dataset.r18QualityManualStandard
    ) as IQualityStandard;
    const id = standard.id.toString();
    if (exportButton) {
      applyStandardIdToLink(exportButton, id);
      applyStandardIdToLink(
        targets.addSectionButton[0] as HTMLAnchorElement,
        id
      );
    }

    const updatedTitle = I18n.t(
      'quality_manual.buttons.table_of_contents_with_name',
      {
        standard: standard.name
      }
    );

    targets.tocButton.forEach((target: HTMLAnchorElement) => {
      applyStandardIdToLink(target, id);
      // have to set the title this way for the overlay scripting to see the change
      $(target).data('r18UiCreateOverlayTitle', updatedTitle);
    });

    if (panel.hasAttribute('data-is-loaded')) return;

    exportButton?.classList?.add('disabled');

    panel.innerHTML = await requestTemplate(
      panel.dataset.r18QualityManualContent,
      selector('wrapper')
    );

    setTimeout(() => {
      targets.controller[0].dispatchEvent(new CustomEvent('qm.panel-loaded'));
    }, 50);

    requestAnimationFrame(() => {
      initLibraries(panel);
      panel.setAttribute('data-is-loaded', 'true');
      exportButton?.classList?.remove('disabled');
    });
  }

  function applyStandardIdToLink(target: HTMLAnchorElement, id: string) {
    const url = new URL(target.href);
    url.searchParams.set('quality_standard_id', id);
    target.href = url.toString();
  }

  function getActivePanel(): QualityManualPanel {
    return document.querySelector<QualityManualPanel>(
      `[data-tabs-content="qualityStandardsTabs"] .tabs-panel.${STATES.isActive}`
    );
  }

  function jumpToSection(id: string) {
    if (id === undefined) return;

    const section = document.getElementById(`quality_manual_section_${id}`);
    const qmControlsHeight =
      document.getElementById('qmControls')?.clientHeight ?? 0;
    const navWrapperHeight =
      document.querySelector<HTMLElement>('.nav-wrapper')?.clientHeight ?? 0;

    window.scroll({
      top: section.offsetTop - (navWrapperHeight + qmControlsHeight),
      behavior: 'auto'
    });
  }
}
