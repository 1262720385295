import { UAParser } from 'ua-parser-js';

/**
 * Safely calculate a top position for an element to transform during
 * an overlay event.
 *
 * Internet Explorer doesn't appear to properly position the overlay and its
 * backdrop. If the page is scrolled at all, both receive `top` with the scroll
 * position, as expected. Unlike newer browsers, though, IE will render the overlay
 * as if its parent is the window, not the parent body; as a result, it appears shifted
 * downward in proportion to the current scroll distance. Applying an equally reverse
 * negative margin "fixes" this issue at the expense of breaking everyone else.
 *
 * While browser sniffing is generally unreliable, in this specific case it is necessary
 * to check for the presence of IE, and add the negative margin when required. Otherwise
 * we set this value to `undefined` and React will ignore it.
 *
 * @param topPosition
 */
export function safeOverlayPosition(topPosition: number) {
  const { name } = new UAParser().getBrowser();

  const top = isNaN(topPosition) ? `0` : `${topPosition}px`;
  const marginTop =
    name === 'IE' && !isNaN(topPosition) ? `-${topPosition}px` : undefined;

  return {
    top,
    marginTop
  };
}
