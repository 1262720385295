import {
  debounce,
  forEach,
  get,
  has,
  isEmpty,
  isNull,
  isNumber,
  isObject,
  isString,
  isUndefined,
  omit
} from 'lodash-es';
import * as React from 'react';
import Select, {
  Async,
  AsyncCreatable,
  components,
  Creatable,
  createFilter
} from 'react-select';
import { LegacyV1Props } from 'react-select-v1';
import { Props as ReactSelectProps } from 'react-select/lib/Select';
import { StylesConfig } from 'react-select/lib/styles';
import { VariableSizeList } from 'react-window';
import { executeGet, getScrollbarWidth } from '../../shared';

const { createRef } = React;
const { MenuList: ReactSelectMenuList } = components;

type ValueType = string | number;

interface SelectFieldProps
  extends ICustomField,
    Omit<Partial<ReactSelectProps<SelectOption>>, 'value'>,
    LegacyV1Props {
  showLabel: boolean;
  allowCustomEntry?: boolean;
  searchPath?: string;
  searchLabel?: string;
  searchKeys?: Array<string>;
  value: ValueType | Array<ValueType> | SelectOption | Array<SelectOption>;
}

interface SelectFieldState {
  currentValue: string | Set<string>;
}

const fetchOptions = (
  props: SelectFieldProps,
  searchTerm: string,
  callback: any,
  listRef?: React.RefObject<VariableSizeList<any>>
) => {
  const { searchPath, searchKeys, searchLabel } = props;
  const searchUrl = new URL(searchPath, window.location.origin);

  searchUrl.searchParams.append('search_key', searchLabel);
  searchKeys.forEach(key => searchUrl.searchParams.append('q['+key+']', searchTerm));

  executeGet(searchUrl.toString()).then(
    response => {
      callback(response);
      requestAnimationFrame(() => {
        listRef?.current?.resetAfterIndex(0, true);
      });
    }
  );
};

export class SelectField extends React.Component<
  SelectFieldProps,
  SelectFieldState
> {
  /**
   * Provides a translation layer to refactor props which were changed or removed
   * from react-select v2. This should allow us to transition all instances of
   * `SelectField` with minimal alteration to existing code.
   *
   * @private
   */
  private get translatedProps(): ReactSelectProps {
    const legacyKeys = this.legacyPropsMapping.map(([key, _]) => key);
    const newProps = this.legacyPropsMapping.reduce(
      (props, [legacyKey, key]) => {
        if (has(this.props, legacyKey)) {
          props[key] = this.props[legacyKey];
        }

        return props;
      },
      {}
    ) as Partial<ReactSelectProps>;

    if (this.props.simpleValue) {
      if ((this.props.joinValues || newProps.isMulti) && this.props.value) {
        const normalizedValue = Array.isArray(this.props.value)
          ? (this.props.value as string[])
          : this.props.value?.toString()?.split(',') ?? [];
        newProps.value = normalizedValue.map(value =>
          this.props.options.find(option => option.value === value)
        );
      } else {
        if (isObject(this.props.value)) {
          newProps.value = this.props.options.find(
            ({ value }) => value === (this.props.value as SelectOption).value
          );
        } else {
          newProps.value = this.props.options.find(
            ({ value }) => value === this.props.value
          );
        }
      }
    }

    return {
      ...omit(this.props, [...legacyKeys, 'simpleValue', 'joinValues']),
      ...newProps
    };
  }

  private get isMulti() {
    return this.props.multi || this.props.isMulti;
  }

  static defaultProps = {
    components: {}
  };

  public props: SelectFieldProps;
  public state: SelectFieldState;

  debouncedFetchOptions = debounce(fetchOptions, 500);

  private readonly pivotPoint: number;
  private readonly canvas: HTMLCanvasElement;
  private readonly selectElementWrapperRef: React.RefObject<HTMLDivElement>;
  private readonly selectRef: React.RefObject<HTMLElement>;
  private readonly listRef: React.RefObject<VariableSizeList<any>>;
  private readonly scrollBarWidth: number;
  private readonly styles: StylesConfig;
  private readonly legacyPropsMapping: [
    keyof LegacyV1Props,
    keyof ReactSelectProps
  ][];
  private inputWidth: number;

  constructor(props) {
    super(props);

    this.pivotPoint = 50;
    this.canvas = document.createElement('canvas');
    this.selectElementWrapperRef = createRef();
    this.selectRef = createRef();
    this.listRef = createRef();
    this.scrollBarWidth = getScrollbarWidth();

    this.state = {
      currentValue: this.props.isMulti ? new Set() : ''
    };

    this.legacyPropsMapping = [
      ['autoBlur', 'blurInputOnSelect'],
      ['clearable', 'isClearable'],
      ['closeOnSelect', 'closeMenuOnSelect'],
      ['disabled', 'isDisabled'],
      ['filterOptions', 'filterOption'],
      ['multi', 'isMulti'],
      ['noResultsText', 'noOptionsMessage'],
      ['onClose', 'onMenuClose'],
      ['onInputKeyDown', 'onKeyDown'],
      ['onOpen', 'onMenuOpen'],
      ['openOnClick', 'openMenuOnClick'],
      ['openOnFocus', 'openMenuOnFocus'],
      ['rtl', 'isRtl'],
      ['searchable', 'isSearchable']
    ];

    this.styles = {
      container: (provided, state) => ({
        marginBottom: '1rem',
        boxShadow: state.isFocused
          ? 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 0, 0, 0.2)'
          : 'none'
      }),
      clearIndicator: provided => ({
        ...provided,
        padding: 6
      }),
      control: (provided, state) => ({
        ...provided,
        boxShadow: (() => {
          if (state.isFocused) return '0 2px 4px rgba(51, 51, 51, 0.2)';
          if (state.isHovered) return '0 1px 0 rgba(0, 0, 0, 0.06)';
          return 'none';
        })(),
        borderColor: state.isHovered || state.isFocused ? '#315F94' : '#d7d7d7',
        borderRadius: 0,
        minHeight: 32.5,
        maxHeight: 32.5,
        height: 32.5
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        alignItems: 'center',
        justifyContent: 'center',
        color: '#333',
        width: 20,
        height: '100%',
        padding: 0,
        background: 'transparent',
        verticalAlign: 'baseline'
      }),
      indicatorsContainer: provided => ({
        ...provided,
        height: 'inherit'
      }),
      indicatorSeparator: (_, state) => ({
        width: state.isFocused || state.menuIsOpen ? 0 : 1
      }),
      multiValue: () => ({
        backgroundColor: '#f5f7fa',
        borderColor: '#f5f7fa',
        padding: 0
      }),
      placeholder: provided => ({
        ...provided,
        color: '#d7d7d7',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
      }),
      menu: provided => ({
        ...provided,
        margin: '-1px 0 0 0',
        borderRadius: 0,
        borderColor: '#315F94',
        boxShadow: '0 2px 4px rgba(51, 51, 51, 0.2)'
      }),
      menuList: provided => ({
        ...provided,
        maxHeight: 190
      }),
      valueContainer: provided => ({
        ...provided,
        paddingTop: this.state.currentValue && this.props.isMulti ? 8 : 0,
        paddingBottom: this.state.currentValue && this.props.isMulti ? 8 : 0
      }),
      option: (provided, state) => ({
        ...provided,
        padding: '0.5rem',
        opacity: state.isDisabled ? 0.5 : 1
      })
    };
  }

  static getPadding(padding, direction) {
    const splitPadding = padding.match(/\d*px/g);
    const index = Number(direction === 'horizontal');

    return parseInt(splitPadding[index], 10);
  }

  static getDerivedStateFromProps({ multi, isMulti, value, options }) {
    let currentValue = value;

    if (isMulti || multi) {
      if (Array.isArray(value)) {
        if (value.every(v => isString(v) || isNumber(v))) {
          currentValue = new Set(
            options?.filter(option => value.includes(option.value)) ?? []
          );
        } else {
          currentValue = new Set(value);
        }
      } else {
        const preparedValues = (value ?? '')
          .split(',')
          .map(v =>
            options.find(option => option.value.toString() === v.toString())
          );
        currentValue = new Set(preparedValues);
      }
    } else {
      if (isString(value) || isNumber(value)) {
        currentValue = options.find(
          ({ value: optionValue }) => optionValue === value
        );
      }
    }

    return {
      currentValue
    };
  }

  /**
   * Determine if a value is present and valid. Handles multiple kinds of input.
   * NOTE: there might be some redundancy here.
   *
   * @param {*} value - The value to check.
   */
  private static hasValidValue(value: any) {
    // an undefined or null value is always invalid
    if (isUndefined(value) || isNull(value)) return false;

    // if the value is a number we need to explicitly check for `null`
    if (isNumber(value)) return !isNull(value);

    // otherwise we can check for null or empty state
    return !isNull(value) && !isEmpty(value);
  }

  componentDidMount() {
    if (!document.querySelector('#rowContainer')) {
      /*
          Here we create an empty, hidden container
          at the bottom of the page. We will use this
          as part of `this.computeRowHeight`. We create
          a single element and reuse it for performance
          reasons. Obviously we only want to create
          this element once.
      */
      const rowContainer = document.createElement('div');

      rowContainer.style.visibility = 'hidden';
      rowContainer.style.position = 'absolute';
      rowContainer.style.top = '0';
      rowContainer.id = 'rowContainer';

      document.body.appendChild(rowContainer);
    }

    if (
      (this.props.options || []).length > this.pivotPoint ||
      this.props.isAsync
    ) {
      this.setInputWidth();
    }

    window.addEventListener(
      'resize',
      debounce(() => {
        this.setInputWidth();
      }, 500)
    );
  }

  componentDidUpdate() {
    if (!this.inputWidth) {
      this.setInputWidth();
    }
  }

  setInputWidth = () => {
    if (!this.selectElementWrapperRef.current) return;

    this.inputWidth = this.computeInputWidth();
  };

  computeInputWidth = () => {
    const inputContainerWidth = window.getComputedStyle(
      this.selectElementWrapperRef.current
    ).width;
    const { padding } = this.styles.menu({}, {});

    return (
      parseInt(inputContainerWidth, 10) -
      (Number(padding) * 2 + this.scrollBarWidth)
    );
  };

  computeRowHeight = (listProps, rowIndex) => {
    const row = document.querySelector<HTMLElement>('#rowContainer');

    /*
        Each time an option is rendered, this method is called
        to compute its height. This is done by transferring
        the styles from the option to our hidden container
        (to match the rendering) and applying the known
        width of the option as dictated by the width of the
        select control itself.
    */
    forEach(listProps.getStyles('option', listProps), (value, property) => {
      row.style[property] = value;
    });

    row.style.width = `${this.inputWidth}px`;

    /*
       Then, we insert the current option label into the container.
       This will cause it to wrap in the same manner as the rendered
       option itself. As a fallback we pass in a string with one space.
     */
    row.textContent = get(listProps, `children.${rowIndex}.props.label`, ' ');

    /*
      Finally, we return the height of the hidden element. This should
      almost always match the height which react-window will apply
      to the option.

      Note that once a row's height has been computed once, it is
      cached by react-window and does not need to be recalculated
      _as long as the menu remains open._
     */
    return row.clientHeight;
  };

  MenuList = props => {
    const { children, maxHeight, getStyles } = props;
    const optionStyles = getStyles('option', props);
    const { padding } = optionStyles as { padding: number };

    if (children.length < this.pivotPoint) {
      return <ReactSelectMenuList {...props} />;
    }

    return (
      <VariableSizeList
        ref={this.listRef}
        height={maxHeight}
        width={this.inputWidth}
        itemCount={children.length}
        itemSize={index => this.computeRowHeight(props, index)}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </VariableSizeList>
    );
  };

  DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <span className="react-select-arrow" />
      </components.DropdownIndicator>
    );
  };

  getOptions = (searchTerm, callback) => {
    if (!searchTerm) return callback([]);
    this.debouncedFetchOptions(this.props, searchTerm, callback, this.listRef);
  };

  handleOnChange = (value, action) => {
    this.setState(
      {
        currentValue: value
      },
      () => {
        if (this.isMulti) {
          this.props.onChange(value.map(v => v.value).join(','), action);
        } else {
          this.props.onChange(value, action);
        }

        if (
          ['select-option', 'remove-value'].some(act => act === action.action)
        ) {
          this.listRef.current?.resetAfterIndex(0);
        }
      }
    );
  };

  render() {
    const {
      isAsync,
      label,
      required,
      id,
      value,
      showLabel,
      options,
      ...props
    } = this.translatedProps;
    const { allowCustomEntry } = this.props;
    const { currentValue } = this.state;
    let Component: any;

    if (isAsync) {
      Component = allowCustomEntry ? AsyncCreatable : Async;
    } else {
      Component = allowCustomEntry ? Creatable : Select;
    }

    const components = Object.assign(
      {},
      {
        DropdownIndicator: this.DropdownIndicator,
        MenuList: this.MenuList
      },
      props.components
    );

    const selectProps = Object.assign(
      {},
      // common props for both versions
      {
        classNamePrefix: 'react-select',
        className: 'react-select',
        styles: this.styles,
        filterOptions: createFilter({
          ignoreAccents: true,
          ignoreCase: true
        })
      },
      // props from the calling component
      id,
      props,
      // specific overrides
      {
        components,
        onChange: this.handleOnChange,
        value: props.isMulti ? Array.from(currentValue || []) : currentValue
      },
      allowCustomEntry
        ? {
            createOptionPosition: 'first',
            formatCreateLabel: inputValue =>
              `Create New Option From "${inputValue}"`
          }
        : {},
      // async options if needed
      isAsync
        ? {
            cacheOptions: true,
            loadOptions: this.getOptions
          }
        : {
            options
          }
    );

    return (
      <label
        htmlFor={id}
        className={
          required && !SelectField.hasValidValue(value)
            ? 'is-required'
            : undefined
        }
      >
        {label && showLabel && <span>{label}</span>}
        <div ref={this.selectElementWrapperRef}>
          <Component ref={this.selectRef} {...selectProps} />
        </div>
      </label>
    );
  }
}
