import {
  filter,
  find,
  forEach,
  includes,
  isArray,
  isEmpty,
  last
} from 'lodash-es';

import { dataSelector, decodeString, kebabToCamel } from '../shared';

/**
 * The `setAttr` module.
 */
export function setAttr<T extends HTMLElement>(
  source: T,
  targets: any // TODO: fix that
): {
  init: () => void;
} {
  function getValue(element: HTMLElement, key?: string): string {
    switch (element.tagName) {
      case 'SELECT':
        const options = element.querySelectorAll('option') as NodeListOf<
          HTMLOptionElement
        >;
        const option = find(
          options,
          option => option.selected && option.selected === true
        );

        if (option) {
          return option.value;
        } else {
          return '';
        }

      // return option.label || option.title || option.innerText;
      case 'INPUT':
        const inputElement = element as HTMLInputElement;

        if (inputElement.type === 'checkbox') {
          return inputElement.checked.toString();
        } else {
          return inputElement.value;
        }
      default:
        return null;
    }
  }

  /**
   * Prepare and return the final value.
   *
   * @protected
   * @param {HTMLElement} element The element to inspect and evaluate.
   * @param {string} value The value provided to set.
   * @param {string} attr The attribute to set.
   *
   * @returns {string}
   */
  function prepareValue(
    element: HTMLElement,
    value: string,
    attr: string
  ): string {
    let currentValue;

    if (attr === 'class') {
      const currentClassList = Array.from(element.classList);

      // if the provided value starts with `!`, always remove it
      if (value.startsWith('!')) {
        value = value.slice(1);
        return currentClassList.filter(c => c !== value).join(' ');
      } else {
        // when setting a class, we want to dedupe the class list — insert it if not
        // present, or remove it without affecting the other items.
        const newClassList = includes(currentClassList, value)
          ? currentClassList.filter(v => v !== value)
          : currentClassList.concat([value]);

        return newClassList.join(' ');
      }
    }

    // if all the other conditions fail, just return the value with no
    // transformation.
    return value;
  }

  /**
   * Identify the target element and value to write, and then write it.
   *
   * @param attrs {string[]} The attribute name as an array of strings.
   * @param index {number} The current iteration index.
   */
  function writeAttr(attrs: string[], index: number) {
    const target = attrs.shift(); // the first value in the array is the target name
    const targetElement = document.querySelector(
      dataSelector('r18UiSetAttrOutput', target)
    ) as HTMLElement;

    if (targetElement) {
      attrs.forEach(prop => {
        const attrArray = kebabToCamel(prop).split('-');
        const key: string | undefined =
          attrArray.length > 1 ? last(attrArray) : undefined;
        const attr: string = attrArray.join('');
        let value: string;

        if (source.dataset.r18SetAttrValue) {
          if (isArray(source.dataset.r18UiSetAttrValue)) {
            value = source.dataset.r18UiSetAttrValue[index];
          } else {
            value = source.dataset.r18UiSetAttrValue;
          }
        } else {
          value = getValue(source, key);
        }

        if (['value', 'class'].some(a => a === attr)) {
          value = prepareValue(targetElement, value, attr);
        }

        targetElement.setAttribute(attr, value);

        if (!source.hasAttribute('data-r18-ui-set-attr-silent')) {
          const event = new Event('change', {
            bubbles: true
          });

          targetElement.dispatchEvent(event);
        }
      });
    }
  }

  /**
   * Initialize the module.
   */
  function init() {
    let params: string[][];

    if (source.dataset.r18UiSetAttr) {
      // split the attribute value for each item
      params = source.dataset.r18UiSetAttr
        .split(',')
        .map((param): string[] => param.split('|'));

      if (params) {
        params.forEach(writeAttr);
      }
    }
  }

  return { init };
}
