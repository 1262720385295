import classNames from 'classnames';
import * as React from 'react';
import { InputTypes, Switch } from 'react-foundation';
import type { SwitchSizes } from 'react-foundation/enums';

interface CheckboxFieldProps extends ICustomField {
  value?: string;
  hiddenFieldValue?: string;
  includeHiddenField?: boolean;
  checked?: boolean;
  subLabel?: string;
  className?: string;
  size?: SwitchSizes;
  onChange?: (event: any) => void;
}

export const CheckboxField = (props: CheckboxFieldProps) => {
  const {
    name,
    className,
    label,
    subLabel,
    value,
    hiddenFieldValue,
    includeHiddenField = true,
    checked,
    size,
    onChange
  } = props;

  const inputProps = {
    type: InputTypes.CHECKBOX,
    name,
    value,
    checked,
    onChange
  };

  return (
    <div className={classNames('checkbox-field', className)}>
      {includeHiddenField && (
        <input type="hidden" name={name} value={hiddenFieldValue || '0'} />
      )}
      <Switch
        size={size}
        input={inputProps}
        active={{
          text: size === 'tiny' ? undefined : I18n.t('global.labels.yes_label')
        }}
        inactive={{
          text: size === 'tiny' ? undefined : I18n.t('global.labels.no_label')
        }}
      />
      {!subLabel && <span>{label}</span>}
      {subLabel && (
        <span className="two-tier two-tier--staggered">
          <span>{label}</span>
          <span>{subLabel}</span>
        </span>
      )}
    </div>
  );
};
