import * as React from 'react';
import { Menu, MenuItem } from 'react-foundation';
import { isUndefined } from 'lodash-es';

import { IStateDesignation } from '../StateDesignations';
import {
  actionModelTitle,
  createMarkup,
  processForm,
  fieldName
} from '../../../shared';
import { Form, FormProps, FormState } from '../../common/Form';
import { TextField } from '../../fields';
import { OverlayForm } from '../../common';

interface StateDesignationFormProps extends FormProps<IStateDesignation> {
  closeOverlay(): void;
}

interface StateDesignationFormState extends FormState {}

export class StateDesignationForm extends Form<
  StateDesignationFormProps,
  StateDesignationFormState
> {
  props: StateDesignationFormProps;
  state: StateDesignationFormState;
  form: HTMLFormElement;
  fName: (
    params: string | Array<string>,
    isMultiple?: boolean
  ) => string = fieldName('state_code');

  constructor(props: StateDesignationFormProps) {
    super(props);

    this.state = this.initialState();
  }

  public UNSAFE_componentWillReceiveProps(nextProps: StateDesignationFormProps) {
    this.setState(
      (prevState: StateDesignationFormState): StateDesignationFormState => {
        if (nextProps.currentItem) {
          const { title } = nextProps.currentItem;

          return Object.assign(this.initialState(), {
            title
          });
        } else {
          return this.initialState();
        }
      }
    );
  }

  public render() {
    const {
      coreName,
      currentItem,
      managementContext,
      saveRoute,
      formSuccess,
      formFailure,
      closeOverlay,
      isAdminView
    } = this.props;

    const { name, isSubmitting } = this.state;

    const f = this.fName;

    return (
      <OverlayForm
        title={actionModelTitle(managementContext, coreName)}
        icon="inventory"
        saveRoute={currentItem ? currentItem.saveRoute : saveRoute}
        form={this.form}
        showCancelButton
        canSubmit={this.formIsValid() && !isSubmitting}
        handleSubmit={this.handleSubmit}
        updatesItem={!isUndefined(currentItem)}
        formSuccess={formSuccess}
        formFailure={formFailure}
        closeOverlay={closeOverlay}>
        <form ref={form => (this.form = form)}>
          {isAdminView && (
            <input type="hidden" name={f('admin')} value="true" />
          )}
          <TextField
            name={f('name')}
            label={I18n.t('global.labels.name')}
            value={name}
            required
            showLabel
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              this.handleTextChange('name', event.target.value);
            }}
          />
        </form>
      </OverlayForm>
    );
  }

  private initialState() {
    return {
      name: '',
      isSubmitting: false
    };
  }
}
