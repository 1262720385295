import { Controller } from 'framewerk';
import { throttle, startsWith } from 'lodash-es';

import { ckEditor } from '../plugins';
import { fixedTable } from '../modules';
import {
  STATES,
  findParentNodeByTag,
  findParentNodeByDataAttribute,
  kebabToCamel,
  dataPropName,
  dataSelector,
  executeFetch,
  saveDataToField
} from '../shared';

/**
 * The initialization function for creating the `r18-emailNotifications` Controller.
 */
export function emailNotifications(): Controller {
  /**
   * The name of the controller.
   */
  const name: string = 'r18-email-notifications';

  /**
   * Selector strings for the `r18-email-notifications` Controller.
   */
  const targets = Controller.getTargets({
    form: `form[data-${name}-form]`,
    container: `div[data-${name}-container]`,
    partialPath: `input[data-${name}-partial-path]`,
    tab: `a[data-${name}-tab]`
  });

  /**
   * Events created for the `r18-email-notifications` Controller.
   */
  const events = {
    windowLoad: () => {},
    collapseEvents: () => {
      targets.container.forEach(c => {
        c.addEventListener('mouseover', throttle(mouseEventHandler, 100));
        c.addEventListener('mouseout', throttle(mouseEventHandler, 100));
        c.addEventListener('mousedown', event => {
          const target = event.target as HTMLElement;

          if (target) {
            if (target.hasAttribute(`data-${name}-toggle`)) {
              event.stopPropagation();

              toggleHandler(
                target.previousElementSibling as HTMLInputElement,
                target.dataset[dataPropName(name, "toggle")]
              );

              window.dispatchEvent(
                new CustomEvent("fixedTable.correctPosition")
              );
            }

            if (target.hasAttribute(`data-${name}-collapse`)) {
              // if a fixed table is in effect, there might be a duplicate of the clicked
              // target. we want to catch 'em all.
              const collapseTargets = c.querySelectorAll<
                HTMLTableHeaderCellElement
              >(
                `[data-${name}-collapse="${
                  target.dataset.r18EmailNotificationsCollapse
                }"]`
              );

              Array.from(collapseTargets).forEach(collapseTarget => {
                const row = getRow(collapseTarget);
                row.classList.toggle(STATES.isCollapsed);

                const rowTbody = findParentNodeByTag(row, 'tbody');

                rowTbody.querySelectorAll('tr:not(.collapse)').forEach(row => {
                  row.classList.toggle(STATES.isHidden);
                });
              });

              window.dispatchEvent(new CustomEvent('fixedTable.setLimits'));
            }
          }
        });
      });
    },
    tabClick: () => {
      targets.tab.forEach(tab => {
        tab.addEventListener('click', event => {
          event.preventDefault();

          const eventTarget = event.target as HTMLAnchorElement;
          const contentToLoad = eventTarget.dataset[dataPropName(name, 'tab')];

          setTabState(eventTarget);
          toggleLoadingState(true);
          loadPartial(contentToLoad);
        });
      });
    }
  };

  return new Controller({ name, targets, events });

  /////////////////

  function toggleHandler(element: HTMLInputElement, action: string) {
    switch (action) {
      case 'allEquipment':
        toggleAllEquipment(element);
        break;
      case 'category':
        toggleCategory(element);
        break;
      case 'manager':
        toggleManager(element);
      case 'misc':
        toggleMisc(element);
        break;
    }
  }

  function mouseEventHandler(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (target === null) return;

    if (target.hasAttribute(`data-${name}-collapse`)) {
      let action: string;

      switch (event.type) {
        case 'mouseover':
          action = 'add';
          break;
        case 'mouseout':
          action = 'remove';
          break;
      }

      if (action) {
        setCollapseRowState(
          target as HTMLTableHeaderCellElement,
          STATES.isHovered,
          action
        );
      }
    }
  }

  function toggleLoadingState(isLoading: boolean) {
    targets.container[0].classList.toggle(STATES.isLoading, isLoading);
  }

  function setCollapseRowState(
    cell: HTMLTableCellElement | HTMLTableHeaderCellElement,
    state: string,
    action: string
  ) {
    const row = getRow(cell);

    Array.from(row.children).forEach(cell => {
      cell.classList.toggle(state, action === 'add');
    });
  }

  /**
   * Sets the appropriate active state on the tab bar.
   *
   * @param target The active target.
   */
  function setTabState(target: HTMLAnchorElement) {
    targets.tab.forEach(tab => {
      tab.parentElement.classList.remove(STATES.isActive);
      tab.setAttribute('aria-selected', 'false');
    });

    target.parentElement.classList.add(STATES.isActive);
    target.setAttribute('aria-selected', 'true');
  }

  async function loadPartial(partialKey: string) {
    const url = (targets.partialPath[0] as HTMLInputElement).value;
    const formAction = targets.form[0].querySelector<HTMLInputElement>(
      `input[data-${name}-mailing-path=${partialKey}]`
    );

    const partial = await executeFetch(
      `${url}?mail_tab=${partialKey}`,
      {
        method: 'get'
      },
      true,
      false
    );

    targets.container[0].innerHTML = partial;

    if (formAction) {
      (targets.form[0] as HTMLFormElement).action = formAction.value;
    }

    toggleLoadingState(false);

    const fixedTableTarget = targets.container[0].querySelector<
      HTMLTableElement
    >('[data-r18-ui-fixed-table]');

    if (fixedTableTarget) {
      fixedTable(fixedTableTarget);
    }
  }

  /**
   * Get the parent row of a provided table cell.
   *
   * @param tableCell The target cell.
   */
  function getRow(
    tableCell: HTMLTableHeaderCellElement | HTMLTableCellElement
  ) {
    return tableCell.parentElement;
  }

  /**
   * Toggle all equipment in a column.
   *
   * @param category A checked input.
   */
  function toggleAllEquipment(category: HTMLInputElement) {
    const parts = category.id.split('_user');
    const match = parts[0] + '.*_user' + parts[1];
    const catRe = new RegExp(match);
    const checked = !category.checked; // checkbox is not yet checked at this point!

    targets.container[0]
      .querySelectorAll<HTMLInputElement>('input.equipment')
      .forEach((input: HTMLInputElement) => {
        if (catRe.test(input.id)) {
          input.checked = checked;
        }
      });
  }

  /**
   * Toggle an entire category.
   *
   * @param equipment A checked input.
   */
  function toggleCategory(equipment: HTMLInputElement) {
    const categoryId = equipment.id.split(/_equipment_record_\d+/).join('');
    const target = document.getElementById(`${categoryId}`);

    if (target) {
      (target as HTMLInputElement).checked = false;
    }
  }

  /**
   * Toggle an entire miscellaneous row.
   *
   * @param checkbox
   */
  function toggleMisc(checkbox: HTMLInputElement) {
    const categoryId = checkbox.id.match(/\d+/);
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      `input[id^="misc_notification_user_${categoryId[0]}"]`
    );
    const checked = !checkbox.checked;

    checkboxes.forEach(c => {
      if (c.id !== checkbox.id) {
        c.checked = checked;
      }
    });
  }

  /**
   * Toggle an entire manager row.
   *
   * @param {HTMLInputElement} checkbox
   */
  function toggleManager(checkbox: HTMLInputElement) {
    const categoryId = checkbox.id.match(/\d+/);
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      `input[id^="tech_eval_manager_${categoryId[0]}"]`
    );
    const checked = !checkbox.checked;

    checkboxes.forEach(c => {
      if (c.id !== checkbox.id) {
        c.checked = checked;
      }
    });
  }
}
