import * as React from 'react';
import { ConfirmAction } from '../ConfirmAction';
import { createMarkup } from '../../../shared';

interface ConfirmActivationActionProps {
  id: string;
  title?: string;
  itemType?: string;
  message?: string;
  buttonProps?: {
    label: string | JSX.Element;
    classNames: string;
  };
  action(): void;
}

export const ConfirmActivationAction = ({
  id,
  title,
  itemType,
  action,
  message,
  buttonProps
}: ConfirmActivationActionProps) => (
  <ConfirmAction
    id={`ConfirmAction_Activation_${id}`}
    title={I18n.t('modals.confirm_activation.title')}
    message={
      message || (
        <p
          dangerouslySetInnerHTML={createMarkup(
            I18n.t('modals.confirm_activation.message_html', {
              title: title,
              item: itemType
            })
          )}
        />
      )
    }
    buttonProps={
      buttonProps || {
        label: I18n.t('global.buttons.activate'),
        classNames: 'small hollow warning emphasis'
      }
    }
    action={action}
  />
);
