import { render } from 'mustache';
import { UAParser } from 'ua-parser-js';
import { flatten, uniqueId, throttle, isEqual } from 'lodash-es';

export function fixedTable(sourceTable: HTMLTableElement) {
  // HACK: For some reason, this function has been included as part of the
  // plugins array stored in the global `R18` object. Sadly, I did not
  // document why I did this so I don't feel comfortable removing it.
  // But this causes an issue where this module can fail if the provided
  // element isn't actually a table. As a hacky-yet-effective fix, we need
  // to make sure we were actually provided a table before proceeding.
  if (sourceTable.tagName !== 'TABLE') return;

  const { name } = new UAParser().getBrowser();
  const TABLE_ID_PREFIX = `${uniqueId('fixedTable_')}`;
  const { scrollbarWidth, scrollbarHeight } = _getScrollbarDimensions();
  const hasHeaders = sourceTable.hasAttribute(
    'data-r18-ui-fixed-table-has-headers'
  );

  sourceTable.style.opacity = '0';
  sourceTable.style.transition = 'opacity .125s ease';

  const tableParent = sourceTable.parentElement;
  const preparedTable = _prepareTable(sourceTable);

  tableParent.removeChild(sourceTable);
  tableParent.appendChild(preparedTable);

  const { thead, tbodyArray, fixedColHead, fixedColBody } = _getComponentPieces(
    preparedTable
  );

  // // these values shouldn't change
  const INITIAL_TABLE_WIDTH = preparedTable.offsetWidth;
  const INITIAL_TABLE_HEIGHT = preparedTable.offsetHeight;
  const THEAD_HEIGHT = thead.clientHeight;
  const COL_WIDTH = parseInt(thead.querySelector('th').style.width, 10) + 1; // account for border

  // // these values might change
  let tableScrollTop = 0;
  let tableScrollLeft = 0;
  let requiresCorrection = false;

  const data = {
    uniqueIdPrefix: TABLE_ID_PREFIX,
    tableHeight: _valueToPx(INITIAL_TABLE_HEIGHT + scrollbarHeight),
    tableWidth: _valueToPx(INITIAL_TABLE_WIDTH + scrollbarWidth),
    thead: thead.outerHTML,
    tbodyArray: tbodyArray.map(tbody => tbody.outerHTML).join('\n'),
    firstColWidth: _valueToPx(COL_WIDTH),
    headHeight: _valueToPx(THEAD_HEIGHT),
    fixedColHead: fixedColHead.outerHTML,
    fixedColBody,
    scrollbarWidth: _valueToPx(scrollbarWidth),
    scrollbarHeight: _valueToPx(scrollbarHeight)
  };

  while (tableParent.hasChildNodes()) {
    tableParent.removeChild(tableParent.lastChild);
  }

  tableParent.insertAdjacentHTML('afterbegin', render(_template(), data));

  const fixedTableWrapper = tableParent.querySelector<HTMLElement>(
    `#${TABLE_ID_PREFIX}_wrapper`
  );

  const fixedTableTarget = tableParent.querySelector<HTMLTableElement>(
    `#${TABLE_ID_PREFIX}_table`
  );

  const fixedHeadTarget = tableParent.querySelector<HTMLTableElement>(
    `#${TABLE_ID_PREFIX}_head`
  );

  const fixedColumnTarget = tableParent.querySelector<HTMLTableElement>(
    `#${TABLE_ID_PREFIX}_column`
  );

  let isScrolling = false;
  let isFinishedScrolling: any;
  let prevScrollLeft = 0;
  let prevScrollTop = 0;

  fixedTableWrapper.addEventListener('scroll', (event: Event) => {
    const wrapperTarget = event.target as HTMLElement;

    if (!isScrolling) {
      let scrollDirection: string;

      window.clearTimeout(isFinishedScrolling);

      isFinishedScrolling = window.setTimeout(() => {
        if (name === 'IE') {
          _setFixedTargetPositions(prevScrollTop, prevScrollLeft);

          switch (scrollDirection) {
            case 'up':
            case 'down':
              _fadeIn(fixedHeadTarget);
              break;
            case 'left':
            case 'right':
              _fadeIn(fixedColumnTarget);
              break;
          }
        }
      }, 500);

      const target = event.target as HTMLElement;
      const { scrollTop, scrollLeft } = target;

      scrollDirection = _getScrollDirection({
        prevScrollTop,
        prevScrollLeft,
        scrollTop,
        scrollLeft
      });

      if (name === 'IE') {
        switch (scrollDirection) {
          case 'up':
          case 'down':
            fixedHeadTarget.style.display = 'none';
            break;
          case 'left':
          case 'right':
            fixedColumnTarget.style.display = 'none';
            break;
        }
      }

      if (requiresCorrection) {
        requiresCorrection = false;
      }

      window.requestAnimationFrame(() => {
        if (name !== 'IE') {
          _setFixedTargetPositions(scrollTop, scrollLeft);
        }
        isScrolling = false;
      });

      isScrolling = true;
    }

    prevScrollLeft = wrapperTarget.scrollLeft;
    prevScrollTop = wrapperTarget.scrollTop;
  });

  fixedTableWrapper.addEventListener('click', (event: Event) => {
    const target = event.target as HTMLElement;
    let scrollLeft: number;
    let scrollTop: number;

    if (target.tagName === 'LABEL') {
      scrollLeft = fixedTableWrapper.scrollLeft;
      scrollTop = fixedTableWrapper.scrollTop;
      
      window.requestAnimationFrame(() => {
        fixedTableWrapper.scrollTop = scrollTop;
        fixedTableWrapper.scrollLeft = scrollLeft;
      });
    }
  });

  window.removeEventListener('resize', _handleWindowResizeEvent);
  window.removeEventListener('fixedTable.setLimits', _handleWindowResizeEvent);
  window.removeEventListener(
    'fixedTable.correctPosition',
    _handleCorrectPosition
  );

  window.addEventListener('resize', throttle(_handleWindowResizeEvent, 100));
  window.addEventListener('fixedTable.setLimits', _handleWindowResizeEvent);
  window.addEventListener('fixedTable.correctPosition', _handleCorrectPosition);

  function _handleWindowResizeEvent() {
    const { offsetHeight } = fixedTableTarget;

    fixedTableWrapper.style.height = _valueToPx(offsetHeight);

    const wrapperMaxHeight = parseInt(
      window.getComputedStyle(fixedTableWrapper).maxHeight,
      10
    );

    if (offsetHeight > wrapperMaxHeight) {
      fixedTableWrapper.style.height = _valueToPx(
        wrapperMaxHeight + scrollbarHeight
      );
    }
  }

  function _handleCorrectPosition() {
    tableScrollLeft = fixedTableWrapper.scrollLeft;
    tableScrollTop = fixedTableWrapper.scrollTop;
    requiresCorrection = true;
  }

  function _setFixedTargetPositions(scrollTop: number, scrollLeft: number) {
    fixedHeadTarget.style.transform = `translateY(${_valueToPx(scrollTop)})`;
    fixedColumnTarget.style.transform = `translateX(${_valueToPx(
      scrollLeft - 1
    )})`;
  }

  /**
   * Breaks the source table into the following component parts:
   *
   * - A clone of the original `thead` element
   * - An array of `tbody` elements from the original table
   * - A prepared `table` which creates the header portion of
   *   the fixed column
   * - A prepared `table` which creates the body portion of the
   *   fixed column.
   *
   * @param table The table element to process.
   */
  function _getComponentPieces(table: HTMLTableElement) {
    const thead = table
      .querySelector('thead')
      .cloneNode(true) as HTMLTableSectionElement;
    const tbodyArray: HTMLTableSectionElement[] = [].slice.call(
      table.querySelectorAll('tbody')
    );
    const th = thead.querySelector('th');

    const fixedColBody = Array.from(table.querySelectorAll('tbody'), tbody => {
      const trArray = [].slice.call(tbody.querySelectorAll('tr'));
      let newTrArray = [];

      for (let i = 0; i < trArray.length; i++) {
        const tr = trArray[i];
        const trClone = tr.cloneNode(true) as HTMLTableRowElement;
        const tagName = i === 0 && hasHeaders ? 'th' : 'td';

        const trCloneArray = [].slice
          .call(trClone.querySelectorAll(tagName))
          .filter(
            (
              element: HTMLTableCellElement | HTMLTableHeaderCellElement,
              elementIndex: number
            ) => elementIndex === 0
          );

        for (let ei = 0; ei < trCloneArray.length; ei++) {
          const newTr = document.createElement('tr');

          _adjustForScroll(trCloneArray[ei], 'width');

          newTr.style.height = tr.style.height;
          newTr.style.width = tr.style.width;
          newTr.classList.add(...[].slice.call(tr.classList));

          newTr.appendChild(trCloneArray[ei]);
          newTrArray.push(newTr.outerHTML);
        }
      }

      return flatten(newTrArray);
    });

    let fixedColHead = document.createElement('tr');
    let fixedColHeadRow = thead
      .querySelector('th')
      .cloneNode() as HTMLTableHeaderCellElement;

    fixedColHead.appendChild(fixedColHeadRow);
    fixedColHead.style.height = _valueToPx(thead.clientHeight);

    _adjustForScroll(th, 'width');

    return {
      thead,
      tbodyArray,
      fixedColHead,
      fixedColBody
    };
  }

  type ScrollDirection = 'up' | 'down' | 'left' | 'right' | 'none';
  type GetScrollDirectionArguments = {
    prevScrollTop: number;
    prevScrollLeft: number;
    scrollTop: number;
    scrollLeft: number;
  };

  /**
   * Calculates the current scroll direction.
   *
   * @param prevScrollTop
   * @param prevScrollLeft
   * @param scrollTop
   * @param scrollLeft
   */
  function _getScrollDirection({
    prevScrollTop,
    prevScrollLeft,
    scrollTop,
    scrollLeft
  }: GetScrollDirectionArguments): ScrollDirection {
    if (prevScrollTop === scrollTop && prevScrollLeft === scrollLeft)
      return 'none';

    if (prevScrollTop !== scrollTop) {
      return prevScrollTop > scrollTop ? 'up' : 'down';
    }

    if (prevScrollLeft !== scrollLeft) {
      return prevScrollLeft > scrollLeft ? 'left' : 'right';
    }
  }

  /**
   * Calculates heights and widths for all table cells and applies these
   * values to the markup for later use. For performance reasons, the
   * source table is cloned, all of the calculations and attribute changes
   * are applied to the clone, and finally the clone replaces the original
   * (both in the DOM and in future references to `sourceTable`).
   */
  function _prepareTable(sourceTable: HTMLTableElement) {
    type Row = HTMLTableRowElement;
    type Cell = HTMLTableCellElement | HTMLTableHeaderCellElement;

    let targetTable = sourceTable.cloneNode(true) as HTMLTableElement;

    const sourceRows = _getElements<Row>(sourceTable, 'tr');
    const sourceCells = _getElements<Cell>(sourceTable, 'th, td');

    const targetRows = _getElements<Row>(targetTable, 'tr');
    const targetCells = _getElements<Cell>(targetTable, 'th, td');

    for (let i = 0; i < targetRows.length; i++) {
      const sourceRow = sourceRows[i];
      const targetRow = targetRows[i];

      targetRow.className = sourceRow.className;
    }

    sourceTable
      .querySelectorAll('tr')
      .forEach(row => row.classList.remove('is-hidden'));

    for (let i = 0; i < targetCells.length; i++) {
      const sourceElement = sourceCells[i];
      const targetElement = targetCells[i];

      targetElement.style.height = _valueToPx(sourceElement.offsetHeight);
      targetElement.style.width = _valueToPx(sourceElement.offsetWidth);
      targetElement.className = sourceElement.className;
    }

    return targetTable;
  }

  /**
   * Returns the Mustache template used to create the final table markup.
   */
  function _template() {
    return `
      <div
        class="fixed-table"
        id="{{uniqueIdPrefix}}_wrapper"
        style="width: 100%; max-height: 65vh; height: {{tableHeight}}"
        data-ui-fixed-table-scroll-target>
        <div class="fixed-table__column"   style="width: {{firstColWidth}}">
          <div class="fixed-table-col">
            <div class="fixed-table-col__head">
              <table>
                <thead>
                  {{{fixedColHead}}}
                </thead>
              </table>
            </div>
            <div class="fixed-table-col__body">
              <table id="{{uniqueIdPrefix}}_column" style="margin-top: 0" class="fixed-table-col__inner">
                <thead>
                  {{{fixedColHead}}}
                </thead>
                {{#fixedColBody}}
                  <tbody>
                    {{#.}}
                      {{{.}}}
                    {{/.}}
                  </tbody>
                {{/fixedColBody}}
              </table>
            </div>
          </div>
        </div>
        <div class="fixed-table__body">
          <div class="fixed-table-body">
            <div
              class="fixed-table-body__head"
              id="{{uniqueIdPrefix}}_head"
              style="height: {{headHeight}}; width: {{firstColWidth}}">
              <table id="{{uniqueIdPrefix}}_head">{{{thead}}}</table>
            </div>
            <div class="fixed-table-body__body">
              <table
                id="{{uniqueIdPrefix}}_table"
                style="margin-top: 0; width: {{tableWidth}};">
                {{{thead}}}
                {{{tbodyArray}}}
              </table>
            </div>
          </div>
        </div>
      </div>
    `;
  }

  function _valueToPx(val: number) {
    return `${val}px`;
  }

  function _adjustForScroll(element: HTMLElement, dimension: string) {
    let originalValue: string;
    let scrollbarValue: number;

    switch (dimension) {
      case 'width':
        element.style.width = _valueToPx(
          parseInt(element.style.width, 10) + scrollbarWidth
        );
        break;
      case 'height':
        element.style.height = _valueToPx(
          parseInt(element.style.height, 10) + scrollbarHeight
        );
        break;
    }
  }

  function _getElements<T>(source: HTMLElement, selector: string): T[] {
    return [].slice.call(source.querySelectorAll(selector));
  }

  /**
   * Get the current scrollbar width and height dimensions.
   */
  function _getScrollbarDimensions() {
    const scrollDiv = document.createElement('div');

    scrollDiv.style.width = '100px';
    scrollDiv.style.height = '100px';
    scrollDiv.style.overflow = 'scroll';
    scrollDiv.style.position = 'absolute';
    scrollDiv.style.top = '-9999px';

    document.body.appendChild(scrollDiv);

    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    const scrollbarHeight = scrollDiv.offsetHeight - scrollDiv.clientHeight;

    document.body.removeChild(scrollDiv);

    return {
      scrollbarWidth,
      scrollbarHeight
    };
  }
}

function _fadeIn(target: HTMLElement, lastOpacity = 0) {
  let currentOpacity = lastOpacity;

  target.style.display = '';
  target.style.opacity = currentOpacity.toString();

  if (currentOpacity < 1) {
    currentOpacity += 0.2375;

    setTimeout(function() {
      _fadeIn(target, currentOpacity);
    }, 100);
  }

  // let interval: any;

  // const stopFade = () => {
  //   window.clearInterval(interval);
  // };

  // interval = window.setInterval(() => {
  //   if (currentOpacity >= 1) {
  //     stopFade();
  //   } else {
  //     currentOpacity = currentOpacity + 0.008;
  //     target.style.opacity = currentOpacity.toString();
  //   }
  // }, 1);
}
