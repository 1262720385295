import * as React from 'react';
import { createMarkup } from '../../../shared';
import { ConfirmAction } from '../ConfirmAction';

interface ConfirmNinjaLoginProps {
  name: string;
  buttonLabel: JSX.Element;
  action(): void;
}

export const ConfirmNinjaLogin = ({
  name,
  buttonLabel,
  action
}: ConfirmNinjaLoginProps) => (
  <ConfirmAction
    id="ConfirmAction_CorrectiveAction"
    title={I18n.t('modals.confirm_ninja_login.title')}
    message={
      <p
        dangerouslySetInnerHTML={createMarkup(
          I18n.t('modals.confirm_ninja_login.message_html', { name })
        )}
      />
    }
    buttonProps={{
      label: buttonLabel,
      classNames: 'clear'
    }}
    action={action}
  />
);
