import { format, isValid, parse } from 'date-fns';
import { isString } from 'lodash-es';
import * as React from 'react';
import { Button, Colors, Label, Menu, Sizes } from 'react-foundation';
import { componentClasses, createMarkup } from '../../shared';

import { Icon } from '../common';

export interface ListItemDateProps {
  date: string;
  color?: string;
  showYear?: boolean;
}

/**
 * Stateless component to render the date within a ListItem.
 */
export const ListItemDate = ({ date, color, showYear }: ListItemDateProps) => {
  const parsedDate = parse(date);

  return (
    <div className="list-item__date">
      {isValid(parsedDate) && (
        <div className={componentClasses('list-item-date', { [color]: true })}>
          <span className="list-item-date__month">{format(date, 'MMM')}</span>
          <span className="list-item-date__day">{format(date, 'D')}</span>
          {showYear && <span className="list-item-date__year">{format(date, 'YYYY')}</span>}
        </div>
      )}

      {isValid(parsedDate) && []}
    </div>
  );
};

export interface ListItemTitleProps {
  title: string | Array<string>;
  subtitle?: string;
  label?: string;
  itemModifier?: string;
  type?: string;
}

/**
 * Stateless component to render a title and optional subtitle within a ListItem.
 */
export const ListItemTitle = ({
  title,
  subtitle,
  label,
  itemModifier,
  type
}: ListItemTitleProps) => {
  const titleWrapper = (WrappedTitle: any) => (props: any) =>
    (
      <span className="list-item-title__main">
        <WrappedTitle {...props} />
      </span>
    );

  const Title = ({ title }: { title: string | Array<string> }) =>
    Array.isArray(title) ? (
      title.map((t, i) => <span key={`ListItemTitle_${t}_${i}`}>{t}</span>)
    ) : (
      <span>{title}</span>
    );

  const WrappedTitle = titleWrapper(Title);

  const listItemTitleComponentModifiers = Object.assign(
    {
      split: Array.isArray(title)
    },
    isString(itemModifier)
      ? {
          [itemModifier]: true
        }
      : {},
    isString(type)
      ? {
          [type]: true
        }
      : {}
  );

  return (
    <div className="list-item__title">
      <div className={componentClasses('list-item-title', listItemTitleComponentModifiers)}>
        <WrappedTitle title={title} />
        {subtitle && (
          <span
            className="list-item-title__secondary"
            dangerouslySetInnerHTML={createMarkup(subtitle)}
          />
        )}
      </div>
      {label && <Label dangerouslySetInnerHTML={createMarkup(label)} />}
    </div>
  );
};

export interface ListItemMenuProps {
  subActions: Array<IActionButton>;
}

export const ListItemMenu = ({ subActions }: ListItemMenuProps) => {
  return (
    <Menu>
      {subActions.map((subAction, index) => {
        return (
          <Button size={Sizes.SMALL} color={Colors.SECONDARY} isHollow>
            {subAction.title}
          </Button>
        );
      })}
    </Menu>
  );
};

export interface ListItemActionProps {
  title: string;
  actions?: Array<JSX.Element>;
  url?: string;
  deleteRoute?: string;
  updateListData?: (items: Array<IAction>) => void;
}

/**
 * Stateless component to render an action within a list item. Accepts one
 * of two params:
 *
 * - `actions`: an array of JSX elements to render in sequence
 * - `url`: a string which is converted to a link with a right-facing arrow
 */
export const ListItemAction = ({ title, actions, url, updateListData }: ListItemActionProps) => {
  if (actions || url) {
    return (
      <div className="list-item__action">
        {actions &&
          React.Children.map(actions, (action, index) => {
            return React.cloneElement(action, {
              key: `Action_${index}`
            });
          })}
        {url && <Icon icon="arrow-right" size="medium" />}
      </div>
    );
  } else {
    return null;
  }
};
