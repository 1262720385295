import { assignTests } from './r18.controller.assignTests';
import { certifications } from './r18.controller.certifications';
import { emailNotifications } from './r18.controller.emailNotifications';
import { equipmentProcedure } from './r18.controller.equipmentProcedure';
import { labPositions } from './r18.controller.labPositions';
import { labRelations } from './r18.controller.labRelations';
import { orgChart } from './r18.controller.orgChart';
import { procedureEvaluation } from './r18.controller.procedureEvaluation';
import { procedureHistory } from './r18.controller.procedureHistory';
import { qualityManual } from './r18.controller.qualityManual';
import { qualityStandard } from './r18.controller.qualityStandard';
import { technicianEvaluation } from './r18.controller.technicianEvaluation';
import { userInterface } from './r18.controller.userInterface';

const controllers = {
  'r18-assign-tests': assignTests,
  'r18-certifications': certifications,
  'r18-email-notifications': emailNotifications,
  'r18-equipment-procedure': equipmentProcedure,
  'r18-lab-positions': labPositions,
  'r18-lab-relations': labRelations,
  'r18-quality-manual': qualityManual,
  'r18-quality-standard': qualityStandard,
  'r18-procedure-evaluation': procedureEvaluation,
  'r18-procedure-history': procedureHistory,
  'r18-technician-evaluation': technicianEvaluation,
  'r18-org-chart': orgChart,
  'r18-ui': userInterface
};

export default controllers;
