import { Controller } from 'framewerk';
import { render } from 'mustache';
import { ckEditor } from '../plugins';
import {
  controllerSelector,
  decodeString,
  findParentNodeByDataAttribute,
  initLibraries,
  STATES
} from '../shared';

/**
 * The initialization function for creating the `r18-certifications` Controller.
 */
export function certifications(): Controller {
  /**
   * The name of the controller.
   */
  const name: string = 'r18-certifications';

  /**
   * Factory method for creating selectors.
   */
  const selector = controllerSelector(name);

  /**
   * Selector strings for the `r18-certifications` Controller.
   */
  const targets = Controller.getTargets({
    add: selector('add'),
    container: selector('container'),
    list: selector('list'),
    template: selector('template')
  });

  const events = {
    init: () => {
      targets.container.forEach(element => initCertification(element));
    },
    buttonClick: () => {
      targets.add[0].addEventListener('click', handleAddCertification);
    }
  };

  return new Controller({ name, targets, events });

  /////////////////

  function renumberList() {
    let currentNumber = 1;

    for (let i = 0; i < targets.list[0].children.length; i++) {
      const element = targets.list[0].children[i];
      if (element.classList.contains(STATES.isHidden)) continue;

      element.querySelector(selector('number')).innerHTML =
        currentNumber.toString();
      currentNumber++;
    }
  }

  function initCertification(element: HTMLElement) {
    element
      .querySelector(selector('remove'))
      ?.addEventListener('click', event => {
        const parent = findParentNodeByDataAttribute(
          event.target as HTMLButtonElement,
          selector('container', true)
        );

        if (parent) {
          parent.classList.add(STATES.isHidden);
          toggleDestroy(parent, true);
        }
      });
  }

  function toggleDestroy(element: HTMLElement, isDestroyed: boolean) {
    const textarea = element.querySelector('textarea');
    const textareaName = decodeString(textarea.name);

    const destroyFlagName = [
      textareaName[0],
      ...textareaName.slice(1, -1).map(name => `[${name}]`),
      '[_destroy]'
    ].join('');

    textarea.disabled = isDestroyed;
    if (isDestroyed) {
      element.insertAdjacentHTML(
        'beforeend',
        `<input type="hidden" name="${destroyFlagName}" value="true">`
      );
    } else {
      element.querySelector(`input[name="${destroyFlagName}"]`)?.remove();
    }
    renumberList();
  }

  function handleAddCertification() {
    const template = targets.template[0].innerHTML.replace(/<!--|-->/g, '');
    const target = targets.list[0];
    const currentCount = target.querySelectorAll('li:not(.is-hidden)').length;

    target.insertAdjacentHTML(
      'beforeend',
      render(template, {
        index: currentCount,
        count: currentCount + 1
      })
    );

    requestAnimationFrame(() => {
      const newItem = target.lastElementChild as HTMLElement;
      initLibraries(newItem);
      ckEditor(newItem);
      initCertification(newItem);
      renumberList();
    });
  }
}
