import 'jquery';
import 'orgchart';

/**
 * Wrapper for instantiating an instance of OrgChart.js.
 *
 * @export
 * @param {string} target The target container to create the chart within.
 * @param {string} data The data source. Can either be JSON or a selector string for an unordered list.
 * @param {Object} [options={}] Options to pass to the new instance of `OrgChart`.
 *
 * @returns {OrgChart}
 */
export function initializeOrgChart(
  target: string,
  data: string,
  options: Partial<OrgChart.Options> = {}
): OrgChart.API {
  // attempt to parse the data as JSON. if it fails, assume we have an ID string
  try {
    data = JSON.parse(data);
  } catch (e) {}

  const DEFAULT_OPTIONS: Partial<OrgChart.Options> = {
    nodeContent: 'title',
    nodeId: 'slug',
    pan: true
  };

  const orgChart = $(target).orgchart(
    Object.assign({}, DEFAULT_OPTIONS, options, {
      chartContainer: target,
      data: data
    })
  );

  return orgChart;
}
