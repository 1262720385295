import * as React from 'react';

/**
 * Accepts a component with props and attaches additional props
 * before rendering.
 */
export const asLink = (url: string, className?: string, target?: string) => (
  WrappedComponent: React.ComponentClass<any> | React.StatelessComponent<any>
) => {
  const ComponentAsLink = (ownProps: any) => (
    <a href={url} target={target} className={className}>
      <WrappedComponent {...ownProps} />
    </a>
  );

  return ComponentAsLink;
};
