import * as React from 'react';
import * as Foundation from 'react-foundation';
import { CSSProperties } from 'react';

const { Button, Menu, MenuItem, Sizes, Colors } = Foundation;

interface DropdownMenuProps {
  buttonLabel: string;
  buttonSize: string;
  options: Array<ISubAction>;
}

interface DropdownMenuState {
  isExpanded: boolean;
}

export class DropdownMenu extends React.Component<
  DropdownMenuProps,
  DropdownMenuState
> {
  public props: DropdownMenuProps;
  public state: DropdownMenuState = {
    isExpanded: false
  };

  public render() {
    const { buttonLabel, buttonSize, options } = this.props;
    const { isExpanded } = this.state;

    return (
      <div style={{ position: 'relative' }}>
        <Button
          size={Sizes.SMALL}
          color={Colors.SECONDARY}
          isDropdown
          onClick={() => {
            this.setState({
              isExpanded: !isExpanded
            });
          }}>
          {buttonLabel}
        </Button>
        <div className="dropdown-pane" style={this.visibility(isExpanded)}>
          <Menu isVertical>
            {options.map((option, index) => {
              const {
                url,
                label,
                parent,
                parentSlug,
                actionIndex,
                action
              } = option;

              return (
                <MenuItem key={`DropdownMenu_${buttonLabel}_option_${index}`}>
                  <a
                    role="button"
                    onClick={() => {
                      action(url, parentSlug);
                      this.setState({
                        isExpanded: false
                      });
                    }}>
                    {label}
                  </a>
                </MenuItem>
              );
            })}
          </Menu>
        </div>
      </div>
    );
  }

  /**
   * Sets visibility styles on the dropdown pane.
   *
   * @param {boolean} isExpanded Whether or not the pane is visible.
   */
  private visibility(isExpanded: boolean): CSSProperties {
    return {
      display: isExpanded ? 'block' : 'none',
      visibility: isExpanded ? 'visible' : 'hidden'
    };
  }
}
