import { parse } from 'date-fns';
import { get, has, isNull, isUndefined, last, merge } from 'lodash-es';
import * as React from 'react';
import { Button, Colors, Column, Row } from 'react-foundation';

import {
  actionModelTitle,
  canScroll,
  executeGet,
  fieldName,
  processForm,
  STATES
} from '../../../shared';
import {
  ControlBar,
  Icon,
  Loading,
  OverlayForm,
  OverlayFormButtons
} from '../../common';
import { Form, FormProps, FormState } from '../../common/Form';
import { ImageUploadField, SelectField, TextField } from '../../fields';
import { ImageUploadFieldState } from '../../fields/ImageUploadField';
import { InspectionDateFormFields } from './InspectionDateFormFields';
import { PlmFields, SubscriptionFields } from './lab-details-form';
import { SsoFormFields } from './SsoFormFields';
import { formatErrors } from '../../../shared/utils';

type PlmUser = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  isNew: boolean;
};

export type Subscription = {
  id: string;
  startDate: Date;
  endDate: Date;
  amount: number;
  isActive: boolean;
  isExpired: boolean;
  isCanceled: boolean;
  toggle: boolean;
  isNew: boolean;
};

export interface LabDetailsFormProps extends FormProps<IPrimaryLab> {
  currentUserId: number;
  dataRoute: string;
  plmRoleId: number;
  availableStates: Array<SelectOption>;
  availableCountries: Array<SelectOption>;
  availableLabTypes: Array<SelectOption>;
  baseParam: string;
  isOverlayForm: boolean;
  showInspectionDates: boolean;
  showSubscription?: boolean;
  showPrimaryLabManager?: boolean;
  canAddPrimaryLabManager?: boolean;
  overlayContext?: string;
  requireFormTag?: boolean;
  primaryLab?: IPrimaryLab;
  currentPrimaryLab?: IPrimaryLab;
  setComponent?: (
    name: string,
    lab: IPrimaryLab,
    options?: GenericObject
  ) => void;
  closeOverlay?: () => void;
  useRemote?: boolean;
  newSatellite?: boolean;
}

export interface LabDetailsFormState extends FormState {
  isLoading: boolean;
  name: string;
  selectedLabType: string;
  addressOne: string;
  addressTwo: string;
  city: string;
  selectedState: SelectOption;
  zipCode: string;
  selectedCountry: SelectOption;
  logo: ImageUploadFieldState;
  lastInspectionDate: Date;
  nextInspectionDate: Date;
  subscriptionStartDate: Date;
  subscriptionEndDate: Date;
  subscriptionActivate: boolean;
  subscriptionCancel: boolean;
  subscriptionIsActive: boolean;
  subscriptionAmount: number;
  primaryLabManagerFirstName: string;
  primaryLabManagerLastName: string;
  primaryLabManagerEmail: string;
  showSubscriptionFields: boolean;
  showPrimaryLabManagerFields: boolean;
  primaryLabManagers: Array<IAction>;
  newPrimaryLabManagers: Array<PlmUser>;
  currentSubscriptions: Array<Subscription>;
  newSubscriptions: Array<Subscription>;
  saveRoute: string;
  formIsValid: boolean;
  overrideInspectionDate: boolean;
  active_saml: boolean;
  idp_cert: string;
  idp_entity_id: string;
  idp_sso_target_url: string;
}

export class LabDetailsForm extends Form<
  LabDetailsFormProps,
  LabDetailsFormState
> {
  props: LabDetailsFormProps;
  state: LabDetailsFormState;
  form: HTMLFormElement;
  formFields: HTMLElement;
  fName: (params: string | Array<string>, isMultiple?: boolean) => string;

  constructor(props: LabDetailsFormProps) {
    super(props);

    this.fName = fieldName(this.props.baseParam);
    this.state = {
      ...this.setFormState(this.props),
      isLoading: true
    };

    this.handleAddPlm = this.handleAddPlm.bind(this);
    this.handleAddSubscription = this.handleAddSubscription.bind(this);
    this.handleUpdateItem = this.handleUpdateItem.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
  }

  public componentDidMount() {
    if (
      this.props.useRemote &&
      this.state.isLoading &&
      this.props.dataRoute &&
      !this.props.newSatellite
    ) {
      executeGet(`${this.props.dataRoute}?section=form`).then(
        (response: IPrimaryLab) => {
          this.setState({
            ...this.setFormState({
              ...this.props,
              currentItem: response
            }),
            isLoading: false
          });
        }
      );
    } else {
      this.setState({
        isLoading: false
      });
    }
  }

  public componentDidUpdate(
    prevProps: LabDetailsFormProps,
    prevState: LabDetailsFormState
  ) {
    if (this.state.formIsValid !== this.formIsValid()) {
      this.setState({
        formIsValid: this.formIsValid()
      });
    }

    if (!this.props.currentItem && prevProps.currentItem) {
      this.setState({
        ...this.setFormState(this.props),
        isLoading: false
      });
    }
  }

  public handleRemoveItem(group: string, id: string) {
    this.setState((prevState: LabDetailsFormState) => {
      const newGroup = prevState[group].filter(
        (item: PlmUser | ISubscription) => item.id !== id
      );

      return { [group]: newGroup };
    });
  }

  public handleUpdateItem<T extends GenericObject>(
    group: string,
    id: string,
    prop: string,
    value: any
  ) {
    this.setState((prevState: LabDetailsFormState) => {
      let newGroup = prevState[group];
      let groupIndex = newGroup.findIndex((g: T) => g.id === id);

      newGroup[groupIndex][prop] = value;

      return { [group]: newGroup };
    });
  }

  public handleAddPlm() {
    this.setState((prevState: LabDetailsFormState) => {
      let { newPrimaryLabManagers } = prevState;

      newPrimaryLabManagers.push({
        id: new Date().getTime().toString(),
        firstName: '',
        lastName: '',
        email: '',
        isNew: true
      });

      return {
        newPrimaryLabManagers
      };
    });
  }

  public handleAddSubscription() {
    this.setState((prevState: LabDetailsFormState) => {
      const { currentItem } = this.props;
      let { newSubscriptions } = prevState;

      const today = new Date();

      newSubscriptions.push({
        id: newSubscriptions.length.toString(),
        startDate: today,
        endDate: today,
        amount: 0,
        isActive: false,
        isExpired: false,
        isCanceled: false,
        isNew: true,
        toggle: false
      });

      return {
        newSubscriptions
      };
    });
  }

  public handleFieldChange<V>(key: string, value: V) {
    this.setState({
      [key]: value
    });
  }

  public renderFormFields() {
    const {
      availableStates,
      availableCountries,
      availableLabTypes,
      isOverlayForm,
      showInspectionDates,
      showPrimaryLabManager,
      showSubscription,
      coreName,
      baseParam,
      currentPrimaryLab: primaryLab,
      currentItem,
      currentUserId,
      plmRoleId,
      canAddPrimaryLabManager,
      managementContext,
      overlayContext,
      setComponent
    } = this.props;

    const {
      name,
      selectedLabType,
      addressOne,
      addressTwo,
      city,
      selectedState,
      zipCode,
      selectedCountry,
      logo,
      newPrimaryLabManagers,
      lastInspectionDate,
      nextInspectionDate,
      subscriptionStartDate,
      subscriptionEndDate,
      subscriptionAmount,
      primaryLabManagerFirstName,
      primaryLabManagerLastName,
      primaryLabManagerEmail,
      showSubscriptionFields,
      newSubscriptions,
      currentSubscriptions,
      primaryLabManagers,
      subscriptionActivate,
      subscriptionCancel,
      subscriptionIsActive,
      showPrimaryLabManagerFields,
      overrideInspectionDate,
      active_saml,
      idp_cert,
      idp_entity_id,
      idp_sso_target_url
    } = this.state;

    const f = this.fName;

    /*
      The state and zip code fields will change depending on the selected country.
      For the US, we want a dropdown of states and need to enforce numeric entry
      for the zip code; outside the US, both of these fields should be freeform entry.
    */
    const usIsSelected = selectedCountry?.value === 'US';
    const StateOrRegionComponent: React.ComponentType<any> = usIsSelected
      ? SelectField
      : TextField;

    const stateOrRegionProps = merge(
      {
        name: f('state'),
        label: I18n.t(`global.labels.${usIsSelected ? 'state' : 'region'}`),
        placeholder: I18n.t(
          `global.labels.${usIsSelected ? 'state' : 'region'}`
        ),
        value: selectedState,
        required: true,
        showLabel: true
      },
      usIsSelected
        ? {
            options: availableStates,
            className: 'push--bottom',
            simpleValue: true,
            joinValues: true,
            onChange: (newOption: SelectOption) => {
              this.handleSelectChange('selectedState', newOption);
            }
          }
        : {
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
              this.handleTextChange('selectedState', event.target.value);
            }
          }
    );

    return (
      <>
        <input type="hidden" name="primary_lab[admin]" value="true" />
        <Row>
          <Column>
            <p className="h4">{I18n.t('lab.labels.lab_information')}</p>
            {managementContext === 'create' && primaryLab && (
              <input
                type="hidden"
                name={f('parent_id')}
                value={primaryLab.id}
              />
            )}
          </Column>
        </Row>
        <Row>
          <Column small={12}>
            <ImageUploadField
              name={f('logo')}
              id="satellite_logo"
              label="Logo"
              file={logo ? logo.file : undefined}
              imagePreview={logo ? logo.imagePreview : undefined}
              onChange={(imageFieldState: ImageUploadFieldState) => {
                this.setState({
                  logo: imageFieldState
                });
              }}
            />
          </Column>
          <Column small={12} medium={6}>
            <TextField
              name={f('name')}
              label={I18n.t('lab.labels.lab_name')}
              value={name}
              showLabel
              required
              className="push--bottom"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                this.handleTextChange('name', event.target.value);
              }}
            />
          </Column>
          <Column small={12} medium={6}>
            <SelectField
              name={f('lab_type_text')}
              label={I18n.t('global.labels.lab_type.one')}
              options={availableLabTypes}
              value={selectedLabType}
              placeholder={I18n.t('global.labels.lab_type.one')}
              className="push--bottom"
              simpleValue
              showLabel
              joinValues
              allowCustomEntry
              onChange={newOption => {
                this.handleSelectChange('selectedLabType', newOption);
              }}
            />
          </Column>
          <Column small={12} medium={isOverlayForm ? 12 : 6}>
            <SelectField
              name={f('country_code')}
              label={I18n.t('global.labels.country')}
              options={availableCountries}
              value={selectedCountry}
              placeholder={I18n.t('global.labels.country')}
              className="push--bottom"
              simpleValue
              showLabel
              joinValues
              required
              onChange={newOption => {
                this.handleSelectChange('selectedCountry', newOption);
              }}
            />
          </Column>
        </Row>
        <Row>
          <Column small={12} medium={6}>
            <TextField
              name={f('address_line_one')}
              label={I18n.t('global.labels.address_line_one')}
              value={addressOne}
              showLabel
              required
              className="push--bottom"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                this.handleTextChange('addressOne', event.target.value);
              }}
            />
          </Column>
          <Column small={12} medium={6}>
            <TextField
              name={f('address_line_two')}
              label={I18n.t('global.labels.address_line_two')}
              value={addressTwo}
              showLabel
              className="push--bottom"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                this.handleTextChange('addressTwo', event.target.value);
              }}
            />
          </Column>
        </Row>
        <Row>
          <Column small={12} medium={isOverlayForm ? 5 : 4}>
            <TextField
              name={f('city')}
              label={I18n.t('global.labels.city')}
              value={city}
              showLabel
              required
              className="push--bottom"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                this.handleTextChange('city', event.target.value);
              }}
            />
          </Column>
          <Column small={12} medium={isOverlayForm ? 4 : 4}>
            <StateOrRegionComponent {...stateOrRegionProps} />
          </Column>
          <Column small={12} medium={isOverlayForm ? 3 : 4}>
            <TextField
              name={f('zipcode')}
              label={I18n.t(
                `global.labels.${usIsSelected ? 'zip_code' : 'postal_code'}`
              )}
              value={zipCode}
              showLabel
              required
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                this.handleTextChange('zipCode', event.target.value);
              }}
            />
          </Column>
        </Row>
        {showInspectionDates && (
          <InspectionDateFormFields
            baseParam={baseParam}
            lastInspectionDate={lastInspectionDate}
            nextInspectionDate={nextInspectionDate}
            overrideInspectionDate={overrideInspectionDate}
          />
        )}
        {(primaryLab || (currentItem && currentItem.isPrimaryLab)) && (
          <SsoFormFields
            baseParam={baseParam}
            idp_cert={idp_cert}
            idp_entity_id={idp_entity_id}
            idp_sso_target_url={idp_sso_target_url}
            active_saml={active_saml}
          />
        )}
        {showSubscription && (
          <SubscriptionFields
            {...{
              subscriptionStartDate,
              subscriptionEndDate,
              subscriptionAmount,
              subscriptionActivate,
              subscriptionCancel,
              subscriptionIsActive,
              currentSubscriptions,
              newSubscriptions,
              baseParam
            }}
            addSubscription={this.handleAddSubscription}
            updateItem={this.handleUpdateItem}
            removeItem={this.handleRemoveItem}
          />
        )}
        {showPrimaryLabManager && (
          <PlmFields
            {...{
              canAddPrimaryLabManager,
              currentItem,
              currentUserId,
              newPrimaryLabManagers,
              plmRoleId,
              primaryLab,
              primaryLabManagerEmail,
              primaryLabManagerFirstName,
              primaryLabManagerLastName,
              showPrimaryLabManagerFields,
              primaryLabManagers,
              baseParam
            }}
            addPlm={this.handleAddPlm}
            updateItem={this.handleUpdateItem}
            removeItem={this.handleRemoveItem}
          />
        )}
      </>
    );
  }

  public renderForm() {
    const {
      overlayContext,
      currentItem,
      setComponent,
      currentPrimaryLab: primaryLab,
      requireFormTag
    } = this.props;

    const showBackButton =
      overlayContext !== 'standalone' && currentItem && setComponent;
    const wrapAsForm = !isUndefined(requireFormTag) ? requireFormTag : true;

    return (
      <>
        {showBackButton && (
          <ControlBar>
            <Button
              color={Colors.SECONDARY}
              className="clear"
              onClick={() => {
                setComponent(overlayContext, primaryLab);
              }}>
              <Icon icon="back" />
              <span>{I18n.t('global.buttons.back')}</span>
            </Button>
          </ControlBar>
        )}
        {wrapAsForm && (
          <form ref={form => (this.form = form)}>
            {this.renderFormFields()}
          </form>
        )}
        {!wrapAsForm && this.renderFormFields()}
      </>
    );
  }

  public render() {
    const {
      isOverlayForm,
      coreName,
      currentItem,
      currentPrimaryLab: primaryLab,
      managementContext,
      overlayContext,
      useRemote,
      formSuccess,
      formFailure,
      closeOverlay,
      setComponent
    } = this.props;

    const { isSubmitting, saveRoute, formIsValid, isLoading } = this.state;
    let title;

    if (currentItem) {
      title = currentItem.isPrimaryLab ? 'primary_lab' : 'satellite_lab';
    } else {
      title = primaryLab ? 'satellite_lab' : 'primary_lab';
    }

    if (useRemote && isLoading) {
      return (
        <div className="push--top push--bottom">
          <Loading strokeColor="#a1a1a1" />
        </div>
      );
    }

    if (isOverlayForm) {
      return (
        <OverlayForm
          title={actionModelTitle(managementContext, title)}
          icon="buildings"
          saveRoute={saveRoute}
          form={this.form}
          showCancelButton
          canSubmit={formIsValid && !isSubmitting}
          handleSubmit={this.handleSubmit}
          updatesItem={!isUndefined(currentItem)}
          formSuccess={(lab: IPrimaryLab) => {
            const eventKey = lab.isPrimaryLab ? 'primaryLab' : 'satellite';
            const eventAction =
              managementContext === 'create' ? 'create' : 'update';

            window.dispatchEvent(
              new CustomEvent(`${eventKey}.${eventAction}`, {
                detail: { lab }
              })
            );

            formSuccess(lab);
          }}
          formFailure={formFailure}
          closeOverlay={closeOverlay}>
          {this.renderForm()}
        </OverlayForm>
      );
    }

    if (useRemote) {
      return (
        <div className="form">
          <div
            ref={fields => (this.formFields = fields)}
            className={`form__fields ${
              canScroll(this.formFields) ? STATES.canScroll : ''
            }`}>
            {this.renderForm()}
          </div>
          <div className="form__actions form__actions--footer">
            <OverlayFormButtons
              buttons={[
                <Button
                  color={Colors.SECONDARY}
                  isExpanded
                  isHollow
                  onClick={e => {
                    e.preventDefault();
                    if (overlayContext) {
                      setComponent(overlayContext, currentItem);
                    } else {
                      closeOverlay();
                    }
                  }}>
                  {I18n.t('global.buttons.cancel')}
                </Button>,
                <Button
                  color={Colors.SECONDARY}
                  isDisabled={!formIsValid || isSubmitting}
                  isExpanded
                  onClick={e => {
                    e.preventDefault();
                    this.handleSubmit({ isSubmitting: true }, () => {
                      processForm(
                        saveRoute,
                        this.form,
                        (
                          lab: IPrimaryLab & {
                            errors: Record<string, string[]>;
                            status: number;
                          }
                        ) => {
                          if (lab.errors) {
                            alert(formatErrors(lab.errors));
                          } else {
                            const eventKey = lab.isPrimaryLab
                              ? 'primaryLab'
                              : 'satellite';
                            const eventAction =
                              managementContext === 'create'
                                ? 'create'
                                : 'update';

                            window.dispatchEvent(
                              new CustomEvent(`${eventKey}.${eventAction}`, {
                                detail: { lab }
                              })
                            );

                            // defeat type errors
                            type FormSuccess = (
                              lab: IPrimaryLab,
                              overlayComponentName?: string | null,
                              nextCurrentItem?: IPrimaryLab
                            ) => void;
                            const callback =
                              formSuccess as unknown as FormSuccess;

                            let overlayComponentName: string | null = null;
                            if (
                              eventKey === 'satellite' &&
                              eventAction === 'update' &&
                              overlayContext !== 'standalone'
                            ) {
                              overlayComponentName = 'SatelliteLabs';
                            }

                            callback(
                              lab,
                              overlayComponentName,
                              lab.isPrimaryLab ? undefined : primaryLab
                            );
                          }
                        },
                        (errors: Record<string, string[]>) => {
                          this.setState({
                            isSubmitting: false
                          });
                          formFailure(errors);
                        },
                        !isUndefined(currentItem) && !isNull(currentItem)
                          ? { method: 'put', responseType: 'json' }
                          : {}
                      );
                    });
                  }}>
                  {I18n.t(
                    `global.${
                      isSubmitting ? 'labels.please_wait' : 'buttons.save'
                    }`
                  )}
                </Button>
              ]}
              saveRoute={saveRoute}
              form={this.form}
              showCancelButton
              canSubmit={formIsValid && !isSubmitting}
              handleSubmit={this.handleSubmit}
              updatesItem={!isUndefined(currentItem)}
              // tslint:disable-next-line:no-empty
              formSuccess={(_: IPrimaryLab) => {}}
              formFailure={formFailure}
              closeOverlay={closeOverlay}
            />
          </div>
        </div>
      );
    }

    return this.renderForm();
  }

  private setFormState(props: LabDetailsFormProps) {
    const {
      currentItem,
      primaryLab,
      canAddPrimaryLabManager,
      baseParam,
      coreName,
      currentPrimaryLab
    } = props;

    let saveRoute;

    switch (coreName) {
      case 'primary_lab':
        saveRoute = currentItem ? currentItem.saveRoute : this.props.saveRoute;
        break;
      case 'satellite_lab':
        saveRoute = currentItem
          ? currentItem.saveRoute
          : currentPrimaryLab.satellitesSaveRoute;
        break;
    }

    let labDetails;
    let primaryLabManagers;
    let lab = currentPrimaryLab || currentItem;

    let currentSubscriptions: Array<Subscription> = [];
    let subscriptionStartDate: Date;
    let subscriptionEndDate: Date;
    let subscriptionAmount: number;
    let subscriptionIsActive: boolean;
    let subscriptionIsExpired: boolean;

    if (
      lab &&
      has(lab, ['users', I18n.t('manage_lab.labels.primary_lab_manager.one')])
    ) {
      primaryLabManagers = lab.users[
        I18n.t('manage_lab.labels.primary_lab_manager.one')
      ].map(user => {
        return {
          title: user.name,
          subtitle: user.emailAddress
        };
      });
    }

    if (currentItem) {
      const {
        name,
        labType,
        addressOne,
        addressTwo,
        city,
        state,
        zipCode,
        country,
        lastInspectionDate,
        nextInspectionDate,
        subscriptions,
        logo,
        active_saml,
        idp_cert,
        idp_entity_id,
        idp_sso_target_url
      } = currentItem;

      const today = new Date();
      const lastSubscription = last(subscriptions);

      subscriptionIsActive = lastSubscription && lastSubscription.isActive;
      subscriptionIsExpired = lastSubscription && lastSubscription.isExpired;

      currentSubscriptions = subscriptions
        .filter(s => !s.isExpired && !s.isCanceled)
        .map(s => {
          const {
            id,
            startDate,
            endDate,
            amount,
            isActive,
            isExpired,
            isCanceled
          } = s;

          return {
            id: s.id.toString(),
            startDate: parse(startDate),
            endDate: parse(endDate),
            amount: amount,
            isActive: isActive,
            isExpired: isExpired,
            isCanceled: isCanceled,
            isNew: false,
            toggle: false
          };
        });

      if (subscriptionIsActive) {
        const { startDate, endDate, amount } = lastSubscription;

        subscriptionStartDate = parse(startDate);
        subscriptionEndDate = parse(endDate);
        subscriptionAmount = amount;
      }

      const nextImageProps =
        currentItem && currentItem.logo
          ? {
              file: currentItem.logo,
              imagePreview: currentItem.logo.fileObject
                ? currentItem.logo.fileObject.downloadUrl
                : ''
            }
          : {};

      labDetails = {
        name: name,
        selectedLabType: get(labType, 'id'),
        addressOne: addressOne,
        addressTwo: addressTwo,
        city: city,
        selectedState: state,
        zipCode: zipCode,
        selectedCountry: country,
        logo: nextImageProps,
        lastInspectionDate:
          lastInspectionDate !== undefined && lastInspectionDate !== null
            ? parse(lastInspectionDate)
            : today,
        nextInspectionDate:
          nextInspectionDate !== undefined && nextInspectionDate !== null
            ? parse(nextInspectionDate)
            : today,
        active_saml: active_saml,
        idp_cert: idp_cert,
        idp_entity_id: idp_entity_id,
        idp_sso_target_url: idp_sso_target_url
      };
    }

    if (labDetails || primaryLabManagers) {
      return merge(
        this.initialState(),
        labDetails,
        {
          primaryLabManagers,
          currentSubscriptions,
          saveRoute,
          subscriptionStartDate,
          subscriptionEndDate,
          subscriptionAmount,
          subscriptionIsActive
        },
        {
          showSubscriptionFields: !subscriptionIsExpired,
          showPrimaryLabManagerFields: !isUndefined(primaryLab),
          formIsValid: this.formIsValid()
        }
      );
    } else {
      return { ...this.initialState(), saveRoute };
    }
  }

  private initialState(): LabDetailsFormState {
    const today = new Date();

    return {
      name: '',
      selectedLabType: '',
      addressOne: '',
      addressTwo: '',
      city: '',
      selectedState: undefined,
      zipCode: '',
      selectedCountry: undefined,
      logo: null,
      lastInspectionDate: today,
      nextInspectionDate: today,
      subscriptionStartDate: today,
      subscriptionEndDate: today,
      subscriptionAmount: null,
      subscriptionIsActive: false,
      subscriptionActivate: false,
      subscriptionCancel: false,
      primaryLabManagerFirstName: '',
      primaryLabManagerLastName: '',
      primaryLabManagerEmail: '',
      showSubscriptionFields: false,
      showPrimaryLabManagerFields: false,
      primaryLabManagers: [],
      newPrimaryLabManagers: [],
      newSubscriptions: [],
      currentSubscriptions: [],
      isSubmitting: false,
      saveRoute: '',
      formIsValid: false,
      overrideInspectionDate: false,
      active_saml: false,
      idp_cert: '',
      idp_entity_id: '',
      idp_sso_target_url: '',
      isLoading: true
    };
  }
}
