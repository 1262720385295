import * as React from 'react';

/**
 * Accepts a component with props and attaches additional props
 * before rendering.
 */
export const asAction = (action: () => any, className?: string) => (
  WrappedComponent: React.ComponentClass<any> | React.StatelessComponent<any>
) => {
  const ComponentAsLink = (ownProps: any) => (
    <a role="button" className={className} onClick={action}>
      <WrappedComponent {...ownProps} />
    </a>
  );

  return ComponentAsLink;
};
