import * as React from 'react';
import {
  isNull,
  isEmpty,
  isNumber,
  isEqual,
  isUndefined,
  merge
} from 'lodash-es';

import { validateForm } from '../../modules';

export interface FormProps<T> {
  coreName: string;
  saveRoute: string;
  managementContext: string;
  currentItem?: T;
  availableFileFormats?: string;
  isAdminView?: boolean;
  errors?: GenericObject;
  updateParent?: (record: any) => void;
  formSuccess(item: any, callback?: Function): void;
  formFailure(data: any): void;
}

export interface FormState {
  [key: string]: any;
  hasNewFiles?: boolean;
  isValid?: boolean;
  isSubmitting?: boolean;
  useProfileSignature?: boolean;
}

/**
 * A generic form component, intended to be extended to build out forms
 * more quickly. Includes a few base-level handlers.
 */
export class Form<
  P extends FormProps<any>,
  S extends FormState
> extends React.Component<any, any> {
  props: FormProps<any>;
  state: FormState;
  form: HTMLFormElement;
  fName: (params: string | Array<string>, isMultiple?: boolean) => string;
  validationFields: Array<HTMLInputElement> = [];
  validationKeys: Array<string> = [];

  constructor(props: FormProps<any>) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSignatureFieldChange = this.handleSignatureFieldChange.bind(
      this
    );
    this.handleFlagNewFiles = this.handleFlagNewFiles.bind(this);
    this.handleFileListChange = this.handleFileListChange.bind(this);
  }

  public componentDidMount() {
    this.forceUpdate();
  }

  public componentDidUpdate(prevProps: FormProps<any>, prevState: FormState) {
    if (!isEqual(prevProps, this.props)) {
      this.forceUpdate();
    }

    const isValid = validateForm(this.form, false);

    if (isValid !== prevState.isValid) {
      this.setState({ isValid });
    }
  }

  /**
   * Handler to set state indicating whether or not the form is
   * currently submitting.
   *
   * @param isSubmitting
   * @param callback
   */
  public handleSubmit(state: Partial<FormState>, callback?: Function) {
    this.setState(
      (prevState: FormState) => {
        return merge(state, { isSubmitting: true });
      },
      () => {
        if (callback) callback();
      }
    );
  }

  /**
   * Handler for change to a `TextField` component.
   *
   * @param key The field key.
   * @param value The current value.
   */
  public handleTextChange(key: string, value: string) {
    const { updateParent } = this.props;

    this.setState((prevState: FormState) => {
      prevState[key] = value;

      return prevState;
    }, this.handleUpdateParent);
  }

  /**
   * Handler for change to a `TextField` component with `isNumeric` enabled.
   *
   * @param key The field key.
   * @param value The current value.
   */
  public handleNumberChange(key: string, value: string) {
    this.setState((prevState: FormState) => {
      prevState[key] = value === '' ? null : Number(value);

      return prevState;
    }, this.handleUpdateParent);
  }

  /**
   * Handler for change to a `SelectField` component.
   *
   * @param key The field key.
   * @param selection The selected option.
   */
  public handleSelectChange(key: string, selection: any) {
    this.setState((prevState: FormState) => {
      prevState[key] = selection;

      return prevState;
    }, this.handleUpdateParent);
  }

  /**
   * Handler for change to a `DatePickerField` component.
   *
   * @param key The relevant field key.
   * @param date The selected `Date`.
   */
  public handleDateChange(key: string, date: Date) {
    this.setState((prevState: FormState) => {
      prevState[key] = date;

      return prevState;
    }, this.handleUpdateParent);
  }

  /**
   * Handler for change to a `CheckboxField` component.
   *
   * @param key The relevant field key.
   * @param value The value of the checkbox.
   */
  public handleCheckboxChange(key: string, isChecked: boolean) {
    this.setState((prevState: FormState) => {
      prevState[key] = isChecked;

      return prevState;
    }, this.handleUpdateParent);
  }

  public handleFileListChange(key: string, files: Array<IFile>) {
    this.setState((prevState: FormState) => {
      prevState[key] = files;

      return prevState;
    }, this.handleUpdateParent);
  }

  public handleSignatureFieldChange(key: string, value?: string) {
    if (!isUndefined(value)) {
      this.handleTextChange(key, value);
    } else {
      this.setState({
        useProfileSignature: !this.state.useProfileSignature
      });
    }
  }

  /**
   * Handler to convert the current form state into a complete
   * object for transmission to a parent component.
   */
  public handleUpdateParent() {
    const { updateParent } = this.props;

    if (updateParent) {
      updateParent(this.state);
    }
  }

  /**
   * Handler for unsuccessful form post;
   */
  public handleFormFailure() {}

  /**
   * Handler for setting the `hasNewFiles` flag, which
   * governs when to remove any instances of `FileUploadControl`.
   *
   * @param hasNewFiles
   */
  public handleFlagNewFiles(hasNewFiles: boolean) {
    this.setState({
      hasNewFiles
    });
  }

  public getRequiredFields() {
    return Array.from<HTMLInputElement | HTMLTextAreaElement>(
      this.form.querySelectorAll('input, select, textarea')
    ).filter(
      field =>
        field && field.required && !isEmpty(field.name)
    );
  }

  /**
   * Simple form validation.
   */
  public formIsValid() {
    if (!this.form) return;

    const values = this.getRequiredFields().map(field => field.value);
    // .concat(this.validationKeys.map(key => this.state[key]));

    return values.every(
      value => value && (isNumber(value) ? !isNull(value) : !isEmpty(value))
    );
  }

  /**
   * Given an array of strings, builds an array of input fields named
   * for those strings.
   *
   * @param fields
   */
  public setValidationFields(fields: Array<string>) {
    this.validationFields = fields.map(current =>
      this.form.querySelector<HTMLInputElement>(
        `input[name="${this.fName(current)}"]`
      )
    );
  }

  /**
   * Render method. This is a stub and will always be overridden.
   */
  render() {
    return <div />;
  }
}
