import * as React from 'react';

import { componentClasses } from '../../shared';

interface ControlBarProps {
  isAutoHeight?: boolean;
  isFlush?: boolean;
  alignment?: string;
  children: React.ReactNode;
}

export const ControlBar = (props: ControlBarProps) => {
  const { isAutoHeight, isFlush, alignment, children } = props;

  const controlBarClasses = componentClasses('control-bar', {
    'auto-height': isAutoHeight,
    flush: isFlush,
    'align-left': alignment === 'left',
    'align-right': alignment === 'right'
  });

  return <div className={controlBarClasses}>{children}</div>;
};
