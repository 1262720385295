import * as React from 'react';
import { Button, Sizes, Colors } from 'react-foundation';
import { startsWith, endsWith, isUndefined } from 'lodash-es';

import {
  executeGet,
  executePost,
  executePut,
  executeDelete
} from '../../shared';
import { DropdownMenu } from '../common';
import * as ConfirmActions from '../common/confirm-actions';

type ActionCallback = (data: any, slug?: string) => void;

interface ActionProps {
  title: string;
  index: number;
  button: IButton;
  slug: string;
  item: IAction;
  actionCallback: ActionCallback;
}

const setColor = (color: string) => {
  switch (color) {
    case 'primary':
      return Colors.PRIMARY;
    case 'secondary':
      return Colors.SECONDARY;
    case 'success':
      return Colors.SUCCESS;
    case 'warning':
      return Colors.WARNING;
    case 'alert':
      return Colors.ALERT;
    default:
      return Colors.PRIMARY;
  }
};

interface SetActionProps {
  [key: string]: any;
  actionCallback: ActionCallback;
  slug: string;
  url: string;
}

const setAction = (
  method: string,
  { actionCallback, url, slug }: SetActionProps
) => {
  let action: Function;

  switch (method) {
    case 'get':
      action = executeGet;
      break;
    case 'post':
      action = executePost;
      break;
    case 'put':
      action = executePut;
      break;
    case 'delete':
      action = executeDelete;
      break;
    default:
      action = executeGet;
  }

  return async (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const data = await action(url);
    actionCallback(data, slug);
  };
};

export const Action = ({
  title,
  index,
  button,
  slug,
  item,
  actionCallback
}: ActionProps) => {
  const { label, url, style, context, method, subButtons } = button;
  const hasSubButtons = !isUndefined(subButtons);
  const key = `button_${title}_${index}`;
  const confirmActions: {
    [key: string]: React.ComponentClass<any> | React.StatelessComponent<any>;
  } = ConfirmActions;

  if (hasSubButtons) {
    const options: Array<ISubAction> = subButtons.map((button, subIndex) => {
      return {
        url: button.url,
        label: button.label,
        parent: title,
        parentSlug: slug,
        actionIndex: subIndex,
        action: executeAction
      };
    });

    return (
      <DropdownMenu
        key={key}
        buttonLabel={label}
        buttonSize={Sizes.SMALL}
        options={options}
      />
    );
  } else {
    if (context) {
      if (startsWith(context, 'link')) {
        return (
          <a
            key={key}
            href={url}
            className={
              endsWith(context, 'text')
                ? 'link stack'
                : 'button small secondary hollow'
            }
            onClick={
              method
                ? setAction(method, { actionCallback, slug, url })
                : undefined
            }>
            {label}
          </a>
        );
      } else if (startsWith(context, 'confirm')) {
        const componentName = context.split('|')[1];
        const Confirm = confirmActions[componentName];

        return (
          <Confirm
            key={key}
            name={item.title}
            label={label}
            action={executeAction.bind(
              undefined,
              button.url,
              slug.split('|')[0]
            )}
          />
        );
      } else {
        // noop to fail out
        return null;
      }
    } else {
      return (
        <Button
          key={key}
          size={Sizes.SMALL}
          color={style ? setColor(style) : Colors.SECONDARY}
          isHollow
          isDropdown={hasSubButtons}
          onClick={event => {
            event.preventDefault();
            executeAction(button.url, slug.split('|')[0]);
          }}>
          {label}
        </Button>
      );
    }
  }

  /**
   * Handler for executing an action dispatched by a specific element,
   * and passing the response to a callback.
   */
  async function executeAction(url: string, slug: string) {
    const data = await executeGet(url);
    actionCallback(data, slug);
  }
};
