import * as React from 'react';
import { Button, Colors } from 'react-foundation';
import { createMarkup, propIfAvailable } from '../../shared';
import { get } from 'lodash-es';

import { reveal, revealContext, revealOpen } from '../reveal/reveal';
import { Loading } from './Loading';

export interface ConfirmActionButtonProps {
  label: string | JSX.Element;
  classNames: string;
  excludeButtonCoreClass?: boolean;
  disabled?: boolean;
}

interface OpenModalProps {
  title: string;
  message: string;
  buttonProps: ConfirmActionButtonProps;
  actionButtonLabel: string;
  preserveModal: boolean;

  action(): void;

  openReveal(props: { [key: string]: any }): void;
}

const OpenModal = revealOpen({ name: 'confirmModal' })(
  ({ buttonProps, openReveal, ...props }: OpenModalProps) => {
    const { label, classNames, excludeButtonCoreClass, disabled } = buttonProps;
    return (
      <button
        type="button"
        className={`${excludeButtonCoreClass ? '' : 'button'} ${classNames}`}
        disabled={disabled}
        onClick={() => openReveal(props)}>
        {label}
      </button>
    );
  }
);

interface ModalComponentProps {
  revealData: {
    title: string;
    message: string | JSX.Element;
    actionButtonLabel: string | JSX.Element;
    preserveModal: boolean;
    action(): void;
  };
  size?: string;

  closeReveal(): void;
}

interface ModalComponentState {
  isWorking: boolean;
}

class ModalComponent extends React.Component<
  ModalComponentProps,
  ModalComponentState
> {
  state: ModalComponentState = {
    isWorking: false
  };

  handleConfirmClick = e => {
    const { closeReveal, revealData } = this.props;
    const { action, preserveModal } = revealData;

    e.preventDefault();
    action();

    if (preserveModal) {
      this.setState({
        isWorking: true
      });
    } else {
      closeReveal();
    }
  };

  renderMessage = (message: string | JSX.Element) => {
    if (!message) return null;
    if (React.isValidElement(message)) return message;
    return <p dangerouslySetInnerHTML={createMarkup(message.toString())} />;
  };

  render() {
    const { revealData, size, closeReveal } = this.props;
    const { isWorking } = this.state;

    return (
      <div style={size ? { width: size } : undefined}>
        <div className="reveal__title">
          {propIfAvailable('title', revealData)}
        </div>
        <div className="reveal__body">
          {revealData && this.renderMessage(revealData.message)}
        </div>
        <div className="reveal__footer">
          <Button
            color={Colors.SECONDARY}
            className="clear"
            isDisabled={isWorking}
            onClick={e => {
              e.preventDefault();
              closeReveal();
            }}>
            {I18n.t('global.buttons.cancel')}
          </Button>
          <Button
            color={Colors.SECONDARY}
            onClick={this.handleConfirmClick}
            isDisabled={isWorking}>
            {isWorking && (
              <>
                <Loading size="small" />
                <span className="nowrap">
                  {I18n.t('global.labels.please_wait')}
                </span>
              </>
            )}
            {!isWorking &&
              get(
                revealData,
                'actionButtonLabel',
                I18n.t('global.buttons.confirm')
              )}
          </Button>
        </div>
      </div>
    );
  }
}

const Modal = reveal({ name: 'confirmModal' })(ModalComponent);

interface ConfirmModalProps {
  id: string;
  title: string;
  message: string;
  buttonProps: ConfirmActionButtonProps;
  actionButtonLabel: string | JSX.Element;
  preserveModal: boolean;
  size?: string;

  action(): void;
}

const ConfirmActionModal = (props: ConfirmModalProps) => {
  const { id } = props;

  return [
    <OpenModal key={`${id}_OpenModal`} {...props} />,
    <Modal key={`${id}_Modal`} size={props.size}/>
  ];
};

export const ConfirmAction = revealContext()(ConfirmActionModal);
