import * as React from 'react';

import { componentClasses, createMarkup } from '../../shared';

interface TwoTierProps {
  title: string | JSX.Element;
  content: string | JSX.Element;
  isHtmlContent?: boolean;
  modifiers?: Modifiers;
  componentClass?: string;
}

export const TwoTier = ({
  title,
  content,
  isHtmlContent,
  modifiers,
  componentClass = 'div'
}: TwoTierProps) => {
  return React.createElement(
    componentClass,
    {
      className: componentClasses(
        'two-tier',
        Object.assign(
          {
            html: isHtmlContent
          },
          modifiers
        )
      )
    },
    <>
      {React.isValidElement(title) ? title : <span>{title}</span>}
      {React.isValidElement(content) && content}
      {!React.isValidElement(content) && (
        <>
          {isHtmlContent && (
            <span dangerouslySetInnerHTML={createMarkup(content as string)} />
          )}
          {!isHtmlContent && <span>{content}</span>}
        </>
      )}
    </>
  );
};
