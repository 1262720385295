import * as React from 'react';
import { findDOMNode } from 'react-dom';
import {
  Row,
  Column,
  Button,
  Colors,
  Sizes,
  Alignments
} from 'react-foundation';

import { canScroll, processForm, STATES } from '../../shared';
import { OverlayContent } from '../overlay/OverlayContent';

export interface OverlayFormProps {
  title: string;
  icon: string;
  saveRoute: string;
  form: HTMLFormElement;
  saveButtonLabel?: string;
  showCancelButton?: boolean;
  updatesItem?: boolean;
  canSubmit?: boolean;
  isSubmitting?: boolean;
  buttons?: Array<JSX.Element>;
  children: JSX.Element;
  handleSubmit?: (state: Partial<any>, callback?: Function) => void;
  closeOverlay(): void;
  formSuccess(data: any): void;
  formFailure(data: any): void;
}

/**
 * A common class used for wrapping a form as part of an overlay. The `OverlayForm`
 * also includes handling for form submissions, generating submit buttons (either
 * programmatically via several flags or by simply passing in an array of JSX buttons),
 * and setting validation states on the submit.
 *
 * The component should have a single child - a root `form` element, which contains
 * all of the fields to include within the `OverlayForm`. A ref to that form should also
 * be provided in the `form` prop.
 *
 * @todo expand this documentation
 *
 * @export
 * @class OverlayForm
 * @extends {React.Component<OverlayFormProps>}
 */
export class OverlayForm extends React.Component<OverlayFormProps> {
  props: OverlayFormProps;
  formFields: HTMLElement;

  public render() {
    const { title, icon, children, closeOverlay } = this.props;

    return (
      <OverlayContent
        title={title}
        icon={icon}
        closeOverlay={() => {
          this.resetForm();
          closeOverlay();
        }}>
        <div className="form">
          <div
            ref={fields => (this.formFields = fields)}
            className={`form__fields ${
              canScroll(this.formFields) ? STATES.canScroll : ''
            }`}>
            {children}
          </div>
          <div className="form__actions form__actions--footer">
            <OverlayFormButtons {...this.props} />
          </div>
        </div>
      </OverlayContent>
    );
  }

  private submitLabel() {
    const { saveButtonLabel, isSubmitting } = this.props;

    if (isSubmitting) {
      return I18n.t('global.labels.please_wait');
    } else {
      return saveButtonLabel || I18n.t('global.buttons.save');
    }
  }

  private resetForm() {
    const { form } = this.props;

    // note: does not currently clear CKEditor
    form.reset();
  }
}

type OverlayFormButtonsProps = Omit<
  OverlayFormProps,
  'title' | 'icon' | 'children'
>;

export const OverlayFormButtons = ({
  buttons,
  closeOverlay,
  showCancelButton,
  canSubmit,
  isSubmitting,
  handleSubmit,
  saveRoute,
  form,
  formSuccess,
  formFailure,
  saveButtonLabel,
  updatesItem
}: OverlayFormButtonsProps) => {
  return (
    <Row horizontalAlignment={Alignments.JUSTIFY}>
      {buttons &&
        buttons.map((button, index) => (
          <Column small={4} key={`OverlayForm_button_${index}`}>
            {button}
          </Column>
        ))}
      {!buttons && (
        <>
          {showCancelButton && (
            <Column small={4}>
              <Button
                color={Colors.SECONDARY}
                isExpanded
                isHollow
                onClick={e => {
                  e.preventDefault();
                  closeOverlay();
                }}>
                {I18n.t('global.buttons.cancel')}
              </Button>
            </Column>
          )}
          <Column small={6} offsetOnSmall={2}>
            <Button
              color={Colors.SECONDARY}
              isDisabled={!canSubmit || isSubmitting}
              isExpanded
              onClick={e => {
                e.preventDefault();

                handleSubmit({ isSubmitting: true }, () => {
                  processForm(
                    saveRoute,
                    form,
                    formSuccess,
                    formFailure,
                    updatesItem ? { method: 'put', responseType: 'json' } : {}
                  );
                });
              }}>
              {saveButtonLabel && isSubmitting
                ? I18n.t('global.labels.please_wait')
                : saveButtonLabel}
              {!saveButtonLabel &&
                I18n.t(
                  `global.${
                    isSubmitting ? 'labels.please_wait' : 'buttons.save'
                  }`
                )}
            </Button>
          </Column>
        </>
      )}
    </Row>
  );
};
