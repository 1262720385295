import { Options as Select2Options } from 'select2';
import { forEach } from 'lodash-es';

import { snakeToCamel } from '../shared';

/**
 * Wrapper for instantiating one or more instances of Select2.
 *
 * @export
 * @param {HTMLElement} [container=document.body]
 *
 * @return {JQuery[]} Returns all initialized datepickers within the container.
 */
export function initializeSelect2(container: HTMLElement = document.body) {
  const SELECT2_DEFAULTS: Select2Options = {
    theme: 'r18',
    width: '100%'
  };

  /**
   * A manifest of individualized options for Select2
   * instances.
   *
   * @todo look for ways to dedupe this
   *
   * @constant
   * @type {Object[]}
   */
  const SELECT2_INSTANCE_OPTIONS: { [key: string]: Partial<Select2Options> } = {
    allowCustomEntry: {
      tags: true
    },
    fixedWidth: {
      width: 'element'
    },
    procedureEvaluationEvaluateeId: {
      allowClear: true,
      placeholder: I18n.t('global.labels.select_a_user')
    },
    procedureEvaluationCreatedById: {
      allowClear: true,
      placeholder: I18n.t('global.labels.select_a_user')
    },
    technicianCertificationUserId: {
      allowClear: true,
      placeholder: I18n.t('global.labels.select_a_user')
    },
    procedureEvaluationOutsideEvaluatorTextTraining: {
      tags: true,
      placeholder: I18n.t('training_and_evaluations.labels.select_or_create_outside_evaluator.training')
    },
    procedureEvaluationOutsideEvaluatorTextEvaluation: {
      tags: true,
      placeholder: I18n.t('training_and_evaluations.labels.select_or_create_outside_evaluator.evaluation')
    },
    procedureEvaluationOutsideEvaluatorId: {
      placeholder: I18n.t('evaluations.labels.select_outside_evaluator'),
      allowClear: true
    },
    measurementStandardSelect: {}
  };

  const SELECT2_INSTANCE_CHANGE_EVENTS: {
    [key: string]: (event: JQuery.Event) => void;
  } = {
    // procedureEvaluationEvaluateeId: (event: JQuery.Event) => {
    // // const $target = $(event.target);
    // // const $counterTarget = $('#procedure_evaluation_created_by_id');
    // // const value = $target.val();
    // // if ($counterTarget.length) {
    // //   const $options: JQuery = $counterTarget.find('option');
    // //   $options.prop('disabled', false);
    // //   $options
    // //     .filter(
    // //       (index, element: HTMLOptionElement) => element.value === value
    // //     )
    // //     .eq(0)
    // //     .prop('disabled', true);
    // //   setTimeout(() => {
    // //     const target = $counterTarget[0];
    // //     const name = $counterTarget.attr('name');
    // //     const value = $counterTarget.val();
    // //     initSelect2(target, name, value);
    // //   });
    // }
    // },
    // procedureEvaluationCreatedById: (event: JQuery.Event) => {
    //   const value = $(this).val();
    //   debugger;
    // }
  };

  const targets = container.querySelectorAll<HTMLSelectElement>(
    'select[data-select2-instance]'
  );
  const $targets: Array<JQuery> = [];

  const initSelect2 = (
    target: HTMLElement,
    name: string,
    value: string | number | string[]
  ) => {
    const options = Object.assign(
      {},
      SELECT2_DEFAULTS,
      SELECT2_INSTANCE_OPTIONS[name] || {}
    );

    const $target = $(target);

    if (!$target.data('select2')) {
      $target.select2(options);
      $target.val(value).trigger('change');
      $targets.push($target);

      const event = SELECT2_INSTANCE_CHANGE_EVENTS[name];

      if (event) {
        $target.off('change').on('change', event);
      }

      $target.on('change', () => {
        window.dispatchEvent(
          new CustomEvent('userInterface.validateForm', {
            detail: target
          })
        );
      });
    }
  };

  if (targets.length) {
    forEach(targets, target => {
      const name = snakeToCamel(target.dataset.select2Instance);
      const initialValue = target.value;

      initSelect2(target, name, initialValue);
    });
  }

  return $targets;
}
