import * as React from 'react';
import { createMarkup } from '../../../shared';
import { ConfirmAction } from '../ConfirmAction';

interface ConfirmDisableAccessProps {
  name: string;
  label: string;
  action(): void;
}

export const ConfirmDisableAccess = ({
  name,
  label,
  action
}: ConfirmDisableAccessProps) => (
  <ConfirmAction
    id={`ConfirmAction_DisableAccess_${name}`}
    title={I18n.t('modals.confirm_disable_access.title')}
    message={
      <p
        dangerouslySetInnerHTML={createMarkup(
          I18n.t('modals.confirm_disable_access.message_html', { name })
        )}
      />
    }
    buttonProps={{
      label: label,
      classNames: 'small alert hollow'
    }}
    action={action}
  />
);
