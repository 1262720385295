import $ from 'jquery';
import { Controller } from 'framewerk';
import { render } from 'mustache';

import { STATES, findParentNodeByTag, executeGet } from '../shared';

const { AccordionMenu } = Foundation;

interface Procedure {
  label: string;
  downloadLink: string;
  removeLink: string;
  canRemove: boolean;
  addendums: Array<Procedure>;
}

interface EquipmentProcedureSummary {
  label: string;
  slug: string;
  procedureCount: number;
  procedureCountDescriptor: string;
}

interface ProcedureHistory {
  label: string;
  items?: Array<ProcedureHistory>;
  procedures: Array<Procedure>;
  procedureCountDescriptor?: string;
}

/**
 * The initialization function for creating the `r18-emailNotifications` Controller.
 */
export function procedureHistory(): Controller {
  let accordionMenus: any = [];

  /**
   * The name of the controller.
   */
  const name: string = 'r18-procedure-history';

  /**
   * Selector strings for the `r18-procedure-history` Controller.
   */
  const targets = Controller.getTargets({
    accordion: `ul[data-${name}-accordion]`,
    categoryIds: `a[data-${name}-category-id]`,
    categoryList: `ul[data-${name}-category-list]`,
    path: `input[data-${name}-path`,
    categoryTemplate: `script[data-${name}-category-template]`,
    equipmentSummaryTemplate: `script[data-${name}-equipment-summary-template]`,
    procedureTypeTemplate: `script[data-${name}-procedure-type-template]`,
    itemTemplate: `script[data-${name}-item-template]`,
    serviceHistoryTemplate: `script[data-${name}-item-service-history-template]`,
    toggleLink: `a[data-${name}-toggle-link]`,
    toggleTarget: `[data-${name}-toggle-target]`,
    userTargets: `[data-${name}-user-target]`
  });

  /**
   * Events created for the `r18-procedure-history` Controller.
   */
  const events = {
    windowLoad: () => {
      targets.accordion.forEach(target => {
        accordionMenus.push(new AccordionMenu($(target), {}));

        target.addEventListener('click', event => {
          event.preventDefault();
        });
      });
    },
    togglerEvents: () => {
      targets.toggleTarget.forEach(target => {
        $(target).on('on.zf.toggler', event => {
          setToggleLinkState(event.target, true);
        });

        $(target).on('off.zf.toggler', event => {
          setToggleLinkState(event.target, false);
        });
      });
    },
    accordionMenuEvents: () => {
      targets.categoryIds.forEach(link => {
        link.addEventListener('click', event => {
          const eventTarget = event.currentTarget as HTMLElement;
          handleCategoryClick(eventTarget);
        });
      });

      // delegate clicks across the entire accordion so events wire up automatically
      // for newly added sections
      targets.accordion[0].addEventListener(
        'click',
        async (event: MouseEvent) => {
          let eventTarget = event.target as HTMLElement;

          const equipmentSummaryTarget = findTargetWithAttribute(
            eventTarget,
            `data-${name}-equipment-summary`
          );
          const procedureTypeTarget = findTargetWithAttribute(
            eventTarget,
            `data-${name}-procedure-type`
          );

          if (equipmentSummaryTarget) {
            await handleEquipmentSummaryClick(equipmentSummaryTarget);
          } else if (procedureTypeTarget) {
            await handleProcedureTypeClick(procedureTypeTarget);
          } else if (
            eventTarget.classList.contains('download') ||
            eventTarget.parentElement.classList.contains('download')
          ) {
            if (!eventTarget.hasAttribute('href')) {
              eventTarget = findParentNodeByTag(eventTarget, 'a');
            }

            if (eventTarget && eventTarget.hasAttribute('href')) {
              window.open((eventTarget as HTMLAnchorElement).href, '_blank');
            }
          }
        }
      );
    }
  };

  return new Controller({ name, targets, events });

  /////////////////

  async function handleCategoryClick(target: HTMLElement) {
    if (sectionIsLoading(target.nextElementSibling as HTMLElement)) {
      target.classList.add(STATES.isDisabled);

      const categoryId = target.dataset.r18ProcedureHistoryCategoryId;

      try {
        const categoryList = await getCategory(categoryId);
        const category = addCategoryToView(categoryId, categoryList);

        accordionMenus.push(new AccordionMenu($(category)));
        target.classList.remove(STATES.isDisabled);
      } catch (error) {
        target.classList.remove(STATES.isDisabled);
        // TODO: handleTheError
      }
    }
  }

  async function handleEquipmentSummaryClick(target: HTMLElement) {
    if (sectionIsLoading(target.nextElementSibling as HTMLElement)) {
      target.classList.add(STATES.isDisabled);

      const equipmentId = target.dataset.r18ProcedureHistoryEquipmentSummary;

      try {
        const equipmentSummaryList = await getEquipmentSummary(equipmentId);
        const equipmentSummary = addEquipmentSummaryToView(
          equipmentId,
          equipmentSummaryList
        );
        accordionMenus.push(new AccordionMenu($(equipmentSummary)));
        target.classList.remove(STATES.isDisabled);
      } catch (error) {
        target.classList.remove(STATES.isDisabled);
      }
    }
  }

  async function handleProcedureTypeClick(target: HTMLElement) {
    if (sectionIsLoading(target.nextElementSibling as HTMLElement)) {
      target.classList.add(STATES.isDisabled);

      const equipmentId = (
        findParentNodeByTag(target, 'ul').previousElementSibling as HTMLElement
      ).dataset.r18ProcedureHistoryEquipmentSummary;
      const procedureTypeName = target.dataset.r18ProcedureHistoryProcedureType;

      try {
        const procedureTypeList = await getProcedureType(
          procedureTypeName,
          equipmentId
        );
        const procedureType = addProcedureTypeToView(
          procedureTypeName,
          procedureTypeList,
          target
        );

        accordionMenus.push(new AccordionMenu($(procedureType)));
        target.classList.remove(STATES.isDisabled);
      } catch (error) {
        target.classList.remove(STATES.isDisabled);
      }
    }
  }

  /**
   * Retrieve the procedure history for a specific category id.
   *
   * @param {string} categoryId The category id to retrive.
   */
  async function getCategory(categoryId: string): Promise<string> {
    const baseUrl = (targets.path[0] as HTMLInputElement).value;
    const url = `${baseUrl}?category_id=${categoryId}`;

    try {
      return buildCategoryList(await executeGet(url));
    } catch (error) {
      // TODO: handleTheError
    }
  }

  /**
   * Inserts a formatted category accordion menu at the appropriate place.
   * Returns the selected target.
   *
   * @param categoryId The target category id.
   * @param template HTML markup to insert.
   */
  function addCategoryToView(categoryId: string, categoryList: string) {
    const target = targets.categoryList.find(
      cl => cl.dataset.r18ProcedureHistoryCategoryList === categoryId
    );

    target.innerHTML = categoryList;

    return target;
  }

  /**
   * Build the markup for the expanded section.
   *
   * @param {Array<ProcedureHistory>} items Category items.
   */
  function buildCategoryList(items: Array<ProcedureHistory>): string {
    const template = targets.categoryTemplate[0].innerHTML;
    const itemTemplate = targets.itemTemplate[0].innerHTML;
    const itemServiceHistoryTemplate =
      targets.serviceHistoryTemplate[0].innerHTML;

    const list = items.map(item =>
      render(template, item, {
        item: itemTemplate,
        serviceHistory: itemServiceHistoryTemplate
      })
    );

    return list.join('');
  }

  /**
   * Retrieves the equipment summary for a provided equipment ID, and returns
   * formatted markup (once resolved).
   *
   * @param equipmentId
   */
  async function getEquipmentSummary(equipmentId: string): Promise<string> {
    const baseUrl = (targets.path[0] as HTMLInputElement).value;
    const url = `${baseUrl}?equipment_id=${equipmentId}`;

    try {
      return buildEquipmentSummaryList(await executeGet(url));
    } catch (error) {
      // TODO: handle the error!?
    }
  }

  /**
   * Renders the equipment record summaries into Mustache templates.
   *
   * @param equipmentRecords
   */
  function buildEquipmentSummaryList(
    equipmentRecords: Array<EquipmentProcedureSummary>
  ): string {
    const template = targets.equipmentSummaryTemplate[0].innerHTML;

    return render(template, {
      items: equipmentRecords
    });
  }

  /**
   * Inserts a formatted equipment summary accordion menu at the appropriate place.
   * Returns the selected target.
   *
   * @param equipmentId The target category id.
   * @param template HTML markup to insert.
   */
  function addEquipmentSummaryToView(
    equipmentId: string,
    equipmentList: string
  ) {
    const targetSibling = targets.accordion[0].querySelector(
      `[data-r18-procedure-history-equipment-summary="${equipmentId}"]`
    );
    targetSibling.nextElementSibling.innerHTML = equipmentList;
    return targetSibling.nextElementSibling;
  }

  async function getProcedureType(procedureType: string, equipmentId: string) {
    const baseUrl = (targets.path[0] as HTMLInputElement).value;
    const url = `${baseUrl}?procedure_type=${procedureType}&equipment_id=${equipmentId}`;

    try {
      return buildProcedureTypeList(await executeGet(url), procedureType);
    } catch (error) {}
  }

  function buildProcedureTypeList(
    procedures: Array<Procedure>,
    procedureType: string
  ): string {
    const template = targets.procedureTypeTemplate[0].innerHTML;
    const itemTemplate = targets.itemTemplate[0].innerHTML;
    const itemServiceHistoryTemplate =
      targets.serviceHistoryTemplate[0].innerHTML;

    if (procedureType === 'service_record') {
      return render(
        template,
        {
          serviceRecords: procedures,
          hasServiceRecords: true
        },
        {
          serviceHistory: itemServiceHistoryTemplate
        }
      );
    } else {
      return render(
        template,
        {
          items: procedures
        },
        {
          item: itemTemplate
        }
      );
    }
  }

  function addProcedureTypeToView(
    procedureType: string,
    procedureTypeList: string,
    originalTarget: HTMLElement
  ) {
    const targetSibling = originalTarget.nextElementSibling;
    targetSibling.innerHTML = procedureTypeList;
    return targetSibling;
  }

  /**
   * Determines if a section is loading by checking for the loading text.
   *
   * @param section The section to check.
   */
  function sectionIsLoading(section: HTMLElement) {
    /*
      only load the data if it hasn't been loaded before.
      we call `textContent` here to strip away all of the markup.
      this is a little hacky, but should be reliable
    */
    return (
      section.textContent.trim() ===
      I18n.t('global.labels.loading.with_ellipses')
    );
  }

  function setToggleLinkState(target: HTMLElement, isOn: boolean) {
    const targetButton = targets.toggleLink.find(
      t => t.dataset.toggle === target.id
    );

    if (targetButton) {
      targetButton.textContent = isOn ? 'Show Summary' : 'Hide Summmary';
    }
  }

  /**
   * Locates a normalized click target when seeking a specific attribute.
   * Since clicks are delegated across the entire accordion, this ensures
   * we get the correct target to insert generated menus.
   *
   * @param target
   * @param attribute
   */
  function findTargetWithAttribute(target, attribute) {
    return [target, target.parentElement].find(target =>
      target.hasAttribute(attribute)
    );
  }
}
