import classNames from 'classnames';
import * as React from 'react';
import { createPortal } from 'react-dom';
import { Button, Colors } from 'react-foundation';
import { ckEditor, select2 } from '../../plugins';
import { requestTemplate } from '../../shared';
import { Loading } from './Loading';

interface ModalProps {
  title: string;
  size: 'tiny' | 'small' | 'large' | 'full';
  isOpen: boolean;
  children?: JSX.Element;
  contentUrl?: string;
  closeModal(): void;
}

interface ModalState {
  hasLoaded: boolean;
}

export class Modal extends React.Component<ModalProps, ModalState> {
  props: ModalProps;
  state: ModalState = {
    hasLoaded: this.props.children !== undefined
  };
  modalBodyRef: HTMLDivElement;

  get visibilityStyle() {
    const { isOpen } = this.props;
    return {
      display: isOpen ? 'block' : 'none'
    };
  }

  async componentDidUpdate(prevProps: ModalProps, prevState: ModalState) {
    const { contentUrl } = this.props;

    if (!prevProps.isOpen && this.props.isOpen) {
      if (contentUrl) {
        const [url, selector] = contentUrl.split(' ');
        const formPreview = await requestTemplate(url, selector);
        this.setState(
          {
            hasLoaded: true
          },
          () => {
            const updateView = new Promise<HTMLElement>(resolve => {
              this.modalBodyRef.innerHTML = formPreview;
              resolve(this.modalBodyRef);
            });

            updateView.then(container => {
              ckEditor(container);
              select2(container);
            });
          }
        );
      }
    } else if (prevProps.isOpen && !this.props.isOpen) {
      this.modalBodyRef.innerHTML = '';
      this.setState({
        hasLoaded: false
      });
    }
  }

  render() {
    const { closeModal, size, children, title } = this.props;
    const { hasLoaded } = this.state;

    return createPortal(
      <div className="reveal-overlay" style={this.visibilityStyle}>
        <div className={classNames('reveal', size)} style={this.visibilityStyle}>
          <div className="reveal__title">{title}</div>
          <div
            className="reveal__body"
            ref={element => (this.modalBodyRef = element)}>
            {!hasLoaded && !children && <Loading />}
            {hasLoaded && children}
          </div>
          <div className="reveal__footer">
            <Button
              color={Colors.SECONDARY}
              onClick={e => {
                e.preventDefault();
                closeModal();
              }}>
              {I18n.t('global.buttons.close')}
            </Button>
          </div>
        </div>
      </div>,
      document.body
    );
  }
}
