import * as React from 'react';

import { componentClasses } from '../../shared';

interface IconProps extends Partial<React.SVGProps<any>> {
  icon: string;
  classNames?: string;
  size?: string;
}

/**
 * Stateless component to render an SVG icon.
 */
export const Icon = ({ icon, classNames, size, ...rest }: IconProps) => {
  const componentModifiers = size ? { [size]: true } : undefined;

  return (
    <svg
      className={`${componentClasses(
        'icon',
        componentModifiers
      )} ${classNames || ''}`}
      {...rest}>
      <use xlinkHref={`#${icon}-icon`} />
    </svg>
  );
};
