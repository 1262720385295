/**
 * @fileOverview The `validateForm` module.
 *
 * @author Justin Toon
 * @since 0.1.0
 *
 * @requires NPM:lodash
 */

import { filter, forEach, isEmpty, isNull, isUndefined } from 'lodash-es';

import {
  isValidUrl as validateUrl,
  findParentNodeByTag,
  findParentNodeByClass
} from '../shared';

type ValidationFunction = (
  value: string,
  isNegated: boolean,
  fieldId?: string
) => boolean;

type FormField = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

/**
 * A module for validating a form for completeness and optionally
 * setting a hidden field with the validation status.
 */

/**
 * Validate a form.
 *
 * @since 0.1.0
 *
 * @param {HTMLFormElement} form The form to validate.
 */
export function validateForm(form: HTMLFormElement, disableSubmit = true) {
  const VALIDATE_AS: { [key: string]: ValidationFunction } = {
    url: isValidUrl,
    match: isFieldMatch,
    includedIn: isIncludedIn
  };

  if (form) {
    const fields = getRequiredFields(form);

    fields.map(setFieldMessage);

    const validFields = getValidFields(fields);
    const invalidFields = fields.filter(field => !validFields.includes(field));

    for (let i = 0; i < validFields.length; i++) {
      setFieldState(validFields[i], true);
    }

    for (let i = 0; i < invalidFields.length; i++) {
      setFieldState(invalidFields[i], false);
    }

    let isValid = invalidFields.length === 0;

    if (form.hasAttribute('data-r18-ui-validate-form-require-file')) {
      const existingFiles = form.querySelectorAll('.file');
      const fileInputFields = fields.filter(field => field.type === 'file');

      // allow the user to reuse a previously uploaded file
      if (existingFiles.length) {
        isValid = true;
      } else if (!fileInputFields.length && !existingFiles.length) {
        isValid = false;
      }
    }

    if (disableSubmit) {
      const submitElements = getSubmitElements();

      if (submitElements.length) {
        submitElements.forEach(submit => {
          submit.disabled = !isValid;
        });
      }
    }

    return isValid;
  }

  /////////////////

  /**
   * Get all possible controls for submitting a form.
   *
   * @param {HTMLFormElement} form The form to query.
   *
   * @returns {(HTMLElement)[]}
   */
  function getSubmitElements(): (HTMLInputElement | HTMLButtonElement)[] {
    return Array.from(
      form.querySelectorAll<HTMLInputElement | HTMLButtonElement>(
        'input[type="submit"], button[type="submit"]'
      )
    ).filter(input => !input.classList.contains('ignore-validation'));
  }

  /**
   * Get all required fields within the current form.
   *
   * @return {HTMLElement[]} Returns an array of elements.
   */
  function getRequiredFields(form: HTMLFormElement): FormField[] {
    if (form) {
      const fields = form.querySelectorAll<FormField>(
        'input, select, textarea'
      );

      return Array.from(fields).filter(
        field =>
          field.required && field.required === true && field.disabled === false
      );
    }
  }

  function getValidFields(fields: Array<FormField>) {
    return fields.filter((field, index, fields) => {
      // skip validation of hidden fields
      const { width, height } = field.getBoundingClientRect();
      if (width === 0 && height === 0) return true;

      switch (field.type) {
        case 'radio':
          const radioControlsSet = (fields as Array<HTMLInputElement>).filter(
            f => f.name === field.name
          );
          return radioControlsSet.some(radio => radio.checked);
        case 'checkbox':
          return (field as HTMLInputElement).checked;
        case 'file':
          const fileField = field as HTMLInputElement;
          const validationField = form.querySelector<HTMLInputElement>(
            '[data-r18-ui-validate-form-file-field]'
          );

          if (fileField.files.length) return true;

          if (validationField) {
            return !isEmpty(validationField.value);
          } else {
            return false;
          }
        default:
          if (!isUndefined(field.dataset.validateAs)) {
            let validateAs = field.dataset.validateAs.split('|');
            let isNegated = false;

            if (validateAs[0].startsWith('!')) {
              validateAs[0] = validateAs[0].slice(1);
              isNegated = true;
            }

            return VALIDATE_AS[validateAs[0]](
              field.value,
              isNegated,
              validateAs[1]
            );
          } else {
            return !isEmpty(field.value);
          }
      }
    }, true);
  }

  function setFieldState(field: FormField, isValid: boolean) {
    let parentLabel: HTMLLabelElement;
    /*
      Unlike most input types, radio inputs each have an independent label,
      and the set will be bracketed by a shared label tag. We want to mark the
      shared label, not the individual labels. Therefore we must use a different
      lookup pattern.
    */
    if (field.type === 'radio') {
      // generally, radios will be grouped within a Foundation menu…
      const parentMenu = findParentNodeByClass(field, 'menu');
      // …and the target label will be a previous sibling to that menu.
      if (parentMenu) {
        parentLabel = parentMenu.previousElementSibling as HTMLLabelElement;
      }
    } else {
      parentLabel = findParentNodeByTag(field, 'label') as HTMLLabelElement;
    }

    if (parentLabel && !parentLabel.classList.contains('not-required')) {
      parentLabel.classList.toggle('is-required', !isValid);
    }
  }

  function setFieldMessage(field: FormField) {
    if (!field.dataset.validateAs || hasMessage(field)) return;

    const parentLabel = field.parentElement;
    const validateAs = field.dataset.validateAs.split('|');
    let params: GenericObject = {};

    switch (validateAs[0]) {
      case 'match':
        const matchField = form.querySelector<FormField>(`#${validateAs[1]}`);
        if (matchField) {
          params.name = matchField.previousElementSibling.textContent;
        }
    }

    const message =
      field.dataset.validateError ||
      I18n.t(`validate_form.messages.${validateAs[0]}`, params);

    parentLabel.insertAdjacentHTML(
      'beforeend',
      `<span class="form-error">${message}</span>`
    );
  }

  function isValidUrl(value: string, isNegated: boolean) {
    return validateUrl(value) && isNegated !== true;
  }

  function isFieldMatch(
    value: string,
    isNegated: boolean,
    matchFieldId: string
  ) {
    const field = form.querySelector<FormField>(`#${matchFieldId}`);

    if (!field) return true;
    return field.value === value;
  }

  function isIncludedIn(value: string, isNegated: boolean, fieldId: string) {
    const field = form.querySelector<FormField>(`#${fieldId}`);
    if (!field) return false;

    const collection = JSON.parse(field.value);

    if (isNegated) return !collection.includes(value.toLowerCase());
    if (!isNegated) return collection.includes(value.toLowerCase());
  }

  function hasMessage(field: FormField) {
    return !isNull(field.nextElementSibling);
  }
}
