import * as React from 'react';
import { Button, Colors } from 'react-foundation';
import { throttle } from 'lodash-es';

import { lockScrolling } from '../../modules';
import { Overlay } from '../overlay/Overlay';
import { Icon } from '../common';
import * as Forms from '../common/forms';

export interface ButtonFormProps {
  slug: string;
  type: 'text' | 'icon';
  classes: string;
  label: string;
  formProps: Object;
  size?: string;
}

export interface ButtonFormState {
  overlayIsVisible: boolean;
}

/**
 * A common component which launches a slide-over panel when clicked.
 */
export class ButtonForm<
  P extends ButtonFormProps,
  S extends ButtonFormState
> extends React.Component<any, any> {
  public props: ButtonFormProps;
  public state: ButtonFormState;
  public lockScrolling = lockScrolling();

  constructor(props: ButtonFormProps) {
    super(props);

    this.state = {
      overlayIsVisible: false
    };

    this.toggleOverlay = this.toggleOverlay.bind(this);
  }

  public componentDidMount() {
    // set up event listener to cache scroll position while scrolling…
    window.addEventListener(
      'scroll',
      throttle(event => {
        window.requestAnimationFrame(() => {
          if (!this.state.overlayIsVisible) {
            this.lockScrolling.cachePosition();
          }
        });
      }, 500)
    );

    // …and then immediately cache an initial value
    this.lockScrolling.cachePosition();
  }

  public shouldComponentUpdate(
    nextProps: ButtonFormProps,
    nextState: ButtonFormState
  ) {
    /*
      Since this component lives with other DOM-centric scripting, we want to
      ensure it does not trigger unnecessary updates as these can incorrectly
      disable the scroll locking.
     */
    return nextState.overlayIsVisible !== this.state.overlayIsVisible;
  }

  public componentDidUpdate(
    prevProps: ButtonFormProps,
    prevState: ButtonFormState
  ) {
    this.lockScrolling.setState(this.state.overlayIsVisible);
  }

  public toggleOverlay(isVisible: boolean) {
    this.setState({
      overlayIsVisible: isVisible
    });
  }

  public renderForm(name: string, formProps: Object) {
    const forms: { [key: string]: any } = Forms;
    const FormComponent = forms[name];

    return (
      <FormComponent
        formSuccess={() => {
          // TODO: implement a toast message
          this.toggleOverlay(false);
        }}
        formFailure={() => {
          // TODO: implement error handling
        }}
        closeOverlay={() => {
          this.toggleOverlay(false);
        }}
        {...formProps}
      />
    );
  }

  public render() {
    const { slug, type, classes, size, label, formProps } = this.props;
    const { overlayIsVisible } = this.state;

    return (
      <React.Fragment>
        <button
          className={classes}
          onClick={() => {
            this.toggleOverlay(true);
          }}>
          {type === 'text' && <span>{label}</span>}
          {type === 'icon' && <Icon icon={label} size="medium" />}
        </button>
        <Overlay
          size={size}
          isVisible={overlayIsVisible}
          topPosition={
            overlayIsVisible ? this.lockScrolling.getScrollPosition() : 0
          }
          render={() => {
            return this.renderForm(slug, formProps);
          }}
        />
      </React.Fragment>
    );
  }
}
