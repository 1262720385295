import * as React from 'react';

import { executeGet } from '../../shared/utils';
import { Loading } from './Loading';
import { isFunction } from 'lodash-es';
import get from 'lodash-es/get';

interface DeferredLoadProps<T> {
  loadPath: string;
  render: (object: T) => React.ReactNode;
  cacheItem?: (id: number, item: T) => void;
}

interface DeferredLoadState<T> {
  isLoading: boolean;
  object: T;
}

export class DeferredLoad<T extends any> extends React.Component<
  DeferredLoadProps<T>,
  DeferredLoadState<T>
> {
  props: DeferredLoadProps<T>;
  state: DeferredLoadState<T>;

  constructor(props: DeferredLoadProps<T>) {
    super(props);

    this.state = {
      isLoading: true,
      object: undefined
    };
  }

  public componentDidMount() {
    if (!this.state.object) {
      this.getObject();
    }
  }

  public async componentDidUpdate(
    prevProps: DeferredLoadProps<T>,
    prevState: DeferredLoadState<T>
  ) {
    if (!this.state.object) {
      this.getObject();
    }
  }

  render() {
    const { object, isLoading } = this.state;

    if (!object || isLoading) {
      return (
        <div className="push--top push--bottom" style={{ overflow: 'hidden' }}>
          <Loading strokeColor="#a1a1a1" />
        </div>
      );
    }

    return this.props.render(object);
  }

  private async getObject() {
    const { loadPath, cacheItem: updateParent } = this.props;
    const object: T = await executeGet(loadPath);

    this.setState({ object, isLoading: false });

    if (isFunction(updateParent)) {
      const id = get(object, 'id') as number | undefined;

      if (id) {
        updateParent(id.toString(), object);
      }
    }
  }
}
