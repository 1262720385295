import * as React from 'react';

/**
 * Accepts a component with props and attaches additional props
 * before rendering.
 */
export const withProps = (newProps: any) => (
  WrappedComponent: React.ComponentClass<any> | React.StatelessComponent<any>
) => {
  const ComponentWithProps = (ownProps: any) => (
    <WrappedComponent {...ownProps} {...newProps} />
  );

  return ComponentWithProps;
};
