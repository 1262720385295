import * as React from 'react';
import { Button } from 'react-foundation';
import { format } from 'date-fns';
import { startsWith, isUndefined, random } from 'lodash-es';

import { DataPoint, Icon } from '../common';
import { STATES, componentClasses, formatDate } from '../../shared';

interface ImageUploadFieldProps extends ICustomField {
  file?: any;
  imagePreview?: string;
  onChange?: (state: ImageUploadFieldState) => void;
}

export interface ImageUploadFieldState {
  file: IFile;
  imagePreview: string;
  errorMessage: string;
}

export class ImageUploadField extends React.Component<
  ImageUploadFieldProps,
  ImageUploadFieldState
> {
  public props: ImageUploadFieldProps;
  public state: ImageUploadFieldState;
  private originalFile: IFile;
  private input: HTMLInputElement;
  private reader = new FileReader();

  constructor(props: ImageUploadFieldProps) {
    super(props);

    let { file, imagePreview } = this.props;
    const errorMessage = '';
    let initialState: ImageUploadFieldState;

    if (file) {
      const { fileObject } = file as ImageObj;
      const { fileName, downloadUrl } = fileObject;
      const { id } = file;
      const uploadType = 'local';

      this.originalFile = file;

      initialState = {
        file: {
          id,
          uploadType,
          fileName,
          downloadUrl
        },
        imagePreview: downloadUrl,
        errorMessage: ''
      };
    } else {
      initialState = this.initialState();
    }

    this.state = initialState;

    this.handleResetImage = this.handleResetImage.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: ImageUploadFieldProps) {
    const { file, imagePreview } = nextProps;

    if (!startsWith(imagePreview, 'data:image')) {
      this.originalFile = file;
    }

    this.setState({
      file,
      imagePreview
    });
  }

  public componentDidUpdate() {
    if (isUndefined(this.state.file) && this.input.files.length > 0) {
      this.input.value = null;
    }
  }

  public handleResetImage(event: React.MouseEvent<HTMLButtonElement>) {
    this.setState({
      file: this.originalFile,
      imagePreview: this.originalFile
        ? this.originalFile.downloadUrl
        : undefined
    });
  }

  public handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();

    const { onChange } = this.props;
    const file = event.target.files[0];

    if (file) {
      this.createPreviewImage(file).then(
        result => {
          this.setState(
            (prevState: ImageUploadFieldState) => {
              return Object.assign(prevState, {
                file: this.fileProps(file),
                imagePreview: result,
                errorMessage: ''
              });
            },
            () => {
              if (onChange) {
                onChange(this.state);
              }
            }
          );
        },
        errorMessage => {
          this.setState({ errorMessage });
        }
      );
    }
  }

  public render() {
    const { label, id, name } = this.props;
    const { file, imagePreview, errorMessage } = this.state;

    const buttonLabel = file ? 'Select New Image' : 'Attach An Image';

    return (
      <div
        className={componentClasses('file-upload', {
          single: true,
          image: true
        })}>
        <div className="file-upload__image">
          <div className="badge-wrapper">
            <div className="badge-wrapper__contents text-center">
              {!imagePreview && (
                <DataPoint
                  title={I18n.t('image_upload.labels.select_an_image')}
                  content={I18n.t('image_upload.labels.valid_formats')}
                  modifiers={{
                    staggered: true,
                    datapoint: false
                  }}
                />
              )}
              {imagePreview && <img src={imagePreview} />}
              {errorMessage !== '' && (
                <span className="text-alert">{errorMessage}</span>
              )}
            </div>
            {this.showResetButton() && (
              <div className="badge-wrapper__badge">
                <button
                  type="button"
                  className="file-upload-remove"
                  onClick={this.handleResetImage}>
                  <Icon icon="close" size="tiny" />
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="file-upload__controls">
          <label className={file ? STATES.isActive : ''}>
            {!file && label}
            {file && file.fileName}
          </label>
          <label htmlFor={id} className="button secondary hollow">
            {buttonLabel}
          </label>
          <input
            ref={input => (this.input = input)}
            type="file"
            name={name}
            id={id}
            className="file-upload__input show-for-sr"
            onChange={this.handleFileChange}
          />
        </div>
      </div>
    );
  }

  private initialState(): ImageUploadFieldState {
    return {
      file: undefined,
      imagePreview: '',
      errorMessage: ''
    };
  }

  private createPreviewImage(file: File) {
    return new Promise((resolve, reject) => {
      const regex = RegExp('image/(jpe?g|jpg|gif|png)', 'i');

      if (!regex.test(file.type)) {
        reject(I18n.t('image_upload.labels.invalid_format'));
      }

      this.reader.onloadend = () => resolve(this.reader.result);
      this.reader.readAsDataURL(file);
    });
  }

  private fileProps(file: File): IFile {
    return {
      id: random(10000, 20000),
      fileName: file.name,
      downloadUrl: '',
      uploadType: 'local',
      createdAt: format(new Date(file.lastModified), 'MM/DD/YYYY'),
      createdBy: ''
    };
  }

  private showResetButton() {
    // only show the reset if the current preview is base64 —
    // meaning it was added by the user but not yet saved
    return startsWith(this.state.imagePreview, 'data:image');
  }
}
