import classNames from 'classnames';
import * as React from 'react';
import { format, parse, addYears } from 'date-fns';
import DatePicker, { DatePickerProps } from 'react-date-picker';
import { isNull } from 'lodash-es';

import { Icon } from '../common';

export type DatePickerFieldChangeDetail = {
  field: HTMLInputElement;
  newValue: Date;
};

interface DatePickerFieldProps extends ICustomField {
  value: Date;
  datepickerProps?: Partial<DatePickerProps>;
  alignCalendar?: string;
  allowClear?: boolean;
  isStandalone?: boolean;
  handleDateChange(date: Date): void;
}

interface DatePickerFieldState {
  currentValue: Date;
}

export class DatePickerField extends React.Component<
  DatePickerFieldProps,
  DatePickerFieldState
> {
  inputField: HTMLInputElement;
  MIN_DATE: Date;
  MAX_DATE: Date;

  constructor(props: DatePickerFieldProps) {
    super(props);

    const { datepickerProps, isStandalone, value } = props;

    if (datepickerProps) {
      if (datepickerProps.minDate) {
        this.MIN_DATE = parse(datepickerProps.minDate);
      }

      if (datepickerProps.maxDate) {
        this.MAX_DATE = parse(datepickerProps.maxDate);
      }
    }

    if (isStandalone) {
      this.state = {
        currentValue: parse(value)
      };
    }

    // if a max date is not specified, restrict to five years from today.
    // this prevents users from entering dates which are far into the future,
    // which can confuse the application and cause breakage
    if (!this.MAX_DATE) {
      this.MAX_DATE = addYears(new Date(), 5);
    }
  }

  /**
   * Utility method to get the correct value based on
   * the context of the field.
   */
  get fieldValue() {
    const { isStandalone, value } = this.props;

    if (isStandalone) return this.state.currentValue;
    return value;
  }

  componentDidUpdate(
    prevProps: DatePickerFieldProps,
    prevState: DatePickerFieldState
  ) {
    if (this.props.isStandalone) {
      window.dispatchEvent(
        new CustomEvent('userInterface.validateForm', {
          detail: this.inputField
        })
      );
    }
  }

  handleChange = (date: Date) => {
    const { handleDateChange, isStandalone, name } = this.props;

    if (isStandalone) {
      this.setState({
        currentValue: date
      });

      window.dispatchEvent(
        new CustomEvent('DatePickerField.change', {
          detail: {
            newValue: date,
            field: this.inputField
          }
        })
      );
    } else {
      handleDateChange(date);
    }
  };

  render() {
    const {
      name,
      id,
      label,
      required,
      disabled,
      allowClear,
      alignCalendar,
      datepickerProps
    } = this.props;

    return (
      <>
        {label && (
          <label
            htmlFor={id}
            className={classNames({ 'is-required': required })}>
            {label}
          </label>
        )}
        <input
          ref={input => (this.inputField = input)}
          type="hidden"
          name={name}
          required={required}
          id={id}
          value={
            disabled || isNull(this.fieldValue)
              ? ''
              : format(this.fieldValue, 'YYYY-MM-DD')
          }
        />
        <DatePicker
          className={
            alignCalendar ? `react-date-picker--${alignCalendar}` : undefined
          }
          calendarIcon={<Icon icon="calendar" size="small" />}
          clearIcon={allowClear ? <Icon icon="remove" size="small" /> : null}
          disabled={disabled}
          prevLabel={<Icon icon="back" size="small" />}
          prev2Label={<Icon icon="arrow-left" size="medium" />}
          nextLabel={<Icon icon="forward" size="small" />}
          next2Label={<Icon icon="arrow-right" size="medium" />}
          onChange={this.handleChange}
          value={this.fieldValue}
          {...datepickerProps}
          minDate={this.MIN_DATE}
          maxDate={this.MAX_DATE}
        />
      </>
    );
  }
}
