import * as React from 'react';
import {
  Row,
  Column,
  Button,
  Label,
  Colors,
  Alignments
} from 'react-foundation';
import {
  random,
  isEqual,
  isEmpty,
  find,
  map,
  flatten,
  uniqueId,
  last,
  isUndefined,
  isFunction
} from 'lodash-es';

import {
  STATES,
  actionModelTitle,
  componentClasses,
  decodeString
} from '../../shared';
import { FileListing } from '../common';
import { FileUploadControl } from './FileUploadControl';

const { Tooltip } = Foundation;

interface FileUploadFieldProps extends ICustomField {
  files?: Array<IFile>;
  existingFiles?: Array<IFile>;
  isMultiple?: boolean;
  availableFileFormats?: string;
  showLabel?: boolean;
  canDeleteFiles?: boolean;
  fileListColumns?: number;
  resetFileFields?: boolean;
  hasBottomMargin?: boolean;
  accept?: string;
  flagNewFiles?: (hasNewFiles: boolean) => void;
  onChange?: (files: Array<IFile> | Array<string>, list?: string) => void;
}

interface FileUploadFieldState {
  [key: string]: any;

  fileType: string;
  existingFiles: Array<IFile>;
  newFileInputs: { [key: string]: JSX.Element };
  newFileInputsWithFiles: Array<string>;
}

export class FileUploadField extends React.Component<
  FileUploadFieldProps,
  FileUploadFieldState
> {
  static defaultProps = {
    hasBottomMargin: true
  };

  public props: FileUploadFieldProps;
  public state: FileUploadFieldState = {
    existingFiles: [],
    newFileInputs: {},
    newFileInputsWithFiles: [],
    fileType: undefined
  };
  private fileUploadFieldContainer: HTMLElement;
  private tooltip: any;
  private tooltipElement: HTMLElement;

  constructor(props: FileUploadFieldProps) {
    super(props);

    this.handleUpdateFileList = this.handleUpdateFileList.bind(this);
    this.handleRemoveFileInput = this.handleRemoveFileInput.bind(this);
    this.handleSetInputFilePresence =
      this.handleSetInputFilePresence.bind(this);
  }

  public componentDidMount() {
    this.setInputState(this.props);

    if (this.props.availableFileFormats && this.tooltipElement) {
      this.tooltip = new Tooltip($(this.tooltipElement));
    }
  }

  public componentDidUpdate() {
    if (
      this.props.availableFileFormats &&
      this.tooltipElement &&
      !this.tooltip
    ) {
      this.tooltip = new Tooltip($(this.tooltipElement));
    }

    // this triggers the `r18.validateForm' module, in case the field
    // is being used in a standalone manner
    window.dispatchEvent(
      new CustomEvent('userInterface.validateForm', {
        detail: this.fileUploadFieldContainer
      })
    );
  }

  public componentWillUnmount() {
    /*
      If the field unmounts, make sure we remove the tooltip
     */
    if (this.tooltip) {
      this.tooltip.destroy();
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: FileUploadFieldProps) {
    this.setInputState(nextProps);
  }

  public handleUpdateFileList(files: Array<IFile>, list: string) {
    this.setState(
      (prevState: FileUploadFieldState) => {
        return {
          [list]: files
        };
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange(this.state[list], 'files');
        }
      }
    );
  }

  public handleRemoveFileInput(id: string) {
    this.setState(
      (prevState: FileUploadFieldState) => {
        let newFileInputs = prevState.newFileInputs;

        delete newFileInputs[id];

        return {
          newFileInputs
        };
      },
      () => {
        const { flagNewFiles } = this.props;
        if (flagNewFiles) {
          flagNewFiles(!isEmpty(this.state.newFileInputs));
        }
      }
    );
  }

  public handleSetInputFilePresence(id: string, hasFile: boolean) {
    this.props.onChange?.(this.state.existingFiles, 'files');

    this.setState(
      (prevState: FileUploadFieldState) => {
        let { newFileInputsWithFiles } = prevState;

        if (hasFile === true) {
          newFileInputsWithFiles.push(id);
        } else {
          newFileInputsWithFiles = newFileInputsWithFiles.filter(i => i !== id);
        }

        return {
          newFileInputsWithFiles
        };
      },
      () => {
        if (isFunction(this.props.flagNewFiles)) {
          this.props.flagNewFiles(!isEmpty(this.state.newFileInputs));
        }
        if (isFunction(this.props.onChange)) {
          this.props.onChange(this.state.newFileInputsWithFiles, 'newFiles');
        }
      }
    );
  }

  public render() {
    const {
      name,
      label,
      isMultiple,
      accept,
      availableFileFormats,
      files,
      help,
      required,
      disabled,
      fileListColumns,
      hasBottomMargin,
      canDeleteFiles,
      flagNewFiles,
      showLabel
    } = this.props;
    const {
      newFileInputs,
      newFileInputsWithFiles,
      existingFiles,
      tooltipIsVisible,
      fileType
    } = this.state;

    let id: string;

    if (this.props.id) {
      id = this.props.id;
    } else if (this.props.name) {
      id = this.props.name.replace('[', '_').replace(']', '');
    } else {
      id = `fileUploadField_${random(10000, 20000)}`;
    }

    return (
      <div
        className={hasBottomMargin ? 'push--bottom' : ''}
        ref={container => (this.fileUploadFieldContainer = container)}>
        {showLabel && (
          <label className={!this.fieldIsValid() ? 'is-required' : undefined}>
            {help ? (
              <span className="two-tier two-tier--inline-centered">
                <span>{label}</span>
                {help}
              </span>
            ) : (
              <span>{label}</span>
            )}
          </label>
        )}
        {required && (
          <input
            name="hasFiles"
            type="hidden"
            required
            value={
              existingFiles?.length === 0 &&
              newFileInputsWithFiles?.length === 0
                ? ''
                : 'true'
            }
          />
        )}
        {!isMultiple && (
          <>
            <FileUploadControl
              {...{ name, id, accept, required, disabled, canDeleteFiles }}
              setInputFilePresence={this.handleSetInputFilePresence}
            />
            {availableFileFormats && (
              <span
                ref={e => (this.tooltipElement = e)}
                title={availableFileFormats}
                className="label hollow has-tip"
                aria-haspopup="true">
                {I18n.t('report_entry.labels.supported_file_formats')}
              </span>
            )}
            {existingFiles && existingFiles.length > 0 && (
              <div className="push--top">
                <hr />
                <label>Previously Uploaded File</label>
                <FileListing
                  files={existingFiles}
                  columns={fileListColumns || 2}
                  canDeleteFiles={canDeleteFiles}
                  openInNewWindow
                  updateList={file => {
                    this.handleUpdateFileList(file, 'existingFiles');
                  }}
                />
              </div>
            )}
            {existingFiles &&
              existingFiles.length === 0 &&
              newFileInputsWithFiles.length === 0 &&
              fileType === 'local' &&
              this.removeLocalUploadField()}
          </>
        )}
        {isMultiple && (
          <>
            <Row isCollapsed verticalAlignment={Alignments.MIDDLE}>
              <Column isShrunk>
                <button
                  type="button"
                  className="button secondary"
                  disabled={disabled}
                  onClick={() => {
                    this.setState(
                      (prevState: FileUploadFieldState) => {
                        let { newFileInputs } = prevState;
                        const fileInputId = uniqueId('fileUploadField_');

                        newFileInputs[fileInputId] = (
                          <FileUploadControl
                            key={fileInputId}
                            {...{ name, accept, required, canDeleteFiles }}
                            id={fileInputId}
                            setInputFilePresence={
                              this.handleSetInputFilePresence
                            }
                            removeFileInput={this.handleRemoveFileInput}
                          />
                        );

                        return {
                          newFileInputs
                        };
                      },
                      () => {
                        if (flagNewFiles) {
                          flagNewFiles(!isEmpty(this.state.newFileInputs));
                        }
                      }
                    );
                  }}>
                  {actionModelTitle('add', 'file')}
                </button>
              </Column>
              {availableFileFormats && (
                <Column>
                  <span
                    ref={e => (this.tooltipElement = e)}
                    title={availableFileFormats}
                    className="label hollow push--left has-tip"
                    aria-haspopup="true">
                    {I18n.t('report_entry.labels.supported_file_formats')}
                  </span>
                </Column>
              )}
            </Row>
            {newFileInputs && !isEmpty(newFileInputs) && (
              <div className="push--top">
                {map(newFileInputs, (input, id) =>
                  React.cloneElement(input, {
                    canDeleteFiles:
                      canDeleteFiles && Object.values(newFileInputs).length > 1
                  })
                )}
              </div>
            )}
            {existingFiles && existingFiles.length > 0 && (
              <div className="push--top">
                <hr />
                <label>
                  {I18n.t('global.labels.previously_updated_files')}
                </label>
                <FileListing
                  files={existingFiles}
                  columns={fileListColumns || 2}
                  canDeleteFiles={canDeleteFiles}
                  openInNewWindow
                  updateList={file => {
                    this.handleUpdateFileList(file, 'existingFiles');
                  }}
                />
              </div>
            )}
          </>
        )}
        {required && (
          <p
            className="help-text push--top-half t85"
            style={{ lineHeight: 1.2 }}>
            {isMultiple && 'This form requires at least one attachment. '}
            {!isMultiple && 'This form requires an attachment. '}
            Removing an existing file without attaching a new one may cause
            unexpected errors.
          </p>
        )}
      </div>
    );
  }

  private removeLocalUploadField() {
    const { name, existingFiles } = this.props;
    const { fileType } = this.state;

    if (fileType !== 'local') return null;

    const decodedName = decodeString(name);
    const fieldName = last(decodedName);

    return (
      <input
        type="hidden"
        name={name.replace(fieldName, `remove_${fieldName}`)}
        value="1"
      />
    );
  }

  private setInputState(props: FileUploadFieldProps) {
    const { existingFiles, resetFileFields } = props;
    let { newFileInputs, fileType, newFileInputsWithFiles } = this.state;

    if (resetFileFields) {
      newFileInputs = {};
      newFileInputsWithFiles = [];
    }

    if (existingFiles && existingFiles.length && isUndefined(fileType)) {
      fileType = existingFiles[0].uploadType;
    }

    this.setState({
      existingFiles,
      newFileInputsWithFiles,
      newFileInputs,
      fileType
    });
  }

  private fieldIsValid() {
    const { required } = this.props;
    const { newFileInputsWithFiles, existingFiles } = this.state;

    if (!required) return true;
    if (!isEmpty(existingFiles)) return true;
    return newFileInputsWithFiles.length > 0;
  }
}
