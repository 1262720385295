import * as React from 'react';
import { isUndefined } from 'lodash-es';

import { IAstmDesignation } from '../AstmDesignations';
import {
  actionModelTitle,
  createMarkup,
  processForm,
  fieldName
} from '../../../shared';
import { Form, FormProps, FormState } from '../../common/Form';
import { TextField } from '../../fields';
import { OverlayForm } from '../../common';

interface AstmDesignationFormProps extends FormProps<IAstmDesignation> {
  closeOverlay(): void;
}

interface AstmDesignationFormState extends FormState {}

export class AstmDesignationForm extends Form<
  AstmDesignationFormProps,
  AstmDesignationFormState
> {
  props: AstmDesignationFormProps;
  state: AstmDesignationFormState;
  form: HTMLFormElement;
  fName: (
    params: string | Array<string>,
    isMultiple?: boolean
  ) => string = fieldName('astm_code');

  constructor(props: AstmDesignationFormProps) {
    super(props);

    this.state = this.initialState();
  }

  public UNSAFE_componentWillReceiveProps(nextProps: AstmDesignationFormProps) {
    this.setState((prevState: AstmDesignationFormState) => {
      if (nextProps.currentItem) {
        const { title } = nextProps.currentItem;

        return Object.assign(this.initialState(), {
          title
        });
      } else {
        return this.initialState();
      }
    });
  }

  public render() {
    const {
      coreName,
      saveRoute,
      currentItem,
      managementContext,
      formSuccess,
      formFailure,
      closeOverlay,
      isAdminView
    } = this.props;

    const { title, isSubmitting } = this.state;

    const f = this.fName;

    return (
      <OverlayForm
        title={actionModelTitle(managementContext, coreName)}
        icon="inventory"
        saveRoute={currentItem ? currentItem.saveRoute : saveRoute}
        form={this.form}
        showCancelButton
        canSubmit={this.formIsValid() && !isSubmitting}
        handleSubmit={this.handleSubmit}
        updatesItem={!isUndefined(currentItem)}
        formSuccess={formSuccess}
        formFailure={formFailure}
        closeOverlay={closeOverlay}>
        <form ref={form => (this.form = form)}>
          {isAdminView && (
            <input type="hidden" name={f('admin')} value="true" />
          )}
          <TextField
            name={f('name')}
            label={I18n.t('global.labels.name')}
            value={title}
            required
            showLabel
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              this.handleTextChange('title', event.target.value);
            }}
          />
        </form>
      </OverlayForm>
    );
  }

  private initialState(): AstmDesignationFormState {
    return {
      title: '',
      isSubmitting: false
    };
  }
}
