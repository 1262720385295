/* === VENDOR LIBRARIES === */
import './vendor/jquery';
import 'jquery-ujs';
import 'ua-parser-js';
import './vendor/foundation';
import './vendor/jspdf';
import { fw } from 'framewerk';
import('select2').then(m => m.default());
import ReactOnRails from 'react-on-rails';

/* === BEGIN APPLICATION CODE === */
import './fonts/default_vfs';
import controllers from './controllers';
import { ButtonForm } from './components/common/ButtonForm';
import { Notifications } from './components/notifications/Notifications';
import { SearchForm } from './components/search/SearchForm';
import { UsersNinjaLogin } from './components/users/UsersNinjaLogin';
import { FileListing } from './components/common/FileListing';
import {
  DatePickerField,
  FileUploadField,
  ImageUploadField,
  SignatureField
} from './components/fields';

window.ReactOnRails = ReactOnRails;
window.jsPDF = jsPDF;

ReactOnRails.register({
  ButtonForm,
  DatePickerField,
  Notifications,
  SearchForm,
  FileUploadField,
  FileListing,
  ImageUploadField,
  SignatureField,
  UsersNinjaLogin
});

window.addEventListener('load', () => {
  $(document).foundation();
  fw(controllers).initialize();
});
