import * as React from 'react';
import { componentClasses } from '../../shared';

interface FilterControlProps {
  label: string;
  wrapLabel?: boolean;
  invertLayout?: boolean;
  render(): JSX.Element;
}

export const FilterControl = ({
  label,
  wrapLabel,
  invertLayout,
  render
}: FilterControlProps) => {
  return (
    <div
      className={componentClasses('filter-control', {
        'wrap-label': wrapLabel,
        'invert-layout': invertLayout
      })}>
      <label>{label}</label>
      {render()}
    </div>
  );
};
