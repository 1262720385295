import * as React from 'react';
import { Column as ReactTableColumn } from 'react-table';
import { isEmpty, merge, throttle } from 'lodash-es';
import matchSorter from 'match-sorter';

import { simpleClone, executeDelete } from '../../shared';

import { Table } from '../table';
import { Overlay } from '../overlay/Overlay';
import { ControlBar, PageTitle, Icon } from '.';
import { lockScrolling } from '../../modules';
import { executeGet } from '../../shared/utils';
import pickBy from 'lodash-es/pickBy';

const { rankings } = matchSorter;

export type UpdateItemOptions = {
  prop?: string;
  hideOverlay?: boolean;
  setAsCurrentItem?: boolean;
};

export type PrimarySectionData<T> = {
  [key: string]: Array<T & { [key: string]: any }>;
};

export type PrimarySectionRankings = Array<
  matchSorter.KeyOptions<any> | matchSorter.ExtendedKeyOptions<any>
>;

export interface PrimarySectionProps<T> extends IPrimarySection<T> {
  [key: string]: any;
  name?: string;
  availableFileFormats?: string;
  isLabSetup: boolean;
  deferLoading?: boolean;
  allowCreate?: boolean;
  hideTitle?: boolean;
  canDownload?: boolean;
  isAdminView?: boolean;
  labPreference?: ILabPreference;
  labPreferencePath?: string;
  updateParent?: (
    name: string,
    data: PrimarySectionData<T> | Array<ISubSection<any>>,
    forceUpdate?: boolean
  ) => void;
}

export interface PrimarySectionState<T> {
  currentSection: string;
  shouldUpdateParent: boolean;
  data: PrimarySectionData<T>;
  overlayIsVisible: boolean;
  overlaySize: IOverlaySizes;
  currentItem?: T;
  managementContext?: string;
  isLoadingData?: boolean;
  retrievedItems: Record<string, T>;
  labPreference?: ILabPreference;
  savingPreference?: keyof ILabPreference | null;
}

/**
 * `PrimarySection` is a common template for creating targeted sections of items, similar
 * to an index page within Rails. `PrimarySection` includes common structure for adding an
 * `Overlay`, managing grouped data structures, and processing forms. Any of these can be
 * overridden after extending the `PrimarySection` class.
 *
 * When extending `PrimarySection`, a base type is required; when extending `PrimarySectionProps`
 * and `PrimarySectionState`, that same base type must be provided. This ensures TypeScript
 * will accurately track that base type.
 *
 * The main purpose for `PrimarySection` is to render a page containing one or more ReactTable components,
 * organized into tabs. To this end, `PrimarySection` manages the column settings for the table,
 * the sections to render, and stores an instance of the created `Table` component.
 *
 * The `PrimarySection` can render two page components:
 *
 * - The title bar can be optionally rendered. A 'Back' route can be added by passing a URL
 *   to the `backRoute` prop of the component extending `PrimarySection`. call `super.render()`
 *   in the `render()` function to render this portion.
 * - An overlay can be added. Calling `super.renderOverlay()` and passing a component to that
 *   method will render it within an overlay wrapper and instantiate ncessary state. Note
 *   the method `toggleOverlay` on `PrimarySection` will toggle the overlay.
 *
 * @export
 * @class PrimarySection
 * @extends {React.Component<any, any>}
 */
export class PrimarySection<
  T,
  P extends PrimarySectionProps<T>,
  S extends PrimarySectionState<T>
> extends React.Component<any, any> {
  public props: PrimarySectionProps<T>;
  public state: Partial<PrimarySectionState<T>>;
  /**
   * Subsections within the Primary Section, accessible
   * via tabbed navigation.
   */
  public SECTIONS: Array<string>;
  /**
   * Column options used to initiate ReactTable.
   */
  public COLUMNS: Array<ReactTableColumn>;
  /**
   * Match-sorter rankings to apply to the global table search.
   */
  public RANKINGS: PrimarySectionRankings | Array<PrimarySectionRankings>;
  /**
   * Is this currently an admin view?
   */
  public IS_ADMIN_VIEW: boolean;
  /**
   * The `Table` component created by the component extending `PrimarySection`.
   */
  public table: Table;
  /**
   * The "core name" is a string representing a singular instance
   * of the thing being tracked within the `PrimarySection.`
   */
  public coreName: (count: number) => string;
  /**
   * An instance of the `lockScrolling` module.
   */
  public lockScrolling = lockScrolling();
  /**
   * A top margin value to assist with correct placement of the overlay.
   */
  private TOP_MARGIN: number;

  constructor(props: P) {
    super(props);

    const { isAdminView, sections, deferLoading } = this.props;
    const shouldDefer = deferLoading && !sections.length;

    this.IS_ADMIN_VIEW = isAdminView;
    this.SECTIONS = shouldDefer ? [] : sections.map(section => section.slug);
    this.TOP_MARGIN = this.lockScrolling.getTopMargin();

    this.state = {
      // when true, triggers an update via a parent API
      shouldUpdateParent: false,
      // whether or not the overlay is visible
      overlayIsVisible: false,
      // automatically set the first provided section as the current one, unless we're using deferred loading
      currentSection: this.SECTIONS[0],
      // convert provided lists into a set of key-array pairs, unless we're using deferred loading
      data: shouldDefer ? {} : PrimarySection.formatDataForState<T>(sections),
      // don't enforce a default size so individual components have control
      overlaySize: undefined,
      // if we are using deferred loading, this flag identifies that loading is occurring
      isLoadingData: false,
      // when using deferred loading, loaded items are cached locally to save on requests
      retrievedItems: {},
      savingPreference: null,
      labPreference: props.labPreference ?? {}
    };

    this.coreName = (count: number) =>
      I18n.t(`global.labels.${props.coreItem}`, { count });

    this.handleUpdateRetrievedItems =
      this.handleUpdateRetrievedItems.bind(this);
    this.handleEditItem = this.handleEditItem.bind(this);
    this.handleDeleteItem = this.handleDeleteItem.bind(this);
    this.addItem = this.addItem.bind(this);
    this.moveItem = this.moveItem.bind(this);
    this.toggleOverlay = this.toggleOverlay.bind(this);
    this.setOverlaySize = this.setOverlaySize.bind(this);
    this.handleFormSuccess = this.handleFormSuccess.bind(this);
    this.handleFormFailure = this.handleFormFailure.bind(this);
    this.setSection = this.setSection.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.handleToggleLabPreference = this.handleToggleLabPreference.bind(this);
  }

  public static formatDataForState<O = any>(sections: Array<ISubSection<O>>) {
    return sections.reduce((final: { [key: string]: Array<O> }, section) => {
      final[section.slug] = section.items;
      return final;
    }, {});
  }

  public componentDidMount() {
    window.addEventListener(
      'scroll',
      throttle(event => {
        window.requestAnimationFrame(() => {
          /*
            Only cache position if overlay is hidden and scrolling is unlocked.
            When scrolling is locked, scroll position will always be `0`.
          */
          if (!this.state.overlayIsVisible) {
            this.lockScrolling.cachePosition();
          }
        });
      }, 500)
    );

    // cache the scroll position right away for a baseline
    this.lockScrolling.cachePosition();

    if (
      this.props.deferLoading &&
      this.props.indexRoute &&
      !this.props.sections.length
    ) {
      this.setState({ isLoadingData: true }, () => {
        this.getData(this.props.indexRoute);
      });
    } else {
      // HACK: we need to immediately update the component to properly wire up any table row expanders
      this.forceUpdate();
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: PrimarySectionProps<T>) {
    nextProps.sections.forEach(section => {
      if (section.items.length !== this.state.data[section.slug].length) {
        this.setState({
          data: PrimarySection.formatDataForState<T>(nextProps.sections)
        });
      }
    });
  }

  public componentDidUpdate(
    prevProps: PrimarySectionProps<T>,
    prevState: PrimarySectionState<T>
  ) {
    const { name, updateParent } = this.props;
    const { data, overlayIsVisible, shouldUpdateParent } = this.state;

    if (
      prevState.overlayIsVisible !== overlayIsVisible &&
      overlayIsVisible !== undefined &&
      prevState.overlayIsVisible !== undefined
    ) {
      /*
        If the overlay is closing, we want to delay the scroll unlock
        until after it has finished moving offcanvas. This will prevent it
        from visually jumping on screen. If the overlay is opening, we want
        to lock scrolling immediately, for the same reason.
      */
      setTimeout(
        () => {
          this.lockScrolling.setState(overlayIsVisible);
        },
        overlayIsVisible ? 0 : 750
      );
    }

    if (shouldUpdateParent) {
      if (updateParent) {
        updateParent(name, data);
      }

      this.setState({
        shouldUpdateParent: false
      });
    }
  }

  /**
   * Generic handler to cache an item locally after deferred loading.
   *
   * @param id The id of the item to cache.
   * @param item The item to cache.
   */
  public handleUpdateRetrievedItems(id: number, item: T) {
    this.setState((prevState: PrimarySectionState<T>) => ({
      retrievedItems: {
        ...prevState.retrievedItems,
        [id.toString()]: item
      }
    }));
  }

  /**
   * Generic handler for setting up the UI to edit an item. Locates
   * the relevant item by id within the currently-visible section,
   * then loads it into the overlay and shows it with the correct context.
   *
   * @param {number} id The id of the item to modify.
   */
  public handleEditItem(id: number) {
    const { deferLoading } = this.props;
    const { currentSection, data, retrievedItems } = this.state;

    let item = data[currentSection].find(
      (i: T & { id: React.ReactText }) => i.id === id
    );

    /* If we're using deferred loading, we want to make sure we use the
       retrieved version as it's likely to have more data available.
     */
    if (deferLoading && retrievedItems[id]) {
      item = retrievedItems[id];
    }

    window.dispatchEvent(new CustomEvent('overlay.opened'));
    this.setState({
      currentItem: item,
      managementContext: 'edit',
      overlayIsVisible: true
    });
  }

  /**
   * Generic handler for deleting an item and updating the UI.
   *
   * @param {(T & { saveRoute: string })} item The item to delete.
   * @param {string} section The section to access.
   */
  public async handleDeleteItem(
    item: T & { saveRoute: string; id: React.ReactText },
    section: string
  ) {
    const { deferLoading } = this.props;

    const deletedItem = await executeDelete(item.saveRoute);

    if (isEmpty(deletedItem)) {
      // failure will return an empty array
    } else {
      this.setState((prevState: PrimarySectionState<T>) => {
        const { retrievedItems } = prevState;
        const data = simpleClone(prevState.data);

        data[section] = data[section].filter(
          (i: T & { id: React.ReactText }) => i.id !== deletedItem.id
        );

        return {
          data,
          shouldUpdateParent: true,
          retrievedItems: deferLoading
            ? pickBy(retrievedItems, (value, key) => {
                return key !== item.id.toString();
              })
            : retrievedItems
        };
      });
    }
  }

  /**
   * Generic handler for adding a new item to the UI.
   *
   * @param {any} item The item to add.
   * @param {string} section The section to add to.
   * @param {Function} [callback] A callback to fire once the item has been added.
   * @param {boolean} [hideOverlay=true] Should the overlay be hidden after the item is added?
   */
  public addItem(
    item: T & { id: React.ReactText },
    section: string,
    callback?: Function,
    hideOverlay: boolean = true
  ) {
    const { deferLoading } = this.props;
    const { retrievedItems } = this.state;

    this.setState(
      (prevState: PrimarySectionState<T>) => {
        const data = simpleClone(prevState.data);
        const overlayIsVisible =
          hideOverlay === false ? true : !prevState.overlayIsVisible;

        data[section].push(item);

        if (!overlayIsVisible) {
          window.dispatchEvent(new CustomEvent('overlay.closed'));
        }

        return {
          data,
          overlayIsVisible,
          retrievedItems: deferLoading
            ? {
                ...retrievedItems,
                [item.id.toString()]: item
              }
            : retrievedItems,
          shouldUpdateParent: true
        };
      },
      () => {
        if (callback && typeof callback === 'function') {
          callback(this.state);
        }
      }
    );
  }

  /**
   * A generic handler for updating an item within the current view.
   *
   * @param {any} item The item to update.
   * @param {string} section The section to search within.
   * @param {Function} [callback] A callback to fire once the item has been updated.
   * @param {GenericObject} [options={
   *       prop: 'id',
   *       hideOverlay: true,
   *       changeOverlay: true
   *     }] Options to pass to the update method.
   */
  public updateItem(
    item: T & { [key: string]: any },
    section: string,
    callback?: Function,
    options?: GenericObject
  ) {
    const { deferLoading } = this.props;
    const { retrievedItems } = this.state;

    options = merge(
      {
        prop: 'id',
        hideOverlay: true,
        changeOverlay: true,
        setAsCurrentItem: false
      },
      options
    );

    this.setState(
      (prevState: PrimarySectionState<T>) => {
        const { prop, hideOverlay, changeOverlay } = options;

        const data = simpleClone(prevState.data);
        let overlayIsVisible: boolean;

        if (changeOverlay) {
          if (hideOverlay === false) {
            overlayIsVisible = true;
          } else {
            overlayIsVisible = !prevState.overlayIsVisible;
          }
        }

        if (!overlayIsVisible) {
          window.dispatchEvent(new CustomEvent('overlay.closed'));
        }

        let itemIndex = data[section].findIndex(
          (itemToCompare: T & { [key: string]: any }) =>
            itemToCompare[prop] === item[prop]
        );

        data[section][itemIndex] = item;

        const currentItem = options.setAsCurrentItem
          ? item
          : prevState.currentItem;

        if (deferLoading) {
          retrievedItems[item.id.toString()] = item;
        }

        return {
          data,
          overlayIsVisible,
          currentItem,
          shouldUpdateParent: true,
          retrievedItems
        };
      },
      () => {
        if (callback && typeof callback === 'function') {
          callback(this.state);
        }
      }
    );
  }

  /**
   * A generic handler from moving an item from one section
   * to another section.
   *
   * @param item The item to move
   * @param prevSection The previous section
   * @param nextSection The new section
   */
  public moveItem(
    item: T & { [key: string]: any },
    prevSection: string,
    nextSection: string,
    hideOverlay: boolean = true,
    callback?: Function
  ) {
    this.setState(
      (prevState: PrimarySectionState<T>) => {
        let prevSectionData = simpleClone(prevState.data[prevSection]);
        let nextSectionData = simpleClone(prevState.data[nextSection]);

        prevSectionData = prevSectionData.filter(i => i.id !== item.id);

        if (nextSection !== prevSection) {
          nextSectionData = nextSectionData.concat(item);
        }

        const overlayIsVisible = !hideOverlay;
        let updatedData = simpleClone(prevState.data);

        updatedData[prevSection] = prevSectionData;
        updatedData[nextSection] = nextSectionData;

        if (!overlayIsVisible) {
          window.dispatchEvent(new CustomEvent('overlay.closed'));
        }

        return {
          data: updatedData,
          shouldUpdateParent: true,
          overlayIsVisible
        };
      },
      () => {
        if (callback) callback(this.state);
      }
    );
  }

  /**
   * A generic handler for managing a successful form transaction.
   *
   * @param item The item returned by the server.
   * @param callback An optional callback to fire after state has updated.
   */
  public handleFormSuccess(
    item: T & { id: React.ReactText },
    callback?: Function
  ) {
    const { managementContext, currentSection } = this.state;

    switch (managementContext) {
      case 'create':
      case 'new':
        this.addItem(item, currentSection, callback);
        break;
      case 'edit':
        this.updateItem(item, currentSection, callback);
        break;
    }
  }

  public handleFormFailure(data: any) {}

  public handleToggleLabPreference(event, callback?: Function) {
    const { labPreferencePath } = this.props;
    const { labPreference } = this.state;
    const { name, checked } = event.currentTarget as HTMLInputElement;

    if (!labPreferencePath) return;

    const params = new URLSearchParams({
      lab_preference: name,
      enable: checked ? '1' : '0'
    });

    this.setState(
      {
        savingPreference: name
      },
      () => {
        executeGet(`${labPreferencePath}?${params.toString()}`).then(
          response => {
            this.setState({
              labPreference: {
                ...labPreference,
                [name]: response[name]
              },
              savingPreference: null
            });

            if (callback) callback();
          }
        );
      }
    );
  }

  /**
   * Create and show the overlay with a specific context attached.
   *
   * @param {string} context Accepts 'create' and 'edit'
   */
  public getOverlayWithContext(context: string) {
    this.setState(
      (prevState: PrimarySectionState<T>) => {
        const managementContext = context;

        return {
          managementContext: managementContext,
          currentItem: context === 'new' ? undefined : prevState.currentItem
        };
      },
      () => {
        this.toggleOverlay(true);
      }
    );
  }

  /**
   * Dynamically adjust the size of the overlay.
   *
   * @param {string} size Accepts 'small', 'medium', 'large' or 'xlarge'
   */
  public setOverlaySize(size: IOverlaySizes) {
    this.setState({
      overlaySize: size
    });
  }

  /**
   * Toggle the visibility of the overlay.
   *
   * @param {boolean} [isVisible] Optionally force a particular state.
   */
  public toggleOverlay(isVisible?: boolean) {
    window.dispatchEvent(
      new CustomEvent(`overlay.${isVisible ? 'opened' : 'closed'}`)
    );
    this.setState((prevState: PrimarySectionState<T>) => {
      return {
        overlayIsVisible: isVisible || !prevState.overlayIsVisible
      };
    });
  }

  /**
   * Set the current section.
   *
   * @param {string} section The section slug.
   */
  public setSection(section: string) {
    this.setState((prevState: PrimarySectionState<T>) => {
      return Object.assign(prevState, {
        currentSection: section
      });
    });
  }

  /**
   * Renders the overlay, using provided content.
   *
   * @param {JSX.Element} content Content for the overlay.
   */
  public renderOverlay(content: JSX.Element) {
    const { overlayIsVisible, overlaySize } = this.state;
    const size = overlaySize || 'small';

    return (
      <Overlay
        isVisible={overlayIsVisible}
        topPosition={this.lockScrolling.getScrollPosition() - this.TOP_MARGIN}
        size={size}
        render={() => content}
      />
    );
  }

  render() {
    const { title, backRoute, isLabSetup } = this.props;

    const backTitle = this.props.backTitle || '';

    return (
      <>
        {backRoute && (
          <ControlBar>
            <a href={backRoute} className="button secondary clear">
              <Icon icon="back" />
              <span>
                {I18n.t('global.buttons.back_to', { title: backTitle })}
              </span>
            </a>
          </ControlBar>
        )}
        <PageTitle
          title={title}
          modifiers={[].concat(isLabSetup ? ['align-top', 'small'] : [])}>
          <Icon icon="help" size={isLabSetup ? 'medium' : 'large'} />
        </PageTitle>
      </>
    );
  }

  private async getData(route: string) {
    const { name, updateParent } = this.props;
    const rawData: Array<ISubSection<T>> = await executeGet(route);
    const data = PrimarySection.formatDataForState(rawData);
    const sectionNames = rawData.map(section => section.slug);

    this.SECTIONS = sectionNames;

    this.setState({
      data,
      currentSection: sectionNames[0],
      isLoadingData: false
    });

    if (updateParent) {
      updateParent(name, rawData, true);
    }
  }
}
