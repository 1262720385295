import * as React from 'react';
import { Row, Column, Menu, MenuItem } from 'react-foundation';
import { isNumber, isString, isUndefined, isEmpty, isNull } from 'lodash-es';

import { ITestMethod } from '../TestMethods';
import {
  actionModelTitle,
  createMarkup,
  processForm,
  fieldName
} from '../../../shared';
import { Form, FormProps, FormState } from '../../common/Form';
import {
  CheckboxField,
  CKEditorField,
  DatePickerField,
  FileUploadField,
  SelectField,
  TextField
} from '../../fields';
import { OverlayForm } from '../../common';

interface TestMethodFormProps extends FormProps<ITestMethod> {
  testMethodCategories: Array<SelectOption>;
  astmStandards: Array<SelectOption>;
  aashtoStandards: Array<SelectOption>;
  stateDesignations: Array<SelectOption>;
  closeOverlay(): void;
}

interface TestMethodFormState extends FormState {
  selectedTestMethodCategory: string;
  selectedAstmStandard: string;
  selectedAashtoStandard: string;
  selectedStateDesignation: string;
  other: string;
}

export class TestMethodForm extends Form<
  TestMethodFormProps,
  TestMethodFormState
> {
  props: TestMethodFormProps;
  state: TestMethodFormState;
  form: HTMLFormElement;
  fName: (
    params: string | Array<string>,
    isMultiple?: boolean
  ) => string = fieldName('test_method');

  constructor(props: TestMethodFormProps) {
    super(props);

    this.state = this.initialState();
  }

  public UNSAFE_componentWillReceiveProps(nextProps: TestMethodFormProps) {
    const {
      testMethodCategories,
      astmStandards,
      aashtoStandards,
      stateDesignations
    } = this.props;

    this.setState((prevState: TestMethodFormState) => {
      if (nextProps.currentItem) {
        const {
          title,
          category,
          aashto,
          astm,
          stateCode,
          other
        } = nextProps.currentItem;

        const selectedTestMethodCategory = testMethodCategories.find(
          o => o.label === category
        );
        const selectedAstmStandard = astmStandards.find(o => o.label === astm);
        const selectedAashtoStandard = aashtoStandards.find(
          o => o.label === aashto
        );
        const selectedStateDesignation = stateDesignations.find(
          o => o.label === stateCode
        );

        const nextState: Partial<TestMethodFormState> = {
          name: title,
          selectedTestMethodCategory: selectedTestMethodCategory
            ? selectedTestMethodCategory.value
            : undefined,
          selectedAstmStandard: selectedAstmStandard
            ? selectedAstmStandard.value
            : undefined,
          selectedAashtoStandard: selectedAashtoStandard
            ? selectedAashtoStandard.value
            : undefined,
          selectedStateDesignation: selectedStateDesignation
            ? selectedStateDesignation.value
            : undefined,
          other: other
        };

        return Object.assign(this.initialState(), nextState);
      } else {
        return this.initialState();
      }
    });
  }

  /**
   * Custom override for form validation to check if one of the following four values
   * has been completed: AASHTO, ASTM, State Code or Other.
   */
  public formIsValid() {
    const {
      selectedAashtoStandard,
      selectedAstmStandard,
      selectedStateDesignation,
      other
    } = this.state;

    return (
      super.formIsValid() &&
      [
        selectedAashtoStandard,
        selectedAstmStandard,
        selectedStateDesignation,
        other
      ].some(value => {
        if (isNull(value)) return false;

        if (isNumber(value)) {
          return !isNull(value);
        } else if (isString(value)) {
          return !isEmpty(value);
        } else {
          return !isUndefined(value);
        }
      })
    );
  }

  public render() {
    const {
      coreName,
      saveRoute,
      managementContext,
      currentItem,
      testMethodCategories,
      astmStandards,
      aashtoStandards,
      stateDesignations,
      isAdminView,
      formSuccess,
      formFailure,
      closeOverlay
    } = this.props;

    const {
      name,
      selectedTestMethodCategory,
      selectedAstmStandard,
      selectedAashtoStandard,
      selectedStateDesignation,
      other,
      isSubmitting
    } = this.state;

    const f = this.fName;

    return (
      <OverlayForm
        title={actionModelTitle(managementContext, coreName)}
        icon="inventory"
        saveRoute={currentItem ? currentItem.saveRoute : saveRoute}
        form={this.form}
        showCancelButton
        canSubmit={this.formIsValid() && !isSubmitting}
        handleSubmit={this.handleSubmit}
        updatesItem={!isUndefined(currentItem)}
        formSuccess={formSuccess}
        formFailure={formFailure}
        closeOverlay={closeOverlay}>
        <form ref={form => (this.form = form)}>
          {isAdminView && (
            <input type="hidden" name={f('admin')} value="true" />
          )}
          <Row>
            <Column small={12} medium={6}>
              <TextField
                name={f('name')}
                label={I18n.t('manage_lab.labels.test_method.name')}
                value={name}
                required
                showLabel
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  this.handleTextChange('name', event.target.value);
                }}
              />
            </Column>
            <Column small={12} medium={6}>
              <SelectField
                name={f('test_method_category_id')}
                label={I18n.t('manage_lab.labels.test_method.category')}
                options={testMethodCategories}
                value={selectedTestMethodCategory}
                placeholder={I18n.t(
                  'manage_lab.labels.test_method.placeholders.category'
                )}
                simpleValue
                joinValues
                showLabel
                required
                onChange={newOption => {
                  this.handleSelectChange(
                    'selectedTestMethodCategory',
                    newOption
                  );
                }}
              />
            </Column>
            <Column small={12} medium={6}>
              <SelectField
                name={f('aashto_code_text')}
                label={I18n.t(
                  'manage_lab.labels.test_method.aashto_designation'
                )}
                options={aashtoStandards}
                value={selectedAashtoStandard}
                placeholder={I18n.t(
                  'manage_lab.labels.test_method.placeholders.aashto_designation'
                )}
                simpleValue
                allowCustomEntry
                joinValues
                showLabel
                onChange={newOption => {
                  this.handleSelectChange('selectedAashtoStandard', newOption);
                }}
              />
            </Column>
            <Column small={12} medium={6}>
              <SelectField
                name={f('astm_code_text')}
                label={I18n.t('manage_lab.labels.test_method.astm_designation')}
                options={astmStandards}
                value={selectedAstmStandard}
                placeholder={I18n.t(
                  'manage_lab.labels.test_method.placeholders.astm_designation'
                )}
                simpleValue
                allowCustomEntry
                joinValues
                showLabel
                onChange={newOption => {
                  this.handleSelectChange('selectedAstmStandard', newOption);
                }}
              />
            </Column>
            <Column small={12} medium={6}>
              <SelectField
                name={f('state_code_text')}
                label={I18n.t(
                  'manage_lab.labels.test_method.state_designation'
                )}
                options={stateDesignations}
                value={selectedStateDesignation}
                placeholder={I18n.t(
                  'manage_lab.labels.test_method.placeholders.state_designation'
                )}
                simpleValue
                allowCustomEntry
                joinValues
                showLabel
                onChange={newOption => {
                  this.handleSelectChange(
                    'selectedStateDesignation',
                    newOption
                  );
                }}
              />
            </Column>
            <Column small={12} medium={6}>
              <TextField
                name={f('other')}
                label={I18n.t('manage_lab.labels.test_method.other')}
                value={other}
                showLabel
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  this.handleTextChange('other', event.target.value);
                }}
              />
            </Column>
          </Row>
        </form>
      </OverlayForm>
    );
  }

  /**
   * Returns an initial state for the form.
   */
  private initialState(): TestMethodFormState {
    return {
      name: '',
      selectedTestMethodCategory: undefined,
      selectedAstmStandard: undefined,
      selectedAashtoStandard: undefined,
      selectedStateDesignation: undefined,
      other: '',
      isSubmitting: false
    };
  }
}
