export const STATES = {
  canScroll: 'can-scroll',
  hasNoHeight: 'has-no-height',
  hasSpinner: 'has-spinner',
  hasOverlay: 'has-overlay',
  isActive: 'is-active',
  isCollapsed: 'is-collapsed',
  isDisabled: 'is-disabled',
  isHidden: 'is-hidden',
  isHovered: 'is-hovered',
  isLoading: 'is-loading',
  isRemoved: 'is-removed',
  isSpinning: 'is-spinning',
  isTransformed: {
    up: 'is-transformed-up',
    right: 'is-transformed-right',
    down: 'is-transformed-down',
    left: 'is-transformed-left'
  },
  isVisible: 'is-visible',
  isWorking: 'is-working'
};
