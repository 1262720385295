import * as React from 'react';
import { ConfirmAction } from '../ConfirmAction';
import { Icon } from '../Icon';
import { CheckboxField } from '../../fields';
import { createMarkup } from '../../../shared';

interface ConfirmSetNonIntervalFormProps {
  onChange(useProcedureInternals: boolean): void;
}

interface ConfirmSetNonIntervalFormState {
  useProcedureIntervals: boolean;
}

class ConfirmSetNonIntervalForm extends React.Component<
  ConfirmSetNonIntervalFormProps,
  ConfirmSetNonIntervalFormState
> {
  public props: ConfirmSetNonIntervalFormProps;
  public state: ConfirmSetNonIntervalFormState = {
    useProcedureIntervals: true
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      useProcedureIntervals: e.target.checked
    });
    this.props.onChange(e.target.checked);
  };

  render() {
    return (
      <>
        <p
          dangerouslySetInnerHTML={createMarkup(
            I18n.t('modals.set_non_interval.message_html')
          )}
        />
        <CheckboxField
          name="useProcedureInterval"
          label={I18n.t('modals.set_non_interval.title')}
          checked={this.state.useProcedureIntervals}
          onChange={this.handleChange}
        />
      </>
    );
  }
}

interface ConfirmSetNonIntervalProps {
  disabled: boolean;
  buttonLabel: string;

  action(useProcedureInterval: boolean): void;
}

interface ConfirmSetNonIntervalState {
  useProcedureIntervals: boolean;
}

export class ConfirmSetNonInterval extends React.Component<
  ConfirmSetNonIntervalProps,
  ConfirmSetNonIntervalState
> {
  public props: ConfirmSetNonIntervalProps;
  public state: ConfirmSetNonIntervalState = {
    useProcedureIntervals: true
  };

  handleSubmit = () => {
    this.props.action(this.state.useProcedureIntervals);
  };

  render() {
    const { disabled, buttonLabel } = this.props;

    return (
      <ConfirmAction
        id="setNonInterval"
        size="small"
        title={I18n.t('modals.set_non_interval.title')}
        message={
          <ConfirmSetNonIntervalForm
            onChange={(useProcedureIntervals: boolean) => {
              this.setState({ useProcedureIntervals });
            }}
          />
        }
        buttonProps={{
          classNames: 'button expanded secondary',
          disabled: disabled,
          label: buttonLabel
        }}
        actionButtonLabel={I18n.t(
          'equipment_procedures.buttons.save_and_create_form.main'
        )}
        action={this.handleSubmit}
        preserveModal
      />
    );
  }
}
