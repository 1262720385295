import * as React from 'react';
import { Menu, MenuItem } from 'react-foundation';
import { isUndefined, isEmpty } from 'lodash-es';

import { ILabPosition } from '../LabPositions';
import {
  createMarkup,
  processForm,
  fieldName,
  actionModelTitle
} from '../../../shared';
import { Form, FormProps, FormState } from '../../common/Form';
import {
  CheckboxField,
  CKEditorField,
  FileUploadField,
  TextField
} from '../../fields';
import { OverlayForm } from '../../common';

interface LabPositionFormProps extends FormProps<ILabPosition> {
  labId: number;
  closeOverlay(): void;
}

interface LabPositionFormState extends FormState {
  isManagement: boolean;
  description: string;
}

export class LabPositionForm extends Form<
  LabPositionFormProps,
  LabPositionFormState
> {
  props: LabPositionFormProps;
  state: LabPositionFormState;
  form: HTMLFormElement;
  fName: (
    params: string | Array<string>,
    isMultiple?: boolean
  ) => string = fieldName('lab_position');

  constructor(props: LabPositionFormProps) {
    super(props);

    this.state = this.initialState();
  }

  public UNSAFE_componentWillReceiveProps(nextProps: LabPositionFormProps) {
    this.setState((prevState: LabPositionFormState): LabPositionFormState => {
      return nextProps.currentItem || this.initialState();
    });
  }

  public render() {
    const {
      coreName,
      saveRoute,
      labId,
      managementContext,
      availableFileFormats,
      currentItem,
      formSuccess,
      formFailure,
      closeOverlay
    } = this.props;

    const {
      title,
      description,
      isManagement,
      files,
      isSubmitting,
      hasNewFiles
    } = this.state;

    const f = this.fName;

    return (
      <OverlayForm
        title={actionModelTitle(managementContext, coreName)}
        icon="inventory"
        saveRoute={currentItem ? currentItem.saveRoute : saveRoute}
        form={this.form}
        showCancelButton
        canSubmit={this.formIsValid() && !isSubmitting}
        handleSubmit={this.handleSubmit}
        updatesItem={!isUndefined(currentItem)}
        formSuccess={(item: ILabPosition) => {
          formSuccess(item, () => {
            this.setState(this.initialState());
          });
        }}
        formFailure={formFailure}
        closeOverlay={closeOverlay}>
        <form ref={form => (this.form = form)}>
          <input type="hidden" name={f('lab_id')} value={labId} />

          <TextField
            name={f('title')}
            label={I18n.t('manage_lab.labels.lab_positions.title')}
            value={title}
            className="push--bottom"
            showLabel
            required
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              this.handleTextChange('title', event.target.value);
            }}
          />
          <FileUploadField
            name={f('file')}
            label={I18n.t('manage_lab.labels.lab_positions.file')}
            showLabel
            availableFileFormats={availableFileFormats}
            fileListColumns={1}
            canDeleteFiles
            existingFiles={files}
            resetFileFields={!hasNewFiles}
            flagNewFiles={this.handleFlagNewFiles}
          />
          <CKEditorField
            name={f('description_text')}
            label={I18n.t('manage_lab.labels.lab_positions.description')}
            initialValue={description}
            onChange={(value: string) => {
              this.handleTextChange('description', value);
            }}
          />
          <CheckboxField
            name={f('management')}
            label={I18n.t('manage_lab.labels.lab_positions.part_of_management')}
            checked={isManagement}
            value="1"
            onChange={(event: React.SyntheticEvent<HTMLInputElement>) => {
              this.handleCheckboxChange(
                'isManagement',
                event.currentTarget.checked
              );
            }}
          />
        </form>
      </OverlayForm>
    );
  }

  private initialState(): LabPositionFormState {
    return {
      title: '',
      description: '',
      isManagement: false,
      files: [],
      isSubmitting: false,
      hasNewFiles: false
    };
  }
}
