import * as React from 'react';
import CKEditor from 'react-ckeditor-component';
import { isUndefined } from 'lodash-es';

import { STATES } from '../../shared';
import classNames from 'classnames';

interface CKEditorFieldProps extends ICustomField {
  resizable?: boolean;
  toolbar?: string | (string | string[])[];

  onChange?(value: string): void;
}

interface CKEditorFieldState {
  hasValue: boolean;
}

export class CKEditorField extends React.Component<CKEditorFieldProps> {
  static defaultProps = {
    resizable: true
  };
  public props: CKEditorFieldProps;
  public state: CKEditorFieldState = {
    hasValue: (this.props.initialValue?.length ?? 0) > 0
  };
  private editor: CKEDITOR.editor;

  public UNSAFE_componentWillReceiveProps(nextProps: CKEditorFieldProps) {
    if (this.editor && this.props.initialValue !== nextProps.initialValue) {
      this.setEditorState(nextProps.initialValue, nextProps.disabled);
    }
  }

  public render() {
    const {
      label,
      name,
      id,
      disabled,
      toolbar,
      initialValue,
      required,
      resizable,
      onChange
    } = this.props;

    const { hasValue } = this.state;

    let config: Partial<CKEDITOR.config> = {
      allowedContent: true,
      ignoreEmptyParagraph: true,
      fillEmptyBlocks: false,
      forceEnterMode: false,
      enterMode: CKEDITOR.ENTER_P,
      extraPlugins: 'nuke',
      resize_enabled: resizable,
      toolbar: toolbar || [
        [
          'Cut',
          'Copy',
          'Paste',
          'PasteText',
          'PasteFromWord',
          '-',
          'Undo',
          'Redo',
          '-',
          'Table',
          'HorizontalRule',
          'Smiley',
          'SpecialChar',
          'PageBreak',
          'Iframe',
          '-',
          'Nuke'
        ],
        [
          'Bold',
          'Italic',
          'Strike',
          '-',
          'RemoveFormat',
          'NumberedList',
          'BulletedList',
          '-',
          'Outdent',
          'Indent',
          '-',
          'Blockquote',
          'Link',
          'Unlink',
          'Anchor'
        ],
        ['Styles', 'Format']
      ]
    };

    if (disabled) {
      config.readOnly = true;
      config.removeButtons = 'toolbar';
    }

    return (
      <div className="ckeditor-field">
        {label && (
          <label
            htmlFor={id}
            key={`${id}_label`}
            className={classNames({ 'is-required': required && !hasValue })}>
            <span>{label}</span>
          </label>
        )}
        <textarea
          name={name}
          id={id}
          value={initialValue}
          className={STATES.isHidden}>
          {initialValue}
        </textarea>
        <CKEditor
          key={`${id}_ckeditor`}
          content={initialValue}
          isScriptLoaded={true}
          config={config}
          events={{
            change: (event: CKEDITOR.eventInfo) => {
              const newData = event.editor.getData();

              if (onChange && (newData !== initialValue || newData === '')) {
                onChange(event.editor.getData());
              }

              this.setState({
                hasValue: newData !== ''
              });
            },
            instanceReady: (event: CKEDITOR.eventInfo) => {
              this.editor = event.editor;
              this.setEditorState(this.props.initialValue, this.props.disabled);
              window.dispatchEvent(new CustomEvent('ckeditor:instanceReady'));
            }
          }}
        />
      </div>
    );
  }

  private setEditorState(content: string, disabled?: boolean) {
    if (this.editor.getData() !== content) {
      this.editor.setData(content);
    }

    this.editor.setReadOnly(disabled === true);
  }
}
