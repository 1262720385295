import { filter, findIndex, findLastIndex, isNaN, kebabCase } from 'lodash-es';
import { capitalizeFirstLetter } from '../shared';

/**
 * A module for applying updates to the Quality Manual page. Handles querying for the appropriate location
 * and making the necessary changes.
 *
 * @export
 * @param {('add'|'update'|'remove')} action The action to perform.
 * @param {HTMLElement} targetContainer Container to add, update or remove from
 * @param {Object} sectionData Data about the location of the specific section.
 * @param {[string]} newContent HTML to insert at an appropriate place.
 */
export function updateQualityManual(
  action: 'add' | 'update' | 'remove',
  targetContainer: HTMLElement,
  sectionData: string,
  newContent?: string
) {
  const runAction: { [key: string]: Function } = {
    add: addNewSection,
    update: updateSection,
    remove: removeSection
  };

  const parsedSectionData: QmSection = JSON.parse(sectionData);
  const { id } = parsedSectionData;
  const qmSections = targetContainer.querySelectorAll<HTMLElement>(
    'div[data-qm-section]'
  );
  const targetSectionIndex = getSectionIndex();

  let targetSection;
  let insertPosition;

  if (qmSections.length > 0) {
    if (targetSectionIndex === -1) {
      targetSection = qmSections[0].parentElement;
      insertPosition = 'afterbegin';
    } else {
      targetSection = qmSections[targetSectionIndex];
      insertPosition = 'afterend';
    }
  }

  runAction[action](
    targetSection,
    () => {
      ReactOnRails.reactOnRailsPageLoaded();
    },
    insertPosition
  );

  /**
   * Insert a new section into the manual, directly below the
   * target section.
   *
   * @param {HTMLElement | null} targetSection
   * @param {Function} cb Callback to execute once the operation is finished.
   * @param {boolean} insertPosition Where to add the element. Passed to `insertAdjacentElement`.
   */
  function addNewSection(
    targetSection: HTMLElement | undefined,
    cb: Function,
    insertPosition: InsertPosition = 'afterend'
  ) {
    const newSectionWrap = document.createElement('div');
    newSectionWrap.innerHTML = newContent;

    if (targetSection) {
      targetSection.insertAdjacentElement(
        insertPosition,
        newSectionWrap.firstChild as HTMLElement
      );
    } else {
      targetContainer.appendChild(newSectionWrap);
    }

    cb();
  }

  /**
   * Replace an existing section in the manual.
   *
   * @param {HTMLElement} targetSection
   * @param {Function} cb Callback to execute once the operation is finished.
   */
  function updateSection(targetSection: HTMLElement, cb: Function) {
    targetSection.outerHTML = newContent;
    cb();
  }

  /**
   * Remove a section from the manual.
   *
   * @param {HTMLElement} targetSection
   * @param {Function} cb Callback to execute once the operation is finished.
   */
  function removeSection(targetSection: HTMLElement, cb: Function) {
    const parentElement = targetSection.parentElement;
    parentElement.removeChild(targetSection);
    cb();
  }

  /**
   * Find the appropriate index of the section to append to,
   * replace or remove.
   *
   * @param {number} value The value to search against.
   * @param {string} property The property to index by.
   *
   * @return {number}
   */
  function getSectionIndex() {
    let existingIndex = findIndex(
      qmSections,
      section => section.dataset.qmSection === id.toString()
    );

    if (existingIndex !== -1) {
      return existingIndex;
    } else {
      return ['chapter', 'section', 'subsection', 'minorSubsection'].reduce(
        (
          finalIndex: number,
          current: string,
          loopIndex: number,
          keys: Array<string>
        ) => {
          const sectionsToSearch: Array<HTMLElement> = filter(
            qmSections,
            (section: HTMLElement, index) => index <= finalIndex
          );

          const subValue: number = parsedSectionData[current];

          if (!subValue) return finalIndex;

          const propertyKeysToExecute = keys.filter((k, i) => i <= loopIndex);

          return findLastIndex(sectionsToSearch, section => {
            return propertyKeysToExecute.reduce((result, execKey) => {
              return query(
                section,
                {
                  original: current,
                  kebab: kebabCase(current),
                  capitalized: capitalizeFirstLetter(current)
                },
                parsedSectionData[execKey]
              );
            }, true);
          });
        },
        qmSections.length - 1
      );
    }

    /**
     * Execute a query for a particular key and value against a section.
     *
     * @private
     *
     * @param section The current section.
     * @param keys Lookup keys.
     * @param value The value to check against.
     */
    function query(
      section: HTMLElement,
      keys: { [key: string]: string },
      value: any
    ) {
      const queryValue = Number(
        section.dataset[`qmSection${keys.capitalized}`]
      );

      // if this value is `NaN` it means the target property doesn't exist
      if (isNaN(queryValue)) {
        return true;
      } else {
        return queryValue <= value;
      }
    }
  }
}
