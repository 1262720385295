import _ from 'lodash';
import { STATES } from '../shared';

/**
 * The `uiDisable` module provides a method to disable one or more elements
 * on the page based on the value of a shared condition field.
 *
 * #### Operation
 *
 * After the page has completed initializing, the `uiDisable` module scans
 * the page for elements marked as disable targets. Each one will have at least
 * one reference id, which does not have to be unique; elements sharing a reference
 * id will be considered part of a group. Once gathered, the module then looks
 * for a condition input for each group. The condition input contains a pair
 * of values: the reference id of the group to manage, and the condition which,
 * if met, should disable the elements in that group.
 *
 * A disable target can have more than one reference key. If given more than one,
 * the target will disable if at least condition returns true. Similarly, a
 * condition field can manage more than one group, independently disabling
 * multiple groups with different requirements.
 *
 * #### Available Attributes
 *
 * - `data-r18-ui-disable`: A non-unique reference id used to specify groups of
 *   elements. Multiple ids can be provided, and should be pipe separated.
 * - `data-r18-ui-disable-condition`: A pipe-separated pair of strings containing
 *   instructions for the condition field. Multiple pairs can be provided,
 *   which should be comma separated. Each pair contains the following
 *   values:
 *     - A reference ID matching one or more disable targets on the
 *       current page.
 *     - A condition which, if true, will disable all fields in that group.
 *       Conditions are detailed below.
 *
 * #### Conditions
 *
 * Checkboxes and radio buttons accept the following conditions:
 *
 * - `checked` - Disables elements in its group when checked.
 * - `!checked` - Disables elements in its group when not checked.
 *
 * Text and hidden inputs accept the following conditions:
 *
 * - `length` - Returns true if the condition field has a value of any length
 * - `!length` - Returns true if the condition field has no value.
 * - `includes` - Specifically intended for `<select>` elements. Requires an
 *   additional value: a selector tag to match an input on the page containing
 *   an array of strings as a value. The module will determine if the selected
 *   value matches any value within that array, and return true if a match
 *   is found.
 *
 * #### Sample Usage
 *
 * ```html
 * <!-- This button will be disabled if the checkbox is checked. -->
 * <input type="checkbox" data-r18-ui-disable-condition="stopTheButton|checked" />
 * <button type="submit" data-r18-ui-disable="stopTheButton">Submit Form</button>
 *
 * <!-- This button will be disabled until the condition field has a value. -->
 * <input type="text" data-r18-ui-disable-condition="allowSubmit|length" />
 * <button type="submit" data-r18-ui-disable="allowSubmit">Submit Form</button>
 *
 * <!-- The text input is disabled if the user selects "1" or "4". -->
 * <select data-r18-ui-disable-condition="chosePoorly|includes|#wrongNumbers">
 *   <option value="1">1</option>
 *   <option value="2">2</option>
 *   <option value="3">3</option>
 *   <option value="4">4</option>
 *   <option value="5">5</option>
 * </select>
 * <input type="hidden" id="wrongNumbers" value="["1","4"]" />
 * <input type="text" data-r18-ui-disable="chosePoorly" />
 * ```
 *
 * #### Surface API
 *
 * The `uiDisable` module exposes two methods:
 *
 * - `init`: Initializes the module on the current page.
 * - `setState`: Sets a specified state on a jQuery selection.
 *
 * @namespace disable
 */
export function disable(container = document.body) {
  /**
   * Determine whether or not the element should be considered disabled.
   *
   * @param {jQuery[]} $conditions The conditional field(s) to check.
   *
   * @return {boolean} Returns `true` if the field should be disabled.
   */
  function shouldBeDisabled($conditions: Array<JQuery>): boolean {
    return $conditions.reduce((shouldDisable: boolean, $condition: JQuery) => {
      if (shouldDisable === true) return true;

      const conditionsToCheck: string[] = $condition
        .data('r18UiDisableCondition')
        .toString()
        .split(',');

      if ($condition.is(':checkbox, :radio')) {
        const isChecked = $condition.prop('checked');

        return conditionsToCheck.reduce((isDisabled, condition) => {
          const checkboxCondition = condition.split('|');

          return _.startsWith(checkboxCondition[1], '!')
            ? !isChecked
            : isChecked;
        }, shouldDisable);
      }

      return conditionsToCheck.reduce((isDisabled, condition) => {
        const stringCondition = condition.split('|');

        if (stringCondition[1]) {
          switch (stringCondition[1]) {
            case '!length':
              return _.isEmpty($condition.val());
            case 'length':
              return !_.isEmpty($condition.val());
            case 'includes':
              if (stringCondition[2]) {
                let testGroup: string[];

                try {
                  testGroup = JSON.parse($(stringCondition[2]).val() as string).map(val =>
                    val.toString()
                  );
                } catch (e) {
                  testGroup = [];
                }
                return _.includes(testGroup, $condition.val());
              } else {
                return false;
              }
            default:
              return $condition.val().toString() === stringCondition[1];
          }
        } else {
          return $condition.val() === 'true';
        }
      }, shouldDisable);
    }, false);
  }

  /**
   * Locates the related condition field for a disabled item.
   *
   * @inner
   * @protected
   * @param {string[]} ids One or more lookup strings.
   *
   * @returns {JQuery}
   */
  function getConditionFields(ids: string[]): Array<JQuery> {
    return ids.map(i => $(`[data-r18-ui-disable-condition*="${i}"]`));
  }

  function getInitConditionFields(ids: string[]): Array<JQuery> {
    return ids.map(i => $(`[data-r18-ui-init-disable-onchange*="${i}"]`));
  }

  /**
   * Sets a listener on a condition field to watch for changes.
   *
   * @inner
   * @protected
   * @param {jQuery} $target The element to affect.
   * @param {jQuery[]} $conditions The conditional field(s).
   */
  function setListeners($target: JQuery, $conditions: Array<JQuery>) {
    $conditions.forEach($condition => {
      $condition.on('change', () => {
        setState($target, shouldBeDisabled($conditions));
      });
    });
  }

  /**
   * Updates a target's data-r18-ui-disable field, and re-initializes State and Listeners
   *
   * @inner
   * @protexted
   * @param {jQuery} $target  The element to affect.
   * @param {jQuery[]} $conditions The fields that will modify data-r18-ui-disable when changed.
   */
  function setInitializers($target: JQuery, $conditions: Array<JQuery>) {
    $conditions.forEach($condition => {
      $condition.on('change', (event: JQueryEventObject) => {
        let disableConditions = $target.data('r18UiDisable');
        if (!$($condition[0]).is(':checked')) {
          if (
            $target
              .data('r18UiDisable')
              .split('|')
              .includes($condition.attr('data-r18-ui-init-disable-onchange'))
          ) {
            disableConditions = $target
              .data('r18UiDisable')
              .replace(
                `|${$condition.attr('data-r18-ui-init-disable-onchange')}`,
                ''
              );
          }
        } else {
          if (
            !$target
              .data('r18UiDisable')
              .split('|')
              .includes($condition.attr('data-r18-ui-init-disable-onchange'))
          ) {
            disableConditions =
              $target.data('r18UiDisable') +
              `|${$condition.attr('data-r18-ui-init-disable-onchange')}`;
          }
        }
        $target.data('r18UiDisable', disableConditions);
        initStateAndListeners($target, 'r18UiDisable');
      });
    });
  }

  /**
   * Set a disabled state on a target.
   *
   * @inner
   *
   * @param {JQuery} $target The target to affect.
   * @param {boolean} isDisabled Whether or not to disable.
   */
  function setState($target: JQuery, isDisabled: boolean) {
    $target.prop('disabled', isDisabled === true);
    $target.toggleClass(STATES.isDisabled, isDisabled === true);
    if (isDisabled && $target.is(':checkbox')) {
      $target.removeAttr('checked');
      $target.prop('checked', false);
    }
  }

  /**
   * Sets the target's disabled state and elements to listen for
   * @param {JQuery} $target The target to affect.
   * @param {string} type The data field to initialize on, either uiDisable or uiInitDisable.
   */
  function initStateAndListeners($target: JQuery, type: string) {
    const ids: string[] = $target.data(type).toString().split('|');

    if (type === 'r18UiInitDisable') {
      const $initConditions: Array<JQuery> = getInitConditionFields(ids);
      setInitializers($target, $initConditions);
    } else {
      const $conditions: Array<JQuery> = getConditionFields(ids);
      setState($target, shouldBeDisabled($conditions));
      setListeners($target, $conditions);
    }
  }

  /**
   * Initialize the module for a set of targets
   *
   * @inner
   */
  function init() {
    container = container || document.body;

    const $targets = $(container).find('[data-r18-ui-disable]');
    const $initTargets = $(container).find('[data-r18-ui-init-disable]');

    $targets.each((index, target) => {
      const $target = $(target);
      initStateAndListeners($target, 'r18UiDisable');
    });

    $initTargets.each((index, target) => {
      const $target = $(target);
      initStateAndListeners($target, 'r18UiInitDisable');
    });
  }

  return {
    init,
    setState
  };
}
