import * as React from 'react';
import { isEmpty } from 'lodash-es';
import { actionModelTitle } from '../../shared';

interface FileUploadControlProps {
  name: string;
  id: string;
  required?: boolean;
  disabled?: boolean;
  accept?: string;
  canDeleteFiles?: boolean;
  removeFileInput?: (id: string) => void;

  setInputFilePresence(id: string, hasFile: boolean): void;
}

interface FileUploadControlState {
  currentFileName: string;
}

export class FileUploadControl extends React.Component<
  FileUploadControlProps,
  FileUploadControlState
> {
  props: FileUploadControlProps;
  state: FileUploadControlState = {
    currentFileName: ''
  };
  fileInput: HTMLInputElement;

  constructor(props: FileUploadControlProps) {
    super(props);

    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleResetFile = this.handleResetFile.bind(this);
  }

  public handleFileChange() {
    const { id, setInputFilePresence } = this.props;
    const file = this.fileInput.files[0];

    this.setState(
      {
        currentFileName: file.name
      },
      () => {
        setInputFilePresence(id, true);
      }
    );
  }

  public handleResetFile() {
    const { id, setInputFilePresence } = this.props;
    this.fileInput.value = '';

    this.setState(
      {
        currentFileName: ''
      },
      () => {
        setInputFilePresence(id, false);
      }
    );
  }

  public render() {
    const { name, id, required, accept, disabled, canDeleteFiles, removeFileInput } =
      this.props;
    const { currentFileName } = this.state;

    return (
      <div className="file-upload file-upload--single">
        {!isEmpty(currentFileName) && <label>{currentFileName}</label>}
        <label
          htmlFor={id}
          className="button secondary hollow file-upload__select"
        >
          {actionModelTitle('select', 'file')}
        </label>
        {!isEmpty(currentFileName) && (
          <>
            <button
              type="button"
              className="button warning hollow"
              disabled={disabled}
              onClick={this.handleResetFile}
            >
              {I18n.t('global.buttons.clear_file_field')}
            </button>
          </>
        )}
        {removeFileInput && (
          <button
            type="button"
            className="button alert hollow"
            disabled={required && !canDeleteFiles}
            onClick={() => {
              if (removeFileInput) {
                removeFileInput(id);
              }
            }}
          >
            {actionModelTitle('remove', 'file')}
          </button>
        )}
        <input
          ref={input => (this.fileInput = input)}
          type="file"
          name={name}
          required={required}
          accept={accept}
          disabled={disabled}
          className="file-upload__input show-for-sr"
          id={id}
          onChange={this.handleFileChange}
        />
      </div>
    );
  }
}
