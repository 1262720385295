import * as React from 'react';
import { Link, Sizes, Colors, Row, Column } from 'react-foundation';

import {
  actionModelTitle,
  createMarkup,
  fieldName
} from '../../../../shared/utils';
import { ControlBar, DataPoint, Icon } from '../../../common';
import { LabDetailsFormState } from '../LabDetailsForm';
import { UpdateItemHandler, RemoveItemHandler } from './types';
import SubscriptionFieldset from './LabDetailsFormSubscriptionFieldset';

interface LabDetailsFormSubscriptionFieldsProps
  extends Pick<
    LabDetailsFormState,
    | 'subscriptionStartDate'
    | 'subscriptionEndDate'
    | 'subscriptionAmount'
    | 'subscriptionActivate'
    | 'subscriptionCancel'
    | 'subscriptionIsActive'
    | 'currentSubscriptions'
    | 'newSubscriptions'
  > {
  baseParam: string;
  addSubscription(): void;
  updateItem: UpdateItemHandler;
  removeItem: RemoveItemHandler;
}

const LabDetailsFormSubscriptionFields = (
  props: LabDetailsFormSubscriptionFieldsProps
) => {
  const {
    subscriptionStartDate,
    subscriptionEndDate,
    subscriptionAmount,
    subscriptionActivate,
    subscriptionCancel,
    subscriptionIsActive,
    currentSubscriptions,
    newSubscriptions,
    baseParam,
    addSubscription,
    updateItem,
    removeItem
  } = props;

  const f = fieldName(baseParam);

  const showSubscriptionFields =
    newSubscriptions.length > 0 || subscriptionIsActive;

  const subscriptionFieldsetProps = {
    subscriptionActivate,
    subscriptionCancel,
    subscriptionIsActive,
    baseParam,
    updateItem,
    removeItem
  };

  return (
    <>
      <ControlBar isAutoHeight>
        <p className="h4 flush--bottom">
          {I18n.t('subscription.labels.current')}
        </p>
        <Link
          size={Sizes.SMALL}
          color={Colors.SECONDARY}
          isHollow
          className="clear emphasis"
          onClick={addSubscription}>
          <Icon icon="add-small" size="very-small" />
          <span>{actionModelTitle('add', 'subscription')}</span>
        </Link>
      </ControlBar>
      {currentSubscriptions.length === 0 && newSubscriptions.length === 0 && (
        <DataPoint
          title={I18n.t('subscription.labels.has_no_current')}
          content={
            <em
              dangerouslySetInnerHTML={createMarkup(
                I18n.t('subscription.labels.click_to_add_html')
              )}
            />
          }
          modifiers={{
            staggered: true,
            html: true
          }}
        />
      )}
      <SubscriptionFieldset
        {...subscriptionFieldsetProps}
        subscriptions={currentSubscriptions}
      />
      {showSubscriptionFields && (
        <SubscriptionFieldset
          {...subscriptionFieldsetProps}
          subscriptions={newSubscriptions}
          startingIndex={currentSubscriptions.length}
        />
      )}
    </>
  );
};

export default LabDetailsFormSubscriptionFields;
