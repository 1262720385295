import { Controller } from 'framewerk';
import { render } from 'mustache';

import { ckEditor } from '../plugins';
import {
  STATES,
  findParentNodeByDataAttribute,
  kebabToCamel,
  dataSelector,
  saveDataToField
} from '../shared';

const { Tooltip } = Foundation;

/**
 * The initialization function for creating the `r18-lab-positions` Controller.
 */
export function labPositions(): Controller {
  /**
   * The name of the controller.
   */
  const name: string = 'r18-lab-positions';

  /**
   * Selector strings for the `r18-lab-positions` Controller.
   */
  const targets = Controller.getTargets({
    main: `div[data-controller="${name}"]`,
    addNew: `button[data-${name}-add-new]`,
    list: `ul[data-${name}-list]`,
    listItem: `li[data-${name}-list-item]`,
    edit: `button[data-${name}-edit]`,
    remove: `button[data-${name}-remove]`,
    toRemove: `input[data-${name}-to-remove]`,
    fieldTemplate: `script[data-${name}-field-template]`,
    headerControl: `[data-${name}-header-control]`,
    footerControl: `[data-${name}-footer-control]`
  });

  /**
   * Events created for the `r18-lab-positions` Controller.
   */
  const events = {
    windowLoad: () => {
      document.querySelectorAll(`.${STATES.hasNoHeight}`).forEach(element => {
        element.classList.remove(STATES.hasNoHeight);
        (element as HTMLElement).style.height = '0px';
      });
    },
    addNewClick: () => {
      targets.addNew.forEach(button => {
        button.addEventListener('click', event => {
          event.preventDefault();

          const newPosition = prepareNewPosition().body
            .childNodes[0] as HTMLElement;

          newPosition.style.opacity = '0';

          const editor = ckEditor(newPosition, () => {
            const newPositionEnclosure = newPosition.querySelector(
              `[data-${name}-list-item-enclosure]`
            ) as HTMLElement;

            window.requestAnimationFrame(() => {
              newPositionEnclosure.style.height = `${
                newPositionEnclosure.clientHeight
              }px`;
              newPosition.style.opacity = '1';

              const $tooltips = $(newPosition).find('[data-tooltip]');

              if ($tooltips.length) {
                const tooltip = new Tooltip($tooltips);
              }
            });
          });

          targets.list[0].appendChild(newPosition);
          targets.footerControl[0].classList.toggle(STATES.isHidden, true);
        });
      });
    },
    listEvents: () => {
      targets.list[0].addEventListener('keyup', (event: KeyboardEvent) => {
        if ((event.target as HTMLElement).tagName === 'INPUT') {
          updateTitle(event.target as HTMLInputElement);
        }
      });
      targets.list[0].addEventListener('click', (event: MouseEvent) => {
        if ((event.target as HTMLElement).tagName === 'BUTTON') {
          const target = event.target as HTMLButtonElement;
          const parentListItem = getListItem(target);

          if (`${kebabToCamel(name)}Edit` in target.dataset) {
            toggleListItemEdit(target, parentListItem);
          }

          if (`${kebabToCamel(name)}Remove` in target.dataset) {
            const editButtonTarget = parentListItem.querySelector(
              dataSelector(`${name}-remove`)
            ) as HTMLButtonElement;

            toggleListItemRemove(target, parentListItem);
            toggleListItemEdit(editButtonTarget, parentListItem, true);
          }
        }
      });
    }
  };

  /**
   * A count of the current number of positions currently visible.
   */
  let positionCount: number = targets.listItem.length;

  /**
   * An array of lab position ids which are flagged for removal.
   */
  let itemsToRemove: Array<string> = [];

  return new Controller({ name, targets, events });

  /////////////////

  /**
   * Mark a position for removal or restoration.
   *
   * @param {HTMLButtonElement} target
   * @param {HTMLElement} parentListItem
   */
  function toggleListItemRemove(
    target: HTMLButtonElement,
    parentListItem: HTMLElement
  ) {
    const listItemId = parentListItem.dataset[`${kebabToCamel(name)}ListItem`];

    if (listItemId === '') {
      positionCount--;

      // destroy relevant tooltips
      const $tooltips = $(parentListItem).find('[data-tooltip]');

      if ($tooltips.length) {
        $tooltips.foundation('destroy');
      }

      parentListItem.parentElement.removeChild(parentListItem);
      reindexNewItems();
    } else {
      const isBeingRemoved =
        target.innerHTML === I18n.t('global.buttons.remove');

      target.innerHTML = I18n.t(
        `global.buttons.${isBeingRemoved ? 'restore' : 'remove'}`
      );

      parentListItem.style.opacity = isBeingRemoved ? '.25' : '1';
      (parentListItem.querySelector(
        `[data-${name}-edit]`
      ) as HTMLButtonElement).disabled = isBeingRemoved;

      if (listItemId !== '') {
        itemsToRemove = isBeingRemoved
          ? itemsToRemove.concat([listItemId])
          : itemsToRemove.filter(id => id !== listItemId);
      }

      saveDataToField(itemsToRemove, targets.toRemove[0] as HTMLInputElement);
    }
  }

  /**
   * Toggle an individual position's edit state.
   *
   * @param {HTMLElement} parentListItem The item to toggle.
   * @param {boolean} [shouldHide] Force the item to hide.
   */
  function toggleListItemEdit(
    target: HTMLButtonElement,
    parentListItem: HTMLElement,
    shouldHide?: boolean
  ) {
    if (target && parentListItem) {
      const isBeingEdited = target.innerHTML === I18n.t('global.buttons.edit');
      const form = parentListItem.querySelector(
        `[data-${name}-list-item-form]`
      );
      const formParent = form.parentElement;

      target.innerHTML = I18n.t(
        `global.buttons.${isBeingEdited ? 'close' : 'edit'}`
      );

      target.classList.toggle('hollow', isBeingEdited);

      window.requestAnimationFrame(() => {
        togglePositionForm(formParent, shouldHide, form);
      });
    }
  }

  /**
   * Update the title of a position based on a provided input.
   *
   * @param {HTMLInputElement} target The triggering input.
   */
  function updateTitle(target: HTMLInputElement) {
    const parentListItem = getListItem(target);

    if (parentListItem) {
      const title = parentListItem.querySelector(
        `[data-${name}-list-item-title`
      );
      if (title) {
        title.innerHTML = target.value;
      }
    }
  }

  /**
   * Prepare and render a new list item for a Lab Position.
   */
  function prepareNewPosition() {
    const parser = new DOMParser();
    const template = targets.fieldTemplate[0].innerHTML;
    const index = positionCount + 1;
    const listItemTemplate = render(template, {
      uniqueId: new Date().getTime().toString(),
      index: index,
      indexLength: index.toString().length
    });
    const newPosition = parser.parseFromString(listItemTemplate, 'text/html');

    positionCount++;

    return newPosition;
  }

  /**
   * Get the parent list item element for any element located within it.
   */
  function getListItem(element: HTMLElement) {
    return findParentNodeByDataAttribute(element, `data-${name}-list-item`);
  }

  /**
   * Toggle visibility of a position's edit form.
   *
   * @param {HTMLElement} formParent
   * @param shouldHide
   * @param form
   */
  function togglePositionForm(
    formParent: HTMLElement,
    shouldHide: boolean,
    form: Element
  ) {
    formParent.style.height =
      formParent.clientHeight !== 0 || shouldHide
        ? '0px'
        : `${form.clientHeight}px`;
    form.classList.toggle(STATES.isTransformed.up, shouldHide);
  }

  function reindexNewItems() {
    const items = targets.main[0].querySelectorAll<HTMLElement>(
      `[data-${name}-list-item]`
    );
    const newItems = targets.main[0].querySelectorAll<HTMLElement>(
      `[data-${name}-new-list-item]`
    );

    const existingItemsCount = items.length - newItems.length;

    newItems.forEach((item, index) => {
      const badge = item.querySelector<HTMLElement>('.badge');

      if (badge) {
        badge.textContent = (index + 1 + existingItemsCount).toString();
      }
    });
  }
}
