import { random } from 'lodash-es';
import * as React from 'react';

import { componentClasses } from '../../shared';
import { Action } from '../common';
import { ListItem } from '../list-item/ListItem';

// import './List.scss';

interface ListProps {
  list: IList<any>;
  listStyle: string | Array<string>;
  listModifier?: string;
  isNumbered?: boolean;
  isWide?: boolean;
  color?: string;
  linkTarget?: string;
  showYear?: boolean;
  updateListData?: (items: Array<IAction>) => void;
  actionCallback?: (data: any, slug?: string) => void;
  children?: React.ReactNode;
}

/**
 * Stateless component to render a list of items. Lists have a defined style applied via
 * the `listStyle` prop. The following options are available:
 *
 * - `spaced`: Items have small spaces between them.
 * - `stacked`: Items have large spaces and borders between them. Text on the second
 *    line will have hidden overflow and no text wrap.
 * - `stacked-actions`: when added to a stacked list, will vertically stack any provided
 *    actions on the right side. Text will wrap within the remaining center space.
 * - `panel`: Items appear as individual panels.
 * - `columns`: Items are listed in two columns.
 */
const List = ({
  list,
  listStyle,
  listModifier,
  isWide,
  isNumbered,
  color,
  linkTarget,
  showYear,
  updateListData,
  actionCallback,
  children
}: ListProps) => {
  const listStyles = Array.isArray(listStyle) ? listStyle : [listStyle];
  const componentModifiers = listStyles.reduce((final: { [key: string]: boolean }, style) => {
    final[style] = true;

    return final;
  }, {});

  return (
    <ul className={componentClasses('list', componentModifiers)}>
      {list.items &&
        prepareListForRendering(list).items.map((item, index) => {
          return (
            <li key={`List_${list.slug}_${index}_${random(10000, 20000)}`} className="list__item">
              <ListItem
                itemStyle={listStyle}
                itemModifier={listModifier}
                {...{ index, isWide, isNumbered, updateListData, showYear, linkTarget, color }}
                {...item}
              />
            </li>
          );
        })}
      {children}
    </ul>
  );

  /**
   * Prepares the list for rendering by converting any objects found within
   * the `buttons` key array into clickable actions.
   *
   * @param {IListActions} list
   */
  function prepareListForRendering(list: IListActions) {
    const { title, slug } = list;

    list.items = list.items.map(item => {
      if (item.buttons) {
        item.actions = item.buttons.map((button, index) => {
          const actionProps = {
            title,
            index,
            button,
            slug,
            item,
            actionCallback
          };

          return <Action {...actionProps} />;
        });
      }

      return item;
    });

    return list;
  }
};

export { List };
