import { Controller } from 'framewerk';
import { debounce } from 'lodash-es';
import { exportOrgChart } from '../modules';
import {
  controllerSelector,
  executeFetch,
  findParentNodeByDataAttribute,
  initLibraries,
  requestTemplate,
  STATES
} from '../shared';

type LoadingState = 'waiting' | 'staged' | 'loading' | 'done';

interface QualityStandardDataAttributes {
  r18QualityStandardSection: string;
  r18QualityStandardSectionType: string;
  r18QualityStandardSectionRoute: string;
}

interface QualityStandardSection extends HTMLElement {
  dataset: DOMStringMap & QualityStandardDataAttributes;
}

/**
 * The initialization function for creating the `r18-quality-standard` Controller.
 */
export function qualityStandard(): Controller {
  // let debounceTimeout: (NodeJS.Timeout & number) | null = null;

  /**
   * Identifies which sections have been loaded, so we don't attempt to load them again.
   */
  const loadingState: Record<string, LoadingState> = {};

  /**
   * Proxy the `loadingState` object so we can intercept the `set` function
   * and execute a callback beforehand.
   */
  const loadingStateProxy = new Proxy(loadingState, {
    set(obj, prop: LoadingState, value) {
      console.info(`${prop}: dispatching setSectionStateClass`);
      setSectionStateClass(prop.toString(), value, obj[prop]);
      return Reflect.set(obj, prop, value);
    }
  });

  /** Shared observer to check for when to lazy-load sections. */
  const observer = new IntersectionObserver(handleObservationEntries, {
    threshold: [0, 1.0]
  });

  /**
   * The name of the controller.
   */
  const name: string = 'r18-quality-standard';

  /**
   * Factory method for creating selectors.
   */
  const selector = controllerSelector(name);

  /**
   * Selector strings for the `r18-quality-standard` Controller.
   */
  const targets = Controller.getTargets({
    section: selector('section'),
    orgChartExport: selector('org-chart-export'),
    remote: '[data-remote="true"]'
  });

  /**
   * Events created for the `r18-quality-standard` Controller.
   */
  const events = {
    windowLoad: () => {
      targets.remote.forEach(target => {
        $(target).on('ajax:before', event => {
          /*
            To generate the org chart export, we need to capture the rendered org chart
            from the browser and send it to the server so Prawn can inject it into
            its page templates. This requires intercepting the `data-remote` call on the
            appropriate button and handling the file download directly.
          */
          const url = (event.currentTarget as HTMLAnchorElement).href;
          const parentSection = findParentNodeByDataAttribute(
            event.currentTarget,
            'data-r18-quality-standard-section'
          );

          if (
            parentSection &&
            parentSection.dataset.r18QualityStandardSectionType === 'org_chart'
          ) {
            event.currentTarget.classList.add(STATES.isActive);
            handleExportWithOrgChart(url, getOrgChart()).then(() => {
              event.currentTarget.classList.remove(STATES.isActive);
            });
            // return false to cancel the Rails AJAX action
            return false;
          }
        });
      });

      // wait until the user has stopped scrolling before we init the requests.
      // this way we don't fire off a ton of requests if the user scrolls very quickly.
      window.addEventListener('scroll', debounce(fetchSectionContents, 500));

      if (targets.orgChartExport[0]) {
        targets.orgChartExport[0].classList.add('disabled');
      }

      window.addEventListener(
        'orgchart.loaded',
        () => {
          if (targets.orgChartExport[0]) {
            targets.orgChartExport[0].classList.remove('disabled');
          }
        },
        { once: true }
      );

      setObservers(true);
      setTimeout(fetchSectionContents, 500);
    }
  };

  return new Controller({ name, targets, events });

  /////////////////

  /**
   * Retrieves the HTML element for a specific QM section, using its
   * `ReportEntryTemplate` id from Rails.
   *
   * @param id The id of the section to find.
   */
  function getSectionTargetBySectionId(id: string) {
    return targets.section.find(
      (section: QualityStandardSection) =>
        section.dataset.r18QualityStandardSection === id
    ) as QualityStandardSection;
  }

  /**
   * Sets a state class on a section, and optionally removes a previously set
   * state class.
   *
   * @param id The `ReportEntryTemplate` id of the section to alter.
   * @param [state] A state to apply.
   * @param [previousState] A state to remove.
   */
  function setSectionStateClass(
    id: string,
    state?: LoadingState,
    previousState?: LoadingState
  ) {
    const PREFIX = 'status-';
    const section = getSectionTargetBySectionId(id);

    if (
      previousState &&
      section.classList.contains(`${PREFIX}${previousState}`)
    ) {
      section.classList.remove(`${PREFIX}${previousState}`);
    }

    if (state) {
      section.classList.add(`${PREFIX}${state}`);
    }
  }

  /**
   * Callback handler for `IntersectionObserver`.
   */
  function handleObservationEntries(entries: IntersectionObserverEntry[]) {
    entries.forEach(entry => {
      const section = entry.target as QualityStandardSection;
      const id = section.dataset.r18QualityStandardSection;
      const type = section.dataset.r18QualityStandardSectionType;

      if (loadingStateProxy[id] === 'done') return;

      if (entry.isIntersecting) {
        loadingStateProxy[id] = type === 'title' ? 'done' : 'staged';
      } else {
        if (loadingStateProxy[id] === 'staged') {
          loadingStateProxy[id] = 'waiting';
        }
      }
    });
  }

  /**
   * Sets up IntersectionObservers for each QualityStandardSection in targets.section array.
   *
   * @param {boolean} [isFirstCall=false] - Indicates if this is the first time the function is being called.
   * If true, sets the loading state of the section to 'waiting'.
   */
  function setObservers(isFirstCall = false) {
    targets.section.forEach((section: QualityStandardSection) => {
      const id = section.dataset.r18QualityStandardSection;

      if (isFirstCall) {
        loadingStateProxy[id] = 'waiting';
      }

      observer.unobserve(section);
      observer.observe(section);
    });
  }

  /**
   * Extracts the org chart from the QM page, converts it to Base64,
   * and executes a POST to the initial target URL with the converted
   * data attached.
   *
   * @param url The URL to POST to.
   * @param chart An element containing the rendered chart.
   */
  async function handleExportWithOrgChart(url: string, chart: HTMLElement) {
    /* Render the chart into images but return them instead of passing them to jsPDF.
         They will be returned as an array of Base64 strings. */
    const exportedChart = await exportOrgChart({ chart });
    // We create a new `FormData` and attach the Base64 images.
    const data = new FormData();

    data.append(
      'pages',
      JSON.stringify(exportedChart.map(chartPage => chartPage[0]))
    );

    return executeFetch(
      url,
      {
        body: data,
        method: 'POST'
      },
      false,
      false,
      true
    );
  }

  /**
   * Initializes fetch requests for all staged sections.
   */
  function fetchSectionContents() {
    const idsToFetch = Object.keys(loadingStateProxy).filter(
      key => loadingStateProxy[key] === 'staged'
    );

    idsToFetch.forEach(async id => {
      const section = getSectionTargetBySectionId(id);
      await fetchSectionContent(id, section);
      setObservers();
    });
  }

  /**
   * Fetch the rendered HTML content for the provided section and
   * inject it into the view.
   *
   * @param id The id of the section being fetched. Provided for convenience.
   * @param section A formatted QM section with data attributes
   */
  function fetchSectionContent(id: string, section: QualityStandardSection) {
    loadingStateProxy[id] = 'loading';

    return new Promise<void>(async (resolve, reject) => {
      const contentContainer = section.querySelector<HTMLDivElement>(
        selector('section-content-wrapper')
      );

      if (contentContainer) {
        contentContainer.innerHTML = await requestTemplate(
          section.dataset.r18QualityStandardSectionRoute,
          selector('section-content')
        );

        requestAnimationFrame(() => {
          initLibraries(contentContainer);

          const accordionMenu = contentContainer.querySelector(
            '[data-accordion-menu]'
          );

          if (accordionMenu) {
            accordionMenu
              .querySelectorAll(':scope > li')
              .forEach((element: HTMLElement) => {
                element.style.cssText = 'display: block !important; opacity: 1';
              });
          }

          resolve();
        });

        loadingStateProxy[id] = 'done';
      } else {
        reject();
      }
    });
  }

  /**
   * Gets the element containing the rendered org chart.
   */
  function getOrgChart() {
    return $('.orgchart:not(".hidden")').get(0);
  }
}
