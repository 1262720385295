import * as React from 'react';
import { Row, Column } from 'react-foundation';

import { CheckboxField, TextField } from '../../fields';
import { FormState } from '../../common';
import { fieldName } from '../../../shared';

interface SsoFormFieldsProps {
  baseParam: string;
  idp_cert: string;
  idp_entity_id: string;
  idp_sso_target_url: string;
  active_saml: boolean;
}

interface SsoFormFieldsState extends FormState {
  idp_cert: string;
  idp_entity_id: string;
  idp_sso_target_url: string;
  active_saml: boolean;
}

export class SsoFormFields extends React.Component<
  SsoFormFieldsProps,
  SsoFormFieldsState
  > {
  public props: SsoFormFieldsProps;
  public state: SsoFormFieldsState;
  private fName: (
    params: string | Array<string>,
    isMultiple?: boolean
  ) => string;

  constructor(props: SsoFormFieldsProps) {
    super(props);

    this.fName = fieldName(this.props.baseParam);

    this.state = {
      idp_cert: this.props.idp_cert,
      idp_entity_id: this.props.idp_entity_id,
      idp_sso_target_url: this.props.idp_sso_target_url,
      active_saml: this.props.active_saml
    };

    this.handleFieldChange = this.handleFieldChange.bind(this);
  }

  public componentDidUpdate(
    prevProps: SsoFormFieldsProps,
    prevState: SsoFormFieldsState
  ) {
    const {
      idp_cert,
      idp_entity_id,
      idp_sso_target_url,
      active_saml
    } = this.state;
  }

  public handleFieldChange<V>(key: string, value: V) {
    this.setState({
      [key]: value
    });
  }

  public render() {
    const {
      idp_cert,
      idp_entity_id,
      idp_sso_target_url,
      active_saml
    } = this.state;

    const f = this.fName;

    return (
      <React.Fragment>
        <Row>
          <Column small={12} medium={10}>
            <CheckboxField
              name={f(`active_saml`)}
              label={I18n.t('primary_lab.labels.allow_sso')}
              checked={active_saml}
              value="1"
              onChange={(event: React.SyntheticEvent<HTMLInputElement>) => {
                this.handleFieldChange(
                  'active_saml',
                  event.currentTarget.checked
                );
              }}
            />
          </Column>
        </Row>
        {active_saml && (
          <Row>
            <Column small={12}>
              <label htmlFor="idp_cert" className={ !idp_cert || idp_cert.length === 0 ? 'is-required' : ''} >
                  <span>
                    {I18n.t(
                      `primary_lab.labels.idp_cert`
                    )}
                  </span>
              </label>
              <textarea
                name={f('idp_cert')}
                id="idp_cert"
                value={idp_cert}
                required
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                  this.handleFieldChange('idp_cert', event.target.value);
                }}
              />
            </Column>
            <Column small={12}>
              <TextField
                name={f('idp_entity_id')}
                label={I18n.t(
                  `primary_lab.labels.idp_entity_id`
                )}
                value={idp_entity_id}
                showLabel
                required
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  this.handleFieldChange('idp_entity_id', event.target.value);
                }}
              />
            </Column>
            <Column small={12}>
              <TextField
                name={f('idp_sso_target_url')}
                label={I18n.t(
                  `primary_lab.labels.idp_sso_target_url`
                )}
                value={idp_sso_target_url}
                showLabel
                required
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  this.handleFieldChange('idp_sso_target_url', event.target.value);
                }}
              />
            </Column>
          </Row>
        )}
      </React.Fragment>
    );
  }
}
