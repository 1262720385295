import * as React from 'react';
import { ConfirmAction, ConfirmActionButtonProps } from '../ConfirmAction';
import { Icon } from '../Icon';
import { FileUploadField } from '../../fields';
import { processForm } from '../../../shared';
import { groupBy } from 'lodash-es';

interface ConfirmImportInventoryProps {
  uploadInventoryTemplateRoute: string;
  downloadInventoryTemplateRoute: string;
  setTemplateUploadResponse: (response: TemplateUploadResponse) => void;
}

interface ConfirmInventoryInterfaceState {
  isUploadingTemplate: boolean;
}

export interface TemplateUploadResponse
  extends Omit<RawTemplateUploadResponse, 'unsuccessful'> {
  unsuccessful: Record<string, Array<UploadLinkError>>;
}

interface RawTemplateUploadResponse {
  successful: number;
  unsuccessful: Array<UploadLinkError>;
  error?: string;
}

interface UploadLinkError {
  equipmentId: string;
  equipmentName: string;
  procedureId: number;
  procedureName: string | null;
}

export class ConfirmImportInventory extends React.Component<
  ConfirmImportInventoryProps,
  ConfirmInventoryInterfaceState
> {
  public props: ConfirmImportInventoryProps;
  public state: ConfirmInventoryInterfaceState;
  private importInventoryFormRef: HTMLFormElement;

  constructor(props: ConfirmImportInventoryProps) {
    super(props);

    this.state = {
      isUploadingTemplate: false
    };
  }

  renderForm() {
    const { downloadInventoryTemplateRoute } = this.props;

    return (
      <>
        <p>{I18n.t('inventory.labels.attach_inventory_csv_message')}</p>
        <p>
          <a href={downloadInventoryTemplateRoute} className="underline">
            {I18n.t('inventory.labels.download_template')}
          </a>
        </p>
        <FileUploadField
          name="inventory_document"
          id="inventory_csv_file"
          label={I18n.t('inventory.labels.attach_inventory_csv_file')}
          showLabel
        />
      </>
    );
  }

  handleSubmit = () => {
    const { setTemplateUploadResponse } = this.props;

    const form = this.importInventoryFormRef.cloneNode(true) as HTMLFormElement;
    this.setState(
      {
        isUploadingTemplate: true
      },
      () => {
        processForm(
          form.action,
          form,
          ({ successful, unsuccessful, error }: RawTemplateUploadResponse) => {
            this.setState({
              isUploadingTemplate: false
            });

            setTemplateUploadResponse({
              successful,
              unsuccessful: groupBy(unsuccessful, row => row.equipmentName),
              error
            });
          },
          ({ successful, error }: RawTemplateUploadResponse) => {
            this.setState({
              isUploadingTemplate: false
            });

            setTemplateUploadResponse({
              successful,
              unsuccessful: {},
              error
            });
          },
          { accept: 'text/html' }
        );
      }
    );
  };

  render() {
    const { uploadInventoryTemplateRoute } = this.props;

    return (
      <ConfirmAction
        id="importInventory"
        title={I18n.t('inventory.buttons.import_inventory')}
        message={
          <form
            action={uploadInventoryTemplateRoute}
            method="post"
            ref={form => (this.importInventoryFormRef = form)}>
            {this.renderForm()}
          </form>
        }
        buttonProps={{
          label: (
            <>
              <Icon icon="upload" />
              <span>{I18n.t('inventory.buttons.import_inventory')}</span>
            </>
          ),
          classNames: 'secondary clear'
        }}
        actionButtonLabel={I18n.t('global.labels.upload')}
        action={this.handleSubmit}
        preserveModal
      />
    );
  }
}
