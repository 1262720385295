import { isUndefined } from 'lodash-es';
import { lockScrolling } from '.';

import { select2 } from '../plugins';

import {
  createElementFromHTML,
  createOverlay,
  createOverlayContent,
  initLibraries,
  requestTemplate,
  STATES
} from '../shared';

interface OverlayOptions {
  title?: string;
  icon?: string;
  size?: string;
  url?: string;
}

interface OverlayPlugins {
  select2: Array<JQuery<HTMLElement>>;
}

/**
 * A module for creating overlays using remotely-loaded content.
 *
 * @export
 * @param {OverlayParams} props
 * @param {string} props.title The title of the overlay
 * @param {string} props.icon The icon to show next to the title.
 * @param {string} props.size The size of the overlay.
 * @param {string} props.url The URL to request a template from.
 *
 * @return {HTMLElement} Returns the created overlay.
 */
export function overlay({ title, icon, size, url }: OverlayOptions) {
  /**
   * Internal instance of the `lockScrolling` module.
   * @type {Object}
   */
  const scrolling = lockScrolling();

  /**
   * A top margin which should apply to the scrolling placement.
   */
  const topMargin = _setTopMargin();

  /**
   * Registered plugins for the current overlay interface.
   */
  let plugins: OverlayPlugins = {
    select2: []
  };

  return {
    create,
    destroy
  };

  /////////////////

  /**
   * Request content and build it into a new overlay.
   *
   * @return {HTMLElement} Returns the created overlay.
   */
  async function create() {
    scrolling.cachePosition();
    scrolling.setState(true);

    const overlay: HTMLElement | null = await _buildOverlay();

    const closeButtonHandler = (event: Event) => {
      let $eventTarget = $(event.target);

      if (isUndefined($eventTarget.attr('data-r18-ui-overlay-close'))) {
        $eventTarget = $eventTarget.parents('[data-r18-ui-overlay-close]');
      }

      if ($eventTarget.length && overlay) {
        event.preventDefault();
        event.currentTarget.removeEventListener('click', closeButtonHandler);

        window.dispatchEvent(new CustomEvent('overlay.adjustPosition'));
        window.requestAnimationFrame(() => {
          destroy(overlay);
          window.dispatchEvent(new CustomEvent('overlay.closed'));
        });
      }
    };

    if (overlay !== null) {
      window.addEventListener('click', closeButtonHandler);
      window.requestAnimationFrame(() => {
        document.body.appendChild(overlay);

        // register plugins and store references for later removal
        plugins = {
          select2: select2(overlay)
        };

        initLibraries(overlay);

        // allow a slight stagger so we can see the animation
        setTimeout(() => {
          _toggleOverlay(overlay);
        }, 150);

        // notify before the overlay opens so we can act on the event if necessary
        window.dispatchEvent(new CustomEvent('overlay.opened'));
      });
    }

    return overlay;
  }

  /**
   * Destroy the overlay instance.
   */
  function destroy(overlay: HTMLElement) {
    window.requestAnimationFrame(() => {
      _toggleOverlay(overlay);

      // wait until the animation is complete before removing the overlay
      setTimeout(() => {
        // deregister plugins to clean up the DOM
        plugins.select2.forEach($select => {
          $select.select2('destroy');
        });

        /*
          Some Foundation components create DOM fragments. While we can just call
          `$().foundation()` on the whole thing, evidently `$().foundation('destroy')
          doesn't work. Therefore we need to destroy some plugins individually.
        */
        const $tooltips = $(overlay).find('[data-tooltip]');

        if ($tooltips.length) {
          try {
            $tooltips.foundation('destroy');
          } catch (e) {}
        }

        overlay.parentElement.removeChild(overlay);
        scrolling.setState(false);
      }, 750);
    });
  }

  /**
   * Request content and contruct the overlay element.
   *
   * @return {HTMLElement}
   */
  async function _buildOverlay() {
    try {
      const template = await requestTemplate(url);
      const content = createOverlay(
        size,
        createOverlayContent(icon, title, template)
      );

      return createElementFromHTML(content);
    } catch (error: any) {
      scrolling.setState(false);
      const errorMessage = `
      <div class="callout small alert text-center" data-r18-ui-flash-message>
        ${I18n.t('global.labels.there_was_a_problem')}
      </div>
      `;
      document
        .querySelector('.nav-wrapper')
        ?.insertAdjacentHTML('beforeend', errorMessage);

      setTimeout(() => {
        $('.nav-wrapper [data-r18-ui-flash-message]').fadeOut(400);
      }, 2500);

      return null;
    }
  }

  /**
   * Toggle visibility of an overlay.
   *
   * @param {HTMLElement} overlay The overlay to toggle.
   */
  function _toggleOverlay(overlay: HTMLElement) {
    overlay.classList.toggle(STATES.isVisible);
  }

  /**
   * Set the top margin to account for scrolling.
   */
  function _setTopMargin() {
    const wrapper = document.querySelector<HTMLElement>('.wrapper');

    return wrapper
      ? parseInt(window.getComputedStyle(wrapper).paddingTop, 10)
      : 0;
  }
}
