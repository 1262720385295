import * as React from 'react';

import { executeFetch } from '../../shared';
import { OverlayContent } from '../overlay';
import { Form, FormProps, FormState } from '../common/Form';
import { MultiList } from '../multi-list/MultiList';
import { Loading } from '../common';

interface ISearchQuery extends IPrimarySectionItem {}

interface SearchFormProps extends FormProps<ISearchQuery> {
  searchRoute: string;
}

type SearchResult = {
  id: number;
  name: string;
  url: string;
};

interface SearchFormState extends FormState {
  searchFieldValue: string;
  isSearching: boolean;
  results: Array<SearchResult>;
}

export class SearchForm extends Form<SearchFormProps, SearchFormState> {
  props: SearchFormProps;
  state: SearchFormState = {
    searchFieldValue: '',
    isSearching: false,
    results: []
  };
  form: HTMLFormElement;

  constructor(props: SearchFormProps) {
    super(props);

    this.handleInitSearch = this.handleInitSearch.bind(this);
  }

  get searchResults(): IAction[] {
    const { protocol, host } = window.location;
    return this.state.results.map(result => {
      const url = new URL(`${protocol}//${host}${result.url}`);

      return {
        title: result.name,
        subtitle: url.pathname,
        url: url.href
      };
    });
  }

  public handleInitSearch() {
    const { searchRoute } = this.props;
    const { searchFieldValue } = this.state;

    this.setState(
      (prevState: SearchFormState) => {
        prevState.isSearching = true;
        prevState.results = [];

        return prevState;
      },
      async () => {
        try {
          const results = await executeFetch(
            `${searchRoute}?q=${searchFieldValue}`,
            {
              method: 'get'
            },
            true
          );

          this.setState({
            isSearching: false,
            results: results
          });
        } catch (error) {}
      }
    );
  }

  public render() {
    const { results, isSearching } = this.state;

    return (
      <div className="form">
        <div className="form__fields">
          <div className="input-group input-group--search">
            <input
              className="input-group-field"
              type="text"
              onChange={event => {
                this.handleTextChange('searchFieldValue', event.target.value);
              }}
              onKeyPress={event => {
                if (event.charCode === 13) {
                  this.handleInitSearch();
                }
              }}
            />
            <div className="input-group-button">
              <button
                type="button"
                className="button secondary"
                onClick={this.handleInitSearch}>
                {I18n.t('global.buttons.go')}
              </button>
            </div>
          </div>
          {isSearching && (
            <div className="search-form__loading">
              <Loading />
            </div>
          )}
          {results.length > 0 && (
            <div className="search-form__results">
              <MultiList
                lists={[
                  {
                    slug: 'searchResults',
                    title: 'Search Results',
                    items: this.searchResults
                  }
                ]}
                listStyle="stacked"
                isWide={false}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
