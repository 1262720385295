import * as React from 'react';
import { Row, Column } from 'react-foundation';

import { CheckboxField, DatePickerField } from '../../fields';
import { Form, FormProps, FormState } from '../../common/Form';
import { fieldName } from '../../../shared';
import { isValid, isDate, parse } from 'date-fns';

interface InspectionDateFormFieldsProps {
  baseParam: string;
  lastInspectionDate: string | Date;
  nextInspectionDate: string | Date;
  overrideInspectionDate: boolean;
}

interface InspectionDateFormFieldsState extends FormState {
  lastInspectionDate: Date;
  nextInspectionDate: Date;
  overrideInspectionDate: boolean;
}

export class InspectionDateFormFields extends React.Component<
  InspectionDateFormFieldsProps,
  InspectionDateFormFieldsState
> {
  public props: InspectionDateFormFieldsProps;
  public state: InspectionDateFormFieldsState;
  private fName: (
    params: string | Array<string>,
    isMultiple?: boolean
  ) => string;

  constructor(props: InspectionDateFormFieldsProps) {
    super(props);

    this.fName = fieldName(this.props.baseParam);

    this.state = {
      lastInspectionDate: this._assignDate(this.props.lastInspectionDate),
      nextInspectionDate: this._assignDate(this.props.nextInspectionDate),
      overrideInspectionDate: this.props.overrideInspectionDate
    };

    this.handleFieldChange = this.handleFieldChange.bind(this);
  }

  public componentDidUpdate(
    prevProps: InspectionDateFormFieldsProps,
    prevState: InspectionDateFormFieldsState
  ) {
    const {
      lastInspectionDate,
      nextInspectionDate,
      overrideInspectionDate
    } = this.state;

    if (prevState.overrideInspectionDate && !overrideInspectionDate) {
      this.setState({
        lastInspectionDate: new Date(),
        nextInspectionDate: new Date()
      });
    }
  }

  public handleFieldChange<V>(key: string, value: V) {
    this.setState({
      [key]: value
    });
  }

  public render() {
    const {
      lastInspectionDate,
      nextInspectionDate,
      overrideInspectionDate
    } = this.state;

    const f = this.fName;

    return (
      <Row>
        <Column small={12} medium={6}>
          <DatePickerField
            name={f('last_inspection_date')}
            id="lab_last_inspection_date"
            label={I18n.t('lab.labels.last_inspection_date')}
            value={overrideInspectionDate ? null : lastInspectionDate}
            required={!overrideInspectionDate}
            disabled={overrideInspectionDate}
            handleDateChange={(date: Date) => {
              this.handleFieldChange('lastInspectionDate', date);
            }}
          />
        </Column>
        <Column small={12} medium={6}>
          <DatePickerField
            name={f('next_inspection_date')}
            id="lab_next_inspection_date"
            label={I18n.t('lab.labels.next_inspection_date')}
            value={overrideInspectionDate ? null : nextInspectionDate}
            required={!overrideInspectionDate}
            disabled={overrideInspectionDate}
            handleDateChange={(date: Date) => {
              this.handleFieldChange('nextInspectionDate', date);
            }}
          />
        </Column>
        <Column small={12} medium={10}>
          <CheckboxField
            name={f(`override_inspection_date`)}
            label={I18n.t('primary_lab.labels.override_inspection_date.top')}
            subLabel={I18n.t(
              'primary_lab.labels.override_inspection_date.bottom'
            )}
            checked={!overrideInspectionDate}
            hiddenFieldValue="1"
            value="0"
            onChange={(event: React.SyntheticEvent<HTMLInputElement>) => {
              this.handleFieldChange(
                'overrideInspectionDate',
                !this.state.overrideInspectionDate
              );
            }}
          />
        </Column>
      </Row>
    );
  }

  private _assignDate(date: string | Date): Date {
    if (isDate(date)) return date as Date;

    const parsed = parse(date);

    return isValid(parsed) ? parsed : new Date();
  }
}
