import * as React from 'react';
import { Button, Sizes, Colors } from 'react-foundation';
import { RowInfo } from 'react-table';

import { Table } from '../table';

interface TableRowToggleProps {
  row: RowInfo;
  table: Table;
  toggle(event: any): void;
}

export const TableRowToggle = ({ row, table, toggle }: TableRowToggleProps) => (
  <Button
    size={Sizes.SMALL}
    color={Colors.SECONDARY}
    className="emphasis"
    isHollow
    isExpanded
    onClick={toggle}>
    {I18n.t(
      `global.buttons.${
        table && table.state.expanded[row.viewIndex] === true ? 'close' : 'view'
      }`
    )}
  </Button>
);
