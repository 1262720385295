import * as React from 'react';
import { Link, Sizes, Colors, Row, Column } from 'react-foundation';

import { LabDetailsFormState, Subscription } from '../LabDetailsForm';
import { UpdateItemHandler, RemoveItemHandler } from './types';
import { Icon } from '../../../common';
import { DatePickerField, CheckboxField, TextField } from '../../../fields';
import { fieldName } from '../../../../shared/utils';

interface LabDetailsFormSubscriptionFieldsetProps
  extends Pick<
    LabDetailsFormState,
    'subscriptionActivate' | 'subscriptionCancel' | 'subscriptionIsActive'
  > {
  subscriptions: Array<Subscription>;
  baseParam: string;
  updateItem: UpdateItemHandler;
  removeItem: RemoveItemHandler;
  startingIndex?: number;
}

const LabDetailsFormSubscriptionFieldset = (
  props: LabDetailsFormSubscriptionFieldsetProps
) => {
  const {
    subscriptionActivate,
    subscriptionCancel,
    subscriptionIsActive,
    subscriptions,
    startingIndex = 0,
    baseParam,
    updateItem,
    removeItem
  } = props;

  if (subscriptions.length === 0) return null;

  const f = fieldName(baseParam);

  return (
    <ul className="list list--panel">
      {subscriptions.map(
        (
          {
            id,
            startDate,
            endDate,
            amount,
            isActive,
            isExpired,
            isNew,
            toggle
          },
          index
        ) => {
          const groupName = isNew ? 'newSubscriptions' : 'currentSubscriptions';
          const rowIndex = index + startingIndex;

          return (
            <li className="list__item" key={`subFields_${rowIndex.toString()}`}>
              <div className="list-item list-item--panel">
                {!isNew && (
                  <input
                    type="hidden"
                    name={f(`subscriptions_attributes.${rowIndex}.id`)}
                    value={id}
                  />
                )}
                <Row>
                  <Column small={12} medium={6}>
                    <DatePickerField
                      name={f(
                        `subscriptions_attributes.${rowIndex}.start_date`
                      )}
                      id="lab_subscription_start_date"
                      label={I18n.t('subscription.labels.start_date')}
                      value={startDate}
                      required
                      handleDateChange={(date: Date) => {
                        updateItem(groupName, id, 'startDate', date);
                      }}
                    />
                  </Column>
                  <Column small={12} medium={6}>
                    <DatePickerField
                      name={f(`subscriptions_attributes.${rowIndex}.end_date`)}
                      id="lab_subscription_end_date"
                      label={I18n.t('subscription.labels.end_date')}
                      value={endDate}
                      required
                      alignCalendar="right"
                      handleDateChange={(date: Date) => {
                        updateItem(groupName, id, 'endDate', date);
                      }}
                    />
                  </Column>
                  <Column small={8} medium={3}>
                    <TextField
                      name={f(`subscriptions_attributes.${rowIndex}.amount`)}
                      value={amount.toString()}
                      label={I18n.t('subscription.labels.amount')}
                      isNumeric
                      minValue={0}
                      showLabel
                      required
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        updateItem(groupName, id, 'amount', event.target.value);
                      }}
                    />
                  </Column>
                  <Column small={4} medium={9} className="align-self-bottom">
                    {isNew && (
                      <Link
                        role="button"
                        color={Colors.ALERT}
                        size={Sizes.SMALL}
                        isHollow
                        className="emphasis push--bottom"
                        onClick={() => {
                          removeItem('newSubscriptions', id);
                        }}>
                        {I18n.t('global.buttons.remove')}
                      </Link>
                    )}
                    {isActive && (
                      <p className="icon-text text-success">
                        <Icon
                          icon="success-outline"
                          size="small"
                          classNames="icon--stroke-success"
                        />
                        <strong>
                          {I18n.t('subscription.labels.is_active')}
                        </strong>
                      </p>
                    )}
                  </Column>
                  <Column small={12}>
                    {!isActive && !isExpired && (
                      <CheckboxField
                        name={f(
                          `subscriptions_attributes.${rowIndex}.activate`
                        )}
                        label={I18n.t('subscription.labels.activate.top')}
                        subLabel={I18n.t('subscription.labels.activate.bottom')}
                        checked={toggle}
                        value="1"
                        onChange={(
                          event: React.SyntheticEvent<HTMLInputElement>
                        ) => {
                          updateItem(
                            groupName,
                            id,
                            'toggle',
                            event.currentTarget.checked
                          );
                        }}
                      />
                    )}
                    {isActive && (
                      <CheckboxField
                        name={f(
                          `subscriptions_attributes.${rowIndex}.canceled`
                        )}
                        label={I18n.t('subscription.labels.cancel.top')}
                        subLabel={I18n.t('subscription.labels.cancel.bottom')}
                        checked={toggle}
                        value="1"
                        onChange={(
                          event: React.SyntheticEvent<HTMLInputElement>
                        ) => {
                          updateItem(
                            groupName,
                            id,
                            'toggle',
                            event.currentTarget.checked
                          );
                        }}
                      />
                    )}
                  </Column>
                </Row>
              </div>
            </li>
          );
        }
      )}
    </ul>
  );
};

export default LabDetailsFormSubscriptionFieldset;
