import * as React from 'react';
import { Menu, MenuItem } from 'react-foundation';
import { isUndefined } from 'lodash-es';

import { IEquipmentCategory } from '../EquipmentCategories';
import {
  actionModelTitle,
  createMarkup,
  processForm,
  fieldName
} from '../../../shared';
import { Form, FormProps, FormState } from '../../common/Form';
import { TextField } from '../../fields';
import { OverlayForm } from '../../common';

interface EquipmentCategoryFormProps extends FormProps<IEquipmentCategory> {
  existingCategories: Array<string>;
  closeOverlay(): void;
}

interface EquipmentCategoryFormState extends FormState {
  title: string;
  originalTitle: string;
  displayTitle: string;
}

export class EquipmentCategoryForm extends Form<
  EquipmentCategoryFormProps,
  EquipmentCategoryFormState
> {
  props: EquipmentCategoryFormProps;
  state: EquipmentCategoryFormState;
  form: HTMLFormElement;
  fName: (
    params: string | Array<string>,
    isMultiple?: boolean
  ) => string = fieldName('equipment_category');

  constructor(props: EquipmentCategoryFormProps) {
    super(props);

    this.state = this.initialState();
  }

  public UNSAFE_componentWillReceiveProps(nextProps: EquipmentCategoryFormProps) {
    this.setState(
      (prevState: EquipmentCategoryFormState) => {
        if (nextProps.currentItem) {
          const { title, displayTitle } = nextProps.currentItem;

          return Object.assign(this.initialState(), {
            title,
            displayTitle,
            originalTitle: title
          });
        } else {
          return this.initialState();
        }
      },
      () => {
        this.forceUpdate();
      }
    );
  }

  public formIsValid() {
    return super.formIsValid() && !this.isExistingMatch();
  }

  public render() {
    const {
      coreName,
      saveRoute,
      currentItem,
      managementContext,
      formSuccess,
      formFailure,
      closeOverlay,
      isAdminView
    } = this.props;

    const { title, isSubmitting } = this.state;

    const f = this.fName;

    return (
      <OverlayForm
        title={actionModelTitle(managementContext, coreName)}
        icon="inventory"
        saveRoute={currentItem ? currentItem.saveRoute : saveRoute}
        form={this.form}
        showCancelButton
        canSubmit={this.formIsValid() && !isSubmitting}
        updatesItem={!isUndefined(currentItem)}
        handleSubmit={this.handleSubmit}
        formSuccess={formSuccess}
        formFailure={formFailure}
        closeOverlay={closeOverlay}>
        <form ref={form => (this.form = form)}>
          {isAdminView && (
            <input type="hidden" name={f('admin')} value="true" />
          )}
          <TextField
            name={f('name')}
            label={I18n.t('manage_lab.labels.equipment_category.name')}
            value={title}
            required
            showLabel
            error={
              this.isExistingMatch()
                ? I18n.t('manage_lab.errors.equipment_categories.not_unique')
                : undefined
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              this.handleTextChange('title', event.target.value);
            }}
          />
        </form>
      </OverlayForm>
    );
  }

  private isExistingMatch() {
    const { existingCategories, managementContext } = this.props;
    const { title, originalTitle } = this.state;

    const isMatch = existingCategories.some(
      category => category.toLowerCase() === title.toLowerCase()
    );

    if (managementContext === 'edit') {
      return isMatch && title !== originalTitle;
    }

    return isMatch;
  }

  private initialState(): EquipmentCategoryFormState {
    return {
      title: '',
      displayTitle: '',
      originalTitle: '',
      isSubmitting: false
    };
  }
}
