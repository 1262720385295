import * as React from 'react';
import { Row, Column } from 'react-foundation';
import { isNull } from 'lodash-es';

import { Icon, File } from '.';
import { asLink } from '../common/hoc';

interface FileListingProps {
  files: Array<IFile>;
  columns?: number;
  canDeleteFiles?: boolean;
  canDownload?: boolean;
  openInNewWindow?: boolean;
  isSimple?: boolean;
  useSoftDelete?: boolean;
  updateList?: (files: Array<IFile>) => void;
}

interface FileListingState {
  currentFiles: Array<IFile>;
}

export class FileListing extends React.Component<FileListingProps> {
  props: FileListingProps;
  state: FileListingState;

  constructor(props: FileListingProps) {
    super(props);

    this.state = {
      currentFiles: this.props.files || []
    };

    this.removeFile = this.removeFile.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: FileListingProps) {
    const { files } = nextProps;

    if (files) {
      this.setState({
        currentFiles: files
      });
    }
  }

  public render() {
    const { isSimple } = this.props;

    return isSimple
      ? this.renderSimpleFileListing()
      : this.renderFullFileListing();
  }

  private renderFullFileListing() {
    const {
      columns,
      canDeleteFiles,
      openInNewWindow,
      useSoftDelete
    } = this.props;
    const { currentFiles } = this.state;

    return (
      <Row upOnSmall={1} upOnMedium={columns || 1} className="full">
        {currentFiles.map((file, index) => {
          return !isNull(file) ? (
            <Column key={`file_list_${file.fileName}_${index}`} isColumn>
              <File
                file={file}
                canDeleteFile={canDeleteFiles}
                useSoftDelete={useSoftDelete}
                openInNewWindow={openInNewWindow}
                removeFile={this.removeFile}
              />
            </Column>
          ) : null;
        })}
      </Row>
    );
  }

  private renderSimpleFileListing() {
    const { canDownload } = this.props;
    const { currentFiles } = this.state;

    return (
      <ul className="file-list">
        {currentFiles.map((file, index) => {
          if (isNull(file)) return null;

          const {
            fileName,
            downloadUrl,
            createdAt,
            createdBy,
            removeUrl
          } = file;

          const FileItemName = () => <div>{fileName}</div>;
          const FileName = canDownload
            ? asLink(downloadUrl)(FileItemName)
            : FileItemName;

          return (
            <li
              key={`file_list_${fileName}_${index}`}
              className="file-list-item">
              <div className="file-list-item__icon">
                <Icon icon="reports" size="medium" />
              </div>
              <div className="file-list-item__body">
                <small>
                  {I18n.t('global.labels.file_created_by_and_at', {
                    date: createdAt,
                    name: createdBy
                  })}
                </small>
                <FileName />
              </div>
            </li>
          );
        })}
      </ul>
    );
  }

  private removeFile(file: IFile) {
    const { updateList } = this.props;
    const { currentFiles } = this.state;

    const updatedFiles = currentFiles.filter(f => f.id !== file.id);

    window.dispatchEvent(
      new CustomEvent('fileListing.updateFileList', { detail: updatedFiles })
    );

    this.setState({
      currentFiles: updatedFiles
    }, () => {
      if (updateList) {
        updateList(updatedFiles);
      }
    });
  }
}
