import "./assets/ckeditor/basePath";
import 'ckeditor';

/**
 * Initialize CKEditor on all `<textarea>` elements within a provided
 * container. Defaults to the entire document if no container is provided.
 * If a callback function is provided, it will execute once each instance
 * of CKEditor has completed initializing.
 *
 * @param {HTMLElement} [container=document] The container to initialize within.
 * @param {Function} [instanceReady] A callback to fire after initialization
 *
 * @return Returns an array of CKEditor instances
 */
export function initializeCKEditor(
  container: HTMLElement = document.body,
  instanceReady?: () => void
) {
  const textAreas = container.querySelectorAll<HTMLTextAreaElement>(
    'textarea[data-ckeditor]'
  );

  const config: Partial<CKEDITOR.config> = {
    allowedContent: true,
    ignoreEmptyParagraph: true,
    fillEmptyBlocks: false,
    forceEnterMode: false,
    enterMode: CKEDITOR.ENTER_P,
    extraPlugins: 'nuke',
    toolbar: [
      [
        'Cut',
        'Copy',
        'Paste',
        'PasteText',
        'PasteFromWord',
        '-',
        'Undo',
        'Redo',
        '-',
        'Table',
        'HorizontalRule',
        'Smiley',
        'SpecialChar',
        'PageBreak',
        'Iframe',
        '-',
        'Nuke'
      ],
      [
        'Bold',
        'Italic',
        'Strike',
        '-',
        'RemoveFormat',
        'NumberedList',
        'BulletedList',
        '-',
        'Outdent',
        'Indent',
        '-',
        'Blockquote',
        'Link',
        'Unlink',
        'Anchor'
      ],
      ['Styles', 'Format']
    ]
  };

  if (textAreas.length > 0) {
    return Array.from(textAreas).map((textArea: HTMLTextAreaElement) => {
      return CKEDITOR.replace(
        textArea,
        Object.assign(
          {},
          config,
          instanceReady
            ? {
                on: {
                  instanceReady
                }
              }
            : {}
        )
      );
    });
  }
}
