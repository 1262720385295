import * as React from 'react';
import { Button } from 'react-foundation';
import { format } from 'date-fns';
import { isEmpty } from 'lodash-es';

import { executeGet, createMarkup } from '../../shared';
import { ConfirmDeleteAction } from './confirm-actions';
import { Icon, Loading } from '.';

interface FileProps {
  file: IFile;
  className?: string;
  canDeleteFile?: boolean;
  useSoftDelete?: boolean;
  openInNewWindow?: boolean;
  removeFile?: (file: IFile) => void;
}

interface FileState {
  isBeingDeleted: boolean;
}

export class File extends React.Component<FileProps, FileState> {
  props: FileProps;
  state: FileState = {
    isBeingDeleted: false
  };

  handleDeleteFile = () => {
    const { useSoftDelete, removeFile, file } = this.props;
    const { removeUrl } = file;

    this.setState({
      isBeingDeleted: true
    }, async () => {
      if (useSoftDelete) {
        removeFile(file);
      } else {
        const removedFile = removeUrl ? await executeGet(removeUrl) : file;

        if (isEmpty(removedFile)) {
        } else {
          removeFile(removedFile);
        }
      }
    });
  };

  render() {
    const {
      file,
      className,
      canDeleteFile,
      useSoftDelete,
      openInNewWindow
    } = this.props;
    const { isBeingDeleted } = this.state;
    const { fileName, downloadUrl, createdAt, createdBy } = file;
    const buttonProps = {
      label: isBeingDeleted ? (
        <Loading size="medium" strokeColor="#d0021b" />
      ) : (
        <Icon icon="remove" size="medium" />
      ),
      classNames: '',
      excludeButtonCoreClass: true
    };

    return (
      <div className={`file ${className || ''}`}>
        <a
          href={downloadUrl}
          className="file-info"
          target={openInNewWindow ? '_blank' : undefined}>
          <div className="file-info__icon file-info__icon--download">
            <Icon icon="move-down" size="medium" />
          </div>
          <div className="file-info__details">
            <div className="file-details">
              <span className="file-details__owner">
                {createdAt && createdBy && `${createdAt} • ${createdBy}`}
                {!createdAt ||
                  (!createdBy && `${format(new Date(), 'MM/DD/YYYY')}`)}
              </span>
              <span className="file-details__title">{fileName}</span>
            </div>
          </div>
        </a>
        {canDeleteFile && (
          <div className="file-info file-info--action">
            <div className="file-info__icon file-info__icon--remove">
              {isBeingDeleted ? (
                <button disabled>{buttonProps.label}</button>
              ) : (
                <ConfirmDeleteAction
                  id={`file_${fileName}`}
                  title={fileName}
                  message={
                    <p
                      dangerouslySetInnerHTML={createMarkup(
                        I18n.t(
                          `modals.${useSoftDelete ||
                          'confirm_delete'}.message_html`,
                          {
                            item: I18n.t(
                              'global.labels.file.one'
                            ).toLowerCase(),
                            title: fileName
                          }
                        )
                      )}
                    />
                  }
                  itemType={I18n.t('global.labels.file.one')}
                  buttonProps={buttonProps}
                  action={this.handleDeleteFile}
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
