import { Controller } from 'framewerk';
import { format } from 'date-fns';
import { isEmpty, last } from 'lodash-es';

import {
  STATES,
  actionModelTitle,
  executeFetch,
  findParentNodeByDataAttribute,
  findParentNodeByTag,
  executePut,
  executeGet,
  decodeString
} from '../shared';
import { DatePickerFieldChangeDetail } from '../components/fields/DatePickerField';

/**
 * The initialization function for creating the `r18-technician-evaluation` Controller.
 */
export function technicianEvaluation(): Controller {
  /**
   * Manifest of all available field options.
   * @private
   */
  const FIELDS = ['astm', 'aashto', 'state', 'other'];

  /**
   * Private object for caching loaded data internally.
   * @private
   */
  let testsData: any = {};

  /**
   * The currently selected Test Method.
   * @private
   */
  let currentEntry: any;

  /**
   * The name of the controller.
   */
  const name: string = 'r18-technician-evaluation';

  /**
   * Selector strings for the `r18-technician-evaluation` Controller.
   */
  const targets = Controller.getTargets({
    control: `[data-${name}-control]`,
    field: `[data-${name}-field]`,
    form: `[data-${name}-form]`,
    editRecord: `[data-${name}-edit-record]`,
    editField: `[data-${name}-edit-field]`,
    testMethodsRoute: `[data-${name}-test-methods-route]`
  });

  /**
   * Events created for the `r18-technician-evaluation` Controller.
   */
  const events = {
    init: async () => {
      const activeFields: Record<string, boolean> = targets.control
        .filter((control: HTMLInputElement) => control.type === 'checkbox')
        .reduce(
          (final: Record<string, boolean>, control: HTMLInputElement) => ({
            ...final,
            [control.value]: control.checked
          }),
          {}
        );

      const controlValues: Record<string, any> = targets.control.reduce(
        (final: Record<string, any>, control: HTMLElement) => {
          switch (control.dataset.r18TechnicianEvaluationControl) {
            case 'testMethod':
            case 'testMethodCategory':
              final[
                control.dataset.r18TechnicianEvaluationControl
              ] = (control as HTMLSelectElement).value;
              break;
          }
          return final;
        },
        {}
      );

      if (
        controlValues.testMethodCategory.length &&
        controlValues.testMethod.length
      ) {
        await loadTests(
          controlValues.testMethodCategory,
          controlValues.testMethod
        );
      }

      targets.field.forEach(field => {
        if (field.dataset.r18TechnicianEvaluationField === 'comments') {
          (field as HTMLTextAreaElement).classList.toggle(
            STATES.isHidden,
            field.textContent === ''
          );
        } else {
          field.classList.toggle(
            STATES.isHidden,
            !activeFields[field.dataset.r18TechnicianEvaluationField]
          );
        }
      });
    },
    windowEvents: () => {
      window.addEventListener(
        'DatePickerField.change',
        ({ detail }: Event & { detail: DatePickerFieldChangeDetail }) => {
          handleUpdateTechnicianEvaluation(
            detail.field,
            format(detail.newValue, 'YYYY-MM-DD')
          );
        }
      );
    },
    buttonEvents: () => {
      targets.editRecord.forEach(button => {
        button.addEventListener('click', event => {
          (event.target as HTMLElement).classList.add(STATES.isActive);
        });
      });
    },
    formEvents: () => {
      targets.form[0].addEventListener('submit', event => {
        (event.target as HTMLElement).querySelector<HTMLButtonElement>(
          'button[type="submit"]'
        ).disabled = true;
      });

      targets.editField.forEach(field => {
        field.addEventListener('change', async event => {
          handleUpdateTechnicianEvaluation(event.target as HTMLInputElement);
        });
      });
    },
    controlEvents: () => {
      targets.control.forEach(control => {
        control.addEventListener('change', async event => {
          const eventTarget = event.target as HTMLElement;
          const controlName =
            eventTarget.dataset.r18TechnicianEvaluationControl;

          switch (controlName) {
            case 'testMethodCategory':
              await loadTests((eventTarget as HTMLSelectElement).value);
              break;
            case 'testMethod':
              setFields(eventTarget as HTMLSelectElement);
              break;
            case 'comments':
              toggleCommentsField((eventTarget as HTMLInputElement).checked);
              break;
            default:
              if (currentEntry) {
                setField(
                  controlName,
                  currentEntry,
                  !(eventTarget as HTMLInputElement).checked
                );
              }
          }
        });
      });
    }
  };

  return new Controller({ name, targets, events });

  /////////////////

  /**
   * Load test method data for a specified category, and set it in the view.
   *
   * @param {string} categoryId The category to load.
   * @param {string} [testMethodId]
   */
  async function loadTests(categoryId: string, testMethodId?: string) {
    const url = (targets.testMethodsRoute[0] as HTMLInputElement).value;
    const testMethodControl = getControl<HTMLSelectElement>('testMethod');

    testMethodControl.innerHTML = `
      <option value="">${I18n.t(
        'global.labels.loading.with_ellipses'
      )}</option>`;

    const data = await executeGet(url.replace(':id', categoryId));

    testMethodControl.innerHTML = '';
    testMethodControl.add(
      new Option(`${actionModelTitle('select', 'test')}…`, '', false, true)
    );

    for (let i = 0; i < data.length; i++) {
      const entry = data[i];

      testsData[entry.id] = entry;
      testMethodControl.add(
        new Option(
          entry.name,
          entry.id,
          false,
          testMethodId && Number(testMethodId) === entry.id
        )
      );
    }

    const changeEvent = new Event('change');
    testMethodControl.dispatchEvent(changeEvent);

    setFields(testMethodControl);
  }

  /**
   * Apply selected standards to the view.
   *
   * @param {HTMLSelectElement} testMethodsControl
   */
  function setFields(testMethodsControl: HTMLSelectElement) {
    currentEntry = testsData[testMethodsControl.value];

    if (!currentEntry) return;

    clearStandards();

    FIELDS.forEach(fieldName => {
      const control = getControl<HTMLInputElement>(fieldName);
      if (!control) return;

      setField(fieldName, currentEntry, !control.checked);
    });
  }

  /**
   * Set a value on a specific field. Also toggles visibility of that field
   * based on whether or not a value was provided; an empty string will
   * hide the field.
   *
   * @param {string} name The name of the field.
   * @param {Object} entry The current entry.
   * @param {string} emptyField Whether to set a value
   */
  function setField(name: string, entry: any, emptyField: boolean = true) {
    if (!entry) return;

    const fields = getFields(name);

    const fieldValues = {
      input: entry[name].id,
      display: !isEmpty(entry[name].name)
        ? entry[name].name
        : I18n.t('global.labels.n_a')
    };

    if (fields.length) {
      fields.forEach(field => {
        if (field.tagName === 'INPUT') {
          (field as HTMLInputElement).value = emptyField
            ? ''
            : fieldValues.input;
        } else {
          (field as HTMLElement).getElementsByTagName(
            'span'
          )[1].innerText = emptyField ? '' : fieldValues.display;
          field.classList.toggle(STATES.isHidden, emptyField);
        }
      });
    }
  }

  /**
   * Clear values from all standards fields.
   */
  function clearStandards() {
    FIELDS.forEach(field => {
      setField(field, '');
    });
  }

  /**
   * Locate a specific control based on the name of the control.
   *
   * @param {string} name
   *
   * @return {HTMLElement}
   */
  function getControl<T extends HTMLElement>(name: string): T {
    return Array.from<T>(targets.control as Array<any>).find(
      control => control.dataset.r18TechnicianEvaluationControl === name
    );
  }

  /**
   * Locate one or mow specific fields based on the name of the control.
   *
   * @param {string} name
   *
   * @return {HTMLElement[]}
   */
  function getFields(name: string) {
    return Array.from(targets.field).filter(
      control => control.dataset.r18TechnicianEvaluationField === name
    );
  }

  /**
   * Toggle visibility of the comments text field.
   *
   * @param {boolean} isVisible Should we see the field?
   */
  function toggleCommentsField(isVisible: boolean) {
    const commentField = getFields('comments');

    commentField[0].classList.toggle(STATES.isHidden, !isVisible);
  }

  /**
   * Prepares necessary params for executing a PUT against a technician
   * evaluation.
   *
   * @param {HTMLInputElement} input The source element of the change event.
   */
  function getParams(input: HTMLInputElement) {
    const tbody = findParentNodeByTag(input, 'tbody');

    if (!tbody) return null;

    const url = tbody.dataset.r18TechnicianEvaluationRecordUpdatePath;

    return {
      tbody,
      url,
      id: last(url.split('/')),
      name: last(decodeString(input.name))
    };
  }

  /**
   * Executes an asynchronous update of a technician evaluation. Currently only updates a single field,
   * which is derived from the event target element.
   *
   * @param {HTMLInputElement} input The source element of the change event.
   * @param {string} value The values to attach.
   */
  function handleUpdateTechnicianEvaluation(
    input: HTMLInputElement,
    value: string = input.value
  ) {
    const params = getParams(input);

    if (params) {
      const { url, id, name, tbody } = params;

      tbody.classList.add(STATES.isDisabled);

      executePut(
        url,
        JSON.stringify({
          id,
          [name]: value
        })
      ).then(() => {
        tbody.classList.remove(STATES.isDisabled);
      });
    }
  }
}
