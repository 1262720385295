import { Controller, fw } from 'framewerk';
import 'ckeditor';
import { addMinutes, format, parse, subMinutes } from 'date-fns';
import { forEach, last, startsWith, throttle } from 'lodash-es';
import { render } from 'mustache';

import controllers from '../controllers';
import {
  disable,
  exportOrgChart,
  fixedTable,
  lockScrolling,
  overlay,
  setAttr,
  updateQualityManual,
  validateForm
} from '../modules';
import { ckEditor, select2 } from '../plugins';
import {
  actionModelTitle,
  canScroll,
  executeFetch,
  findParentNodeByClass,
  findParentNodeByDataAttribute,
  getParameterByName,
  initLibraries,
  parseOptions,
  processForm,
  STATES
} from '../shared';

const { Reveal, SmoothScroll } = Foundation;

export function userInterface(
  container: HTMLElement = document.body
): Controller {
  /**
   * The name of the controller.
   */
  const name: string = 'r18-ui';

  /**
   * Internal instance of lockScrolling module.
   */
  const scrolling = lockScrolling();

  /**
   * Target selector strings.
   */
  const TARGETS = {
    fixedTable: `[data-${name}-fixed-table]`,
    setScrollPosition: `[data-${name}-set-scroll-position]`,
    smoothScroll: `[data-${name}-smooth-scroll]`,
    timeZone: `[data-${name}-time-zone]`
  };

  /**
   * Targets for the `r18-ui` Controller.
   */
  const targets = Controller.getTargets(TARGETS);

  /**
   * Events created for the `r18-ui` Controller.
   */
  const events = {
    initialize: () => {
      /*
        Set framewerk, registered controllers and plugins globally
        so we can access them via Rails UJS, which only has access
        to the global scope. Have to store an instance of jQuery as well
        since it's only available within modules. Additionally, we establish
        a few common utility methods.
      */
      window.R18 = {
        fw: fw,
        controllers: controllers,
        modules: {
          updateQualityManual
        },
        plugins: [select2, fixedTable],
        $: $,
        utils: {
          closeCurrentOverlay: () => {
            const $overlay = $('.overlay').last();

            if ($overlay.length) {
              const $closeButton = $overlay.find('[data-r18-ui-overlay-close]');

              if ($closeButton.length) {
                $closeButton[0].click();
              }
            }
          },
          executeFetch: executeFetch,
          renderErrors: (errors: GenericObject) => {
            const template = `
              <div class="callout small alert" data-r18-ui-submit-form-errors>
                <h5>Please correct the following errors:</h5>
                <ul>
                  {{#errors}}
                    <li>{{.}}</li>
                  {{/errors}}
                </ul>
              </div>
            `;

            return render(template, { errors });
          },
          toggleSubmitButtons: (form: HTMLFormElement) => {
            const submitButtons = form.querySelectorAll<
              HTMLInputElement | HTMLButtonElement
            >('[type="submit"]');
            submitButtons.forEach(button => {
              button.disabled = !button.disabled;
              button.value = button.dataset.disableWith;
            });
          },
          initDom: ($container: JQuery) => {
            $container = $container || $('.overlay').last();

            if ($container.length) {
              initLibraries($container[0]);
              _initPlugins($container[0]);

              // make sure we try to initialize UI as well, since the relevant controller
              // name might not be attached
              if ($container.find('[data-controller="r18-ui"]').length === 0) {
                _initUserInterface(
                  Controller.getTargets(TARGETS),
                  $container[0]
                );
              }
            }
          },
          reloadContentEvent: (detail: any) => {
            return new CustomEvent('reloadContent', {
              detail
            });
          }
        }
      };

      _initPlugins();
      _initUserInterface(targets);
    },
    canScrollEvents: () => {
      window.removeEventListener('resize', handleCanScrollResize);
      window.addEventListener('resize', throttle(handleCanScrollResize, 200));

      handleCanScrollResize();

      function handleCanScrollResize() {
        const targets = container.querySelectorAll('[data-r18-ui-can-scroll]');

        if (targets.length > 0) {
          targets.forEach((target: HTMLElement) => {
            target.classList.toggle(STATES.canScroll, canScroll(target));
          });
        }
      }
    },
    fileUploadEvents: () => {
      container.removeEventListener('change', handleFileUploadChange);
      container.addEventListener('change', handleFileUploadChange);

      function handleFileUploadChange(event: Event) {
        const eventTarget = event.target as HTMLElement;

        if (eventTarget.hasAttribute(`data-${name}-file-upload`)) {
          const input = eventTarget as HTMLInputElement;
          const parent = input.parentElement;
          const files = Array.from(input.files);

          if (parent) {
            const fileList = parent.querySelector<HTMLUListElement>(
              `[data-${name}-file-upload-names]`
            );
            const selectButton = parent.querySelector<HTMLButtonElement>(
              `[data-${name}-file-upload-select]`
            );
            const revertButton = parent.querySelector<HTMLButtonElement>(
              '[data-r18-ui-file-upload-revert]'
            );

            if (fileList) {
              fileList.innerHTML = files
                .map(file => `<li>${file.name}</li>`)
                .join('\n');
            }

            if (selectButton) {
              selectButton.textContent = actionModelTitle(
                files.length === 0 ? 'select' : 'select_new',
                'file'
              );
            }

            if (revertButton) {
              revertButton.classList.remove(STATES.isHidden);
            }
          }
        }
      }

      container.removeEventListener('click', handleFileDeleteClick);
      container.addEventListener('click', handleFileDeleteClick);

      function handleFileDeleteClick(
        event: MouseEvent & { target: HTMLElement }
      ) {
        let $eventTarget = $(event.target);

        if (!$eventTarget.attr(`data-${name}-file-delete`)) {
          $eventTarget = $eventTarget.parents(`[data-${name}-file-delete]`);
        }

        if ($eventTarget.length) {
          event.stopImmediatePropagation();
          event.preventDefault();

          const $wrapper = $eventTarget.parents(`[data-${name}-file]`);

          $wrapper.toggleClass(STATES.isRemoved);
        }
      }
    },
    createOverlayEvents: () => {
      const selector = `data-${name}-create-overlay`;

      container.removeEventListener('click', handleCreateOverlayClick);
      container.addEventListener('click', handleCreateOverlayClick);

      async function handleCreateOverlayClick(event: MouseEvent) {
        let $eventTarget = $(event.target);

        if (!$eventTarget.attr(selector)) {
          $eventTarget = $eventTarget.parents(`[${selector}]`);
        }

        if ($eventTarget.length) {
          event.stopImmediatePropagation();
          event.preventDefault();

          // prevent double-clicks
          if ($eventTarget.hasClass(STATES.isActive)) return false;

          let title, icon;

          $eventTarget.addClass(STATES.isActive);

          if ($eventTarget.data('r18UiCreateOverlayParams')) {
            const parsedOptions = parseOptions<{ title: string; icon: string }>(
              $eventTarget.data('r18UiCreateOverlayParams')
            );

            title = parsedOptions.title;
            icon = parsedOptions.icon;
          } else if (
            $eventTarget.data('r18UiCreateOverlayTitle') &&
            $eventTarget.data('r18UiCreateOverlayIcon')
          ) {
            title = $eventTarget.data('r18UiCreateOverlayTitle');
            icon = $eventTarget.data('r18UiCreateOverlayIcon');
          }

          const size = $eventTarget.data('r18UiCreateOverlay');
          const url = $eventTarget.attr('href');

          const overlayInstance = await overlay({
            title,
            icon,
            size,
            url
          });

          const overlayElement = await overlayInstance.create();

          $eventTarget.removeClass(STATES.isActive);
        }
      }
    },
    setAttrEvents: () => {
      ['change', 'click'].forEach(e => {
        container.removeEventListener(e, setAttrEventHandler);
        container.addEventListener(e, setAttrEventHandler);
      });

      function setAttrEventHandler(
        event: Event & { target: HTMLInputElement }
      ) {
        const $eventTarget = $(event.target);

        if ($eventTarget.attr(`data-${name}-set-attr`)) {
          setAttr<HTMLInputElement>(
            $eventTarget[0] as HTMLInputElement,
            targets.setAttrOutput
          ).init();
        }
      }
    },
    smoothScrollInit: () => {
      const wrapper = document.querySelector('.wrapper');

      // TODO: this is duplicated in r18.lockScrolling. come up with a better way to share;
      const topMargin = wrapper
        ? parseInt(window.getComputedStyle(wrapper).paddingTop, 10)
        : 0;

      let instances: Array<FoundationSites.SmoothScroll> = [];

      targets.smoothScroll.forEach(target => {
        instances.push(
          new SmoothScroll($(target), {
            offset: topMargin
          })
        );
      });
    },
    submitFormEvents: () => {
      container.removeEventListener('click', handleSubmitFormClick);
      container.addEventListener('click', handleSubmitFormClick);

      function handleSubmitFormClick(event: MouseEvent) {
        const $eventTarget = $(event.target);

        if ($eventTarget.attr(`data-${name}-submit-form`)) {
          event.stopImmediatePropagation();
          event.preventDefault();

          const button = $eventTarget[0] as HTMLInputElement;
          const originalLabel = button.value;

          button.value = button.dataset.disableWith || I18n.t('global.labels.please_wait');

          const targetForm = container.querySelector<HTMLFormElement>(
            `form[name=${$eventTarget.data('r18UiSubmitForm').toString()}]`
          );

          const targetFormResponse = targetForm.dataset.r18UiSubmitFormResponse;
          const preserveOverlay =
            button.dataset.r18UiSubmitFormPreserveOverlay === 'true';

          if (targetForm) {
            let preserveOverlayField = targetForm.querySelector<
              HTMLInputElement
            >('[name="preserve_overlay"]');

            if (preserveOverlayField) {
              preserveOverlayField.parentElement.removeChild(
                preserveOverlayField
              );
            }

            if (preserveOverlay) {
              preserveOverlayField = document.createElement('input');
              preserveOverlayField.name = 'preserve_overlay';
              preserveOverlayField.type = 'hidden';
              preserveOverlayField.value = 'true';

              targetForm.prepend(preserveOverlayField);
            }

            const buttons = targetForm.querySelectorAll<
              HTMLInputElement | HTMLButtonElement
            >('.button');

            buttons.forEach(button => {
              button.disabled = true;
            });

            processForm(
              targetForm.action,
              targetForm,
              (response: any) => {
                const formOverlay = findParentNodeByClass(
                  targetForm,
                  'overlay'
                );

                if (
                  formOverlay &&
                  targetForm.dataset.r18UiSubmitFormCloseOverlay
                ) {
                  overlay({}).destroy(formOverlay);
                } else {
                  button.value = originalLabel;

                  buttons.forEach(button => {
                    button.disabled = false;
                  });
                }

                if (targetFormResponse === 'js') {
                  const responseFunction = new Function(response);
                  responseFunction()
                }
              },
              (error: any) => {
                button.value = originalLabel;
                button.disabled = false;
              },
              {
                responseType: targetFormResponse === 'json' ? 'json' : 'text',
                submitter: { name: button.name, value: originalLabel }
              }
            );
          }
        }
      }
    },
    toggleFieldEvents: () => {
      container.removeEventListener('change', handleToggleFieldChange);
      container.addEventListener('change', handleToggleFieldChange);

      function handleToggleFieldChange(event: Event) {
        const input = event.target as HTMLSelectElement | HTMLInputElement;
        const value = input.type === 'checkbox' ? (input as HTMLInputElement).checked.toString() : input.value;

        const toggleField = input.dataset.r18UiToggleField;
        const targetFields = document.querySelectorAll<HTMLInputElement>(
          `[data-r18-ui-toggle-field-target="${toggleField}"]`
        );
        const targetLabels = document.querySelectorAll<HTMLLabelElement>(
          `[data-r18-ui-toggle-field-label="${toggleField}"]`
        );

        // TODO: optimize this
        Array.from(targetFields).forEach(field => {
          const fieldCondition = field.dataset.r18UiToggleFieldTargetCondition;
          const isMatch = fieldCondition === value;
          const shouldDisableAndHide = startsWith(fieldCondition, '!')
            ? fieldCondition.substring(1) === value
            : fieldCondition !== value;

          field.disabled = shouldDisableAndHide;
          field.classList.toggle(STATES.isHidden, shouldDisableAndHide);
        });

        Array.from(targetLabels).forEach(label => {
          const fieldCondition = label.dataset.r18UiToggleFieldTargetCondition;
          const isMatch = fieldCondition === value;
          const shouldDisableAndHide = startsWith(fieldCondition, '!')
            ? fieldCondition.substring(1) === value
            : fieldCondition !== value;

          label.classList.toggle(STATES.isHidden, shouldDisableAndHide);
        });
      }
    },
    validateFormEvents: () => {
      container.removeEventListener('change', handleValidateFormChange);
      container.addEventListener('change', handleValidateFormChange);

      window.removeEventListener(
        'userInterface.validateForm',
        handleValidateFormChange
      );
      window.addEventListener(
        'userInterface.validateForm',
        handleValidateFormChange
      );

      function handleValidateFormChange(
        event: Event & CustomEvent & { detail: HTMLElement }
      ) {
        const eventTarget: HTMLElement = event.detail || event.target;
        const parentForm = findParentNodeByDataAttribute(
          eventTarget,
          'data-r18-ui-validate-form'
        );

        if (parentForm) {
          validateForm(parentForm as HTMLFormElement);
        }
      }

      Array.from(
        document.querySelectorAll<HTMLFormElement>(
          '[data-r18-ui-validate-form]'
        )
      ).forEach(form => validateForm(form));
    },
    verifyModalEvents: () => {
      const selector = `data-${name}-verify-modal`;
      let revealOverlay: HTMLDivElement;

      container.addEventListener('click', handleVerifyModalClick);

      function handleVerifyModalClick(event: MouseEvent) {
        let $eventTarget = $(event.target);

        if (!$eventTarget.attr(selector)) {
          $eventTarget = $eventTarget.parents(`[${selector}]`);
        }

        if ($eventTarget.length) {
          const confirmClickEvent = new CustomEvent('confirmClick', {
            detail: {
              originalTarget: $eventTarget[0]
            }
          });

          event.preventDefault();
          event.stopPropagation();

          const modalId = $eventTarget.data('r18UiVerifyModal');
          const $modal = $(`#modal_${modalId}`);
          const modalInstance = new Reveal($modal, {
            closeOnClick: false,
            closeOnEsc: false
          });

          $modal.on('open.zf.reveal', openEvent => {
            const transform = document.body.style.transform;
            revealOverlay = last(
              Array.from(
                document.querySelectorAll<HTMLDivElement>('.reveal-overlay')
              )
            );

            /*
              There is a very good chance this modal might open from within an overlay,
              which means a negative transform will have been applied to the body to compensate
              for scrolling. Here we retrieve that transform, invert the pixel distance and
              apply a reverse transform to the reveal container to position it appropriately.
             */
            if (revealOverlay && transform) {
              const transformDistance = parseInt(transform.match(/\d+/)[0], 10);

              revealOverlay.style.transform = transform.replace(
                /-\d+/,
                transformDistance.toString()
              );
            }

            // Foundation is applying a `top` value to the html element which equals the current scroll position.
            // however, it ALSO applies a class that adds fixed positioning. Consequently, if the modal
            // triggered from further down the page, the entire page is visually shifted up. This is a hack
            // to override that behavior.
            // document.documentElement.style.top = `-${window.scrollY}px`;

            $modal.find('[data-modal-confirm]').one('click', confirmEvent => {
              modalInstance?.close();
              modalInstance?.$overlay.remove();
              modalInstance?.destroy();
              window.requestAnimationFrame(() => {
                container.dispatchEvent(confirmClickEvent);
              });
            });

            $modal.find('[data-modal-cancel]').one('click', cancelClick => {
              modalInstance?.close();
              modalInstance?.$overlay.remove();
              modalInstance?.destroy();
            });
          });

          modalInstance.open();
        }
      }

      container.addEventListener('confirmClick', (event: CustomEvent) => {
        const originalTarget = event.detail.originalTarget as HTMLElement;
        const clickType = originalTarget.dataset.r18UiVerifyModalClick;

        switch (clickType) {
          case 'href':
            // load a new page
            window.requestAnimationFrame(() => {
              document.location.href = (originalTarget as HTMLAnchorElement).href;
            });
            break;
          case 'closeOverlay':
            window.requestAnimationFrame(() => {
              window.R18.utils.closeCurrentOverlay();
            });
            break;
          default:
            // trigger the original click as a Rails event to see what happens!
            $(originalTarget).trigger('click.rails');
        }
      });
    }
  };

  return new Controller({ name, targets, events });
}

/**
 * Execute start-up UI scripts. A catch-all for loose stuff, I guess.
 *
 * @param container
 * @param targets
 */
function _initUserInterface(
  targets: fw.TargetsObject,
  container: HTMLElement = document.body
) {
  disable(container).init();
  if (window.location.search.length > 0) {
    document.body.scrollTop =
      document.body.scrollTop -
      document.querySelector<HTMLElement>('.nav-wrapper').clientHeight;

    const scrollEvent = new Event('scroll');
    window.dispatchEvent(scrollEvent);
  }

  let completed_pdf = getParameterByName('pdf_record', window.location.href);
  if (completed_pdf) {
    window.open(completed_pdf, '_blank');
  }

  if (targets) {
    targets.fixedTable.forEach((target: HTMLTableElement) => {
      fixedTable(target);
    });

    targets.timeZone.forEach(target => {
      const formatString = target.dataset.r18UiTimeZone;
      const offset = new Date().getTimezoneOffset();
      let date = parse(target.textContent);

      if (offset > 0) {
        date = subMinutes(date, offset);
      } else if (offset < 0) {
        date = addMinutes(date, offset);
      }

      target.textContent = format(date, formatString);
      target.classList.remove(STATES.isHidden);
    });

    targets.setScrollPosition.forEach(target => {
      const scrollTarget = target.querySelector<HTMLElement>(
        `[data-r18-ui-set-scroll-position-target]`
      );

      if (scrollTarget) {
        target.scrollTop = scrollTarget.offsetTop;
      }
    });
  }

  if (document.querySelector('[data-r18-ui-flash-message]')) {
    setTimeout(() => {
      $('[data-r18-ui-flash-message]').fadeOut(400);
    }, 2500);
  }

  $(document)
    .off('change', '#labSelect')
    .on('change', '#labSelect', (event: Event) => {
      const $labSelect = $(event.target);
      const $globalRoleSelect = $('#globalRoleSelect');
      const $hasAtLeastOnePosition = $('#hasAtLeastOnePosition');
      const $onInvoiceCheckbox = $('#onInvoiceCheckbox');

      const showLabPosition =
        $labSelect.val() === '2' || $labSelect.val() === '5';

      if ($globalRoleSelect.length) {
        $globalRoleSelect.prop('required', showLabPosition);
        $globalRoleSelect
          .parents('.columns')
          .toggleClass('is-hidden', !showLabPosition);

        $globalRoleSelect.trigger('change');
      }

      if ($hasAtLeastOnePosition.length) {
        $hasAtLeastOnePosition.prop('required', !showLabPosition);
      }

      if ($onInvoiceCheckbox.length) {
        $onInvoiceCheckbox.toggleClass('is-hidden', $labSelect.val() !== '2');
      }
    });

  window.addEventListener(
    'fileListing.updateFileList',
    (event: CustomEvent & { detail: Array<IFile> }) => {
      const $primaryFileIdsField = $('#primary_file_ids');
      const fileIds = event.detail.map((file: IFile) => file.id);

      if ($primaryFileIdsField.length) {
        $primaryFileIdsField.val(JSON.stringify(fileIds));
      }
    }
  );

  // attach appropriate state to create-overlay buttons
  forEach(
    document.querySelectorAll<HTMLAnchorElement | HTMLButtonElement>(
      '[data-r18-ui-create-overlay]'
    ),
    e => {
      e.classList.add('has-spinner');

      if (e instanceof HTMLButtonElement) {
        e.disabled = false;
      } else {
        e.classList.remove('disabled');
      }
    }
  );

  $(document)
    .off('change', '#labSetupCopyQms')
    .on('change', '#labSetupCopyQms', (event: Event) => {
      const button = document.querySelector<HTMLAnchorElement>(
        '#labSetupSkipSetup'
      );
      const checkbox = event.target as HTMLInputElement;
      const queryParam = '&copy_qms=true';

      if (button) {
        let url = button.href.replace(queryParam, '');

        if (checkbox.checked) {
          url = url + queryParam;
        }

        button.href = url;
      }
    });

  $(document)
    .off('click', '#exportOrgChart')
    .on('click', '#exportOrgChart', event => {
      event.preventDefault();
      const $target = $(event.currentTarget);
      const $orgChart = $('.orgchart:not(".hidden")');
      const labName = $target.data('labName');

      if ($orgChart.length) {
        exportOrgChart({
          chart: $orgChart.get(0),
          labName,
          generatePdf: true,
          callback: () => {
            // time for some amazing jQuery
            const $parentDropdown = $orgChart.parents('.dropdown-pane');
            const commonId = $parentDropdown.attr('id');
            const $button = $(`[data-toggle="${commonId}"]`);

            if ($button.length) {
              $button.foundation('close');
            }
          }
        });
      }
    });

  // undo the inline styling applied in the <head />
  requestAnimationFrame(() => {
    $('.accordion-menu > li').attr(
      'style',
      'display: block !important; opacity: 1;'
    );
  });
}

/**
 * Initialize scripting for a given container.
 *
 * @param container
 */
function _initializeContainer(container: HTMLElement = document.body) {
  $(container).foundation();
  fw(controllers).initialize(container);
  _initPlugins(container);
}

/**
 * Initialize all plugins on the current page, or within a given element.
 *
 * @param {HTMLElement} [container=document.body] The container to operate on.
 */
function _initPlugins(container: HTMLElement = document.body) {
  [ckEditor, select2].forEach(plugin => plugin.call(undefined, container));
}

/**
 * Detroy all plugins on the current page, or within a given element.
 *
 * @param {HTMLElement} [container=document.body] The container to operate on.
 */
function _destroyPlugins(container: HTMLElement = document.body) {}
