import { Controller } from 'framewerk';
import { STATES, actionModelTitle, executeFetch, executeGet } from '../shared';

interface EquipmentRecordSearchResult {
  id: number;
  name: string;
  mfg: string;
  model_number: string;
  reference_or_serial: string;
}

/**
 * The initialization function for creating the `r18-equipment-procedure` Controller.
 */
export function equipmentProcedure(): Controller {
  /**
   * Internal counter for tracking the number of added files.
   */
  let procedureFileCount = 0;

  /**
   * The name of the controller.
   */
  const name: string = 'r18-equipment-procedure';

  /**
   * Selector strings for the `r18-equipment-procedure` Controller.
   */
  const targets = Controller.getTargets({
    container: `[data-controller="${name}"]`,
    control: `[data-${name}-control]`,
    recordsPath: `[data-${name}-records-path]`,
    historyForm: `[data-${name}-history-form]`,
    fileList: `[data-${name}-file-list]`,
    addHistory: `[data-${name}-add-history]`,
    addFile: `[data-${name}-add-file]`,
    signatureField: `[data-${name}-signature-field]`,
    signatureImage: `[data-${name}-signature-image]`,
    useSignatureImage: `[data-${name}-use-signature-image]`
  });

  /**
   * Events created for the `r18-equipment-procedure` Controller.
   */
  const events = {
    controlChangeEvents: () => {
      targets.control.forEach(control => {
        // have to use jQuery here to interface with Select2
        $(control).on('change', async event => {
          const eventTarget = event.target as HTMLElement;

          switch (eventTarget.dataset.r18EquipmentProcedureControl) {
            case 'measurementStandard':
              const values = $(eventTarget).val();
              try {
                const records = await retrieveRecords(values);
                if (records.length === 0) return;

                const table = createEquipmentTable(records);
                const select2Field = eventTarget.nextElementSibling;

                select2Field.nextElementSibling?.remove();
                select2Field.insertAdjacentElement('afterend', table);
              } catch (error) {}
              break;
          }
        });
      });
    },
    addHistoryEvents: () => {
      targets.container[0].addEventListener('click', event => {
        const eventTarget = event.target as HTMLElement;

        if (eventTarget.hasAttribute(`data-${name}-add-history`)) {
          const historyForm = targets.container[0].querySelector(
            `[data-${name}-history-form`
          );
          const labels = eventTarget.dataset.r18EquipmentProcedureToggleLabels.split(
            '|'
          );

          eventTarget.textContent = labels.find(
            label => label !== eventTarget.textContent
          );

          if (historyForm) {
            historyForm.classList.toggle(STATES.isHidden);
          }
        }
      });
    },
    fileListEvents: () => {
      targets.addFile[0].addEventListener('click', event => {
        targets.fileList[0].insertAdjacentHTML('beforeend', createFileInput());
        procedureFileCount++;
      });

      targets.fileList[0].addEventListener('click', event => {
        const eventTarget = event.target as HTMLButtonElement;

        if (eventTarget.hasAttribute('data-r18-equipment-procedure-remove')) {
          const parent = eventTarget.parentElement;
          const input = parent.querySelector<HTMLInputElement>(
            'input[type="file"]'
          );

          parent.classList.add(STATES.isHidden);
          input.disabled = true;
        }
      });
    },
    useSignatureEvents: () => {
      targets.useSignatureImage[0]?.addEventListener('change', event => {
        const eventTarget = event.target as HTMLInputElement;

        setSignatureImageState(eventTarget.checked);
      });
    }
  };

  return new Controller({ name, targets, events });

  /////////////////

  function setSignatureImageState(isChecked: boolean) {
    const image = targets.signatureImage[0] as HTMLImageElement;
    const field = targets.signatureField[0] as HTMLInputElement;

    image.classList.toggle(STATES.isHidden, !isChecked);
    field.classList.toggle(STATES.isHidden, isChecked);
    field.disabled = isChecked;
  }

  async function retrieveRecords(
    values: string | number | string[] | undefined
  ) {
    const baseUrl = (targets.recordsPath[0] as HTMLInputElement).value;
    const params = `equipment_record_ids=${JSON.stringify(values)}`;

    try {
      return await executeGet(`${baseUrl}?${params}`);
    } catch (error) {}
  }

  /**
   * Produces a table of equipment data.
   *
   * @param {*} data
   * @returns {HTMLElement}
   */
  function createEquipmentTable(data: Array<EquipmentRecordSearchResult>) {
    const TABLE_COLS: Array<{ title: string; key: string }> = [
      {
        title: 'Equipment Name',
        key: 'name'
      },
      {
        title: 'Mfg.',
        key: 'mfg'
      },
      {
        title: 'Model',
        key: 'model_number'
      },
      {
        title: 'Serial # or Reference ID',
        key: 'reference_or_serial'
      }
    ];

    const table = document.createElement('table');

    table.style.marginTop = '20px';
    table.style.width = '100%';

    const thead = document.createElement('thead');
    const theadTr = document.createElement('tr');

    TABLE_COLS.forEach(col => {
      const th = document.createElement('th');

      th.innerHTML = col.title;
      theadTr.appendChild(th);
    });

    thead.appendChild(theadTr);

    const tbody = document.createElement('tbody');

    data.forEach((row: any) => {
      const tbodyTr = document.createElement('tr');

      TABLE_COLS.forEach(col => {
        const td = document.createElement('td');

        td.innerHTML = row[col.key];
        tbodyTr.appendChild(td);
      });

      tbody.appendChild(tbodyTr);
    });

    table.appendChild(thead);
    table.appendChild(tbody);

    return table;
  }

  /**
   * Generate a single file input.
   *
   * @returns {string}
   */
  function createFileInput() {
    const identifier = `multi_file_${procedureFileCount}`;

    return `
      <div class="file-upload file-upload--single">
        <button
          type="button"
          class="button alert hollow"
          data-r18-ui-file-upload-revert
          data-r18-equipment-procedure-remove="${procedureFileCount}">
          ${actionModelTitle('remove', 'file')}
        </button>
        <label
          for="${identifier}"
          class="button secondary hollow"
          data-r18-ui-file-upload-select>
          ${actionModelTitle('select', 'file')}
        </label>
        <input
          type="file"
          name="${identifier}"
          id="${identifier}"
          class="file-upload__input show-for-sr"
          data-r18-ui-file-upload>
        <ul class="file-upload__names" data-r18-ui-file-upload-names></ul>
      </div>
    `;
  }
}
