import * as React from 'react';

import { Icon, Loading } from '../common';
import { componentClasses } from '../../shared';

interface OverlayContentProps {
  title: string;
  icon: string;
  alignTop?: boolean;
  controls?: Array<IWidgetControl>;
  children?: React.ReactNode;
  isLoading?: boolean;
  closeOverlay(overlay: string): void;
}

/**
 * A stateless component for rendering content within the overlay content
 * container.
 */
export const OverlayContent = ({
  title,
  icon,
  alignTop,
  controls,
  isLoading,
  children,
  closeOverlay
}: OverlayContentProps) => {
  return (
    <div className="card overlay-content">
      <div className="card-divider overlay-content__title">
        <div className="overlay-content-title">
          <div className="overlay-content-title__icon">
            <Icon icon={icon} size="medium" />
          </div>
          <span className="overlay-content-title__text">{title}</span>
          <a
            role="button"
            className="overlay-content-title__button"
            onClick={closeOverlay.bind(undefined, '')}>
            <Icon icon="shrink" size="medium" />
          </a>
        </div>
      </div>
      <div
        className={`card-section overlay-content__window ${
          alignTop ? 'overlay-content__window--align-top' : ''
        }`}>
        {isLoading && (
          <div className="push--top push--bottom">
            <Loading strokeColor="#a1a1a1" />
          </div>
        )}
        {!isLoading && children}
      </div>
    </div>
  );
};
