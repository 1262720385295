import { STATES, safeOverlayPosition } from '../shared';

/**
 * A module for identifying and locking the current page's scroll position
 * when an overlay is opened, then reverting it afterward.
 *
 * @export
 */
export function lockScrolling() {
  /**
   * The active scroll position.
   * @private
   */
  let scrollPosition: number;

  /**
   * A top margin value to apply when necessary.
   * @private
   */
  const topMargin = getTopMargin();

  return {
    getScrollPosition,
    setState,
    cachePosition,
    getTopMargin
  };

  /**
   * Get the current cached scroll position.
   *
   * @return {number}
   */
  function getScrollPosition() {
    return scrollPosition;
  }

  /**
   * Sets a locked or unlocked state.
   *
   * @param isLocked Is scrolling to be locked?
   */
  function setState(isLocked: boolean) {
    const wrapper =
      document.querySelector<HTMLElement>('[data-r18-overlay-wrapper]') ??
      document.body;
    const navWrapper = document.querySelector<HTMLElement>('.nav-wrapper');
    const qmControls = document.getElementById('qmControls');
    const topPosition = scrollPosition - topMargin;
    const overlayPosition = safeOverlayPosition(scrollPosition - topMargin);

    /*
     Here we set three potential document states. All three are wrapped in Promises
     so we can ensure all three have completed before firing a status event.
     */

    const setDocumentState = new Promise<void>(resolve => {
      /*
        Toggle the `hasOverlay` state on both the body and the wrapper element to
        add or remove `position: fixed` and `overflow: hidden`. This prevents
        the background from scrolling, while allowing the overlay to scroll.
      */
      wrapper.classList.toggle(STATES.hasOverlay, isLocked);
      document.body.classList.toggle(STATES.hasOverlay, isLocked);

      /*
        If we try to set `scrollTop` on the document while the body has fixed position,
        it won't be retained. To simulate this, we apply a negative vertical transform to
        the body while the overlay is open, using the cached scroll position. If the
        overlay is closed, just remove this value.
      */
      wrapper.style.transform = isLocked ? `translateY(-${topPosition}px)` : '';

      /*
        We do, however, want to set `scrollTop` anyway. If the overlay is shown, this will
        be ignored; if it is hidden, this will adjust the scroll position of the document
        the exact opposite amount of the negative transform we just removed. The end result
        is the page will appear to retain its original scroll position.
      */
      document.documentElement.scrollTop = topPosition;

      resolve();
    });

    const setNavWrapperState = new Promise<void>(resolve => {
      /*
        We also have to adjust the position of the fixed navigation at the top (if present)
        or else it will vanish while the overlay is open. :/
       */
      if (navWrapper) {
        navWrapper.style.top = isLocked ? overlayPosition.top : '';

        if (overlayPosition.marginTop) {
          navWrapper.style.marginTop = isLocked
            ? overlayPosition.marginTop
            : '';
        }

        resolve();
      }
    });

    const setQmControlsState = new Promise<void>(resolve => {
      /*
        And sometimes there is a special nav for the Quality Manual.
        If there isn't we resolve right away.
       */
      if (!qmControls) {
        resolve();
      } else {
        const navWrapperHeight = navWrapper.clientHeight;

        const qmControlsMarginTop =
          overlayPosition.marginTop && isLocked
            ? overlayPosition.marginTop
            : '';

        const qmControlsTop = isLocked
          ? `${
              parseInt(overlayPosition.top) +
              navWrapperHeight +
              qmControls.clientHeight
            }px`
          : '';

        qmControls.classList.add('no-transition');

        window.requestAnimationFrame(() => {
          qmControls.style.top = qmControlsTop;
          qmControls.style.marginTop = qmControlsMarginTop;

          setTimeout(() => {
            qmControls.classList.remove('no-transition');

            resolve();
          }, 750);
        });
      }
    });

    Promise.all([
      setDocumentState,
      setNavWrapperState,
      setQmControlsState
    ]).then(() => {
      window.dispatchEvent(
        new CustomEvent('lockScrolling.setState', {
          detail: isLocked
        })
      );
    });
  }

  /**
   * Store the current scroll position of the document.
   */
  function cachePosition() {
    scrollPosition = document.documentElement.scrollTop + topMargin;
  }

  /**
   * Check for a wrapper, then get the top paddding value if found.
   *
   * @return {number}
   */
  function getTopMargin() {
    const wrapper = document.querySelector<HTMLElement>('.wrapper');

    return wrapper
      ? parseInt(window.getComputedStyle(wrapper).paddingTop, 10)
      : 0;
  }
}
